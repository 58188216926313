import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { TranslationProvider } from "translation";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import ToastProvider from "components/Toast/ToastProvider";
import GlobalLoader from "components/loader/GlobalLoader";
import { persistor, store } from "store/store";

import App from "./App";
import "./index.css";
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
    <PersistGate loading={<GlobalLoader />} persistor={persistor}>
      <TranslationProvider>
        <ToastProvider>
          <QueryClientProvider client={queryClient}>
            <App />
            {process.env.NODE_ENV === "development" && (
              <ReactQueryDevtools initialIsOpen={false} />
            )}
          </QueryClientProvider>
        </ToastProvider>
      </TranslationProvider>
    </PersistGate>
  </Provider>
);
