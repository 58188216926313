import {
  changePasswordAPI,
  resetPasswordAPI,
  setCurrentUserAPI,
  signInAPI,
  signOutAPI,
} from "api/auth/auth";
import { AxiosResponse } from "axios";
import { COUNTRY } from "config";
import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  changePassword,
  resetPassword,
  setAccessToken,
  setCurrentUser,
  setErrorMessage,
  setForgotPasswordEmailSent,
  setIdToken,
  setLoadingValue,
  setPasswordCreated,
  setRefreshToken,
  setUser,
  signIn,
  signOut,
} from "store/features/authSlice";
import { RootState } from "store/store";

export function* signInSaga(action) {
  try {
    const response: AxiosResponse<AxiosCommonResponse<SignInResponse>> =
      yield call(signInAPI, action.payload);
    if (response?.status === 200 && response?.data?.success) {
      yield put(setRefreshToken(response.data.results.refresh_token));
      yield put(setIdToken(response.data.results.id_token));
      yield put(setAccessToken(response.data.results.access_token));
      yield put(setCurrentUser());
      yield put(setErrorMessage(""));
    } else {
      yield put(setLoadingValue(false));
      yield put(
        setErrorMessage(
          COUNTRY === "br"
            ? "E-mail ou senha inválida."
            : COUNTRY === "mx"
            ? "Correo electrónico o contraseña no válidos."
            : "Invalid email or password."
        )
      );
    }
  } catch (e) {
    console.error(e);
  }
}

export function* signOutSaga() {
  try {
    const response: AxiosResponse<AxiosCommonResponse<string>> = yield call(
      signOutAPI
    );
    if (response?.status === 200 && response?.data?.success) {
      yield put(setAccessToken(null));
      yield put(setRefreshToken(null));
      yield put(setIdToken(null));
      yield put(setUser(null));
    } else {
      yield put(setErrorMessage("Error occured while signing out."));
    }
  } catch (e) {
    console.error(e);
  } finally {
    yield put(setLoadingValue(false));
  }
}

export function* changePasswordSaga(action) {
  try {
    const response: AxiosResponse<AxiosCommonResponse<string>> = yield call(
      changePasswordAPI,
      action.payload
    );
    const refresh_token = yield select(
      (state: RootState) => state?.auth?.refreshToken
    );
    if (response?.status === 200 && response?.data?.success) {
      if (refresh_token) {
        yield put(signOut());
      }
      yield put(setPasswordCreated(true));
    }
  } catch (e) {
    console.error(e);
    yield put(
      setErrorMessage(
        e?.response?.data?.detail?.[0]?.msg ??
          "Error occured while changing password."
      )
    );
  } finally {
    yield put(setLoadingValue(false));
  }
}

export function* resetPasswordSaga(action) {
  try {
    yield call(resetPasswordAPI, action.payload);
    yield put(setForgotPasswordEmailSent(true));
  } catch (e) {
    console.error(e);
  } finally {
    yield put(setLoadingValue(false));
  }
}

export function* setCurrentUserSaga(action) {
  try {
    const userResponse: AxiosResponse<AxiosCommonResponse<PortalAccount>> =
      yield call(setCurrentUserAPI);

    if (userResponse.status === 200 && userResponse.data.success) {
      yield put(setUser(userResponse.data.results));
    }
  } catch (e) {
    console.error(e);
  } finally {
    yield put(setLoadingValue(false));
  }
}

export default function* authSaga() {
  yield takeLatest(signIn.type, signInSaga);
  yield takeLatest(signOut.type, signOutSaga);
  yield takeLatest(changePassword.type, changePasswordSaga);
  yield takeLatest(resetPassword.type, resetPasswordSaga);
  yield takeLatest(setCurrentUser.type, setCurrentUserSaga);
}
