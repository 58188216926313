import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { ToastCtx } from "components/Toast/ToastProvider";
import AnimatedButton from "components/custom/AnimatedButton";
import { verifyUserRegistration } from "modules/Auth/api";
import { IVerifyUser } from "modules/Auth/types";
import React, { useContext, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useT } from "translation";
const VerifyRegistration: React.FC = () => {
  const { t } = useT();
  const { encodedInfo } = useParams<{ encodedInfo: string }>();
  const { setToast } = useContext(ToastCtx);
  const navigate = useNavigate();

  const verifyUserMutation = useMutation<unknown, any, IVerifyUser>({
    mutationFn: verifyUserRegistration,
    onSuccess: () => {
      navigate("/v2/login");
      setToast({
        severity: "success",
        text: t("DONE_VERIFICATION_ALERT"),
      });
    },
    onError: (err) => {
      const errorCode = err?.error?.code || "";
      if (errorCode === "user_already_exists") {
        setToast({
          severity: "success",
          text: t("USER_ALREADY_REGISTERED"),
        });
        navigate("/v2/login");
        return;
      }
      setToast({
        severity: "error",
        text: t("SOMETHING_WENT_WRONG"),
      });
    },
  });

  useEffect(() => {
    if (!encodedInfo) {
      return;
    }

    if (verifyUserMutation.status !== "idle") {
      return;
    }
    const decodedToken = atob(encodedInfo);
    try {
      const userDetails = JSON.parse(decodedToken) as IVerifyUser;
      verifyUserMutation.mutate(userDetails);
    } catch {
      navigate("/v2/register");
      setToast({
        text: t("SOMETHING_WENT_WRONG"),
        severity: "error",
      });
    }
  }, [encodedInfo, navigate, setToast, verifyUserMutation, t]);

  const renderView = () => {
    if (verifyUserMutation.isError) {
      return (
        <>
          {" "}
          <CancelIcon color="error" fontSize="large" />
          <Typography sx={{ fontSize: "24px", textAlign: "center" }}>
            {t("SOMETHING_WENT_WRONG")}
          </Typography>
          <AnimatedButton variant="outlined" component={Link} href="/v2/login">
            {t("LOGIN")}
          </AnimatedButton>
        </>
      );
    } else if (verifyUserMutation.isSuccess) {
      return (
        <>
          {" "}
          <CheckCircleIcon color="success" fontSize="large" />
          <Typography sx={{ fontSize: "24px", textAlign: "center" }}>
            {t("DONE_VERIFICATION")}
          </Typography>
          <AnimatedButton variant="outlined" component={Link} href="/v2/login">
            {t("LOGIN")}
          </AnimatedButton>
        </>
      );
    }
    return (
      <>
        {" "}
        <CircularProgress />
        <Typography sx={{ fontSize: "24px" }}>{t("VERIFYING_USER")}</Typography>
      </>
    );
  };

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: "8px",
      }}
    >
      {renderView()}
    </Box>
  );
};

export default VerifyRegistration;
