/* eslint-disable jsx-a11y/iframe-has-title */
import { Download } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
// Import the main component
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { useSearchParams } from "react-router-dom";

// Import the styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import { LoadingSpinner } from "components/common/LoadingSpinner";
import AnimatedButton from "components/custom/AnimatedButton";
import { downloadFile, getFileNameFromURL } from "utils/file";

export function PolicyDocumentPage() {
  const [searchParams] = useSearchParams();
  const fileURL = searchParams.get("fileURL");

  return (
    <div>
      {!fileURL ? (
        <Typography color="textSecondary">Document not found.</Typography>
      ) : (
        <div>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h4">Policy</Typography>
            <AnimatedButton
              variant="contained"
              color="primary"
              startIcon={<Download />}
              onClick={() => downloadFile(fileURL, getFileNameFromURL(fileURL))}
            >
              Download
            </AnimatedButton>
          </Box>
          <div style={{ marginTop: 20 }}>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
              <Viewer
                renderLoader={() => <LoadingSpinner />}
                fileUrl={fileURL}
              />
            </Worker>
          </div>
        </div>
      )}
    </div>
  );
}
