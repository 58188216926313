import CheckIcon from "@mui/icons-material/WarningAmber";
import { Box, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

import { animateRouteProps } from "constants/common";
import { customStylesSelector } from "store/selectors/selectors";

interface EsignPendingI {}

const EsignPending: React.FC<EsignPendingI> = () => {
  const customStyles = useSelector(customStylesSelector);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        height: "80vh",
      }}
      {...animateRouteProps}
    >
      <Box
        data-cy="success-message"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          m: "auto",
        }}
      >
        <Box
          sx={{
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100px",
            width: "100px",
            background: customStyles.muiTheme.palette.primary.main,
          }}
        >
          <CheckIcon
            data-cy="success-check-icon"
            style={{
              color: customStyles.stepper.step.color,
              fontSize: "75px",
            }}
          />
        </Box>
        <Typography
          sx={{
            color: customStyles.muiTheme.palette.primary.main,
            fontSize: "24px",
            fontWeight: 700,
            mt: "30px",
          }}
        >
          Please sign your documents
        </Typography>
        <Typography
          sx={{
            color: customStyles.muiTheme.palette.text.tertiary,
            fontSize: "20px",
            fontWeight: 500,
            textAlign: "center",
            mt: "24px",
          }}
        >
          Unfortunately, we cannot issue your policy before the documents are
          signed. Please check your email, we will be sending the documents via
          email shortly
        </Typography>
      </Box>
    </Box>
  );
};

export default EsignPending;
