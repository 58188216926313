import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface SurveyJsState {
  surveyData: any;
  surveyPageNumber: number;
  surveyModelFuncs: SurveyModelFuncs;
  siaApplicationId: string;
  clientSiaId: string;
  clientLatestConfig: any;
  siaApplication: any;
  updatedSiaApplication: any;
  scriptRef: any;
  loading: boolean;
  applicant_email: string;
  submissionId: string;
  knockout: boolean;
}

const initialState: SurveyJsState = {
  surveyData: undefined,
  surveyPageNumber: 0,
  surveyModelFuncs: undefined,
  siaApplicationId: "",
  clientSiaId: "",
  clientLatestConfig: undefined,
  siaApplication: undefined,
  updatedSiaApplication: undefined,
  scriptRef: undefined,
  loading: false,
  applicant_email: "",
  submissionId: "",
  knockout: false,
};

const surveyJsSlice = createSlice({
  name: "surveyjs",
  initialState,
  reducers: {
    setSurveyData: (state, action: PayloadAction<any>) => {
      state.surveyData = action.payload;
    },
    setKnockoutFlag: (state, action: PayloadAction<any>) => {
      state.knockout = action.payload;
    },
    setSurveyPageNumber: (state, action: PayloadAction<number>) => {
      state.surveyPageNumber = action.payload;
    },
    setSurveyModelFuncs: (state, action: PayloadAction<SurveyModelFuncs>) => {
      state.surveyModelFuncs = action.payload;
    },
    fetchSIAApplicationId: (state) => {
      state.loading = true;
    },
    setSIAApplicationId: (state, action: PayloadAction<string>) => {
      state.siaApplicationId = action.payload;
      state.loading = false;
    },
    createSiaApplication(state) {
      state.loading = true;
    },
    fetchSIAApplication(state) {
      state.loading = true;
    },
    setSIAApplication: (state, action: PayloadAction<any>) => {
      state.siaApplication = action.payload;
      state.loading = false;
    },
    setLoadingValue(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    updateSIAApplication() {},
    setClientSiaId: (state, action: PayloadAction<any>) => {
      state.clientSiaId = action.payload.id;
      state.clientLatestConfig = action.payload.config;
    },
    sendClientSIALatestConfig(state, action: PayloadAction<any>) {},
    setClientSIALatestConfig(state, action: PayloadAction<any>) {
      state.clientLatestConfig = action.payload;
    },
    setUpdatedSIAApplication(state, action: PayloadAction<any>) {
      state.updatedSiaApplication = action.payload;
    },
    setScriptRef(state, action: PayloadAction<any>) {
      state.scriptRef = action.payload;
    },
    setApplicantEmail(state, action: PayloadAction<string>) {
      state.applicant_email = action.payload;
    },
    setSubmissionid(state, action: PayloadAction<string>) {
      state.submissionId = action.payload;
    },
    resetSurveyJS(state) {
      state.surveyPageNumber = 0;
      state.siaApplicationId = "";
      state.clientSiaId = "";
      state.siaApplication = undefined;
      state.updatedSiaApplication = undefined;
      state.applicant_email = "";
      state.submissionId = "";
    },
  },
});

export const {
  setSurveyData,
  setSurveyPageNumber,
  setSurveyModelFuncs,
  fetchSIAApplicationId,
  setSIAApplicationId,
  createSiaApplication,
  fetchSIAApplication,
  setSIAApplication,
  setLoadingValue,
  updateSIAApplication,
  setClientSiaId,
  sendClientSIALatestConfig,
  setClientSIALatestConfig,
  setUpdatedSIAApplication,
  setScriptRef,
  setApplicantEmail,
  setSubmissionid,
  resetSurveyJS,
  setKnockoutFlag,
} = surveyJsSlice.actions;

export default surveyJsSlice.reducer;
