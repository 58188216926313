import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import { Box, Chip, Typography } from "@mui/material";
import { ReactNode, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { animateOpacityProps } from "constants/common";
import { ClientProduct } from "customTypes/ClientProducts";
import {
  addInvalidProductCombinations,
  removeInvalidProductCombinations,
  updateStartPageSelection,
} from "store/features/clientSlice";
import { setSIAApplication } from "store/features/surveyJsSlice";
import { customStylesSelector } from "store/selectors/selectors";
import { RootState } from "store/store";
import { useT } from "translation";
import { InvalidProductCombinationsMapping } from "utils/utils";

const ProductSelectionCard = ({
  prodIcon,
  prodTitle,
  isComingSoon,
  id,
  product,
}: {
  prodIcon: ReactNode;
  prodTitle: string;
  isComingSoon: boolean;
  id: string;
  product: ClientProduct;
}) => {
  const { t } = useT();
  const {
    startPageSelection,
    invalidProductCombinations: notAllowedCombinationProducts,
  } = useSelector((state: RootState) => {
    return state.client;
  });
  const checkIfAlreadySelected = () => {
    const existingIndex = startPageSelection.findIndex(
      (obj: any) => obj.product.id === product.product.id
    );
    if (existingIndex !== -1) return true;
    return false;
  };
  const customStyles = useSelector(customStylesSelector);

  const [isSelected, setIsSelected] = useState(checkIfAlreadySelected());
  const [hasSpan, setHasSpan] = useState(false);
  const dispatch = useDispatch();
  const onProductCardClicked = () => {
    if (isComingSoon) {
      return;
    }

    setIsSelected(!isSelected);
    if (!isSelected) {
      setHasSpan(true);
    }
    setTimeout(() => {
      setHasSpan(false);
    }, 500);

    const existingIndex = startPageSelection.findIndex(
      (obj: any) => obj.product.id === product.product.id
    );

    const invalidCombinations =
      InvalidProductCombinationsMapping[product?.product?.product_identifier];

    if (existingIndex !== -1) {
      // Remove the existing object
      let newSelection = Array.from(startPageSelection);
      newSelection.splice(existingIndex, 1);
      dispatch(updateStartPageSelection(newSelection));
      if (invalidCombinations?.length) {
        invalidCombinations.forEach((p) => {
          dispatch(removeInvalidProductCombinations(p));
        });
      }
    } else {
      // Add the new object
      let newSelection = Array.from(startPageSelection);
      newSelection.push(product);
      dispatch(updateStartPageSelection(newSelection));

      if (invalidCombinations?.length) {
        invalidCombinations.forEach((p) => {
          dispatch(addInvalidProductCombinations(p));
        });
      }
    }
    dispatch(setSIAApplication(null));
  };

  const IsDisabled = notAllowedCombinationProducts.find(
    (p) => p === product?.product?.product_identifier
  );

  return (
    <Box
      {...animateOpacityProps}
      data-cy={`product-selection-card-${product?.product?.product_identifier}`}
      className="priceCard"
      onClick={() => {
        if (!IsDisabled) {
          onProductCardClicked();
        }
      }}
      sx={{
        border: "2px solid transparent",
        padding: "10px",
        borderRadius: "12px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        width: "120px",
        cursor: IsDisabled ? "auto" : "pointer",
        borderColor: isSelected
          ? customStyles.primaryCard.active.borderColor
          : "transparent",
        "&:hover": {
          borderColor: isSelected
            ? customStyles.primaryCard.active.borderColor
            : customStyles.primaryCard.hover.borderColor,
        },
        backgroundColor: customStyles.primaryCard.backgroundColor,
        transition: "0.3s",
        opacity: IsDisabled ? 0.5 : 1,
      }}
    >
      {hasSpan && <span className="ripple"></span>}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          mb: "20px",
        }}
      >
        {isComingSoon ? (
          <Chip
            color="primary"
            sx={{ fontSize: "10px" }}
            size="small"
            label="Coming Soon"
          />
        ) : null}
        <BookmarkBorderIcon style={{ color: customStyles.icons.fill }} />
      </Box>
      <Box sx={{ mt: "10px" }}>{prodIcon}</Box>
      <Box
        sx={{
          mt: "15px",
          mb: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            color: isComingSoon
              ? customStyles.muiTheme.palette.text.tertiary
              : customStyles.muiTheme.palette.text.secondary,
            fontSize: "14px",
            fontWeight: 500,
            textAlign: "center",
          }}
        >
          {prodTitle}
        </Typography>
      </Box>
    </Box>
  );
};

export default ProductSelectionCard;
