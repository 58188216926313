import { SVGProps } from "react";
import { useSelector } from "react-redux";
import { customStylesSelector } from "store/selectors/selectors";

const StereIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  const customStyles = useSelector(customStylesSelector);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="54"
      height="54"
      fill="none"
      {...props}
    >
      <path
        fill={props?.fill ?? customStyles.icons.fill}
        d="M30.798 43.66c-.023.16-.16.24-.26.343-.735.768-1.126 1.683-1.108 2.746.011.654-.012 1.304-.228 1.92-.45 1.29-1.357 2.13-2.67 2.483-2.336.631-4.678-1.08-4.814-3.493-.017-.306-.03-.613-.029-.922.004-1.082-.388-2.004-1.144-2.772-.796-.808-1.598-1.61-2.407-2.405-.766-.755-1.686-1.154-2.772-1.139-.56.008-1.122.003-1.668-.142-1.739-.46-2.922-2.084-2.856-3.924.064-1.744 1.42-3.299 3.15-3.607 2.422-.43 4.537 1.344 4.585 3.853.01.519-.029 1.04.103 1.553.172.674.49 1.268.977 1.763.844.855 1.69 1.707 2.55 2.547a3.67 3.67 0 0 0 2.159 1.042c.731.092 1.467.076 2.197.03 1.078-.067 1.95-.588 2.688-1.352.06.025.137.035.18.08.462.457.957.886 1.367 1.395ZM36.512 18.581c-1.977.1-3.904-1.663-3.873-3.888.012-.862-.028-1.704-.462-2.482a3.634 3.634 0 0 0-.585-.805 578.264 578.264 0 0 0-2.518-2.522c-.765-.758-1.686-1.149-2.769-1.132-.696.01-1.375-.03-2.043-.275-1.356-.498-2.468-2.004-2.472-3.598-.006-1.771 1.316-3.414 3.028-3.79 2.388-.52 4.674 1.298 4.691 3.746.004.465-.022.93.067 1.394.136.713.411 1.357.917 1.876.913.936 1.834 1.866 2.774 2.775.69.668 1.552.968 2.505.993.678.017 1.36-.038 2.014.217 1.473.573 2.341 1.633 2.541 3.202.27 2.122-1.185 3.846-3.096 4.249-.238.051-.48.029-.719.04Z"
      />
      <path
        fill={props?.fill ?? customStyles.icons.fill}
        d="M30.798 43.66c-.41-.51-.906-.938-1.367-1.398-.043-.044-.12-.054-.18-.08.662-.732 1.386-1.404 2.079-2.105.616-.623 1.109-1.34 1.17-2.218.088-1.252.262-2.532-.348-3.727a3.992 3.992 0 0 0-.739-1.034c-.767-.761-1.536-1.52-2.291-2.292-.845-.863-1.86-1.299-3.072-1.264-.24.007-.48-.012-.719-.019-.096-.086-.213-.05-.324-.052-1.079-.108-1.898-.67-2.538-1.507a3.548 3.548 0 0 1-.746-2.275c.013-.655 0-1.305-.217-1.937a3.793 3.793 0 0 0-.918-1.483c-.801-.801-1.61-1.6-2.404-2.41-.801-.816-1.782-1.197-2.914-1.195-.724 0-1.432-.019-2.117-.317-2.526-1.1-3.11-4.62-1.041-6.349.213.015.313.192.44.32l24.32 24.305c.19.19.32.427.52.601.537.472 1.049.973 1.554 1.478.136.135.24.285.265.48-.64.676-1.43 1.053-2.343 1.189-.472.025-.95-.07-1.418.057a3.852 3.852 0 0 0-2.473 1.152c-.612.61-1.22 1.225-1.833 1.834-.1.1-.177.24-.346.246Z"
      />
      <path
        fill={props?.fill ?? customStyles.icons.fill}
        d="M39.212 39.183c-.027-.194-.13-.344-.266-.481a33.274 33.274 0 0 0-1.554-1.478c-.2-.176-.331-.413-.521-.601-8.109-8.1-16.215-16.202-24.32-24.305-.128-.129-.228-.305-.44-.321.579-.63 1.326-.938 2.151-1.024 1.994-.208 3.693.986 4.207 2.925.138.521.155 1.056.123 1.59-.055.89.232 1.66.747 2.372.185.256.445.418.66.635.702.713 1.417 1.414 2.12 2.124.781.793 1.715 1.203 2.834 1.172.766-.02 1.522.03 2.226.374 1.402.689 2.158 1.816 2.249 3.38.023.412-.024.826.028 1.238.107.847.435 1.6 1.026 2.213.917.95 1.816 1.924 2.817 2.786.712.614 1.563.909 2.508.89.738-.015 1.468.006 2.155.32 1.315.606 2.078 1.625 2.29 3.058.11.7-.005 1.419-.328 2.05-.197.396-.373.786-.712 1.083ZM7.713 25.666c.029 2.031-1.615 3.857-3.857 3.872C1.723 29.55.019 27.803 0 25.657A3.857 3.857 0 0 1 3.842 21.8c2.126-.035 3.869 1.725 3.871 3.865ZM35.451 40.427c.468-.127.946-.032 1.419-.057-.469.129-.946.042-1.419.057ZM25.006 29.472c.11 0 .227-.035.324.052-.112.02-.226.026-.324-.052Z"
      />
    </svg>
  );
};

export default StereIcon;
