import { Box, SxProps, Theme } from "@mui/material";
import AnimatedButton from "components/custom/AnimatedButton";
import React from "react";
import { useSelector } from "react-redux";

import { customStylesSelector } from "store/selectors/selectors";

export interface CustomButtonProps {
  btnText: string | JSX.Element;
  disabled?: boolean;
  type?: "button" | "reset" | "submit";
  sx?: SxProps<Theme>;
  onClickCB?: (e?: any) => void;
  id?: string;
  data_cy?: string;
}

const CustomButton: React.FC<CustomButtonProps> = ({
  btnText,
  disabled,
  type,
  onClickCB,
  sx,
  id,
  data_cy,
}) => {
  const customStyles = useSelector(customStylesSelector);
  const getButtonStyles = () => {
    let obj: any = {};
    if (customStyles.button?.borderColor.includes("gradient")) {
      obj.backgroundImage = customStyles.button?.borderColor;
    } else {
      obj.background = customStyles.button?.borderColor;
    }
    return obj;
  };
  if (!customStyles?.button?.backgroundColor) {
    return (
      <AnimatedButton
        data_cy={data_cy}
        id={id}
        type={type}
        disabled={disabled}
        onClick={onClickCB}
        variant="contained"
        sx={{
          textTransform: "none",
          py: "10px",
          px: "20px",
          ml: "20px",
          borderRadius: "4px",
        }}
      >
        {btnText}
      </AnimatedButton>
    );
  }
  return (
    <AnimatedButton
      id={id}
      data-cy={data_cy}
      disabled={disabled}
      onClick={onClickCB}
      type={type}
      sx={{
        p: "2px",
        ml: "20px",
        borderRadius: "4px",
        boxSizing: "border-box",
        opacity: disabled ? 0.3 : 1,
        transition:
          "all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;",

        ...getButtonStyles(), // general border
        "&:hover": {
          backgroundImage: "none",
          background: "transparent",
          ...getButtonStyles(), // hover border
        },
        ...sx,
      }}
    >
      <Box
        sx={{
          textTransform: "none",
          py: "10px",
          px: "20px",
          cursor: "pointer",
          borderRadius: "4px",
          boxShadow: "none",
          width: "100%",
          transition:
            "all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;",

          background: customStyles.button?.backgroundColor, // button background
          color: customStyles.button?.color,
          "&:hover": {
            color: customStyles.button?.hover?.color,
            backgroundColor: customStyles.button?.hover?.backgroundColor,
          },
        }}
      >
        {btnText}
      </Box>
    </AnimatedButton>
  );
};

export default CustomButton;
