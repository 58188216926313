export function getFileNameFromURL(link: string) {
  const splits = link.split("/");
  const fileName = splits.pop()?.split(".")[0];

  return fileName || "document";
}

export function downloadFile(url: string, filename: string) {
  fetch(url)
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      return response.blob();
    })
    .then((blob) => {
      // Create a link element
      const link = document.createElement("a");
      // Create a URL for the blob
      const blobUrl = URL.createObjectURL(blob);
      // Set the link's href attribute to the URL of the blob
      link.href = blobUrl;
      // Set the download attribute to specify the filename
      link.setAttribute("download", filename);
      // Append the link to the body
      document.body.appendChild(link);
      // Programmatically click the link to trigger the download
      link.click();
      // Clean up by revoking the URL object
      URL.revokeObjectURL(blobUrl);
      // Remove the link from the body
      document.body.removeChild(link);
    })
    .catch((error) => {
      console.error("There was a problem with your fetch operation:", error);
    });
}
