import { MoreVert } from "@mui/icons-material";
import { Box, List, ListItemButton, ListItemText } from "@mui/material";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import AnimatedButton from "components/custom/AnimatedButton";
import { useState } from "react";

const ActionPopover = ({ listOptions }: { listOptions: ActionItem[] }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleListItemClick = (cb) => {
    cb();
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? "action-popover" : undefined;

  return (
    <Box>
      <AnimatedButton aria-describedby={id} onClick={handleClick}>
        <MoreVert
          style={{
            color: "black",
          }}
        />
      </AnimatedButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <List sx={{ p: "0px" }}>
          {listOptions.map((l) => (
            <ListItemButton
              key={l.title}
              id={l.title}
              onClick={() => {
                handleListItemClick(l.cb);
              }}
            >
              <ListItemText
                primary={
                  l.label ?? (
                    <Typography sx={{ fontSize: "16px", color: "black" }}>
                      {l.title}
                    </Typography>
                  )
                }
              />
            </ListItemButton>
          ))}
        </List>
      </Popover>
    </Box>
  );
};

export default ActionPopover;
