import { Box, Chip, Typography, useTheme } from "@mui/material";
import { ProductIconMapping } from "assets/IconMappings";

import { useT } from "translation";
import { Policy, PolicyStatus } from "types/policy";
import { capitalizeFirstLetter } from "utils/utils";

interface PolicyInfoProps {
  policy: Policy;
}

export function PolicyInfo({ policy }: PolicyInfoProps) {
  const { t } = useT();
  const theme = useTheme();
  const productName = policy.product.split("-")?.[0];

  const isPolicyActive =
    policy.status === PolicyStatus.ACTIVE ||
    policy.status === PolicyStatus.INITIATED ||
    policy.status === PolicyStatus.DOCUMENTS_GENERATED;

  const ProductIcon =
    ProductIconMapping[productName as keyof typeof ProductIconMapping];

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "12px",
          flexWrap: "wrap",
        }}
      >
        <Box
          sx={{
            display: "flex",
            bgcolor: "primary.main",
            alignItems: "center",
            py: "10px",
            px: "14px",
            borderRadius: "8px",
          }}
        >
          <ProductIcon
            fill={theme?.palette?.primary?.contrastText}
            height={25}
            width={26}
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="h4">
            {capitalizeFirstLetter(productName)}
          </Typography>
          <Typography variant="subtitle2" sx={{ color: "secondary.main" }}>
            {policy?.policy_number}
          </Typography>
        </Box>
        <Chip
          label={isPolicyActive ? t("ACTIVE") : t("INACTIVE")}
          color={isPolicyActive ? "success" : "error"}
        />
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", mt: "32px" }}>
        <Typography variant="subtitle2" sx={{ color: "secondary.main" }}>
          {t("POLICY_TERMS")}
        </Typography>
        <Typography variant="h6">{`${policy?.start_date} - ${policy?.end_date}`}</Typography>
      </Box>
    </Box>
  );
}
