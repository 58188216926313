import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import DocuSign from "components/DocuSign/DocuSign";
import { IS_BROKER_PORTAL } from "config";
import { STERE_CHECKOUT_COMPLETED } from "constants/webhookEvents";
import { fireWebhookEvent } from "store/features/webhooksSlice";
import { quotesSelector } from "store/selectors/selectors";
import { RootState } from "store/store";
import { useT } from "translation";
import SuccessMessage from "./SuccessMessage";

const Confirmation = () => {
  const { t } = useT();
  const dispatch = useDispatch();
  const { submissionId, siaApplicationId } = useSelector(
    (state: RootState) => state.surveyJs
  );
  const { selectedQuotes } = useSelector(quotesSelector);
  const { applicantId } = useSelector((state: RootState) => state.client);

  useEffect(() => {
    if (!IS_BROKER_PORTAL) {
      dispatch(
        fireWebhookEvent({
          event_type: STERE_CHECKOUT_COMPLETED,
          metadata: {
            applicantId: applicantId,
            applicationId: siaApplicationId,
            submissionid: submissionId,
            selectedQuotes: selectedQuotes?.map((q) => {
              return {
                quote_id: q.id,
                premium: {
                  currency: q.premium?.currency,
                  premium_value: q.premium?.premium_value,
                  tax: q.premium?.tax,
                },
                insurer_name: q?.product_details?.insurer,
              };
            }),
            payment: {
              status: "Success",
              transactionId: sessionStorage.getItem("tId"),
            },
            policyId: sessionStorage.getItem("policyId"),
          },
        })
      );
      sessionStorage.removeItem("tId");
    }
  }, []);

  if (
    sessionStorage.getItem("docusignUrl") &&
    sessionStorage.getItem("docusignUrl") !== "undefined"
  ) {
    return <DocuSign url={sessionStorage.getItem("docusignUrl")} />;
  }

  return <SuccessMessage message={t("PAYMENT_PAGE_SUCCESS_MESSAGE")} />;
};

export default Confirmation;
