import { Box } from "@mui/material";
import { Dayjs } from "dayjs";
import { FunctionComponent } from "react";

import FiltersContainer from "components/common/FiltersContainer";
import { useT } from "translation";

interface ApplicationsFiltersProps {
  onDateChange: (newValue: Dayjs, name: string, filters: Filter[]) => void;
  onApply: (filters: Filter[]) => void;
  onClear: (filters: Filter[]) => void;
}

const insuranceProducts: SelectOption[] = [{ label: "Pet", value: "PET-MEU" }];

const ApplicationsFilters: FunctionComponent<ApplicationsFiltersProps> = ({
  onDateChange,
  onApply,
  onClear,
}) => {
  const { t } = useT();
  const statuses: SelectOption[] = [
    { label: t("APPLICATION_PAGE.status.draft"), value: "incomplete" },
  ];

  return (
    <Box
      sx={{
        mb: "40px",
      }}
    >
      <FiltersContainer
        key={"applications-filter"}
        filters={[
          {
            id: "products",
            title: t("FILTERS.insuranceProducts"),
            options: insuranceProducts,
          },
          {
            id: "status",
            title: "Status",
            options: statuses,
          },
        ]}
        onDateChange={onDateChange}
        onApply={onApply}
        onClear={onClear}
      />
    </Box>
  );
};

export default ApplicationsFilters;
