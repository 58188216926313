import { isEmpty } from "lodash";

import { CurrencyPrefix, formatCurrency } from "utils/utils";

const checkValidDataForNestedElements = (list: any[], clientData: any) => {
  if (!clientData) {
    return;
  }
  for (let i = 0; i < list.length; i++) {
    let ele = list[i];
    if (ele.type === "paneldynamic") {
      clientData?.forEach((c) => {
        checkValidDataForNestedElements(ele.templateElements, c?.[ele.name]);
      });
    } else if (Array.isArray(clientData)) {
      clientData?.forEach((cd) => {
        if (cd[ele.name]) {
          if (ele.type === "dropdown" && !ele.choicesLazyLoadEnabled) {
            const selectedChoice = ele.choices?.find((choice) => {
              return typeof choice?.value === "string"
                ? (choice?.value ?? "").toLowerCase() ===
                    (cd?.[ele.name] ?? "").toLowerCase()
                : choice?.value === cd?.[ele.name];
            });
            cd[ele.name] = selectedChoice?.value || ele.defaultValue;
          }
        }
      });
    }
  }
};

export const checkValidData = (tempSurveyJSON, clientData) => {
  try {
    const { prefill_values, ...rest } = clientData;
    const tempClientData = JSON.parse(
      JSON.stringify({
        ...rest,
        prefill_values: cleanPrefillData(tempSurveyJSON, prefill_values),
      })
    );
    (tempSurveyJSON?.pages || []).forEach((page: any, pageIndex: number) => {
      (page?.elements || []).forEach((element: any, eleIndex: number) => {
        if (
          tempClientData?.prefill_values?.[element.name] ||
          element.defaultValue
        ) {
          if (element.type === "paneldynamic") {
            checkValidDataForNestedElements(
              element.templateElements,
              tempClientData?.prefill_values?.[element.name]
            );
          } else {
            if (
              element.type === "dropdown" &&
              !element.choicesLazyLoadEnabled
            ) {
              const selectedChoice = element.choices?.find((choice) => {
                return typeof choice.value === "string"
                  ? (choice.value ?? "").toLowerCase() ===
                      (
                        tempClientData?.prefill_values?.[element.name] ?? ""
                      ).toLowerCase()
                  : choice?.value ===
                      tempClientData?.prefill_values?.[element.name];
              });
              tempClientData["prefill_values"][element.name] =
                selectedChoice?.value || element.defaultValue;
            } else {
              tempClientData["prefill_values"][element.name] =
                tempClientData?.prefill_values?.[element.name] ||
                element.defaultValue;
            }
          }
        }
      });
    });
    return tempClientData;
  } catch (error) {
    console.error(error);
  }
};

const addPanelsForNestedElements = (
  list: any[],
  parentName: string,
  clientData: any,
  alreadyAddedPanel: any,
  locale: string
) => {
  for (let i = 0; i < list?.length; i++) {
    let ele = list[i];
    if (clientData?.read_only_values?.[ele.name]) {
      ele.readOnly = true;
    }

    if (
      ele.type === "paneldynamic" &&
      ele.templateElements?.length &&
      !alreadyAddedPanel.includes(ele.name)
    ) {
      ele.panelRemoveText =
        locale === "pt"
          ? "Descartar"
          : locale === "es"
          ? "Desechar"
          : "Discard";
      if (ele.isRequired) {
        ele.panelCount = 1;
        ele.minPanelCount = 1;
      }
      list.splice(i, 0, {
        type: "panel-add-remove",
        name: ele.title?.[locale] ?? ele.title.default,
        panelType: ele.name,
        parentPath: `${parentName}$$${ele.name}`,
        ...(ele.minPanelCount ? { minPanelCount: ele.minPanelCount } : {}),
        ...(ele.maxPanelCount ? { maxPanelCount: ele.maxPanelCount } : {}),
        titleLocation: "hidden",
        visibleIf: ele.visibleIf,
      });
      alreadyAddedPanel.push(ele.name);
    }
    if (ele.templateElements?.length) {
      addPanelsForNestedElements(
        ele.templateElements,
        `${parentName}$$${ele.name}`,
        clientData,
        alreadyAddedPanel,
        locale
      );
    }
  }
};

const addDynamicPanelHeaders = (
  surveyJSON: any,
  clientData: any,
  prefillData: any,
  locale: string,
  isMobile: boolean
): any => {
  const tempSurveyJSON = JSON.parse(JSON.stringify(surveyJSON));
  const validClientData = clientData
    ? checkValidData(tempSurveyJSON, clientData)
    : {};
  const alreadyAddedPanel = [""];
  let elementsArray = [];
  try {
    (tempSurveyJSON?.pages || []).forEach((page: any, pageIndex: number) => {
      (page?.elements || []).forEach((element: any) => {
        if (validClientData?.read_only_values?.[element.name]) {
          element.readOnly = true;
        }
        if (
          element.type === "paneldynamic" &&
          !alreadyAddedPanel.includes(element.name)
        ) {
          addPanelsForNestedElements(
            element.templateElements,
            element.name,
            validClientData,
            [""],
            locale
          );
          element.panelRemoveText =
            locale === "pt"
              ? "Descartar"
              : locale === "es"
              ? "Desechar"
              : "Discard";
          if (element.isRequired) {
            element.panelCount = 1;
            element.minPanelCount = 1;
          }
          elementsArray.push({
            type: "panel-add-remove",
            name: element.title?.[locale] ?? element.title.default,
            panelType: element.name,
            parentPath: null,
            ...(element.minPanelCount
              ? { minPanelCount: element.minPanelCount }
              : {}),
            ...(element.maxPanelCount
              ? { maxPanelCount: element.maxPanelCount }
              : {}),
            titleLocation: "hidden",
            visibleIf: element.visibleIf,
          });
          elementsArray.push(element);
          alreadyAddedPanel.push(element.name);
        } else {
          if (element.type === "dropdown" && isMobile) {
            elementsArray.push({ ...element, renderAs: "select" });
          } else {
            elementsArray.push({ ...element });
          }
        }
      });

      // Adding following change for handling the dropdown values for location_id element in location_details panel
      // We have to make it generic/dynamic in future to avoid hard coded implementation

      const locationElement = page?.elements?.find(
        (ele) => ele.name === "liability_liability_location_details"
      );

      if (locationElement) {
        const locationIdElement = locationElement?.templateElements?.find(
          (ele) => ele.name === "liability_location_id"
        );
        if (locationIdElement) {
          locationIdElement.choices = prefillData?.location_details?.length
            ? prefillData?.location_details?.map((loc) => ({
                text: { default: loc.location_id },
                value: loc.location_id,
              }))
            : [
                {
                  text: { default: "LOC1" },
                  value: "LOC1",
                },
              ];
        }
      }

      tempSurveyJSON.pages[pageIndex].elements = elementsArray;
      elementsArray = [];
    });
  } catch (error) {
    console.error(error);
  }
  return tempSurveyJSON;
};

const extraSurveyConfig = () => {
  return {
    showQuestionNumbers: "off",
    requiredText: "*",
    questionErrorLocation: "bottom",
    showNavigationButtons: false,
  };
};

const generateSummaryDataRecursive = (
  element: any,
  data: any,
  locale: string,
  type: string,
  questionDetails?: any
) => {
  if (!Array.isArray(data)) {
    const elementName = element.name;
    if (
      elementName === "liability_limit" ||
      elementName === "equip_value" ||
      elementName === "drone_value" ||
      element.displayStyle === "currency"
    ) {
      return `${CurrencyPrefix}${formatCurrency(data)}`;
    }
    if (
      elementName === "death_accident_value_percent" ||
      elementName === "permanent_disability_accident_ipa_percent" ||
      elementName ===
        "total_permanent_functional_disability_illness_ifpd_percentage" ||
      elementName ===
        "total_permanent_labor_disability_illness_ilpd_percentage" ||
      elementName === "accidental_death_working_hours_percent" ||
      elementName === "total_permanent_disability_accident_percentage" ||
      elementName === "death_automatic_inclusion_spouse_iacm_percentage" ||
      elementName ===
        "accidental_death_automatic_inclusion_spouse_iacma_percent" ||
      elementName ===
        "permanent_disability_automatic_inclusion_spouse_iacipa_percent" ||
      elementName === "automatic_inclusion_children_iaf_percent" ||
      elementName === "congenital_diseases_children_dcf_percent" ||
      elementName === "medical_hospital_dental_expenses_dmho_percent" ||
      elementName === "labor_termination_rtd_percent" ||
      elementName === "complementary_funeral_assistance_afc_percent" ||
      elementName === "spouse_professional_retraining_percent" ||
      elementName === "vocational_training_expenses_percent" ||
      elementName === "miscellaneous_expenses_dd_percent" ||
      elementName === "miscellaneous_accident_expenses_dda_percent"
    ) {
      const percent = (Number(data) || 0) * 100;
      return `${percent}`;
    }

    if (type === "dropdown" || type === "radiogroup") {
      let allChoices = element?.choices;
      if (
        !allChoices &&
        element?.choicesLazyLoadEnabled &&
        questionDetails?.templateElements
      ) {
        allChoices = questionDetails.templateElements.find(
          (templateEle) => templateEle.name === elementName
        )?.choices;
      }
      const selectedChoice = allChoices?.find(
        ({ value }) => value === data
      );
      const val =
        selectedChoice?.text?.[locale] || selectedChoice?.text?.default || data;
      if (val) {
        return val;
      }
    }
    return data;
  }

  if (type === "checkbox") {
    return data?.join(",");
  }

  const results = [];

  try {
    data.forEach((dataItem) => {
      const summary = {};
      (element?.templateElements || []).forEach((templateEle: any) => {
        if (dataItem[templateEle.name] && templateEle.visibleIf !== "false") {
          summary[templateEle.name] = {
            title: templateEle.title?.[locale] ?? templateEle.title.default,
            value: generateSummaryDataRecursive(
              templateEle,
              dataItem[templateEle.name],
              locale,
              templateEle.type,
              questionDetails
            ),
          };
          if (
            Array.isArray(summary[templateEle.name]?.value) &&
            isEmpty(summary[templateEle.name]?.value?.[0])
          ) {
            delete summary[templateEle.name];
          }
        }
      });
      results.push(summary);
    });
  } catch (error) {
    console.error(error);
  }

  return results;
};

const generateSummaryData = (
  surveyJSON: any,
  data: any,
  locale: string,
  allApplicationQuestions?: Record<string, any>[]
) => {
  const summary = {};
  try {
    surveyJSON.pages.forEach((page: any) => {
      page.elements.forEach((element: any) => {
        if (data[element.name] && element.visibleIf !== "false") {
          summary[element.name] = {
            title: element?.title?.[locale] ?? element.title.default,
            value: generateSummaryDataRecursive(
              element,
              data[element.name],
              locale,
              element.type,
              allApplicationQuestions?.find(
                (question) => question.name === element.name
              )
            ),
          };
          if (
            Array.isArray(summary[element.name]?.value) &&
            isEmpty(summary[element.name]?.value?.[0])
          ) {
            delete summary[element.name];
          }
        }
      });
    });
    return summary;
  } catch (error) {
    console.error(error);
  }
};

const cleanPrefillDataRecusrively = (questions: any[], newPrefillData: any) => {
  if (!newPrefillData) {
    return;
  }
  const questionKeys = (questions ?? []).map((question) => question.name);
  for (const key in newPrefillData) {
    if (
      typeof newPrefillData[key] !== "object" &&
      !Array.isArray(newPrefillData[key])
    ) {
      if (!questionKeys.includes(key)) {
        try {
          delete newPrefillData[key];
        } catch (error) {}
      }
    } else {
      const templateQuestions = questions.find(
        (que) => que.name === key
      )?.templateElements;
      if (Array.isArray(newPrefillData[key])) {
        newPrefillData[key]?.forEach((dt) => {
          cleanPrefillDataRecusrively(templateQuestions, dt);
        });
      }
    }
  }
};

const cleanPrefillData = (surveyJSON: any, prefillData: any) => {
  const questions = [];
  if (!prefillData) {
    return prefillData;
  }
  try {
    const newPrefillData = JSON.parse(JSON.stringify(prefillData));
    (surveyJSON?.pages || []).forEach((page) =>
      questions.push(...(page.elements ?? []))
    );
    cleanPrefillDataRecusrively(questions, newPrefillData);
    return newPrefillData;
  } catch (error) {
    console.error(error);
  }
};

const applicationUtil = {
  addDynamicPanelHeaders,
  extraSurveyConfig,
  generateSummaryData,
  cleanPrefillData,
};
export default applicationUtil;
