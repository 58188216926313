import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ConfigObj } from "portalConfig/types";

interface PortalConfigStore {
  data: ConfigObj | null;
  error: Error | null;
  loading: boolean;
}

const initialState: PortalConfigStore = {
  data: null,
  error: null,
  loading: false,
};

const portalConfigSlice = createSlice({
  name: "portalConfig",
  initialState,
  reducers: {
    setPortalConfigLoading: (state) => {
      state.loading = true;
      state.error = null;
      state.data = null;
    },
    setPortalConfigSuccess: (state, action: PayloadAction<ConfigObj>) => {
      state.loading = false;
      state.error = null;
      state.data = action.payload;
    },
    setPortalConfigError: (state, action: PayloadAction<Error>) => {
      state.loading = false;
      state.data = null;
      state.error = action.payload;
    },
  },
});

export const {
  setPortalConfigLoading,
  setPortalConfigSuccess,
  setPortalConfigError,
} = portalConfigSlice.actions;

export default portalConfigSlice.reducer;
