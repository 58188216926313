import { Dispatch, createContext } from "react";
import {
  PolicyholderState,
  initialPolicyholderState,
} from "states/policyholderState";

export const PolicyholderContext = createContext<PolicyholderState>(
  initialPolicyholderState
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const PolicyholderDispatchContext = createContext<Dispatch<any>>(
  () => null
);
