import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";

import LogoWithText from "components/common/LogoWithText";
import {
  customStylesSelector,
  productsSelector,
} from "store/selectors/selectors";
import { InsurerLogoMapping, ProductIconMapping } from "./utils";
import { useT } from "translation";
import { IS_BROKER_PORTAL } from "config";

const InsuranceInfo = () => {
  const { t } = useT();
  const customStyles = useSelector(customStylesSelector);
  const selectedProducts = useSelector(productsSelector);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <Box
        sx={{
          backgroundColor: customStyles.primaryCard.backgroundColor,
          p: "15px",
          borderRadius: "10px",
          width: "100%",
          height: "fit-content",
        }}
      >
        <Typography
          sx={{
            fontSize: "14px",
            marginBottom: "30px",
            fontWeight: 500,
            color: customStyles.muiTheme.palette.text.secondary,
          }}
        >
          {t("APPLICATION_PAGE_SELECTED_INSURANCE")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          {selectedProducts.map((selPro) => (
            <LogoWithText
              key={selPro.product.id}
              logo={ProductIconMapping(selPro.product.product_identifier)}
              text={selPro.product.product_name}
            />
          ))}
        </Box>
      </Box>
      {IS_BROKER_PORTAL && (
        <Box
          sx={{
            backgroundColor: customStyles.primaryCard.backgroundColor,
            p: "15px",
            borderRadius: "10px",
            width: "100%",
            height: "fit-content",
            mb: "30px",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              marginBottom: "30px",
              fontWeight: 500,
              color: customStyles.muiTheme.palette.text.secondary,
            }}
          >
            {t("APPLICATION_PAGE_QUOTES_FROM_INSURER")}
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "10px",
              borderRadius: "12px",
            }}
          >
            {selectedProducts.map((selPro) => (
              <Box
                key={selPro.product.id}
                sx={{
                  marginRight: "20px",
                  display: "flex",
                  gap: "10px",
                  flexWrap: "wrap",
                }}
              >
                {InsurerLogoMapping[selPro.carrier[0]]}
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default InsuranceInfo;
