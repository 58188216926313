import { Box, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import paymentApiUtil from "api/payments";
import { getPaymentStatus, makeStripePayment } from "api/quotes";
import { bindQuote, stripeConfirmAPI } from "api/siaApplication";
import AndDonePayment from "components/common/AnddoneJS";
import SectionInfo from "components/common/SectionInfo/SectionInfo";
import { COUNTRY, IS_BROKER_PORTAL } from "config";
import { Clients } from "constants/common";
import {
  STERE_CHECKOUT_DECLINED,
  STERE_QUOTES_SELECTED,
} from "constants/webhookEvents";
import { useLocation } from "react-router-dom";
import { setErrorAndShowExitBtn } from "store/features/clientSlice";
import {
  nextStep,
  prevStep,
  prevSubStep,
  setCurrentSubStep,
  setShowPaymentScreen,
} from "store/features/stepperSlice";
import { setSurveyPageNumber } from "store/features/surveyJsSlice";
import { fireWebhookEvent } from "store/features/webhooksSlice";
import {
  clientSelector,
  customStylesSelector,
  customerSelector,
  quotesSelector,
} from "store/selectors/selectors";
import { RootState } from "store/store";
import { useT } from "translation";
import { PaymentProviders, calculateTransactionFee } from "utils/utils";
import StripePayment from "./payment/StripePayment";
import SummaryTableMobile from "./payment/SummaryTableMobile";
import { createPolicyHolderDetails } from "./utils";

type PaymentProps = {};

const Payment: React.FC<PaymentProps> = () => {
  const { t } = useT();
  const location = useLocation();
  const customStyles = useSelector(customStylesSelector);
  const dispatch = useDispatch();
  const theme = useTheme();
  const { selectedQuotes } = useSelector(quotesSelector);
  const { showPaymentSummaryPage, clientFeatures, applicantId } = useSelector(
    (state: RootState) => state.client
  );
  const { showPaymentScreen } = useSelector(
    (state: RootState) => state.stepper
  );
  const productIdentifier = useSelector(
    (state: RootState) =>
      state.client.availableProducts[0]?.product?.product_identifier
  );

  const {
    updatedSiaApplication,
    surveyModelFuncs,
    siaApplicationId,
    scriptRef,
    submissionId,
    applicant_email,
  } = useSelector((state: RootState) => state.surveyJs);
  const customerId = useSelector(customerSelector);
  const { clientAPIKey } = useSelector(clientSelector);

  const policyholderDetails = createPolicyHolderDetails(
    updatedSiaApplication?.params,
    selectedQuotes[0]?.product_details?.type
  );
  const [payments, setPayments] = useState<PaymentPayload[]>([]);
  const [summarydata, setsummaryData] = useState<SummaryItem[]>(
    selectedQuotes.map((sq, index) => ({
      frequency: sq?.premium?.monthly ? "month" : "year",
      quote: sq,
      transactionFee:
        clientFeatures?.name === Clients.UNIDIN ||
        !clientFeatures?.payment_provider ||
        clientFeatures?.payment_provider !== PaymentProviders.STRIPE
          ? 0
          : calculateTransactionFee(
              sq?.premium?.premium_value + sq?.premium?.tax,
              index
            ),
      tax: Number(sq?.premium?.tax ?? 0),
    }))
  );

  useEffect(() => {
    if (!IS_BROKER_PORTAL) {
      dispatch(
        fireWebhookEvent({
          event_type: STERE_QUOTES_SELECTED,
          metadata: {
            applicantId: applicantId,
            applicationId: siaApplicationId,
            submissionid: submissionId,
            selectedQuotes: selectedQuotes?.map((q) => {
              return {
                quote_id: q.id,
                premium: {
                  currency: q.premium?.currency,
                  premium_value: q.premium?.premium_value,
                  tax: q.premium?.tax,
                },
                insurer_name: q?.product_details?.insurer,
              };
            }),
          },
        })
      );
    }
    if (localStorage.getItem("scriptLoaded") && scriptRef) {
      document?.head?.removeChild(scriptRef.current);
      // @ts-expect-error Attaching registerBrazilFactoryFunctions when the script is added
      window.factoryFunctionRegistered = false;
      localStorage.removeItem("scriptLoaded");
      localStorage.removeItem("scriptCallMade");
    }
    if (!showPaymentSummaryPage) {
      onMakePayment();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (showPaymentScreen) {
      onMakePayment();
    }
  }, [showPaymentScreen]);

  const generatePaymentData = (
    summaryItem: SummaryItem = null
  ): PaymentPayload[] => {
    if (summaryItem) {
      return [
        {
          carrier: summaryItem.quote.product_details.insurer,
          paymentType: summaryItem.frequency,
          premium:
            (summaryItem.quote.premium.premium_value ?? 0) +
            (summaryItem.quote.premium.tax ?? 0) +
            summaryItem.transactionFee,
          productType: summaryItem.quote.product_details.type,
          quote_id: summaryItem.quote.id,
          currency: summaryItem.quote.premium.currency,
          transaction_fee: summaryItem?.transactionFee,
          applicant_name: policyholderDetails?.policyholder_full_name,
        },
      ];
    }

    return summarydata.map((sd) => ({
      carrier: sd.quote.product_details.insurer,
      paymentType: sd.frequency,
      premium:
        (sd.quote.premium.premium_value ?? 0) +
        (sd.quote.premium.tax ?? 0) +
        sd.transactionFee,
      productType: sd.quote.product_details.type,
      quote_id: sd.quote.id,
      currency: sd.quote.premium.currency,
      transaction_fee: sd?.transactionFee,
      applicant_name: policyholderDetails?.policyholder_full_name,
    }));
  };

  const onMakePayment = (summaryItem: SummaryItem = null) => {
    setPayments(generatePaymentData(summaryItem));
    dispatch(setShowPaymentScreen(true));
  };

  // added this method from Footer navigation
  const handleSurveyBack = () => {
    if (
      surveyModelFuncs?.isFirstPage() ||
      surveyModelFuncs?.isFirstPage() === void 0
    ) {
      dispatch(prevStep());
    } else {
      surveyModelFuncs?.prevPageFunc();
      dispatch(prevSubStep());
      dispatch(setSurveyPageNumber(surveyModelFuncs?.currentPageNo()));
    }
  };

  const RenderPaymentWidget = (key: PaymentProviders) => {
    const onSubmit = async ({
      email,
      name,
      paymentMethodId,
      stripe,
      onError,
    }) => {
      const onSuccess = () => {
        dispatch(nextStep());
        return Promise.resolve();
      };
      const onFailure = () => Promise.reject();
      const [selectedQuote] = selectedQuotes;
      if (COUNTRY === "mx" && productIdentifier === "PET" && selectedQuote) {
        try {
          const resp = await bindQuote(clientAPIKey, selectedQuote.id);
          if (resp.data.status === "bind-failed") {
            dispatch(setErrorAndShowExitBtn(true));
          } else {
            dispatch(nextStep());
          }
          return;
        } catch (error) {
          dispatch(setErrorAndShowExitBtn(true));
          return;
        }
      }
      if (COUNTRY === "br") {
        try {
          const appQuote = JSON.parse(
            JSON.stringify(selectedQuotes[0])
          ) as Quote;
          const planName =
            appQuote?.premium?.additional_info?.plan_name?.default;
          delete appQuote?.premium?.additional_info?.plan_name;
          //@ts-ignore
          appQuote.premium.additional_info.plan_name = planName;
          const paymentData = {
            pasApplicationId: siaApplicationId,
            policyholder: policyholderDetails,
            quote: appQuote,
            companyName: appQuote?.product_details?.insurer,
          };
          const response = await makeStripePayment({
            ...paymentData,
            paymentMethodId,
            quote: appQuote,
            clientAPIKey,
          });

          if (response.data?.results?.submission_status === "paid") {
            return onSuccess();
          }

          if (response.data?.results?.submission_status === "in_progress") {
            let isStatusPaid = false;
            while (!isStatusPaid) {
              await new Promise((resolve) => setTimeout(resolve, 3000));
              const res = await getPaymentStatus(response.data?.results?.id);
              if (res.data.results?.submission_status === "paid")
                isStatusPaid = true;
            }
            return onSuccess();
          }
          if (response.data.results?.submission_status === "paid") {
            return onSuccess();
          } else {
            return onFailure();
          }
        } catch (err) {
          return onFailure();
        }
      }

      const response = await paymentApiUtil.generateClientSecret({
        paymentMethodId: paymentMethodId,
        name: name,
        email: email,
        description: "-",
        customer_id: customerId,
        // payments: payments,
        payments: (payments || []).map((payment) => ({
          ...payment,
          carrier: payment?.carrier?.toLowerCase(),
        })),
      });

      const { error, paymentIntent } = await stripe.confirmCardPayment(
        response.results.clientSecret,
        {
          payment_method: paymentMethodId,
        }
      );

      if (error) {
        if (error.type === "card_error" || error.type === "validation_error") {
          onError({
            cardCvc: "",
            cardExpiry: "",
            cardNumber: error?.message,
          });
        }

        dispatch(
          fireWebhookEvent({
            event_type: STERE_CHECKOUT_DECLINED,
            metadata: {
              applicantId: applicantId,
              applicationId: siaApplicationId,
              submissionid: submissionId,
              selectedQuotes: selectedQuotes?.map((q) => {
                return {
                  quote_id: q.id,
                  premium: {
                    currency: q.premium?.currency,
                    premium_value: q.premium?.premium_value,
                    tax: q.premium?.tax,
                  },
                  insurer_name: q?.product_details?.insurer,
                };
              }),
              payment: {
                status: "Failed",
                transactionId: error.payment_intent?.id,
              },
              error,
            },
          })
        );

        return Promise.reject();
      }

      try {
        if (response.results.invoiceId) {
          await paymentApiUtil.markInvoicePaid(response.results.invoiceId);
        }
        // Make backend API call once payment confirmed
        const stripeConfirmRes = await stripeConfirmAPI(clientAPIKey, {
          payment_type:
            payments[0]?.paymentType === "year" ? "annual" : "monthly",
          premium: payments[0]?.premium,
          premium_currency: payments[0]?.currency,
          quote_id: payments[0]?.quote_id,
          transaction_id: paymentIntent.id,
          transaction_fee: payments[0]?.transaction_fee,
          applicant_name: payments[0]?.applicant_name,
        });
        sessionStorage.setItem("tId", paymentIntent.id);
        sessionStorage.setItem(
          "policyId",
          stripeConfirmRes?.data?.results?.policy_id ?? ""
        );
        if (stripeConfirmRes?.data?.results?.response?.docusign_envelope_id) {
          sessionStorage.setItem("clientAPIKey", clientAPIKey);
          sessionStorage.setItem(
            "envelopId",
            stripeConfirmRes?.data?.results?.response?.docusign_envelope_id
          );
          sessionStorage.setItem(
            "docusignUrl",
            stripeConfirmRes?.data?.results?.response?.docusign_url
          );
        }
        onSuccess();
      } catch (err) {
        console.error(err);
        onFailure();
      }
    };

    switch (key) {
      case PaymentProviders.ANDDONE:
        return (
          <AndDonePayment
            policyholder={policyholderDetails}
            quotes={selectedQuotes}
          />
        );

      case PaymentProviders.STRIPE:
        return (
          <StripePayment
            onCancel={() => {
              if (showPaymentSummaryPage) {
                dispatch(setShowPaymentScreen(false));
              } else {
                if (location?.pathname?.split("/")[2] === "quote") {
                  dispatch(prevStep());
                  dispatch(
                    setCurrentSubStep(surveyModelFuncs?.currentPageNo())
                  );
                } else if (
                  location?.pathname?.split("/")[2] === "application"
                ) {
                  handleSurveyBack();
                } else {
                  dispatch(prevStep());
                }
              }
            }}
            customerEmail={applicant_email}
            onSubmit={onSubmit}
            paymentData={payments}
          />
        );

      default:
        return null;
    }
  };

  return (
    <Box
      sx={{
        pl: { xs: "20px", sm: "40px", md: "20px", lg: "20px" },
        pr: { xs: "20px", sm: "40px", md: "20px", lg: "20px" },
      }}
    >
      <SectionInfo
        subTitle={t("PAYMENT_PAGE_DESCRIPTION_PRIMARY")}
        description={t("PAYMENT_PAGE_DESCRIPTION_SECONDARY")}
      />
      {showPaymentScreen || !showPaymentSummaryPage ? (
        <Box sx={{ display: "flex", width: "100%", flexDirection: "column" }}>
          <Box
            sx={{
              margin: "20px 0px",
              padding: "20px",
              borderRadius: "12px",
              display: "flex",
              flexGrow: "1",
              flexDirection: "column",
              backgroundColor: customStyles.primaryCard.backgroundColor,
            }}
          >
            <Typography
              sx={{
                color: theme.palette.primary.main,
                fontSize: "16px",
                fontWeight: 500,
                mb: "10px",
              }}
            >
              {t("QUOTE_PAGE_SUMMARY")}
            </Typography>
            <SummaryTableMobile
              summarydata={summarydata}
              onMakePayment={onMakePayment}
            />
          </Box>
          <Box
            sx={{
              backgroundColor: customStyles.primaryCard.backgroundColor,
              p: "20px",
              borderRadius: "12px",
            }}
          >
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: 500,
                mb: "20px",
                color: theme.palette.primary.main,
              }}
            >
              {t("PAYMENT_PAGE_SECURE_PAYMENT")}
            </Typography>
            {RenderPaymentWidget(
              clientFeatures?.payment_provider as PaymentProviders
            )}
          </Box>
        </Box>
      ) : (
        <SummaryTableMobile
          summarydata={summarydata}
          onMakePayment={onMakePayment}
        />
      )}
    </Box>
  );
};

export default Payment;
