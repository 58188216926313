import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";

import { useT } from "translation";
import { AppContentContainer } from "./AppContentContainer";

type AppFooterProps = {
  bgColor?: "primary" | "default";
};
export function AppFooter({ bgColor }: AppFooterProps) {
  const { t } = useT();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        bgcolor:
          bgColor === "primary" ? "primary.contrastText" : "primary.main",
        color: bgColor === "primary" ? "primary.main" : "primary.contrastText",
      }}
    >
      <AppContentContainer
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "space-between",
          alignItems: "center",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        <Typography variant="subtitle1">{t("FOOTER_TEXT")}</Typography>
        <Box
          display="flex"
          gap={{ xs: "8px", sm: "8px", md: "24px", lg: "24px" }}
        >
          <Link
            style={{
              color: "unset",
              display: "inline-block",
            }}
            to={{ hash: "#" }}
          >
            <Typography variant="subtitle1"> {t("TERMS")}</Typography>
          </Link>
          <Link
            style={{
              color: "unset",
              display: "inline-block",
            }}
            to={{ hash: "#" }}
          >
            <Typography variant="subtitle1"> {t("PRIVACY")}</Typography>
          </Link>
          <Link
            style={{
              color: "unset",
              display: "inline-block",
            }}
            to={{ hash: "#" }}
          >
            <Typography variant="subtitle1"> {t("LICENSE")}</Typography>
          </Link>
        </Box>
      </AppContentContainer>
    </Box>
  );
}
