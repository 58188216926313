import { Box, Typography } from "@mui/material";
import { InsurerLogoMapping } from "components/Quote/sections/utils";

interface BrokerOnRecordProps {
  name: string;
}

export function BrokerOnRecord({
  name,
}: React.PropsWithChildren<BrokerOnRecordProps>) {
  const Icon =
    InsurerLogoMapping[name.toUpperCase() as keyof typeof InsurerLogoMapping];

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
      {/* {Icon ? <Icon style={{ fill: "black", maxHeight: 40 }} /> : null} */}
      <Typography variant="subtitle2" sx={{ color: "secondary.main" }}>
        {name}
      </Typography>
    </Box>
  );
}
