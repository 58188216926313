import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";

import { customStylesSelector } from "store/selectors/selectors";
import CustomButton from "../CustomButton/CustomButton";

interface ReusableModalProps {
  open: boolean;
  title: string;
  content: string;
  btnText: string;
  onClose: () => void;
  onSave: () => void;
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: "12px",
};

const PopupModal: React.FC<ReusableModalProps> = ({
  open,
  title,
  content,
  btnText,
  onClose,
  onSave,
}) => {
  const customStyles = useSelector(customStylesSelector);
  const handleClose = () => {
    onClose();
  };
  const handleSave = () => {
    onSave();
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            id="modal-modal-title"
            sx={{
              color: customStyles.muiTheme.palette.text.primary,
              fontSize: "22px",
              fontWeight: 600,
            }}
          >
            {title}
          </Typography>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {content}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <CustomButton
            sx={{ mt: "30px", textTransform: "none" }}
            onClickCB={handleSave}
            btnText={btnText}
            data_cy={`button-${btnText.replaceAll(" ", "-")}`}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default PopupModal;
