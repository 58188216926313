import ShareIcon from "@mui/icons-material/Share";
import { Box, Typography } from "@mui/material";
import { CopyToClipboard } from "react-copy-to-clipboard";
// import CloseIcon from "@mui/icons-material/Close";

import PopoverComponent from "components/common/PopoverComponent";
import AnimatedButton from "components/custom/AnimatedButton";
import { useSelector } from "react-redux";
import { customStylesSelector } from "store/selectors/selectors";
import { useT } from "translation";

interface ShareLinkButtonProps {
  paymentLink: string;
}

const ShareLinkButton: React.FC<ShareLinkButtonProps> = ({ paymentLink }) => {
  const { t } = useT();
  const customStyles = useSelector(customStylesSelector);
  const copyToClipboard = async (text: string) => {
    await navigator?.clipboard?.writeText(text);
  };

  return (
    <PopoverComponent
      key={"share-link-popover"}
      title={
        <AnimatedButton variant="contained">
          <ShareIcon fontSize="small" sx={{ mr: "5px" }} />
          <Typography sx={{ fontSize: "14px", textTransform: "none" }}>
            {t("QUOTE_PAGE_SHARE_LINK")}
          </Typography>
        </AnimatedButton>
      }
    >
      <Box sx={{ p: "20px", borderRadius: "12px", maxWidth: "400px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              color: customStyles?.muiTheme?.palette?.text?.primary,
              fontWeight: 500,
            }}
          >
            Share Link
          </Typography>
          {/* <Box id="close-icon">
              <CloseIcon fontSize="small" />
            </Box> */}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            color: customStyles?.muiTheme?.palette?.text?.tertiary,
            alignItems: "center",
            border: "1px solid ",
            p: "2px",
            mt: "20px",
          }}
        >
          <Typography
            sx={{
              fontSize: "12px",
              mr: "20px",
              pl: "10px",
              whiteSpace: "pre-wrap",
              overflowX: "auto",
            }}
          >
            {paymentLink}
          </Typography>
          <CopyToClipboard text={paymentLink} onCopy={() => null}>
            <AnimatedButton
              variant="contained"
              onClick={() => copyToClipboard(paymentLink)}
            >
              Copy
            </AnimatedButton>
          </CopyToClipboard>
        </Box>
      </Box>
    </PopoverComponent>
  );
};

export default ShareLinkButton;
