import axios from "axios";

import { AxiosCommonResponse } from "policyholderApi/types";
import {
  API_BASE_URL,
  createAxiosInstanceWithAccessToken,
  STERE_API_BASE_URL,
} from "utils/axios";
import { getRefreshToken, removeAllTokens } from "utils/token";
import {
  ChangePasswordPayload,
  NewSignInPayload,
  NewSignInResponse,
  RefreshTokenPayload,
  RefreshTokenResponse,
  ResetPasswordPayload,
  SignInPayload,
  SignInResponse,
  VerifyOtpPayload,
  VerifyOtpResponse,
} from "./types";
import { DOMAIN_NAME } from "config";

async function newSignInAPI(payload: NewSignInPayload) {
  return axios.post<NewSignInResponse>(
    `${STERE_API_BASE_URL}/customers/login`,
    payload,
    {
      headers: {
        "Content-Type": "application/json",
        "X-STERE-DOMAIN": DOMAIN_NAME,
      },
    }
  );
}

async function verifyOtpAPI(payload: VerifyOtpPayload) {
  return axios.post<VerifyOtpResponse>(
    `${STERE_API_BASE_URL}/customers/verify-login`,
    payload,
    {
      headers: {
        "Content-Type": "application/json",
        "X-STERE-DOMAIN": DOMAIN_NAME,
      },
    }
  );
}

async function signInAPI(payload: SignInPayload) {
  return axios.post<SignInResponse>(
    `${API_BASE_URL}/portal_policy_holder/auth:sign_in`,
    payload
  );
}

function getRefreshTokenAPI(payload: RefreshTokenPayload) {
  return axios.post<RefreshTokenResponse>(
    `${API_BASE_URL}/portal_policy_holder/auth:refresh_token`,
    payload
  );
}

async function signOutAPI() {
  const refreshToken = getRefreshToken();

  removeAllTokens();

  return axios.post<string>(
    `${API_BASE_URL}/portal_policy_holder/auth:sign_out`,
    {
      refresh_token: refreshToken,
    }
  );
}

async function changePasswordAPI(payload: ChangePasswordPayload) {
  const API = createAxiosInstanceWithAccessToken();

  if (payload.code) {
    return axios.post<string>(
      `${API_BASE_URL}/portal_policy_holder/auth:change_password`,
      payload
    );
  }

  return API.post<string>(
    "/portal_policy_holder/auth:change_password",
    payload
  );
}

async function getCurrentUserAPI() {
  const API = createAxiosInstanceWithAccessToken();

  return API.get<AxiosCommonResponse<PortalAccount>>(
    "/portal_policy_holder/auth:current"
  );
}

async function resetPasswordAPI(payload: ResetPasswordPayload) {
  return axios.post<string>(
    `${API_BASE_URL}/portal_policy_holder/auth:reset_password`,
    payload
  );
}

export const AuthService = {
  newSignInAPI,
  verifyOtpAPI,
  signInAPI,
  signOutAPI,
  getRefreshTokenAPI,
  changePasswordAPI,
  resetPasswordAPI,
  getCurrentUserAPI,
};
