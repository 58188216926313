import { Box, SxProps, Theme, Typography } from "@mui/material";

import { submissionStatusMapping } from "./utils";
import { useT } from "translation";

const QuoteStatus: React.FC<{ quoteData: QuotesData; sx?: SxProps<Theme> }> = ({
  quoteData,
  sx,
}) => {
  const { t } = useT();
  return (
    <Box
      sx={{
        backgroundColor:
          submissionStatusMapping[quoteData?.status]?.backgroundColor,
        p: "5px",
        borderRadius: "100px",
        ...sx,
      }}
    >
      <Typography
        sx={{
          fontSize: "12px",
          fontWeight: 500,
          color: submissionStatusMapping[quoteData?.status]?.color,
          whiteSpace: "pre-wrap",
          textAlign: "center",
        }}
      >
        {t(submissionStatusMapping[quoteData?.status]?.label)}
      </Typography>
    </Box>
  );
};

export default QuoteStatus;
