import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IChildAccount } from "modules/Accounts/types";

interface AuthState {
  portalAccount: PortalAccount;
  errorMessage: string;
  loading: boolean;
  passwordCreated: boolean;
  forgotPasswordEmailSent: boolean;
  accessToken: string;
  refreshToken: string;
  idToken: string;
  v2Login: {
    allAccounts: IChildAccount[];
    activeAccount: IChildAccount | null;
    accessToken: string;
  };
}

const initialState: AuthState = {
  portalAccount: null,
  errorMessage: "",
  loading: false,
  passwordCreated: false,
  forgotPasswordEmailSent: false,
  accessToken: "",
  refreshToken: "",
  idToken: "",
  v2Login: {
    allAccounts: [],
    activeAccount: null,
    accessToken: "",
  },
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    signIn: (state, action: PayloadAction<SignInPayload>) => {
      state.loading = true;
    },
    changePassword: (state, action: PayloadAction<ChangePasswordPayload>) => {
      state.loading = true;
    },
    signOut: (state) => {
      state.portalAccount = null;
    },
    setUser: (state, action: PayloadAction<PortalAccount>) => {
      state.portalAccount = action.payload;
    },
    setCurrentUser: (state) => {
      state.loading = true;
    },
    setErrorMessage: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload;
    },
    setLoadingValue: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setPasswordCreated: (state, action: PayloadAction<boolean>) => {
      state.passwordCreated = action.payload;
    },
    setForgotPasswordEmailSent: (state, action: PayloadAction<boolean>) => {
      state.forgotPasswordEmailSent = action.payload;
    },
    resetPassword: (state, action: PayloadAction<ResetPasswordPayload>) => {
      state.loading = true;
    },
    setAccessToken: (state, action: PayloadAction<string>) => {
      state.accessToken = action.payload;
    },
    setRefreshToken: (state, action: PayloadAction<string>) => {
      state.refreshToken = action.payload;
    },
    setIdToken: (state, action: PayloadAction<string>) => {
      state.idToken = action.payload;
    },
    setAllAccountsV2Login: (state, action: PayloadAction<IChildAccount[]>) => {
      state.v2Login.allAccounts = action.payload;
    },
    setActiveAccountV2Login: (state, action: PayloadAction<IChildAccount>) => {
      state.v2Login.activeAccount = action.payload;
    },
    setAccessTokenV2Login: (state, action: PayloadAction<string>) => {
      state.v2Login.accessToken = action.payload;
    },
  },
});

export const {
  signIn,
  signOut,
  setUser,
  changePassword,
  resetPassword,
  setPasswordCreated,
  setForgotPasswordEmailSent,
  setCurrentUser,
  setErrorMessage,
  setLoadingValue,
  setAccessToken,
  setRefreshToken,
  setIdToken,
  setAllAccountsV2Login,
  setActiveAccountV2Login,
  setAccessTokenV2Login,
} = authSlice.actions;

export default authSlice.reducer;
