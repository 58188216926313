import React from 'react';

import { Box, Card, Divider, Typography } from '@mui/material';
import { IS_ONCE_PH_PORTAL } from 'config';

type CardWithTitleProps = {
  title: React.ReactNode;
  body: React.ReactNode;
};

export function CardWithTitle({ title, body }: CardWithTitleProps) {
  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '12px',
      }}>
      <Typography
        sx={{
          fontSize: '14px',
          fontWeight: 500,
          color: IS_ONCE_PH_PORTAL ? 'text.secondary' : 'primary.main',
          p: '24px',
        }}>
        {title}
      </Typography>
      <Divider />
      <Box sx={{ p: '24px' }}>{body}</Box>
    </Card>
  );
}
