import { Box, Card, Typography } from "@mui/material";
import { IS_ONCE_PH_PORTAL } from "config";

import { useUserProfile } from "hooks/useUserProfile";
import jwtDecode, { JwtPayload } from "jwt-decode";
import { useT } from "translation";
import { getAccessToken } from "utils/token";

export function AccountPage() {
  const { t } = useT();
  const userProfile = useUserProfile();
  const jwtToken: JwtPayload = jwtDecode(getAccessToken())

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: "32px",
        }}
      >
        <Typography variant="h4"> {t("CONTACT_INFO")}</Typography>
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            borderRadius: "12px",
            p: "32px",
            width: { sm: "100%", xs: "100%", md: "75%", lg: "50%" },
            gap: "24px",
          }}
        >
          {
            !IS_ONCE_PH_PORTAL &&
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                variant="subtitle2"
                sx={{
                  color: "secondary.main",
                }}
              >
                {t("NAME")}
              </Typography>
              <Typography variant="subtitle1">
                {`${userProfile.data?.data?.results?.user?.first_name} ${userProfile.data?.data?.results?.user?.last_name}` ||
                  "NA"}
              </Typography>
            </Box>
          }
          {
            !IS_ONCE_PH_PORTAL &&
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                variant="subtitle2"
                sx={{
                  color: "secondary.main",
                }}
              >
                {t("PHONE_NUMBER")}
              </Typography>
              <Typography variant="subtitle1">
                {userProfile.data?.data?.results?.user?.phone_number || "NA"}
              </Typography>
            </Box>
          }
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
              variant="subtitle2"
              sx={{
                color: "secondary.main",
              }}
            >
              {t("EMAIL")}
            </Typography>
            <Typography variant="subtitle1">
              {!IS_ONCE_PH_PORTAL ? userProfile.data?.data?.results?.user?.email : jwtToken.sub}
            </Typography>
          </Box>
        </Card>
        {/* <Typography sx={{ fontSize: '20px', fontWeight: 700 }}>
          Businesses
        </Typography>
        <Card
          sx={{
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '12px',
            p: '32px',
            width: { sm: '100%', xs: '100%', md: '75%', lg: '50%' },
            gap: '24px',
          }}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography
              variant="subtitle2"
              sx={{
                color: 'secondary.main',
              }}>
              Name
            </Typography>
            <Typography variant="subtitle1">Acme Inc.</Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography
              variant="subtitle2"
              sx={{
                color: 'secondary.main',
              }}>
              Address
            </Typography>
            <Typography variant="subtitle1">
              1 Bukit Batok West Ave 2, Singapore 659203
            </Typography>
          </Box>
        </Card> */}
      </Box>
    </Box>
  );
}
