import { Box, Typography } from "@mui/material";
import { Dayjs } from "dayjs";
import { FunctionComponent } from "react";
import { useSelector } from "react-redux";

import FiltersContainer from "components/common/FiltersContainer";
import { customStylesSelector } from "store/selectors/selectors";
import { useT } from "translation";

interface DashboardFiltersProps {
  onDateChange: (newValue: Dayjs, name: string, filters: Filter[]) => void;
  onApply: (filters: Filter[]) => void;
  onClear: (filters: Filter[]) => void;
}

const insuranceProducts: SelectOption[] = [{ label: "Pet", value: "PET-MEU" }];

const DashboardFilters: FunctionComponent<DashboardFiltersProps> = ({
  onDateChange,
  onApply,
  onClear,
}) => {
  const { t } = useT();
  const customStyles = useSelector(customStylesSelector);
  return (
    <Box
      sx={{
        mb: "40px",
      }}
    >
      <FiltersContainer
        title={
          <Typography
            sx={{
              color: customStyles.muiTheme.palette.text.primary,
              fontSize: "24px",
              fontWeight: 600,
              mb: "16px",
            }}
          >
            {t("DASHBOARD")}
          </Typography>
        }
        key="dashboard-filter"
        filters={[
          {
            id: "products",
            title: t("FILTERS.insuranceProducts"),
            options: insuranceProducts,
          },
        ]}
        onDateChange={onDateChange}
        onApply={onApply}
        onClear={onClear}
      />
    </Box>
  );
};

export default DashboardFilters;
