import { SVGProps } from "react";
import { useSelector } from "react-redux";
import { customStylesSelector } from "store/selectors/selectors";

const MobileSvg = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  const customStyles = useSelector(customStylesSelector);
  return (
    <svg
      width="52"
      height="51"
      viewBox="0 0 52 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_3044_25621)">
        <path
          d="M36.958 0.0078125H15.0439C12.9842 0.0078125 11.3086 1.68344 11.3086 3.74316V47.2725C11.3086 49.3322 12.9842 51.0078 15.0439 51.0078H36.958C39.0177 51.0078 40.6934 49.3322 40.6934 47.2725V3.74316C40.6934 1.68344 39.0177 0.0078125 36.958 0.0078125ZM39.1992 40.5488H18.0322C17.6196 40.5488 17.2852 40.8833 17.2852 41.2959C17.2852 41.7085 17.6196 42.043 18.0322 42.043H39.1992V47.2725C39.1992 48.5083 38.1939 49.5137 36.958 49.5137H15.0439C13.8081 49.5137 12.8027 48.5083 12.8027 47.2725V42.043H15.0439C15.4565 42.043 15.791 41.7085 15.791 41.2959C15.791 40.8833 15.4565 40.5488 15.0439 40.5488H12.8027V7.47852H39.1992V40.5488ZM39.1992 5.98438H12.8027V3.74316C12.8027 2.50731 13.8081 1.50195 15.0439 1.50195H36.958C38.1939 1.50195 39.1992 2.50731 39.1992 3.74316V5.98438Z"
          fill={props.fill ?? customStyles.icons.fill}
        />
        <path
          d="M28.9893 2.99609H26.001C25.5884 2.99609 25.2539 3.33058 25.2539 3.74316C25.2539 4.15575 25.5884 4.49023 26.001 4.49023H28.9893C29.4018 4.49023 29.7363 4.15575 29.7363 3.74316C29.7363 3.33058 29.4018 2.99609 28.9893 2.99609Z"
          fill={props.fill ?? customStyles.icons.fill}
        />
        <path
          d="M25.999 43.5371C24.7632 43.5371 23.7578 44.5425 23.7578 45.7783C23.7578 47.0142 24.7632 48.0195 25.999 48.0195C27.2349 48.0195 28.2402 47.0142 28.2402 45.7783C28.2402 44.5425 27.2349 43.5371 25.999 43.5371ZM25.999 46.5254C25.587 46.5254 25.252 46.1903 25.252 45.7783C25.252 45.3663 25.587 45.0312 25.999 45.0312C26.411 45.0312 26.7461 45.3663 26.7461 45.7783C26.7461 46.1903 26.411 46.5254 25.999 46.5254Z"
          fill={props.fill ?? customStyles.icons.fill}
        />
        <path
          d="M23.0127 4.49023C23.4253 4.49023 23.7598 4.15576 23.7598 3.74316C23.7598 3.33057 23.4253 2.99609 23.0127 2.99609C22.6001 2.99609 22.2656 3.33057 22.2656 3.74316C22.2656 4.15576 22.6001 4.49023 23.0127 4.49023Z"
          fill={props.fill ?? customStyles.icons.fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_3044_25621">
          <rect
            width="51"
            height="51"
            fill="white"
            transform="translate(0.5 0.0078125)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MobileSvg;
