import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { StereInsuranceStyles } from "stere-insurance-sdk/dist/types";

import { StylesState } from "customTypes/FunctionalStylesProps";
import { base } from "theme/themes";

const initialState: StylesState = {
  customStyles: base,
};

const stylesSlice = createSlice({
  name: "styles",
  initialState,
  reducers: {
    setCustomStyles: (state, action: PayloadAction<StereInsuranceStyles>) => {
      state.customStyles = action.payload;
    },
  },
});

export const { setCustomStyles } = stylesSlice.actions;
export default stylesSlice.reducer;
