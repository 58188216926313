import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress, {
  circularProgressClasses,
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import { animateOpacityProps } from "constants/common";
import { useSelector } from "react-redux";
import { customStylesSelector } from "store/selectors/selectors";
import { RootState } from "store/store";

function CircularProgressLoader(props: CircularProgressProps) {
  const { loaderText } = useSelector((state: RootState) => state.loader);
  const customStyles = useSelector(customStylesSelector);
  return (
    <Box
      sx={{
        display: "flex",
        minHeight: "80%",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
      {...animateOpacityProps}
    >
      {loaderText && (
        <Typography
          sx={{
            color: customStyles?.startScreen?.color,
            fontSize: "20px",
            mb: "10px",
            px: "20px",
            textAlign: "center",
          }}
        >
          {loaderText}
        </Typography>
      )}
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: customStyles?.startScreen?.color,
          animationDuration: "550ms",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        size={40}
        thickness={4}
        {...props}
      />
    </Box>
  );
}

export default CircularProgressLoader;
