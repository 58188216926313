import { callApi } from "../base";

const generateClientSecret = (data: GenerateClientSecretPayload) =>
  callApi<GenerateClientSecretResponse>({
    endpoint: "/payments/generate-client-secret",
    method: "POST",
    body: data,
  });

const markInvoicePaid = (invoiceId: string) =>
  callApi<GenerateClientSecretResponse>({
    endpoint: "/payments/mark-invoice-paid",
    method: "POST",
    body: { invoiceId },
  });

const paymentApiUtil = {
  generateClientSecret,
  markInvoicePaid,
};

export default paymentApiUtil;
