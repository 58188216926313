import { AxiosResponse } from "axios";
import { IS_BROKER_PORTAL } from "config";

import {
  createAxiosInstanceWithAccessToken,
  createAxiosInstanceWithApiKey,
} from "utils/utils";

const BASE_URL = process.env.REACT_APP_SERVER_URL + "/v1";

export async function createEpfPaymentIntent(
  key: string,
  payload: EpfPaymentIntentPayload
): Promise<AxiosResponse<AxiosCommonResponse<EpfPaymentIntentResponse>>> {
  const QuoteAPI = IS_BROKER_PORTAL
    ? createAxiosInstanceWithAccessToken()
    : createAxiosInstanceWithApiKey(key);

  return await QuoteAPI.post(`${BASE_URL}/anddone/epf-payment-intent`, payload);
}

export async function createPayInFullPaymentIntent(
  key: string,
  payload: EpfPaymentIntentPayload
): Promise<AxiosResponse<AxiosCommonResponse<PayInFullPaymentIntentResponse>>> {
  const QuoteAPI = IS_BROKER_PORTAL
    ? createAxiosInstanceWithAccessToken()
    : createAxiosInstanceWithApiKey(key);

  return await QuoteAPI.post(`${BASE_URL}/anddone/payment-intent`, payload);
}

export async function getAndDonePaymentStatus(
  key: string,
  paymentToken: string
): Promise<AxiosResponse<AxiosCommonResponse<AndDonePaymentStatusResponse>>> {
  const QuoteAPI = IS_BROKER_PORTAL
    ? createAxiosInstanceWithAccessToken()
    : createAxiosInstanceWithApiKey(key);

  return await QuoteAPI.get(
    `${BASE_URL}/anddone/payment-status/${paymentToken}`
  );
}

export async function confirmAndDonePayment(
  key: string,
  payload: ConfirmAndDonePaymentPayload
): Promise<AxiosResponse<AxiosCommonResponse<AndDonePaymentStatusResponse>>> {
  const QuoteAPI = IS_BROKER_PORTAL
    ? createAxiosInstanceWithAccessToken()
    : createAxiosInstanceWithApiKey(key);

  return await QuoteAPI.post(
    `${BASE_URL}/anddone/:payment_confirmation`,
    payload
  );
}
