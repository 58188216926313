import { AllianzSvg } from "assets/brokers/Allianz";
import { BrokerySvg } from "assets/brokers/BrokerySvg";
import { ChubbSvg } from "assets/brokers/ChubbSvg";
import { MeupetLogo } from "assets/brokers/Meupet";
import { StereLogo } from "assets/brokers/StereLogo";
import { BOPSvg } from "assets/insurers/BopSvg";
import { CyberSvg } from "assets/insurers/CyberSvg";
import { DroneSvg } from "assets/insurers/DroneSvg";
import { ENOSvg } from "assets/insurers/ENOSvg";
import { GeneralLiabilitySvg } from "assets/insurers/GeneralLiabilitySvg";
import { HealthSvg } from "assets/insurers/HealthSvg";
import { ProductLiabilitySvg } from "assets/insurers/ProductLiabilitySvg";
import { PetSvg } from "assets/products/PetSvg";
import { JwlSvg } from "./insurers/JwlSvg";

export const ProductIconMapping = {
  GL: GeneralLiabilitySvg,
  BOP: BOPSvg,
  CY: CyberSvg,
  PL: ProductLiabilitySvg,
  "E&O": ENOSvg,
  PET: PetSvg,
  SHC: PetSvg,
  "PET-MEU": PetSvg,
  DRONE: DroneSvg,
  HEALTH: HealthSvg,
  JWL: JwlSvg
};

export const InsurerLogoMapping = {
  CHUBB: ChubbSvg,
  STERE: StereLogo,
  ALLIANZ: AllianzSvg,
  MEU: MeupetLogo,
  BROKERY: BrokerySvg,
  PARAMOUNT: MeupetLogo,
};

export const InsurerNameMapping = {
  CHUBB: "Chubb",
  STERE: "Stere",
  ALLIANZ: "Allianz",
  MEU: "Peti",
  BROKERY: "Brokery",
  PARAMOUNT: "Paramount",
};
