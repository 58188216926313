export enum ServiceStatusCodes {
  HTTP_200 = 200,
  HTTP_201 = 201,
  HTTP_400 = 400,
  HTTP_401 = 401,
  HTTP_403 = 403,
  HTTP_406 = 406,
  HTTP_404 = 404,
  HTTP_422 = 422,
}
