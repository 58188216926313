import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { globalStateSelector } from "store/selectors/selectors";

const FullScreenLoader: React.FC = () => {
  const { globalLoaderMessage } = useSelector(globalStateSelector);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "4px",
        height: "100vh",
        width: "100vw",
      }}
    >
      <CircularProgress color="inherit" />
      <Typography sx={{ fontSize: "20px" }}>
        {globalLoaderMessage || "Loading..."}
      </Typography>
    </Box>
  );
};

export default FullScreenLoader;
