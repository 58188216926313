import { SVGProps } from "react";
import { useSelector } from "react-redux";
import { customStylesSelector } from "store/selectors/selectors";

const GeneralLiabilitySvg = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  const customStyles = useSelector(customStylesSelector);
  return (
    <svg
      width="53"
      height="51"
      viewBox="0 0 53 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M39.6496 40.5019C39.3681 40.5019 39.0886 40.3813 38.8944 40.1478C38.5482 39.731 38.6051 39.1131 39.0219 38.7669L45.185 33.6444C45.6019 33.2982 46.2207 33.356 46.566 33.7719C46.9122 34.1887 46.8553 34.8066 46.4385 35.1528L40.2753 40.2753C40.0929 40.4283 39.8703 40.5019 39.6496 40.5019Z"
        fill={props.fill ?? customStyles.icons.fill}
      />
      <path
        d="M39.6486 40.5019C39.427 40.5019 39.2053 40.4274 39.0219 40.2753L35.65 37.4733C35.2332 37.1271 35.1763 36.5092 35.5226 36.0924C35.8697 35.6756 36.4876 35.6197 36.9035 35.9649L40.2753 38.7669C40.6922 39.1131 40.749 39.731 40.4028 40.1478C40.2096 40.3813 39.9301 40.5019 39.6486 40.5019Z"
        fill={props.fill ?? customStyles.icons.fill}
      />
      <path
        d="M21.3662 49.0385C21.1965 49.0385 21.0278 48.9953 20.8758 48.9071L0.490617 37.1379C0.18658 36.9633 0.000234761 36.6387 0.000234761 36.2885V12.7502C0.000234761 12.4001 0.18658 12.0754 0.490617 11.9008L20.8758 0.131668C21.1798 -0.0438892 21.5525 -0.0438892 21.8566 0.131668L42.2418 11.9008C42.5458 12.0764 42.7321 12.4001 42.7321 12.7502V19.4959C42.7321 20.0373 42.2928 20.4766 41.7514 20.4766C41.21 20.4766 40.7706 20.0373 40.7706 19.4959V13.3161L21.3662 2.11379L1.96176 13.3161V35.7226L21.3662 46.9249L28.1384 43.0146C28.6082 42.7429 29.2064 42.9048 29.4781 43.3736C29.7488 43.8434 29.5879 44.4426 29.1191 44.7133L21.8566 48.9061C21.7046 48.9953 21.5359 49.0385 21.3662 49.0385Z"
        fill={props.fill ?? customStyles.icons.fill}
      />
      <path
        d="M41.0452 51C40.9236 51 40.803 50.9774 40.6882 50.9333C33.7738 48.2313 29.128 41.4277 29.128 34.0043V30.0813C29.128 29.6792 29.3732 29.3182 29.7468 29.1701C33.5718 27.65 37.1732 25.6149 40.4519 23.1218C40.802 22.855 41.2894 22.855 41.6396 23.1218C44.9153 25.6149 48.5167 27.65 52.3436 29.1701C52.7163 29.3182 52.9615 29.6792 52.9615 30.0813V34.0043C52.9615 41.4277 48.3156 48.2303 41.4012 50.9333C41.2875 50.9774 41.1659 51 41.0452 51ZM31.0895 30.7413V34.0043C31.0895 40.498 35.0743 46.4591 41.0452 48.961C47.0151 46.46 51 40.498 51 34.0043V30.7413C47.4653 29.279 44.1229 27.393 41.0452 25.1265C37.9646 27.395 34.6222 29.28 31.0895 30.7413Z"
        fill={props.fill ?? customStyles.icons.fill}
      />
      <path
        d="M9.21256 18.4828C8.87321 18.4828 8.54367 18.3072 8.36223 17.9924C8.09154 17.5226 8.25239 16.9233 8.72119 16.6526L29.1064 4.88347C29.5752 4.6118 30.1744 4.77363 30.4461 5.24243C30.7168 5.71222 30.556 6.31147 30.0871 6.58216L9.70196 18.3513C9.54798 18.4406 9.37929 18.4828 9.21256 18.4828Z"
        fill={props.fill ?? customStyles.icons.fill}
      />
      <path
        d="M13.1356 20.7483C12.7963 20.7483 12.4667 20.5728 12.2853 20.2579C12.0146 19.7882 12.1754 19.1889 12.6443 18.9182L33.0294 7.14904C33.4973 6.87835 34.0975 7.03919 34.3692 7.508C34.6399 7.97778 34.479 8.57703 34.0102 8.84772L13.625 20.6169C13.471 20.7061 13.3023 20.7483 13.1356 20.7483Z"
        fill={props.fill ?? customStyles.icons.fill}
      />
      <path
        d="M13.1346 27.5431C12.5933 27.5431 12.1539 27.1037 12.1539 26.5623V19.7676C12.1539 19.2262 12.5933 18.7868 13.1346 18.7868C13.676 18.7868 14.1154 19.2262 14.1154 19.7676V26.5623C14.1154 27.1037 13.676 27.5431 13.1346 27.5431Z"
        fill={props.fill ?? customStyles.icons.fill}
      />
      <path
        d="M13.1337 27.5431C12.9679 27.5431 12.7982 27.5009 12.6443 27.4116L8.72119 25.1461C8.25141 24.8754 8.09154 24.2752 8.36223 23.8063C8.63391 23.3375 9.23315 23.1747 9.70196 23.4474L13.625 25.7129C14.0948 25.9836 14.2547 26.5839 13.984 27.0527C13.8025 27.3675 13.473 27.5431 13.1337 27.5431Z"
        fill={props.fill ?? customStyles.icons.fill}
      />
      <path
        d="M9.21158 25.2775C8.67019 25.2775 8.23081 24.8381 8.23081 24.2967V17.502C8.23081 16.9606 8.67019 16.5212 9.21158 16.5212C9.75296 16.5212 10.1923 16.9606 10.1923 17.502V24.2967C10.1923 24.8391 9.75296 25.2775 9.21158 25.2775Z"
        fill={props.fill ?? customStyles.icons.fill}
      />
      <path
        d="M21.3662 49.0385C20.8248 49.0385 20.3854 48.5991 20.3854 48.0577V24.5194C20.3854 23.978 20.8248 23.5386 21.3662 23.5386C21.9076 23.5386 22.347 23.978 22.347 24.5194V48.0577C22.347 48.5991 21.9076 49.0385 21.3662 49.0385Z"
        fill={props.fill ?? customStyles.icons.fill}
      />
      <path
        d="M21.3652 25.5001C21.1995 25.5001 21.0308 25.4579 20.8758 25.3687L0.490617 13.5995C0.0208307 13.3288 -0.139034 12.7296 0.131657 12.2598C0.402348 11.79 1.0016 11.6302 1.47138 11.9008L21.8566 23.67C22.3264 23.9407 22.4862 24.5409 22.2155 25.0097C22.0331 25.3246 21.7036 25.5001 21.3652 25.5001Z"
        fill={props.fill ?? customStyles.icons.fill}
      />
      <path
        d="M21.3672 25.5001C21.0278 25.5001 20.6983 25.3246 20.5168 25.0097C20.2462 24.54 20.407 23.9407 20.8758 23.67L41.261 11.9008C41.7288 11.6302 42.3291 11.791 42.6007 12.2598C42.8714 12.7296 42.7106 13.3288 42.2418 13.5995L21.8566 25.3687C21.7016 25.4579 21.5329 25.5001 21.3672 25.5001Z"
        fill={props.fill ?? customStyles.icons.fill}
      />
    </svg>
  );
};

export default GeneralLiabilitySvg;
