import { Box } from "@mui/material";

import CustomStepper from "./CustomStepper";

const Stepper = () => {
  return (
    <>
      <Box
        sx={{
          height: "100%",
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
          py: "40px",
          px: {
            sm: "20px",
            md: "30px",
            lg: "50px",
          },
        }}
      >
        <Box sx={{ marginY: "auto" }}>
          <CustomStepper />
        </Box>
      </Box>
    </>
  );
};

export default Stepper;
