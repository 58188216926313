import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import AccountSwitcher from "components/AccountSwitcher/AccountSwitcher";
import CustomerDetailsPopup from "components/common/Header/CustomerDetailsPopup";
import AnimatedButton from "components/custom/AnimatedButton";
import { FAIRFAX, PORTAL_NAME } from "config";
import { useFlags } from "flagsmith/react";
import { initialCurrentStepSelector } from "portalConfig/selectors";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { updateStartPageSelection } from "store/features/clientSlice";
import { resetQuotes } from "store/features/quotesSlice";
import { resetStepper } from "store/features/stepperSlice";
import { setSurveyPageNumber } from "store/features/surveyJsSlice";
import { authSelector, customStylesSelector } from "store/selectors/selectors";
import { useT } from "translation";
import { LSKeys, getFromLocalStorage } from "utils/ls.utils";
import { IsAgent } from "utils/utils";
import CustomButton from "../CustomButton/CustomButton";
import LanguagePopover from "./LanguagePopover";
import UserPopover from "./UserPopover/UserPopover";
const NOTIFICATION_PAGE = "/home/v2/notifications";
const HeaderRightSection = () => {
  const { t } = useT();
  const { portalAccount } = useSelector(authSelector);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const customStyles = useSelector(customStylesSelector);
  const [isCustomerDetailsModalOpen, setIsCustomerDetailsModalOpen] =
    useState(false);
  const initialCurrentStep = useSelector(initialCurrentStepSelector);
  const location = useLocation();
  const { v2_notifications } = useFlags(["v2_notifications"]);

  const onNewQuoteClick = () => {
    if (PORTAL_NAME === FAIRFAX) {
      setIsCustomerDetailsModalOpen(true);
    } else {
      dispatch(resetStepper(initialCurrentStep));
      dispatch(resetQuotes());
      dispatch(updateStartPageSelection([]));
      dispatch(setSurveyPageNumber(0));
      navigate("/newQuote/start");
    }
  };
  const IS_USER_AUTH_V2 = !!getFromLocalStorage(LSKeys.ACCESS_TOKEN);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {IsAgent(portalAccount) && (
          <CustomButton
            data_cy="new-quote-button"
            onClickCB={onNewQuoteClick}
            btnText={t("NEW_QUOTE")}
          />
        )}
        {/* // TODO: Add feature flag for account switcher */}
        {IS_USER_AUTH_V2 && <AccountSwitcher />}
        <Link to={NOTIFICATION_PAGE}>
          {v2_notifications.enabled && (
            <AnimatedButton
              variant={
                location.pathname === NOTIFICATION_PAGE
                  ? "contained"
                  : "outlined"
              }
              sx={{
                position: "relative",
                ml: "20px",
                padding: "8px 0px",
                borderRadius: "12px",
                border: `1px solid ${customStyles?.muiTheme?.palette?.primary?.main}`,
              }}
            >
              <NotificationsActiveOutlinedIcon />
            </AnimatedButton>
          )}
        </Link>
        <LanguagePopover />
        <Box ml={"20px"}>
          <UserPopover />
        </Box>
      </Box>
      <CustomerDetailsPopup
        isOpen={isCustomerDetailsModalOpen}
        setIsOpen={setIsCustomerDetailsModalOpen}
      />
    </>
  );
};
export default HeaderRightSection;
