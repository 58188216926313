import { Box, CssBaseline } from "@mui/material";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Outlet } from "react-router";
import { PolicyholderThemeProvider } from "theme/PolicyholderThemeProvider";

export function PageLayout() {
  return (
    <PolicyholderThemeProvider>
      <QueryClientProvider client={new QueryClient()}>
        <Box height="100vh" width="100vw" sx={{ overflowY: "auto" }}>
          <CssBaseline />
          <Outlet />
        </Box>
      </QueryClientProvider>
    </PolicyholderThemeProvider>
  );
}
