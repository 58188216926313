import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { COUNTRY, IS_BROKER_PORTAL } from "config";
import {
  addOrRemoveQuote,
  addOrRemoveQuoteForSendingEmail,
} from "store/features/quotesSlice";
import {
  customStylesSelector,
  quotesSelector,
} from "store/selectors/selectors";
import { RootState } from "store/store";
import { useT } from "translation";
import { CurrencyPrefix, formatCurrency } from "utils/utils";
import { InsurerLogoMapping } from "../utils";
import Coverages from "./Coverages";
import QuotesMissing from "./QuotesMissing";

const PlanPriceCard: React.FC<{
  plan: Quote;
}> = ({ plan }) => {
  const { lang } = useT();
  const dispatch = useDispatch();

  const customStyles = useSelector(customStylesSelector);
  const { selectedQuotes, sentQuotesToken, selectedQuotesForSendingEmail } =
    useSelector(quotesSelector);

  const productIdentifier = useSelector(
    (state: RootState) =>
      state.client.availableProducts[0]?.product?.product_identifier
  );

  const [hasSpan, setHasSpan] = useState(false);
  const [isSelected, setIsSelected] = useState(
    selectedQuotes.find((sq) => sq.id === plan.id)
  );

  const selected = selectedQuotes.find((sq) => sq.id === plan.id);
  const selectedForSendingEmail =
    selectedQuotesForSendingEmail.find((sq) => sq.id === plan.id) ||
    plan?.status === "shortlisted" ||
    plan?.status === "bound";

  useEffect(() => {
    setIsSelected(selected);
  }, [selectedQuotes]);

  return (
    <Box sx={{ overflow: "visible", position: "relative" }}>
      {IS_BROKER_PORTAL && (
        <Box
          data-cy={`${plan?.product_details?.identifier?.toLowerCase()}-${plan?.premium?.additional_info?.plan_name?.default
            .replaceAll(" ", "-")
            .toLowerCase()}`}
          sx={{
            position: "absolute",
            zIndex: 1,
            top: "-8px",
            right: "-8px",
            cursor: "pointer",
          }}
          onClick={() =>
            !sentQuotesToken && dispatch(addOrRemoveQuoteForSendingEmail(plan))
          }
        >
          <CheckCircleIcon
            sx={{
              transition: "0.3s",
              background: "white",
              borderRadius: "12px",
              color: selectedForSendingEmail
                ? "#4CAF50"
                : "rgba(0, 0, 0, 0.54)",
            }}
          />
        </Box>
      )}
      <Box
        data-cy={`${plan.product_details.type}-plan-card`}
        className={`priceCard ${isSelected?.id ? "plan-selected" : ""}`}
        onClick={() => {
          dispatch(addOrRemoveQuote(plan));
          if (!selected) {
            setHasSpan(true);
          }
          setTimeout(() => {
            setHasSpan(false);
          }, 1000);
        }}
        sx={{
          border: "2px solid transparent",
          borderRadius: "12px",
          overflow: "hidden",
          display: "flex",
          minWidth: "60px",
          flexDirection: "column",
          gap: "10px",
          alignItems: "center",
          px: "30px",
          py: "10px",
          cursor: "pointer",
          transition: "0.3s",
          backgroundColor: customStyles.secondaryCard.backgroundColor,
          borderColor: isSelected
            ? customStyles.secondaryCard.active.borderColor
            : "transparent",
          "&:hover": {
            borderColor: isSelected
              ? customStyles.secondaryCard.active.borderColor
              : customStyles.secondaryCard.hover.borderColor,
          },
        }}
      >
        {hasSpan && <span className="ripple"></span>}
        <Box>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: 400,
              color: customStyles.muiTheme.palette.text.tertiary,
              textAlign: "center",
            }}
          >
            {plan?.premium?.additional_info?.plan_name?.[lang] ??
              plan?.premium?.additional_info?.plan_name?.default}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            <Typography
              sx={{
                fontSize: "12px",
                mb: "5px",
                fontWeight: 700,
                color: customStyles.muiTheme.palette.text.secondary,
              }}
            >
              {CurrencyPrefix}
            </Typography>
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: 700,
                color: customStyles.muiTheme.palette.text.secondary,
              }}
            >
              {formatCurrency(plan.premium.premium_value)}
            </Typography>
            {COUNTRY === "sg" && productIdentifier === "PET" && (
              <Typography
                sx={{
                  fontSize: "12px",
                  mb: "5px",
                  color: customStyles.muiTheme.palette.text.secondary,
                }}
              >
                /{plan.premium.term}
              </Typography>
            )}
          </Box>
          {COUNTRY === "sg" && productIdentifier === "PET" && (
            <Typography sx={{ fontSize: "11px" }}>Exclusive of GST</Typography>
          )}
        </Box>
        <Box>{InsurerLogoMapping[plan.product_details.insurer]}</Box>
      </Box>
    </Box>
  );
};

const ProductQuoteCard = ({
  prodIcon,
  prodTitle,
  plans,
}: {
  prodIcon: any;
  prodTitle: string;
  plans: Quote[];
}) => {
  const customStyles = useSelector(customStylesSelector);
  const { selectedQuotes } = useSelector(quotesSelector);
  const dispatch = useDispatch();

  const getSelectedQuote = () => {
    return plans.find((p) => selectedQuotes?.find((sp) => sp.id === p.id));
  };
  const isMobile = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    if (
      !selectedQuotes.some(
        (sq) =>
          sq.product_details?.type?.toLowerCase() ===
          plans[0]?.product_details?.type?.toLowerCase()
      )
    ) {
      const monthlyPlans = plans.filter((q) => q?.premium?.monthly);
      if (monthlyPlans.length) {
        dispatch(addOrRemoveQuote(monthlyPlans[0]));
      } else {
        dispatch(addOrRemoveQuote(plans[0]));
      }
    }
  }, []);

  const Plans = (
    <Box
      sx={{
        display: "flex",
        gap: "20px",
        flexWrap: "wrap",
        justifyContent: isMobile ? "center" : "end",
      }}
    >
      {plans.map((plan) => (
        <PlanPriceCard plan={plan} key={plan?.id} />
      ))}
    </Box>
  );

  if (!plans?.length) {
    return <QuotesMissing />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        mx: "-16px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          minHeight: "116px",
          padding: "20px",
          borderRadius: "12px",
          backgroundColor: customStyles.primaryCard.backgroundColor,
          mt: "20px",
        }}
      >
        {/* prod details */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <Box
              sx={{
                padding: "10px",
                borderRadius: "12px",
                backgroundColor: customStyles.secondaryCard.backgroundColor,
              }}
            >
              <Box sx={{ width: "40px", height: "40px" }}>{prodIcon}</Box>
            </Box>

            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: 500,
                color: customStyles.muiTheme.palette.text.secondary,
              }}
            >
              {prodTitle}
            </Typography>
          </Box>

          {/* plans on desktop */}
          {!isMobile && Plans}
        </Box>
        {/* plans on mobile */}
        {isMobile && Plans}
      </Box>

      {/* coverage details of the selected plan */}
      <Coverages selectedQuote={getSelectedQuote()} showHeading={true} />
    </Box>
  );
};

export default ProductQuoteCard;
