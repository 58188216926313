import { fireWebhookEventAPI } from "api/siaApplication";
import { WebhooksRequest } from "customTypes/Webhooks";
import { call, select, takeEvery } from "redux-saga/effects";
import { fireWebhookEvent } from "store/features/webhooksSlice";
import { RootState } from "store/store";
import { sendMessageToClient } from "utils/utils";

export function* fireWebhookEventSaga(action) {
  try {
    const { clientAPIKey, applicantId, clientUrl } = yield select(
      (state: RootState) => state.client
    );
    const payload: WebhooksRequest = {
      ...action.payload,
      customer_system_id: applicantId,
    };
    sendMessageToClient(
      {
        type: action.payload.event_type?.toUpperCase(),
        details: {},
        metaData: { ...action.payload?.metadata },
      },
      clientUrl
    );
    yield call(fireWebhookEventAPI, clientAPIKey, payload);
  } catch (e) {
    console.error(e);
  }
}
export default function* webhooksSaga() {
  yield takeEvery(fireWebhookEvent.type, fireWebhookEventSaga);
}
