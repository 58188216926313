import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Box,
  List,
  ListItemButton,
  ListItemText,
  useTheme,
} from "@mui/material";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { useState } from "react";

import AnimatedButton from "components/custom/AnimatedButton";
import { locales } from "constants/common";
import { useSetTranslationLang, useT } from "translation";

export function LanguagePopover() {
  const { lang } = useT();
  const theme = useTheme();
  const setTranslationLang = useSetTranslationLang();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [showLangs, setShowLangs] = useState<boolean>(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setShowLangs(!showLangs);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setShowLangs(!showLangs);
    setAnchorEl(null);
  };

  const handleTranslationClick = () => {
    const changedLanguage = lang === "pt" ? "en" : "pt";
    setTranslationLang(changedLanguage);
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? "language-popover" : undefined;

  return (
    <Box>
      <AnimatedButton
        aria-describedby={id}
        onClick={handleClick}
        variant="outlined"
        sx={{
          position: "relative",
          //   color: customStyles?.muiTheme?.palette?.primary?.main,
          p: "12px",
          backgroundColor: "white",
          borderRadius: "12px",
          border: `1px solid ${theme?.palette?.primary?.main}`,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography sx={{ fontWeight: 500 }}>{lang.toUpperCase()}</Typography>
          {showLangs ? (
            <KeyboardArrowUpIcon
              style={{
                width: "0.8em",
                marginLeft: "5px",
                color: theme?.palette?.primary?.main,
              }}
            />
          ) : (
            <KeyboardArrowDownIcon
              style={{
                width: "0.8em",
                marginLeft: "5px",
                color: theme?.palette?.primary?.main,
              }}
            />
          )}
        </Box>
      </AnimatedButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{
          mt: "10px",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <List sx={{ p: "0px", minWidth: "150px" }}>
          {locales.map((l) => (
            <ListItemButton
              key={l.code}
              id={l.code}
              selected={lang === l.code}
              sx={{
                backgroundColor: lang === l.code ? "primary" : "secondary",
              }}
              onClick={() => {
                if (lang !== l.code) handleTranslationClick();
              }}
            >
              <ListItemText
                primary={
                  <Typography
                    sx={{
                      color:
                        lang === l.code
                          ? theme?.palette?.primary?.main
                          : theme?.palette?.secondary?.main,
                      textTransform: "none",
                    }}
                  >
                    {l.label}
                  </Typography>
                }
              />
            </ListItemButton>
          ))}
        </List>
      </Popover>
    </Box>
  );
}
