import CallIcon from "@mui/icons-material/CallOutlined";
import EmailIcon from "@mui/icons-material/EmailOutlined";
import { Avatar, Box, Typography } from "@mui/material";

type AgentInfo = {
  agentName: string;
  agentEmail: string;
  agentPhone: string;
};

export function AgentInfo({ agentEmail, agentName, agentPhone }: AgentInfo) {
  return (
    <Box
      sx={{
        display: "flex",
        gap: "40px",
        flexDirection: { xs: "column", xl: "row" },
      }}
    >
      <Avatar
        alt={agentName || "N/A"}
        src="assets/agent.png"
        variant="square"
        sx={{ width: 80, height: 80 }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "12px",
        }}
      >
        <Typography variant="h5">{agentName || "N/A"}</Typography>
        <Box
          sx={{
            display: "flex",
            gap: "14px",
            alignItems: "center",
          }}
        >
          <EmailIcon fontSize="small" />
          <Typography variant="subtitle1" sx={{ wordBreak: "break-all" }}>
            {agentEmail || "N/A"}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "14px",
            alignItems: "center",
          }}
        >
          <CallIcon fontSize="small" />
          <Typography variant="subtitle1">{agentPhone || "N/A"}</Typography>
        </Box>
      </Box>
    </Box>
  );
}
