import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface FlagSmithState {
  isFlagSmithLoading: boolean;
}

const initialState: FlagSmithState = {
  isFlagSmithLoading: false,
};

const flagSmithSlice = createSlice({
  name: "flagSmith",
  initialState,
  reducers: {
    setIsFlagSmithLoading: (state, action: PayloadAction<boolean>) => {
      state.isFlagSmithLoading = action.payload;
    },
  },
});

export const { setIsFlagSmithLoading } = flagSmithSlice.actions;

export default flagSmithSlice.reducer;
