export const generateApplicationsData = (data: any) => {
  let rows: ApplicationData[] = [];
  data.forEach((r: any) => {
    const newRow: ApplicationData = {
      applicationId: r.id,
      applicantName: r?.params?.full_name,
      date: r?.created_at,
      insurance: [],
      status: r?.status,
    };
    rows.push(newRow);
  });
  return rows;
};

export const statusMapping: {
  [key: string]: { label: string; backgroundColor: string; color: string };
} = {
  incomplete: {
    label: "APPLICATION_PAGE.status.draft",
    backgroundColor: "#F5F5F5",
    color: "#616161",
  },
  complete: {
    label: "APPLICATION_PAGE.status.submitted",
    backgroundColor: "#B9F6CA",
    color: "#00C853",
  },
};
