import { SVGProps } from "react";
import { useSelector } from "react-redux";

import { customStylesSelector } from "store/selectors/selectors";

const PropertySvg = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  const customStyles = useSelector(customStylesSelector);
  return (
    <svg
      width="51"
      height="51"
      viewBox="0 0 51 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_288_120)">
        <g clip-path="url(#clip1_288_120)">
          <path
            d="M45.621 24.7875L45.8307 24.9299C45.9969 25.0424 46.1918 25.1015 46.3895 25.1015C46.4521 25.1015 46.5151 25.0953 46.5774 25.0836C46.8369 25.0338 47.0661 24.8829 47.2143 24.6642L50.8283 19.3308C51.1368 18.8756 51.0178 18.2561 50.5625 17.9476L26.0587 1.34201C25.7213 1.11322 25.2785 1.11322 24.9412 1.34201L14.7421 8.25357V5.97657H15.539C16.0892 5.97657 16.5351 5.53066 16.5351 4.98047V0.996104C16.5351 0.445918 16.0892 1.14441e-05 15.539 1.14441e-05H5.57807C5.02788 1.14441e-05 4.58198 0.445918 4.58198 0.996104V4.98047C4.58198 5.53066 5.02788 5.97657 5.57807 5.97657H6.37494V13.9235L0.4373 17.9476C-0.0179455 18.2561 -0.13701 18.8756 0.171546 19.3308L3.78588 24.6642C3.93413 24.8829 4.16292 25.0338 4.42245 25.0836C4.68198 25.1334 4.95045 25.0778 5.16913 24.9299L5.37924 24.7875V49.0078H3.68588C3.1357 49.0078 2.68979 49.4537 2.68979 50.0038C2.68979 50.5536 3.1357 50.9999 3.68588 50.9999H47.3143C47.8645 50.9999 48.3104 50.5536 48.3104 50.0038C48.3104 49.4537 47.8645 49.0078 47.3143 49.0078H45.621V38.3558C45.621 37.8056 45.1751 37.3597 44.6249 37.3597C44.0747 37.3597 43.6288 37.8056 43.6288 38.3558V49.0078H7.37104V23.4378L25.4999 11.1524L43.6288 23.4378V29.391C43.6288 29.9411 44.0747 30.3871 44.6249 30.3871C45.1751 30.3871 45.621 29.9411 45.621 29.391V24.7875ZM6.57416 1.9922H14.5429V3.98438H6.57416V1.9922ZM8.36713 5.97657H12.7499V9.60335L8.36713 12.5737V5.97657ZM25.4999 8.95317C25.305 8.95317 25.11 9.00998 24.9412 9.12437L4.87614 22.7218L2.37968 19.0378L25.4999 3.36999L48.6202 19.0378L46.1237 22.7218L26.0587 9.12437C25.8902 9.00998 25.6949 8.95317 25.4999 8.95317Z"
            fill={props.fill ?? customStyles.icons.fill}
          />
          <path
            d="M44.625 32.9306C44.3631 32.9306 44.1059 33.0372 43.9207 33.2224C43.7355 33.408 43.6289 33.6648 43.6289 33.9267C43.6289 34.1897 43.7355 34.4458 43.9207 34.631C44.1059 34.8174 44.3631 34.9228 44.625 34.9228C44.8869 34.9228 45.1441 34.8174 45.3293 34.631C45.5145 34.4458 45.6211 34.1897 45.6211 33.9267C45.6211 33.6648 45.5145 33.4088 45.3293 33.2224C45.1441 33.0372 44.8869 32.9306 44.625 32.9306Z"
            fill={props.fill ?? customStyles.icons.fill}
          />
          <path
            d="M25.8218 18.4569C25.7194 18.4219 25.6113 18.4036 25.5008 18.4036C25.5004 18.4036 25.4996 18.4036 25.4992 18.4036C25.3891 18.4036 25.2805 18.4219 25.1786 18.4569L14.0469 21.9324C13.6309 22.0623 13.3477 22.4471 13.3477 22.8829V32.1995C13.3477 39.2585 18.3795 45.3464 25.3124 46.6747C25.3743 46.6868 25.4373 46.6926 25.5 46.6926C25.5626 46.6926 25.6253 46.6868 25.6875 46.6747C32.6201 45.3464 37.6523 39.2585 37.6523 32.1995V22.8829C37.6523 22.4471 37.369 22.0619 36.9531 21.9324L25.8218 18.4569ZM35.6601 32.1995C35.6601 38.2406 31.3991 43.4592 25.5 44.681C19.6012 43.4592 15.3398 38.2406 15.3398 32.1995V23.6156L25.5 20.4437L35.6601 23.6156V32.1995Z"
            fill={props.fill ?? customStyles.icons.fill}
          />
          <path
            d="M25.2031 22.6234L18.0312 24.8623C17.6153 24.9922 17.332 25.3774 17.332 25.8132V32.1995C17.332 37.0274 20.5826 41.2916 25.2362 42.5694C25.3226 42.5931 25.4113 42.6048 25.5 42.6048C25.5887 42.6048 25.6774 42.5931 25.7638 42.5694C30.4174 41.2916 33.6679 37.027 33.6679 32.1995V25.8132C33.6679 25.3774 33.3847 24.9922 32.9687 24.8623L25.7969 22.6234C25.6035 22.5627 25.3965 22.5627 25.2031 22.6234ZM31.6758 26.5455V32.1995C31.6758 36.0411 29.1497 39.4445 25.5 40.5713C21.8502 39.4445 19.3242 36.0411 19.3242 32.1995V26.5455L25.5 24.6175L31.6758 26.5455Z"
            fill={props.fill ?? customStyles.icons.fill}
          />
          <path
            d="M23.0168 31.3874C22.6281 30.9983 21.9973 30.9983 21.6082 31.3874C21.2191 31.7765 21.2191 32.4073 21.6082 32.7964L23.9031 35.0913C24.0977 35.2858 24.3525 35.3831 24.6074 35.3831C24.8623 35.3831 25.1175 35.2858 25.3117 35.0913L29.3918 31.0112C29.7809 30.6221 29.7809 29.9914 29.3918 29.6023C29.0027 29.2136 28.3719 29.2136 27.9828 29.6023L24.6074 32.9781L23.0168 31.3874Z"
            fill={props.fill ?? customStyles.icons.fill}
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_288_120">
          <rect width="51" height="51" fill="white" />
        </clipPath>
        <clipPath id="clip1_288_120">
          <rect width="51" height="51" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PropertySvg;
