import { Box, TablePagination } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";

import { LoadingSpinner } from "components/common/LoadingSpinner";
import { QueryKeys } from "constants/policyholder-query-keys";
import { PolicyService } from "policyholderApi/policy/policy";
import { PolicyListItem } from "./PolicyListItem";

export function PolicyList() {
  const [page, setPage] = useState(1);
  const policiesQuery = useQuery({
    queryFn: () => PolicyService.getPolicies({ page }),
    queryKey: QueryKeys.Policy.listing({ page }),
  });

  if (policiesQuery.isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
      {policiesQuery.data?.data.results.data.map((policy) => (
        <PolicyListItem policy={policy} key={policy?.id} />
      ))}
      <TablePagination
        component="div"
        count={policiesQuery.data?.data.results.meta.total || 0}
        page={page - 1}
        onPageChange={(_e, _page) => setPage(_page + 1)}
        rowsPerPage={5}
        labelRowsPerPage=""
        slotProps={{ select: { style: { display: "none" } } }}
        labelDisplayedRows={() => ""}
      />
    </Box>
  );
}
