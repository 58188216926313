import { useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { InferType } from "yup";

import AnimatedButton from "components/custom/AnimatedButton";
import { resetPasswordSchema } from "constants/policyholder-validation-schema";
import { PolicyholderPathnames } from "constants/policyholderPathnames";
import { useMutationWithToast } from "hooks/useMutationWithToast";
import { AuthService } from "policyholderApi/auth/auth";
import { useT } from "translation";

type ResetPasswordSchema = InferType<typeof resetPasswordSchema>;

export function ResetPasswordPage() {
  const navigate = useNavigate();
  const { t } = useT();
  const [searchParams] = useSearchParams();
  const [isPasswordResetSuccessful, setIsPasswordResetSuccessful] =
    useState(false);

  const resetPasswordMutation = useMutationWithToast({
    mutationFn: (newPasswords: string) => {
      const code = searchParams.get("code") as string;

      return AuthService.changePasswordAPI({ password: newPasswords, code });
    },
    onSuccess: () => setIsPasswordResetSuccessful(true),
  });
  const resetPasswordForm = useForm({
    resolver: yupResolver(resetPasswordSchema),
  });

  const onResetPasswordValid = (formData: ResetPasswordSchema) => {
    resetPasswordMutation.mutate(formData.password);
  };

  return (
    <form onSubmit={resetPasswordForm.handleSubmit(onResetPasswordValid)}>
      <Typography variant="h6" marginBottom={1}>
        {t("RESET_PASSWORD")}
      </Typography>

      <Typography color="textSecondary" variant="subtitle2" marginBottom={4}>
        {t("CHOOSE_NEW_PASSWORD")}
      </Typography>

      <TextField
        {...resetPasswordForm.register("password")}
        helperText={resetPasswordForm.formState.errors.password?.message}
        error={!!resetPasswordForm.formState.errors.password?.message}
        type="password"
        variant="outlined"
        label={t("PASSWORD")}
        fullWidth
        sx={{ mb: 4 }}
      />
      <TextField
        {...resetPasswordForm.register("confirmPassword")}
        helperText={resetPasswordForm.formState.errors.confirmPassword?.message}
        error={!!resetPasswordForm.formState.errors.confirmPassword?.message}
        type="password"
        variant="outlined"
        label={t("CONFIRM_PASSWORD")}
        fullWidth
        sx={{ mb: 4 }}
      />

      <LoadingButton
        color="primary"
        variant="contained"
        fullWidth
        sx={{ mb: 2 }}
        type="submit"
        loading={resetPasswordMutation.isPending}
      >
        {t("RESET_PASSWORD")}
      </LoadingButton>

      <Dialog
        open={isPasswordResetSuccessful}
        onClose={() => navigate({ pathname: PolicyholderPathnames.LoginPage })}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          <Typography color="primary" variant="h4">
            {t("RESET_PASSWORD_SUCCESSFUL")}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography color="textSecondary" variant="body2">
            {t("RESET_PASSWORD_SUCCESSFUL_MESSAGE")}
          </Typography>
        </DialogContent>
        <DialogActions>
          <AnimatedButton
            color="primary"
            variant="contained"
            onClick={() =>
              navigate({ pathname: PolicyholderPathnames.LoginPage })
            }
          >
            {t("LOGIN")}
          </AnimatedButton>
        </DialogActions>
      </Dialog>
    </form>
  );
}
