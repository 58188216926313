import {
  IAcceptInvite,
  ICreateMemberInvite,
  IFetchMemberPayload,
} from "modules/Agents/types";
import api from "utils/api.utils";

export const inviteMemberApi = async (
  data: ICreateMemberInvite,
  accountId: string
): Promise<unknown> => {
  try {
    const response = await api.post("members", data, {
      "X-ACCOUNT-ID": accountId,
    });
    return response;
  } catch (err) {
    throw err;
  }
};

export const fetchMembersApi = async (
  data: IFetchMemberPayload,
  accountId: string
): Promise<any> => {
  try {
    const response = await api.post("members/filter", data, {
      "X-ACCOUNT-ID": accountId,
    });
    return response;
  } catch (err) {
    throw err;
  }
};

export const acceptMemberInvite = async (data: IAcceptInvite) => {
  try {
    const response = await api.post("members/accept-invite", data);
    return response;
  } catch (err) {
    throw err;
  }
};
