import { Grid } from "@mui/material";
import NotificationCard from "components/v2/Notifications/NotificationCard";

function NotificationsList() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <NotificationCard
          description={"Notification 1"}
          timeStamp={new Date()}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <NotificationCard
          description={"Notification 1"}
          timeStamp={new Date()}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <NotificationCard
          description={"Notification 1"}
          timeStamp={new Date()}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <NotificationCard
          description={"Notification 1"}
          timeStamp={new Date()}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <NotificationCard
          description={"Notification 1"}
          timeStamp={new Date()}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <NotificationCard
          description={"Notification 1"}
          timeStamp={new Date()}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <NotificationCard
          description={"Notification 1"}
          timeStamp={new Date()}
        />
      </Grid>
    </Grid>
  );
}

export default NotificationsList;
