import { TableCell, TableHead, TableRow, Typography } from "@mui/material";
import React from "react";

const tableCellStyle = { color: "black", paddingLeft: "0px" };

interface TableHeaderComponentProps {
  headCells: TableComponentCell[];
}

const TableHeaderComponent: React.FC<TableHeaderComponentProps> = ({
  headCells,
}) => {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell sx={tableCellStyle} key={headCell.id} align="left">
            <Typography sx={{ fontSize: "0.875rem", fontWeight: 500 }}>
              {headCell.label}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default TableHeaderComponent;
