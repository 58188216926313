import * as React from "react";
const FairfaxLogo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="126"
    height="36"
    viewBox="0 0 1011 284"
    fill="none"
  >
    <path
      d="M494.828 116.825C503.09 116.825 509.636 114.539 514.468 109.968C519.3 105.397 521.717 99.2502 521.717 91.5267C521.717 83.646 519.3 77.4202 514.468 72.8493C509.636 68.2783 503.09 65.9928 494.828 65.9928H466.537V116.825H494.828ZM529.082 43.1774C538.045 47.6696 544.981 54.0924 549.891 62.4461C554.801 70.7997 557.257 80.4934 557.257 91.5267C557.257 102.56 554.801 112.214 549.891 120.489C544.981 128.764 538.045 135.147 529.082 139.64C520.119 144.132 509.792 146.378 498.102 146.378H466.537V201.938H431.465V36.4392H498.102C509.792 36.4392 520.119 38.6853 529.082 43.1774Z"
      fill="#1F74DB"
    />
    <path
      d="M607.289 201.938H574.321V36.4393H607.289V201.938Z"
      fill="#1F74DB"
    />
    <path
      d="M680.472 149.451C673.613 149.451 668.274 150.83 664.455 153.589C660.636 156.346 658.727 160.247 658.727 165.291C658.727 169.547 660.324 172.936 663.52 175.458C666.715 177.98 671.04 179.24 676.497 179.24C685.07 179.24 691.85 176.758 696.838 171.793C701.826 166.828 704.399 160.168 704.554 151.815V149.451H680.472ZM748.512 201.938H731.676C716.557 201.938 709.153 195.318 709.464 182.077C705.1 189.012 699.722 194.293 693.331 197.918C686.94 201.543 679.224 203.356 670.183 203.356C656.778 203.356 645.983 200.243 637.8 194.017C629.617 187.791 625.525 179.004 625.525 167.655C625.525 154.731 630.24 144.762 639.671 137.748C649.101 130.734 662.779 127.227 680.705 127.227H704.554V121.316C704.554 115.799 702.528 111.426 698.474 108.195C694.423 104.963 688.966 103.348 682.108 103.348C676.029 103.348 671.04 104.687 667.144 107.367C663.247 110.047 660.909 113.672 660.13 118.243H628.33C629.422 105.791 634.877 96.1366 644.698 89.2802C654.518 82.4238 667.534 78.9958 683.744 78.9958C700.735 78.9958 713.868 82.7785 723.142 90.3444C732.417 97.9097 737.054 108.864 737.054 123.208V166.001C737.054 168.996 737.678 171.045 738.925 172.148C740.172 173.251 742.12 173.802 744.771 173.802H748.512V201.938Z"
      fill="#1F74DB"
    />
    <path
      d="M818.651 203.356C806.181 203.356 795.269 200.795 785.917 195.672C776.564 190.55 769.355 183.26 764.289 173.802C759.223 164.346 756.69 153.471 756.69 141.176C756.69 128.725 759.223 117.81 764.289 108.431C769.355 99.0528 776.603 91.8022 786.034 86.68C795.464 81.5572 806.414 78.9958 818.885 78.9958C834.94 78.9958 848.034 83.1332 858.165 91.4086C868.298 99.6834 874.532 111.15 876.87 125.808H842.734C841.331 119.819 838.526 115.051 834.316 111.504C830.108 107.958 824.886 106.185 818.651 106.185C810.078 106.185 803.22 109.417 798.075 115.878C792.931 122.341 790.359 130.774 790.359 141.176C790.359 151.579 792.931 160.011 798.075 166.474C803.22 172.936 810.078 176.167 818.651 176.167C825.042 176.167 830.381 174.354 834.667 170.729C838.954 167.104 841.72 162.139 842.968 155.834H876.87C874.688 170.65 868.492 182.274 858.283 190.707C848.073 199.14 834.862 203.356 818.651 203.356Z"
      fill="#1F74DB"
    />
    <path
      d="M968.875 109.85C963.965 105.83 957.769 103.821 950.286 103.821C943.116 103.821 937.076 105.909 932.166 110.086C927.255 114.263 924.333 119.897 923.398 126.99H977.876C976.786 119.582 973.784 113.869 968.875 109.85ZM917.202 86.68C926.321 81.5572 936.881 78.9958 948.884 78.9958C961.042 78.9958 971.758 81.3996 981.033 86.2064C990.307 91.0145 997.556 97.7915 1002.78 106.539C1008 115.287 1010.69 125.572 1010.84 137.393C1010.84 141.649 1010.53 145.432 1009.91 148.742H922.696V149.687C923.476 158.513 926.282 165.449 931.114 170.493C935.946 175.537 942.57 178.059 950.988 178.059C957.69 178.059 963.263 176.6 967.705 173.684C972.148 170.769 975.071 166.473 976.473 160.8H1008.97C1007.73 168.838 1004.69 176.088 999.855 182.55C995.022 189.012 988.632 194.096 980.683 197.8C972.733 201.504 963.614 203.356 953.326 203.356C939.921 203.356 928.347 200.834 918.605 195.79C908.862 190.747 901.38 183.536 896.159 174.157C890.937 164.779 888.325 153.864 888.325 141.412C888.325 128.803 890.859 117.809 895.925 108.431C900.991 99.0528 908.083 91.8022 917.202 86.68Z"
      fill="#1F74DB"
    />
    <path
      d="M359.938 142.043L326.493 80.624H373.104L405.594 140.237L406.656 142.043L405.594 143.85L373.104 203.356H326.493L359.938 142.043ZM280.731 142.043L247.285 80.624H293.897L326.387 140.237L327.448 142.043L326.387 143.85L293.897 203.356H247.285L280.731 142.043Z"
      fill="black"
    />
    <path
      d="M0 0.290634H136.755V42.7955H45.6557V80.6244H136.862V123.129H45.6557V203.462H0V0.290634Z"
      fill="#1F74DB"
    />
    <path
      d="M84.6227 80.6241H221.378V123.129H130.172V160.958H221.378V203.462H130.172V283.796H84.6227V80.6241Z"
      fill="#1F74DB"
    />
  </svg>
);
export default FairfaxLogo;
