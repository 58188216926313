import { Box } from "@mui/material";
import { useSelector } from "react-redux";

import SectionInfo from "components/common/SectionInfo/SectionInfo";
import CircularProgressLoader from "components/loader/Loader";
import { animateRouteProps } from "constants/common";
import { startStepLoadingValueSelector } from "store/selectors/selectors";
import { useT } from "translation";
import StartSectionTabPanel from "./StartSectionTabPanel";

const Start = () => {
  const { t } = useT();
  const loadingState = useSelector(startStepLoadingValueSelector);

  return (
    <Box
      sx={{
        pl: { xs: "20px", sm: "40px", md: "20px", lg: "20px" },
        pr: { xs: "20px", sm: "40px", md: "20px", lg: "20px" },
      }}
      {...animateRouteProps}
    >
      {loadingState ? (
        <CircularProgressLoader />
      ) : (
        <>
          <SectionInfo
            subTitle={t("START_PAGE_DESCRIPTION_PRIMARY")}
            description={t("START_PAGE_DESCRIPTION_SECONDARY")}
          />
          <StartSectionTabPanel />
        </>
      )}
    </Box>
  );
};

export default Start;
