import { Box, Typography } from "@mui/material";

import { DocumentListItem } from "./DocumentListItem";

interface DocumentListProps {
  documents: StereDocument[];
}

export function DocumentList({ documents = [] }: DocumentListProps) {
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {!documents || documents.length === 0 ? (
        <Typography variant="body2" color="textSecondary">
          No documents found.
        </Typography>
      ) : null}
      {documents?.map((document) => (
        <DocumentListItem key={document.id} id="1" document={document} />
      ))}
    </Box>
  );
}
