import { sendQuotesAPI } from "api/quotes";
import { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";

import {
  sendQuotes,
  setSendingQuotes,
  setSentQuotesError,
  setSentQuotesToken,
} from "store/features/quotesSlice";

export function* sendQuotesSaga(action) {
  try {
    const response: AxiosResponse<AxiosCommonResponse<
      SendQuotesResponse
    >> = yield call(sendQuotesAPI, action.payload);
    if (response?.status === 200) {
      if (
        response?.data?.success &&
        response?.data?.errorObject?.message === null
      ) {
        yield put(setSentQuotesToken(response?.data?.results?.token));
      } else if (
        response?.data?.success === false &&
        response?.data?.errorObject?.message !== null
      ) {
        yield put(setSentQuotesError(response?.data?.errorObject?.message));
      }
    }
  } catch (e) {
    console.error(e);
  } finally {
    yield put(setSendingQuotes(false));
  }
}

export default function* quotesSaga() {
  yield takeLatest(sendQuotes.type, sendQuotesSaga);
}
