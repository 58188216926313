import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface PaymentState {
  loading: boolean;
  purchase: PurchaseResponse;
}

const initialState: PaymentState = {
  loading: false,
  purchase: null,
};

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    setLoadingValue(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setPurchase(state, action: PayloadAction<PurchaseResponse>) {
      state.purchase = action.payload;
    },
  },
});

export const { setLoadingValue, setPurchase } = paymentSlice.actions;

export default paymentSlice.reducer;
