import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React, { useState } from "react";

interface SelectComponentProps {
  id?: string;
  selectLabel?: React.ReactNode;
  options: SelectOption[];
  defaultOption: SelectOption;
  onChange: (option: SelectOption) => void;
}

const SelectComponent: React.FC<SelectComponentProps> = ({
  id,
  options,
  selectLabel,
  defaultOption,
  onChange,
}) => {
  const [selectedOption, setSelectedOption] = useState(defaultOption);

  const handleOptionChange = (event) => {
    const newSelectedOption = options.find(
      (option) => option.value === event.target.value
    );
    setSelectedOption(newSelectedOption);
    onChange(newSelectedOption);
  };

  return (
    <FormControl variant="standard">
      {selectLabel && (
        <InputLabel sx={{ fontSize: "14px" }} id={id ?? "select-label"}>
          {selectLabel}
        </InputLabel>
      )}
      <Select
        disableUnderline={true}
        value={selectedOption?.value}
        onChange={handleOptionChange}
        sx={{ px: "20px" }}
      >
        {options?.map((option) => (
          <MenuItem
            sx={{ fontSize: "14px" }}
            key={option.value}
            value={option.value}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectComponent;
