import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { pdfjs } from "react-pdf";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { PolicyDocumentPage } from "pages/app/MyPolicies/:policyId/PolicyDocumentPage/PolicyDocumentPage";
import { customStylesSelector } from "store/selectors/selectors";

import AnimatedButton from "components/custom/AnimatedButton";
import { animateRouteProps } from "constants/common";
import "react-pdf/dist/Page/TextLayer.css";
import { useT } from "translation";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();

const PolicyPdfViewer = () => {
  const customStyles = useSelector(customStylesSelector);
  const navigate = useNavigate();
  const { t } = useT();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflow: "hidden",
        textAlign: "left",
      }}
      {...animateRouteProps}
    >
      <Box>
        <AnimatedButton onClick={() => navigate(-1)}>
          <ArrowBackIosIcon fontSize="small" />
          <Typography
            sx={{
              color: customStyles?.muiTheme?.palette?.text?.primary,
              fontSize: "12px",
              mr: "2px",
              textTransform: "none",
            }}
          >
            {t("BACK")}
          </Typography>
        </AnimatedButton>
      </Box>
      <PolicyDocumentPage />
    </Box>
  );
};

export default PolicyPdfViewer;
