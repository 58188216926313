import { Typography } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";

import { customStylesSelector } from "store/selectors/selectors";
import { useT } from "translation";

const DocuSign = ({ url }: { url: string }) => {
  const [eSignPending, setESignPending] = useState(true);
  const customStyles = useSelector(customStylesSelector);
  const { t } = useT();

  return (
    <div
      style={{
        width: "100%",
        height: "calc(100% + 26px)",
        marginTop: "-30px",
        position: "relative",
      }}
    >
      {eSignPending && (
        <Typography
          sx={{
            color: customStyles.muiTheme.palette.primary.main,
            fontSize: "24px",
            fontWeight: 700,
            mb: "10px",
            pl: "16px",
            position: "absolute",
            top: "40%",
            width: "100%",
            textAlign: "center",
          }}
        >
          {t("LOADING_DOCUMENT_FOR_SIGN")}
        </Typography>
      )}
      {url && (
        <iframe
          src={url}
          style={{ border: "none", height: "100%" }}
          width="100%"
          scrolling="auto"
          onLoad={() => setESignPending(false)}
          title="DocuSign Document"
        />
      )}
    </div>
  );
};

export default DocuSign;
