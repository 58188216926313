import { AxiosResponse } from "axios";
import { IS_BROKER_PORTAL } from "config";

import {
  createAxiosInstanceWithAccessToken,
  createAxiosInstanceWithApiKey,
} from "utils/utils";

const BASE_URL = process.env.REACT_APP_SERVER_URL + "/v1";

export async function getClientFeaturesAPI(
  key: string
): Promise<AxiosResponse<AxiosCommonResponse<ClientFeatures>>> {
  const QuoteAPI = IS_BROKER_PORTAL
    ? createAxiosInstanceWithAccessToken()
    : createAxiosInstanceWithApiKey(key);

  return await QuoteAPI.get(`${BASE_URL}/client/features`);
}
