import { createTheme } from "@mui/material";

export const PolicyholderTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#163B61",
      contrastText: "#FAFAFA",
    },
    secondary: {
      main: "#757575",
      contrastText: "#FAFAFA",
    },
    success: {
      main: "#B9F6CA",
      contrastText: "#00C853",
    },
    background: {
      default: "#F5F5F5",
      paper: "#FFF",
    },
    text: {
      primary: "#212121",
      secondary: "#616161",
    },
  },
  typography: {
    h1: {
      fontWeight: 700,
      fontSize: "34px",
    },
    h2: {
      fontWeight: 700,
      fontSize: "28px",
    },
    h4: {
      fontWeight: 700,
      fontSize: "20px",
      lineHeight: "28px",
    },
    h5: {
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "24px",
    },
    h6: {
      fontWeight: 700,
      fontSize: "14px",
      lineHeight: "24px",
    },
    body1: {
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "24px",
    },
    body2: {
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "20px",
    },
    button: {
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "20px",
    },
    subtitle1: {
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "20px",
    },
    subtitle2: {
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "20px",
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          textTransform: "none",
          paddingTop: 12,
          paddingBottom: 12,
          borderRadius: 4,
        },
        containedSecondary: {
          border: "2px solid #F5F5F5",
        },
      },
    },
  },
});

export const OncePolicyholderTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#F3F2EC",
      contrastText: "#000000",
    },
    secondary: {
      main: "#000000",
      contrastText: "#000000",
    },
    success: {
      main: "#B9F6CA",
      contrastText: "#00C853",
    },
    background: {
      default: "#F3F2EC",
      paper: "#FAFAFA",
    },
    text: {
      primary: "#000000",
      secondary: "#616161",
    },
  },
  typography: {
    h1: {
      fontWeight: 700,
      fontSize: "34px",
    },
    h2: {
      fontWeight: 700,
      fontSize: "28px",
    },
    h4: {
      fontWeight: 700,
      fontSize: "20px",
      lineHeight: "28px",
    },
    h5: {
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "24px",
    },
    h6: {
      fontWeight: 700,
      fontSize: "14px",
      lineHeight: "24px",
    },
    body1: {
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "24px",
    },
    body2: {
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "20px",
    },
    button: {
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "20px",
    },
    subtitle1: {
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "20px",
    },
    subtitle2: {
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "20px",
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          textTransform: "none",
          backgroundColor: "#F3F2EC",
          color: "#000000",
          paddingTop: 12,
          paddingBottom: 12,
          borderRadius: 4,
          "&:hover": {
            backgroundColor: "#E1E1E1", // Slightly darker shade for hover
          },
        },
        containedSecondary: {
          border: "2px solid #F3F2EC",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: "#000000",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontSize: "14px",
          color: "#757575", // Non-active tab color
          "&.Mui-selected": {
            color: "#000000", // Active tab color
          },
          "&:hover": {
            color: "#616161", // Slightly darker grey on hover for non-active tabs
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: "#000000",
            color: "#FAFAFA",
            "&:hover": {
              backgroundColor: "#000000",
            },
          },
          "&:hover": {
            backgroundColor: "#E1E1E1",
          },
        },
      },
    },
  },
});
