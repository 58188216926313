import { SVGProps } from "react";
import { useSelector } from "react-redux";
import { customStylesSelector } from "store/selectors/selectors";

const PetSvg = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  const customStyles = useSelector(customStylesSelector);
  return (
    <svg
      width="52"
      height="51"
      viewBox="0 0 52 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_151_6079)">
        <path
          d="M41.4123 41.1762C40.7867 45.9573 35.7695 48.8937 31.1269 47.5938C29.7453 47.2073 28.1088 46.9832 26.3557 46.9832C24.6314 46.9832 23.0218 47.1994 21.6552 47.5739C16.7069 48.9308 11.311 45.5493 11.311 40.1173C11.311 37.8872 12.2802 35.884 13.8211 34.5044C13.8211 34.5044 17.0454 31.6985 19.4161 26.2569C20.5486 23.5317 23.236 21.6152 26.3706 21.6152C29.5062 21.6152 32.1936 23.5307 33.3261 26.2559C35.2236 30.6108 37.666 33.2762 38.5665 34.1687C40.6224 35.7662 41.7812 38.3368 41.4123 41.1762Z"
          fill={props.fill ?? customStyles.icons.fill}
        />
        <path
          d="M30.3364 30.02C30.8863 30.02 31.3325 29.5738 31.3325 29.0239C31.3325 28.4741 30.8863 28.0278 30.3364 28.0278C29.7866 28.0278 29.3403 28.4741 29.3403 29.0239C29.3403 29.5738 29.7866 30.02 30.3364 30.02Z"
          fill="#E7EDFF"
        />
        <path
          d="M18.1841 26.2473C15.9429 31.3878 12.8811 34.1081 12.8517 34.1338C12.8481 34.1369 12.8447 34.1401 12.8412 34.1432C11.0352 35.7601 9.99951 38.0765 9.99951 40.4982C9.99951 43.4587 11.4093 46.0333 13.8549 47.6646C16.1336 49.1848 18.9578 49.6406 21.6029 48.9154C22.9285 48.5522 24.4628 48.3602 26.0402 48.3602C27.6443 48.3602 29.2013 48.5586 30.5429 48.9339C33.1103 49.6526 35.9284 49.2585 38.2548 47.7215C40.7285 46.087 42.1626 43.4881 42.1626 40.4982C42.1626 37.8686 40.9787 35.4315 38.9117 33.8018C37.9779 32.8686 35.7135 30.3427 33.9271 26.2463C32.5987 23.059 29.5097 21 26.0552 21C22.6013 21 19.5128 23.0594 18.1841 26.2473ZM32.0909 27.0191C32.093 27.0243 32.0953 27.0295 32.0975 27.0346C34.0484 31.5121 36.5604 34.2763 37.5497 35.257C37.5782 35.2851 37.6083 35.3116 37.6398 35.3361C39.2481 36.5857 40.1704 38.4672 40.1704 40.4982C40.1704 42.8157 39.0615 44.8006 37.1564 46.0594C35.3611 47.2455 33.1463 47.594 31.0797 47.0155C29.5655 46.5919 27.823 46.3681 26.04 46.3681C24.2873 46.3681 22.5709 46.5846 21.0761 46.9942C18.9877 47.5669 16.7586 47.2072 14.9603 46.0075C13.0826 44.7549 11.9915 42.7967 11.9915 40.4982C11.9915 38.644 12.784 36.8703 14.1659 35.631C14.3784 35.4446 17.611 32.5505 20.0137 27.0356C20.0159 27.0305 20.0181 27.0253 20.0202 27.0201C21.037 24.5733 23.4058 22.9923 26.055 22.9923C28.7052 22.9922 31.0743 24.5728 32.0909 27.0191Z"
          fill={props.fill ?? customStyles.icons.fill}
        />
        <path
          d="M31.3803 33.0804C32.896 35.4076 34.3332 36.9576 35.0552 37.6743C35.4454 38.0618 36.0762 38.0597 36.464 37.6693C36.8515 37.2789 36.8492 36.6482 36.4589 36.2605C35.7931 35.5995 34.465 34.1662 33.0497 31.9932C32.7495 31.5323 32.1325 31.4017 31.6714 31.702C31.2103 32.0025 31.0801 32.6196 31.3803 33.0804Z"
          fill="#E7EDFF"
        />
        <path
          d="M17.4569 2.15988C15.9661 2.33628 14.6407 3.31485 13.7242 4.91528C12.7676 6.58692 12.3686 8.80891 12.6294 11.0112C13.4552 18.0083 19.969 21.2439 23.1239 15.7328C24.0811 14.0598 24.4801 11.8378 24.2188 9.63717C23.7131 5.35266 20.7582 1.76343 17.4569 2.15988ZM21.3951 14.7434C19.0994 18.754 15.1503 15.3727 14.6078 10.7774C14.3962 8.98971 14.7044 7.21377 15.4533 5.905C15.9986 4.95243 16.8491 4.12408 17.9455 4.12408C19.9061 4.12408 21.8728 6.75566 22.2406 9.87145C22.4527 11.6577 22.1445 13.4335 21.3951 14.7434Z"
          fill={props.fill ?? customStyles.icons.fill}
        />
        <path
          d="M8.65713 16.9132C6.94196 16.0719 5.18544 16.0285 3.71122 16.7908C2.237 17.5525 1.25664 19.0101 0.950441 20.8954C0.662071 22.6708 1.0125 24.6341 1.93707 26.4242C2.86215 28.214 4.26087 29.6358 5.87584 30.4279C7.47855 31.214 9.25419 31.361 10.8215 30.5512C12.2959 29.7895 13.2763 28.3317 13.5824 26.4463C13.8708 24.671 13.5204 22.7076 12.5958 20.9177C11.6708 19.1275 10.272 17.7053 8.65713 16.9132ZM11.616 26.1271C11.4136 27.374 10.8066 28.3165 9.90703 28.7813C9.00756 29.2463 7.88755 29.1958 6.7531 28.6393C5.51893 28.034 4.43717 26.9224 3.70694 25.5096C2.9771 24.0966 2.6964 22.5715 2.91673 21.2147C3.11924 19.9678 3.72626 19.0254 4.62583 18.5606L4.62613 18.5604C5.62083 18.0462 6.77292 18.2079 7.77977 18.7018C9.01384 19.3071 10.0956 20.4189 10.8258 21.8321C11.5557 23.2451 11.8364 24.7704 11.616 26.1271Z"
          fill={props.fill ?? customStyles.icons.fill}
        />
        <path
          d="M34.781 2.15088C31.7608 2.15088 29.0046 5.44786 28.5066 9.65676C27.9755 14.1488 30.2414 18.5363 33.8228 18.5363C36.8425 18.5363 39.599 15.2394 40.0981 11.0304C40.6285 6.54655 38.3694 2.15088 34.781 2.15088ZM38.1198 10.796C37.737 14.0237 35.5976 16.7922 33.5799 16.5304C33.5762 16.53 33.5725 16.5295 33.5688 16.529C32.6631 16.4224 31.8039 15.6963 31.2118 14.5369C30.5532 13.2477 30.2884 11.5543 30.485 9.89084C30.8537 6.77525 32.821 4.14297 34.7811 4.14297C34.8733 4.14297 34.9528 4.14745 35.024 4.15672C36.0861 4.27475 36.9083 5.20302 37.3926 6.1508C38.0514 7.43985 38.3164 9.13301 38.1198 10.796Z"
          fill={props.fill ?? customStyles.icons.fill}
        />
        <path
          d="M39.1217 26.4505C39.7591 30.3758 43.3009 32.1625 46.8282 30.4321C50.1691 28.7934 52.3503 24.5734 51.7536 20.8994C51.1237 17.0201 47.6169 15.1655 44.047 16.9173C40.7071 18.5556 38.5249 22.7761 39.1217 26.4505ZM44.9244 18.7056C47.2682 17.5559 49.3683 18.6384 49.7874 21.2187C50.242 24.0181 48.5131 27.3866 45.951 28.6434C44.8166 29.1997 43.6966 29.25 42.7971 28.7853V28.7854C41.8975 28.3206 41.2905 27.3779 41.088 26.1311C40.6326 23.3265 42.3667 19.9605 44.9244 18.7056Z"
          fill={props.fill ?? customStyles.icons.fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_151_6079">
          <rect
            width="51"
            height="51"
            fill="white"
            transform="translate(0.852051)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PetSvg;
