import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { TextField, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { InferType } from "yup";

import AnimatedButton from "components/custom/AnimatedButton";
import { forgotPasswordSchema } from "constants/policyholder-validation-schema";
import { PolicyholderPathnames } from "constants/policyholderPathnames";
import { useMutationWithToast } from "hooks/useMutationWithToast";
import { AuthService } from "policyholderApi/auth/auth";
import { useT } from "translation";

type ForgotPasswordSchema = InferType<typeof forgotPasswordSchema>;

export function ForgetPasswordPage() {
  const navigate = useNavigate();
  const { t } = useT();
  const forgotPasswordMutation = useMutationWithToast({
    mutationFn: (email: string) => AuthService.resetPasswordAPI({ email }),
    onSuccess: () =>
      navigate({ pathname: PolicyholderPathnames.VerificationEmailSent }),
  });

  const forgotPasswordForm = useForm({
    resolver: yupResolver(forgotPasswordSchema),
  });

  const onForgotPasswordValidate = (formData: ForgotPasswordSchema) => {
    forgotPasswordMutation.mutate(formData.email);
  };

  return (
    <form onSubmit={forgotPasswordForm.handleSubmit(onForgotPasswordValidate)}>
      <Typography variant="h6" marginBottom={1} textAlign="center">
        {t("FORGOT_PASSWORD")}
      </Typography>

      <Typography
        color="textSecondary"
        variant="subtitle2"
        marginBottom={4}
        textAlign="center"
      >
        {t("FORGOT_PASSWORD_DESCRIPTION")}
      </Typography>

      <TextField
        {...forgotPasswordForm.register("email")}
        helperText={forgotPasswordForm.formState.errors.email?.message}
        error={!!forgotPasswordForm.formState.errors.email?.message}
        type="email"
        variant="outlined"
        label={t("EMAIL")}
        fullWidth
        sx={{ mb: 4 }}
      />

      <LoadingButton
        color="primary"
        variant="contained"
        fullWidth
        sx={{ mb: 2 }}
        loading={forgotPasswordMutation.isPending}
        type="submit"
      >
        {t("RECOVER_PASSWORD")}
      </LoadingButton>
      <AnimatedButton
        href={PolicyholderPathnames.LoginPage}
        fullWidth
        onClick={() => navigate(-1)}
      >
        {t("BACK")}
      </AnimatedButton>
    </form>
  );
}
