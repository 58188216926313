import { Box, Typography, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { IS_HOSTED_INSURANCE } from "config";
import { SectionHeaderInfo } from "stere-insurance-sdk/dist/types";
import { customStylesSelector } from "store/selectors/selectors";
import { RootState } from "store/store";
import { useT } from "translation";
import { TranslationId } from "translation/types";

const SectionInfo = ({
  sectionHeading,
  subTitle,
  description,
}: {
  sectionHeading?: string;
  subTitle: string;
  description: string;
}) => {
  const customStyles = useSelector(customStylesSelector);
  const [secInfoConfig, setSecInfoConfig] = useState<SectionHeaderInfo>(null);
  const { sectionHeaderInfoConfig } = useSelector(
    (state: RootState) => state.client
  );
  const { currentSubStep, currentStep } = useSelector(
    (state: RootState) => state.stepper
  );

  const isMobile = useMediaQuery("(max-width:425px)");
  const showSectionTitle = useMediaQuery("(max-width:900px)");
  const location = useLocation();
  const { t } = useT();
  const title = location?.pathname?.split("/")[2];
  const sectionTitle = sectionHeading
    ? sectionHeading
    : title
    ? title.substring(0, 1).toUpperCase() + title.substring(1)
    : "Section Title";

  useEffect(() => {
    if (sectionHeaderInfoConfig?.length) {
      const matchingObject = sectionHeaderInfoConfig.find(
        (obj) => obj.id === sectionTitle
      );
      setSecInfoConfig(matchingObject);
    }
  }, [sectionHeaderInfoConfig]);

  if (
    IS_HOSTED_INSURANCE &&
    isMobile &&
    currentSubStep > 0 &&
    currentStep === 1
  )
    return null;

  if (IS_HOSTED_INSURANCE) {
    return (
      <Box
        sx={{
          maxWidth: "750px",
        }}
      >
        {!showSectionTitle && (
          <Typography
            sx={{
              color: customStyles.muiTheme.palette.text.primary,
              fontSize: "24px",
              fontWeight: 600,
              mb: "16px",
            }}
          >
            {t(sectionTitle)}
          </Typography>
        )}
        {secInfoConfig?.showTitle || secInfoConfig?.showTitle === undefined ? (
          <Typography
            sx={{
              color: customStyles.muiTheme.palette.text.secondary,
              fontSize: "16px",
              fontWeight: 500,
              mb: "5px",
            }}
          >
            {secInfoConfig?.title?.length ? secInfoConfig?.title : subTitle}
          </Typography>
        ) : null}
        {secInfoConfig?.showSubTitle ||
        secInfoConfig?.showSubTitle === undefined ? (
          <Typography
            sx={{
              color: customStyles.muiTheme.palette.text.tertiary,
              fontSize: "14px",
              fontWeight: 500,
              mb: "10px",
            }}
          >
            {secInfoConfig?.subTitle?.length
              ? secInfoConfig?.subTitle
              : description}
          </Typography>
        ) : null}
      </Box>
    );
  }

  return (
    <Box
      sx={{
        pb: "20px",
        maxWidth: "750px",
      }}
    >
      <Typography
        sx={{
          color: customStyles.muiTheme.palette.text.primary,
          fontSize: "24px",
          fontWeight: 600,
          mb: "16px",
        }}
      >
        {t(sectionTitle as TranslationId)}
      </Typography>
      <Typography
        sx={{
          color: customStyles.muiTheme.palette.text.secondary,
          fontSize: "16px",
          fontWeight: 500,
          mb: "5px",
        }}
      >
        {subTitle}
      </Typography>
      <Typography
        sx={{
          color: customStyles.muiTheme.palette.text.tertiary,
          fontSize: "14px",
          fontWeight: 500,
        }}
      >
        {description}
      </Typography>
    </Box>
  );
};

export default SectionInfo;
