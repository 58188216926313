import { Box } from "@mui/material";
import { Dayjs } from "dayjs";
import { FunctionComponent } from "react";

import FiltersContainer from "components/common/FiltersContainer";
import { useT } from "translation";

interface QuotesFiltersProps {
  onDateChange: (newValue: Dayjs, name: string, filters: Filter[]) => void;
  onApply: (filters: Filter[]) => void;
  onClear: (filters: Filter[]) => void;
}

const insuranceProducts: SelectOption[] = [{ label: "Pet", value: "PET-MEU" }];

const statuses: SelectOption[] = [
  { label: "Quotes Generated", value: "quotes_generated" },
  { label: "No Quotes Generated", value: "no_quotes_generated" },
  { label: "Quote Sent", value: "quotes_sent" },
  { label: "Payment Made", value: "payment_made" },
];

const QuotesFilters: FunctionComponent<QuotesFiltersProps> = ({
  onDateChange,
  onApply,
  onClear,
}) => {
  const { t } = useT();
  return (
    <Box
      sx={{
        mb: "40px",
      }}
    >
      <FiltersContainer
        key="quotes-filter"
        filters={[
          {
            id: "products",
            title: t("FILTERS.insuranceProducts"),
            options: insuranceProducts,
          },
          // {
          //   id: "statuses",
          //   title: "Status",
          //   options: statuses,
          // },
        ]}
        onDateChange={onDateChange}
        onApply={onApply}
        onClear={onClear}
      />
    </Box>
  );
};

export default QuotesFilters;
