import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const customerDetailsSlice = createSlice({
  name: "customerDetails",
  initialState: {
    customerDetails: {
      name: "",
      email: "",
      phone: "",
    },
  },
  reducers: {
    setCustomerDetails: (
      state,
      payload: PayloadAction<
        Partial<{
          name: string;
          email: string;
          phone: string;
        }>
      >
    ) => {
      state.customerDetails = {
        ...state.customerDetails,
        ...payload.payload,
      };
    },
  },
});

export const { setCustomerDetails } = customerDetailsSlice.actions;
export default customerDetailsSlice.reducer;
