export const getTotalPremium = (quotes: Quote[]) => {
  let total = 0;
  quotes.forEach((q) => {
    total =
      total + Number(q.premium.premium_value ?? 0) + Number(q.premium.tax ?? 0);
  });
  return total;
};

export const ANDDONE_TRANSACTION_STATUS_AUTHORIZED = "Authorized";
