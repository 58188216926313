import { useQuery } from "@tanstack/react-query";
import { ToastCtx } from "components/Toast/ToastProvider";
import { IChildAccount } from "modules/Accounts/types";
import { fetchAccountsOfUserApi } from "modules/Auth/api";
import { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  setActiveAccountV2Login,
  setAllAccountsV2Login,
} from "store/features/authSlice";
import { useT } from "translation";
import { IListResponse } from "types";
import { LSKeys, getFromLocalStorage } from "utils/ls.utils";

function useAccountsInfo() {
  const accessToken = getFromLocalStorage(LSKeys.ACCESS_TOKEN);
  const { setToast } = useContext(ToastCtx);
  const dispatch = useDispatch();
  const { t } = useT();
  const navigate = useNavigate();
  const location = useLocation();
  const isInviteRoute = location.pathname.includes("/accept-invite");
  const [isAccountsLoading, setIsAccountsLoading] = useState(
    isInviteRoute ? false : !!accessToken
  );
  const {
    data: accountsRes,
    isSuccess,
    isLoading,
    isFetching,
    isError,
  } = useQuery<IListResponse<IChildAccount>>({
    queryKey: ["accounts", accessToken],
    queryFn: fetchAccountsOfUserApi,
    enabled: Boolean(accessToken) && !isInviteRoute,
    refetchOnWindowFocus: false,
  });

  const accountsList = useMemo(
    () => accountsRes?.data || [],
    [accountsRes?.data]
  );

  useEffect(() => {
    if (isLoading) {
      setIsAccountsLoading(true);
    }
  }, [isLoading]);

  useEffect(() => {
    if (!accessToken || isLoading || isFetching || isInviteRoute) return;
    if (isError) {
      setIsAccountsLoading(false);
      return setToast({
        severity: "error",
        text: t("SOMETHING_WENT_WRONG"),
      });
    }
    if (isSuccess) {
      setIsAccountsLoading(false);
      if (accountsList.length > 0) {
        dispatch(setAllAccountsV2Login(accountsList));
        dispatch(setActiveAccountV2Login(accountsList[0]));
      } else {
        if (location.pathname !== "/v2/agencyDetails") {
          navigate("/v2/agencyDetails", { replace: true, state: location });
        }
      }
    }
  }, [
    isInviteRoute,
    accessToken,
    isLoading,
    isFetching,
    isError,
    isSuccess,
    accountsList,
    location.pathname,
    t,
    dispatch,
    navigate,
    location,
    setIsAccountsLoading,
    setToast,
  ]);
  return { isAccountsLoading };
}

export default useAccountsInfo;
