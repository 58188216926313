import { Box } from "@mui/material";
import React from "react";

import { AppFooter } from "./AppFooter";

type PageLayoutWrapperProps = {
  bgColor?: "primary" | "default";
};
export function PageLayoutWrapper({
  children,
  bgColor,
}: React.PropsWithChildren<PageLayoutWrapperProps>) {
  return (
    <Box
      sx={{ display: "flex", flexDirection: "column" }}
      bgcolor={bgColor === "primary" ? "primary.main" : undefined}
      color={bgColor === "primary" ? "primary.contrastText" : undefined}
      height="100%"
    >
      <Box flex={1}>{children}</Box>
      <AppFooter bgColor={bgColor} />
    </Box>
  );
}
