import { Box, Typography } from "@mui/material";
import parse from "html-react-parser";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import NoQuotesGeneratedSvg from "assets/images/NoQuotesGeneratedSvg";
import { COUNTRY, FAIRFAX, IS_HOSTED_INSURANCE, PORTAL_NAME } from "config";
import {
  clientSelector,
  customStylesSelector,
} from "store/selectors/selectors";
import { useT } from "translation";
import { CountryToLanguageMapping, extractEmail } from "utils/utils";

interface QuotesMissingProps {
  invalidQuote?: Quote;
  validQuoteError?: string;
}

const QuotesMissing: React.FunctionComponent<QuotesMissingProps> = ({
  invalidQuote,
  validQuoteError,
}) => {
  const customStyles = useSelector(customStylesSelector);
  const { noQuoteText } = useSelector(clientSelector);
  const [formattedNoQuoteText, setFormattedNoQuoteText] = useState(noQuoteText);
  const invalidaQuoteMessage =
    invalidQuote?.data?.[0]?.Message ?? invalidQuote?.data?.[0]?.message;
  const { t } = useT();

  useEffect(() => {
    if (noQuoteText) {
      const email = extractEmail(noQuoteText);
      if (email) {
        setFormattedNoQuoteText(
          noQuoteText.replace(email, `<a href="mailto:${email}">${email}</a>`)
        );
      }
    }
  }, [noQuoteText]);

  const RenderQuoteInvalidMessage = (
    <Typography
      sx={{
        color: customStyles.muiTheme.palette.text.tertiary,
        fontSize: "16px",
        fontWeight: 500,
        mb: "16px",
        maxWidth: "80%",
        textAlign: "center",
      }}
    >
      {invalidaQuoteMessage}
    </Typography>
  );

  const RenderGenericMessage = (
    <>
      <Typography
        sx={{
          color: customStyles.muiTheme.palette.text.primary,
          fontSize: "20px",
          fontWeight: 600,
          textAlign: "center",
          maxWidth: "80%",
        }}
      >
        There are no instant quotes available
      </Typography>
      {noQuoteText ? (
        <Typography
          sx={{
            color: customStyles.muiTheme.palette.text.tertiary,
            fontSize: "16px",
            fontWeight: 500,
            mb: "16px",
            maxWidth: "80%",
            textAlign: "center",
          }}
        >
          {parse(formattedNoQuoteText)}
        </Typography>
      ) : (
        <Typography
          sx={{
            color: customStyles.muiTheme.palette.text.tertiary,
            fontSize: "16px",
            fontWeight: 500,
            mb: "16px",
            maxWidth: "80%",
            textAlign: "center",
          }}
        >
          Our agent will be in touch right away to get you the quotes you need.
          Alternatively, you can{" "}
          <a
            style={{
              fontWeight: 600,
              color: customStyles.muiTheme.palette.text.primary,
            }}
            href="https://meetings.hubspot.com/abbey-gallegos1"
            target="_blank"
          >
            schedule a meeting
          </a>{" "}
          with our team directly, at your convenience.
        </Typography>
      )}
    </>
  );

  if (IS_HOSTED_INSURANCE) {
    return (
      <Box
        sx={{
          height: "70vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <NoQuotesGeneratedSvg style={{ width: "40%" }} />
        {invalidQuote ? RenderQuoteInvalidMessage : RenderGenericMessage}
      </Box>
    );
  }

  return (
    <Box
      sx={{
        height: "70vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <Typography
        sx={{
          color: customStyles.muiTheme.palette.text.primary,
          fontSize: "18px",
          fontWeight: 600,
          textAlign: "center",
        }}
      >
        {t("QUOTES_NOT_GENERATED.title")}
      </Typography>
      <Typography
        sx={{
          color: customStyles.muiTheme.palette.text.tertiary,
          fontSize: "16px",
          fontWeight: 600,
          mb: "16px",
        }}
      >
        {t("QUOTES_NOT_GENERATED.subTitle")}
      </Typography>
      {PORTAL_NAME === FAIRFAX && (invalidaQuoteMessage || validQuoteError) && (
        <Typography
          sx={{
            color: customStyles.muiTheme.palette.text.tertiary,
            fontSize: "16px",
            fontWeight: 600,
            mb: "16px",
          }}
        >
          {`${t("QUOTES_NOT_GENERATED.reason")}: ${
            validQuoteError
              ? validQuoteError
              : invalidaQuoteMessage?.[CountryToLanguageMapping[COUNTRY]]
          }`}
        </Typography>
      )}
    </Box>
  );
};

export default QuotesMissing;
