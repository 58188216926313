import { Elements } from "@stripe/react-stripe-js";
import {
  Appearance,
  StripeElementsOptions,
  loadStripe,
} from "@stripe/stripe-js";
import { COUNTRY } from "config";
import React from "react";
import { useSelector } from "react-redux";
import { customStylesSelector } from "store/selectors/selectors";
import CheckoutForm, { StripeCheckoutFormProps } from "./CheckoutForm";

const stripePromise = loadStripe(
  COUNTRY === "br"
    ? process.env.REACT_APP_STRIPE_API_KEY_BR
    : process.env.REACT_APP_STRIPE_API_KEY
);

type StripePaymentProps = StripeCheckoutFormProps & {
  paymentData?: PaymentPayload[];
};

const StripePayment: React.FC<StripePaymentProps> = ({
  onCancel,
  onSubmit: onGenerateClientSecret,
  customerEmail,
  paymentData,
}) => {
  const customStyles = useSelector(customStylesSelector);

  const appearance: Appearance = {
    theme: "flat",
    variables: {
      colorBackground: customStyles.input.backgroundColor,
      colorText: customStyles.input.color,
      fontSizeLg: "14px",
      fontSizeXl: "14px",
      fontFamily: customStyles.muiTheme.typography.fontFamily,
    },
  };

  const options: StripeElementsOptions = {
    appearance,
    fonts: [
      {
        cssSrc:
          "https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap",
      },
    ],
  };

  return (
    <Elements options={options} stripe={stripePromise}>
      <CheckoutForm
        onCancel={onCancel}
        onSubmit={onGenerateClientSecret}
        customerEmail={customerEmail}
        paymentData={paymentData}
      />
    </Elements>
  );
};

export default StripePayment;
