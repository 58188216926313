import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { Box, Divider, Typography } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { setErrorAndShowExitBtn } from "store/features/clientSlice";
import { resetSurveyJS } from "store/features/surveyJsSlice";
import {
  authSelector,
  customStylesSelector,
  quotesSelector,
} from "store/selectors/selectors";
import { useT } from "translation";
import PopupModal from "../PopupModal/PopupModal";

const DashboardLinkHeader = (): JSX.Element => {
  const customStyles = useSelector(customStylesSelector);
  const { portalAccount } = useSelector(authSelector);
  const { sentQuotesToken } = useSelector(quotesSelector);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useT();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleOpenModal = () => {
    if (sentQuotesToken) {
      localStorage.removeItem("currentApplicantID");
      localStorage.removeItem("prefillData");
      dispatch(resetSurveyJS());
      navigate("/home/dashboard");
    } else {
      setIsModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSave = () => {
    localStorage.removeItem("currentApplicantID");
    localStorage.removeItem("prefillData");
    dispatch(resetSurveyJS());
    dispatch(setErrorAndShowExitBtn(false));
    navigate("/home/dashboard");
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          py: "20px",
          pr: "20px",
          pl: "15px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "fit-content",
            alignItems: "center",
            cursor: "pointer",
            color: customStyles.muiTheme.palette.text.tertiary,
            transition: "all 0.3s",
            "&:hover": {
              color: customStyles.muiTheme.palette.text.secondary,
            },
          }}
          onClick={handleOpenModal}
        >
          <KeyboardArrowLeftIcon />
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 500,
              ml: "10px",
              mt: "1px",
            }}
          >
            {t("BACK_TO_DASHBOARD")}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Divider sx={{ ml: "10px" }} orientation="vertical" flexItem />
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 500,
              ml: "10px",
              mt: "1px",
              color: customStyles.muiTheme.palette.text.tertiary,
            }}
          >
            {portalAccount?.company?.company_name}
          </Typography>
        </Box>
      </Box>
      <PopupModal
        open={isModalOpen}
        title={t("POP_UPS.BACK_TO_DASHBOARD_POPUP.title")}
        content={t("POP_UPS.BACK_TO_DASHBOARD_POPUP.content")}
        btnText={t("POP_UPS.BACK_TO_DASHBOARD_POPUP.btnText")}
        onClose={handleCloseModal}
        onSave={handleSave}
      />
    </>
  );
};

export default DashboardLinkHeader;
