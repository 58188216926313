import { Box } from "@mui/material";
import OutletHeader from "components/Home/Common/OutletHeader";
import AnimatedButton from "components/custom/AnimatedButton";
import { animateRouteProps } from "constants/common";
import AgentInviteDialog from "modules/Agents/AgentInviteDialog";
import AgentsTabPanel from "modules/Agents/AgentsTabPanel";
import { useState } from "react";
import { useT } from "translation";

const Agents = () => {
  const { t } = useT();
  const [isInviteDialogOpen, setIsInviteDialogOpen] = useState(false);
  return (
    <Box
      sx={{
        backgroundColor: "white",
        borderRadius: "12px",
      }}
      {...animateRouteProps}
    >
      <OutletHeader
        title={t("AGENTS_PAGE.title")}
        actionBtn={
          <AnimatedButton
            variant="contained"
            onClick={() => setIsInviteDialogOpen(!isInviteDialogOpen)}
          >
            {t("AGENTS_PAGE.inviteBtn")}
          </AnimatedButton>
        }
      />
      <AgentsTabPanel />
      <AgentInviteDialog
        isInviteDialogOpen={isInviteDialogOpen}
        setIsInviteDialogOpen={setIsInviteDialogOpen}
      />
    </Box>
  );
};

export default Agents;
