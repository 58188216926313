export const base = {
  startScreen: {
    color: "#163B61",
    backgroundColor: "black",
  },
  button: {
    backgroundColor: "#163B61",
    borderColor: "",
    color: "#ffffff",
    hover: {
      backgroundColor: "#0F2943",
      borderColor: "",
      color: "#ffffff",
    },
  },
  input: {
    backgroundColor: "#FAFAFA",
    color: "#212121",
    placeholder: {
      color: "#757575",
    },
    active: {
      borderColor: "#163B61",
    },
    hover: {
      borderColor: "#163B61",
    },
  },
  primaryCard: {
    backgroundColor: "#FFFFFF",
    hover: {
      borderColor: "#75757560",
    },
    active: {
      borderColor: "#163B61",
    },
  },
  secondaryCard: {
    backgroundColor: "#F5F5F5",
    hover: {
      borderColor: "#75757560",
    },
    active: {
      borderColor: "#163B61",
    },
  },
  icons: {
    fill: "#163B61",
  },
  stepper: {
    backgroundColor: "#163B61",
    step: {
      checkIcon: { fill: "#163B61" },
      backgroundColor:
        "linear-gradient(167.29deg,rgba(255,255,255,.3) 9.2%,rgba(255,255,255,0) 100.31%)",
      color: "white",
      active: {
        borderColor: "white",
      },
    },
    stepConnector: {
      backgroundColor: "linear-gradient(to bottom, transparent, white)",
    },
  },
  muiTheme: {
    typography: {},
    palette: {
      primary: {
        main: "#163B61",
      },
      text: {
        primary: "#163B61",
        secondary: "#212121",
        tertiary: "#757575",
      },
      background: {
        default: "#F5F5F5",
      },
    },
  },
};