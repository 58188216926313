import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { Dayjs } from "dayjs";
import * as React from "react";
import { useSelector } from "react-redux";

import { customStylesSelector } from "store/selectors/selectors";

export default function CustomDatePicker({
  name,
  label,
  value,
  onDateChange,
}: {
  name: string;
  label: string;
  value: Dayjs;
  onDateChange: (newValue: Dayjs) => void;
}) {
  const customStyles = useSelector(customStylesSelector);
  const [date, setDate] = React.useState<Dayjs | null>(value);
  const onChange = (newValue: Dayjs) => {
    if (newValue) {
      setDate(newValue);
      onDateChange(newValue);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={["DatePicker", "DatePicker"]}>
        <DatePicker
          name={name}
          label={label}
          value={date}
          onChange={(newValue) => onChange(newValue)}
          format="DD-MM-YYYY"
          sx={{
            maxWidth: "160px !important",
            minWidth: "160px !important",
            ".MuiInputBase-root": {
              borderRadius: "4px !important",
              background: customStyles?.muiTheme?.palette?.background?.default,
              ".MuiInputBase-input": {
                py: "12px",
                pl: "12px",
                fontSize: "14px",
              },
            },
          }}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}
