import DangerousIcon from '@mui/icons-material/Dangerous';
import { Box, Typography } from "@mui/material";
import { animateRouteProps } from "constants/common";
import React from "react";
import { useSelector } from "react-redux";

import { customStylesSelector } from "store/selectors/selectors";
import { useT } from "translation";

interface ErrorMessageI {
  message: string;
}

const ErrorMessage: React.FC<ErrorMessageI> = ({ message }) => {
  const { t } = useT();
  const customStyles = useSelector(customStylesSelector);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        height: "100%",
      }}
      {...animateRouteProps}
    >
      <Box
        data-cy="error-message"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          m: "auto",
        }}
      >
        <Box
          sx={{
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100px",
            width: "100px",
            background: customStyles.stepper.backgroundColor,
          }}
        >
          <DangerousIcon
            data-cy="error-check-icon"
            style={{
              color: customStyles.stepper.step.color,
              fontSize: "75px",
            }}
          />
        </Box>
        <Typography
          sx={{
            color: customStyles.muiTheme.palette.primary.main,
            fontSize: "24px",
            fontWeight: 700,
            mt: "30px",
          }}
        >
          {t("ERROR_PAGE_CONTENT.line1")}
        </Typography>
        <Typography
          sx={{
            color: customStyles.muiTheme.palette.text.tertiary,
            fontSize: "16px",
            fontWeight: 600,
            mb: "16px",
            ml: "20px",
            mr: "20px",
          }}
        >
          {`${t("QUOTES_NOT_GENERATED.reason")}: ${message}`}
        </Typography>
      </Box>
    </Box>
  );
};

export default ErrorMessage;
