import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import Box from "@mui/material/Box";
import { FunctionComponent } from "react";

import { useT } from "translation";

interface ViewOptionProps {}

const ViewOption: FunctionComponent<ViewOptionProps> = () => {
  const { t } = useT();
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <VisibilityOutlinedIcon fontSize="small" sx={{ mr: "2px" }} />
      {t("TABLES.view")}
    </Box>
  );
};

export default ViewOption;
