const URL = window.location.host.split(":")[0];

export const hostMatch = URL.split(".");

export enum Environments {
  LOCAL = "local",
  SANDBOX = "sandbox",
  PRODUCTION = "production",
}

// Utility function to determine if a substring exists in the URL
const includesInURL = (substring: string) => URL.includes(substring);

// Portal type checks
export const IS_POLICYHOLDER_PORTAL = hostMatch[1] === "policyholder";
export const IS_FAIRFAX_PORTAL = includesInURL("produto-vida");
export const IS_CORX_PORTAL = includesInURL("corx.io");

// Indexes for different parts of the URL based on portal type
const PORTAL_NAME_INDEX = 0;
const COUNTRY_INDEX = IS_POLICYHOLDER_PORTAL ? 2 : 1;
const ENVIRONMENT_INDEX = IS_POLICYHOLDER_PORTAL ? 3 : 2;

// Environment determination for specific portals
const determineEnvironment = (
  isPortal: boolean,
  sandboxIdentifier: string,
  defaultEnvironment: Environments
) =>
  isPortal && includesInURL(sandboxIdentifier)
    ? Environments.SANDBOX
    : defaultEnvironment;

export const FAIRFAX_PORTAL_ENVIRONMENT = determineEnvironment(
  IS_FAIRFAX_PORTAL,
  "uat",
  Environments.PRODUCTION
);
export const CORX_PORTAL_ENVIRONMENT = determineEnvironment(
  IS_CORX_PORTAL,
  "sandbox",
  Environments.PRODUCTION
);

// Portal name and country determination
export const PORTAL_NAME = IS_CORX_PORTAL
  ? "brokerpro"
  : IS_FAIRFAX_PORTAL
  ? "fairfax"
  : hostMatch[PORTAL_NAME_INDEX];
export const COUNTRY =
  IS_CORX_PORTAL || IS_FAIRFAX_PORTAL ? "br" : hostMatch[COUNTRY_INDEX];

// Environment determination for all portals
export const ENVIRONMENT = (() => {
  if (IS_CORX_PORTAL) return CORX_PORTAL_ENVIRONMENT;
  if (IS_FAIRFAX_PORTAL) return FAIRFAX_PORTAL_ENVIRONMENT;
  if (URL.endsWith(".lvh.me")) return Environments.LOCAL;
  return hostMatch[ENVIRONMENT_INDEX] === Environments.SANDBOX
    ? Environments.SANDBOX
    : Environments.PRODUCTION;
})();

console.log(
  "PORTAL_NAME",
  PORTAL_NAME,
  "COUNTRY",
  COUNTRY,
  "ENVIRONMENT",
  ENVIRONMENT
);

export const BROKERPRO = "brokerpro";

export const FAIRFAX = "fairfax";

export const LIBERSEGUROS = "liberseguros";

export const PORTALS = [BROKERPRO, FAIRFAX, LIBERSEGUROS];

export const IS_BROKER_PORTAL =
  IS_FAIRFAX_PORTAL || PORTALS.includes(PORTAL_NAME);

export const IS_HOSTED_INSURANCE = PORTAL_NAME === "hosted-insurance";

export const IS_SANDBOX = ENVIRONMENT === Environments.SANDBOX;

export const IS_LOCAL = ENVIRONMENT === Environments.LOCAL;

export const CountryMapping: { [key: string]: string } = {
  br: "brazil",
  us: "usa",
  mx: "mexico",
  ph: "philippines",
  sg: "singapore",
};

export const PLATFORM_TYPE = IS_POLICYHOLDER_PORTAL
  ? "PHPortal"
  : "BrokerPortal";

export const CLIENT_NAME = PORTAL_NAME;

export const isKnockoutEnabled = (product: string) => {
  switch (product) {
    case "PET":
      return COUNTRY === "mx" || COUNTRY === "sg";
    case "JWL":
      return COUNTRY === "us";
    default:
      return false;
  }
};

export const getDomainName = (
  portalName: string,
  country: string,
  environment: Environments,
  isPolicyholderPortal: boolean
) => {
  if (!portalName || !country || !environment) {
    throw new Error(
      "Missing required parameters to construct the domain name."
    );
  }
  const envSegment =
    environment === Environments.PRODUCTION ? "" : `.${environment}`;
  const policyholderSlug = isPolicyholderPortal ? ".policyholder" : "";
  // TODO: Need to ask API team to add .stere
  const tldSlug =
    environment === Environments.LOCAL ? `.lvh.me:3000` : ".stere.io";
  return `${portalName}${policyholderSlug}.${country}${envSegment}${tldSlug}`;
};

export const DOMAIN_NAME = getDomainName(
  PORTAL_NAME,
  COUNTRY,
  ENVIRONMENT,
  IS_POLICYHOLDER_PORTAL
);

const IS_ONCE = PORTAL_NAME === "once";

export const IS_ONCE_PH_PORTAL = IS_ONCE && IS_POLICYHOLDER_PORTAL;
