export const generatePolicyData = (p: Policy): PolicyData => {
  const policyData: PolicyData = {
    policy_num: p?.policy_number,
    commission: p?.commission,
    insurance: p?.product.split("-")[0],
    insurer: p?.product.split("-")[1],
    policy_holder: p?.customer,
    status: p?.status,
    renewal_date: null,
    documents: p?.documents,
  };
  return policyData;
};

export const generatePoliciesData = (data: Policy[]) => {
  let rows: PolicyData[] = [];
  data.forEach((r: Policy) => {
    rows.push(generatePolicyData(r));
  });
  return rows;
};

export const policyStatusMapping: {
  [key: string]: { label: string; backgroundColor: string; color: string };
} = {
  document_generated: {
    label: "POLICIES_PAGE.status.active",
    backgroundColor: "#B9F6CA",
    color: "#00C853",
  },
  initiated: {
    label: "POLICIES_PAGE.status.active",
    backgroundColor: "#B9F6CA",
    color: "#00C853",
  },
};
