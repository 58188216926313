import { Outlet } from "react-router";

import { ContentWithBackButton } from "components/policyholderComponents/layout/ContentWithBackButton";

export function PolicyDetailLayout() {
  return (
    <ContentWithBackButton>
      <Outlet />
    </ContentWithBackButton>
  );
}
