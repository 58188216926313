import { Box, TextField, Typography } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import StereLogo from "assets/logos/StereLogo";
import { ToastCtx } from "components/Toast/ToastProvider";
import AnimatedButton from "components/custom/AnimatedButton";
import { animateRouteProps } from "constants/common";
import { ErrorMessage, Field, Form, Formik } from "formik";
import {
  createAccountAPI,
  generateKeysForChildAccountAPI,
} from "modules/Accounts/api";
import {
  ICreateAccount,
  ICreateAccountRes,
  IGenerateKeysRes,
} from "modules/Accounts/types";
import { useContext } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { customStylesSelector } from "store/selectors/selectors";
import { useT } from "translation";
import { LSKeys, getFromLocalStorage } from "utils/ls.utils";
import * as Yup from "yup";

const initialAgencyDetails = {
  cnpj: "",
  licenseNumber: "",
  agencyName: "",
  agencyAddress: "",
};
const AgencyDetails = () => {
  const customStyles = useSelector(customStylesSelector);
  const { setToast } = useContext(ToastCtx);
  const navigate = useNavigate();
  const { t } = useT();
  const queryClient = useQueryClient();

  const createAccountMutation = useMutation<
    ICreateAccountRes,
    any,
    ICreateAccount
  >({
    mutationFn: createAccountAPI,
    onSuccess: (data) => {
      generateKeysMutation.mutate(data.id);
    },
    onError: () => {
      setToast({
        severity: "error",
        text: t("SOMETHING_WENT_WRONG"),
      });
    },
  });

  const generateKeysMutation = useMutation<IGenerateKeysRes, any, string>({
    mutationFn: generateKeysForChildAccountAPI,
    onSuccess: () => {
      queryClient
        .invalidateQueries({
          queryKey: ["accounts", getFromLocalStorage(LSKeys.ACCESS_TOKEN)],
        })
        .then(() => {
          navigate("/home/dashboard", { replace: true });
        });
    },
    onError: () => {
      setToast({
        severity: "error",
        text: t("SOMETHING_WENT_WRONG"),
      });
    },
  });

  const handleSave = (values) => {
    createAccountMutation.mutate({
      name: values.agencyName,
      regulatory_info: {
        tax_id: values.cnpj,
        agency_address: values.agencyAddress,
        license_id: values.licenseNumber,
      },
    });
  };
  const agencyDetailsValidationSchema = Yup.object().shape({
    cnpj: Yup.string().required("CNPJ is required"),
    licenseNumber: Yup.string(),
    agencyName: Yup.string().required("Agency Name is required"),
    agencyAddress: Yup.string().required("Agency Address is required"),
  });

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      flexDirection={"column"}
      bgcolor={customStyles.muiTheme.palette.background.default}
      {...animateRouteProps}
    >
      <Box>
        <StereLogo />
      </Box>
      <Box
        textAlign="center"
        p={3}
        width="60%"
        display="flex"
        flexDirection="column"
      >
        <Typography
          variant="h6"
          gutterBottom
          style={{ fontWeight: 700, marginBottom: "16px" }}
        >
          {t("AGENCY_DETAILS")}
        </Typography>
        <Formik
          initialValues={initialAgencyDetails}
          validationSchema={agencyDetailsValidationSchema}
          validateOnMount={true}
          onSubmit={handleSave}
        >
          {({ isValid }) => (
            <Form>
              <Box
                display={"flex"}
                flexDirection="column"
                alignItems={"flex-start"}
                gap={"4px"}
              >
                <>
                  <Field
                    as={TextField}
                    label={t("AGENCY_NAME")}
                    variant="outlined"
                    name="agencyName"
                    margin="normal"
                    fullWidth
                    InputProps={{
                      style: {
                        borderRadius: "8px",
                        width: "100%",
                      },
                    }}
                  />
                  <ErrorMessage
                    className="error-message"
                    name="agencyName"
                    component="div"
                  />
                </>
                <>
                  <Field
                    as={TextField}
                    label={t("TAX_ID")}
                    variant="outlined"
                    name="cnpj"
                    margin="normal"
                    fullWidth
                    InputProps={{
                      style: {
                        borderRadius: "8px",
                      },
                    }}
                  />
                  <ErrorMessage
                    className="error-message"
                    name="cnpj"
                    component="div"
                  />
                </>
                <>
                  <Field
                    as={TextField}
                    label={t("AGENCY_ADDRESS")}
                    variant="outlined"
                    name="agencyAddress"
                    margin="normal"
                    fullWidth
                    InputProps={{
                      style: {
                        borderRadius: "8px",
                      },
                    }}
                  />
                  <ErrorMessage
                    className="error-message"
                    name="agencyAddress"
                    component="div"
                  />
                </>
                <>
                  <Field
                    as={TextField}
                    fullWidth
                    label={t("LICENSE_NUMBER")}
                    variant="outlined"
                    name="licenseNumber"
                    margin="normal"
                    InputProps={{
                      style: {
                        borderRadius: "8px",
                      },
                    }}
                  />
                  <ErrorMessage
                    className="error-message"
                    name="licenseNumber"
                    component="div"
                  />
                </>
                <AnimatedButton
                  variant="contained"
                  color="primary"
                  type="submit"
                  isLoading={createAccountMutation.isPending}
                >
                  {t("SAVE")}
                </AnimatedButton>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default AgencyDetails;
