import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import TableRowEmpty from "components/common/Table/TableRowEmpty";
import { COUNTRY } from "config";
import {
  clientSelector,
  customStylesSelector,
  productsSelector,
} from "store/selectors/selectors";
import { RootState } from "store/store";
import { useT } from "translation";
import { CurrencyPrefix, PaymentProviders, formatCurrency } from "utils/utils";
import { InsurerLogoMapping, ProductIconMapping } from "../utils";

const RenderAmount = ({
  sx,
  children,
}: {
  sx?: any;
  maximumFractionDigits?: number;
  children: number;
}) => {
  const customStyles = useSelector(customStylesSelector);

  return (
    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
      <Typography
        sx={{
          fontSize: "14px",
          fontWeight: 500,
          color: customStyles.muiTheme.palette.text.tertiary,
          ...(sx ? sx : {}),
        }}
      >
        {CurrencyPrefix}
      </Typography>
      <Typography
        sx={{
          fontSize: "14px",
          fontWeight: 500,
          color: customStyles.muiTheme.palette.text.tertiary,
          ...(sx ? sx : {}),
        }}
      >
        {formatCurrency(Number(children ?? 0), 10)}
      </Typography>
    </Box>
  );
};

type SummaryTableMobileProps = {
  summarydata: SummaryItem[];
  onMakePayment: (item?: SummaryItem) => void;
};

const SummaryTableMobile: React.FC<SummaryTableMobileProps> = ({
  summarydata,
}) => {
  const { t } = useT();
  const customStyles = useSelector(customStylesSelector);
  const selectedProducts = useSelector(productsSelector);
  const { clientFeatures } = useSelector(clientSelector);
  const productIdentifier = useSelector(
    (state: RootState) =>
      state.client.availableProducts[0]?.product?.product_identifier
  );

  const [totalAmount, setTotalAmount] = useState<number>();
  const [totalTax, setTotalTax] = useState<number>();
  const [totalTransactionFees, setTotalTransactionFees] = useState<number>();

  const calculateTotal = useCallback(() => {
    let sum = 0;
    let tax = 0;
    let transactionFeesSum = 0;
    summarydata.forEach((sd) => {
      transactionFeesSum = transactionFeesSum + sd.transactionFee;
      tax = tax + sd?.quote?.premium?.tax;
      sum = sum + sd.quote.premium.premium_value;
    });
    setTotalAmount(sum);
    setTotalTax(tax);
    setTotalTransactionFees(transactionFeesSum);
  }, [summarydata]);

  useEffect(() => {
    calculateTotal();
  }, [calculateTotal, summarydata]);

  const LogoAndProductName = ({ row }: { row: SummaryItem }) => (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "10px",
      }}
    >
      <Box
        sx={{
          padding: "10px",
          borderRadius: "12px",
          backgroundColor: customStyles.secondaryCard.backgroundColor,
        }}
      >
        <Box sx={{ width: "30px", height: "30px" }}>
          {ProductIconMapping(row.quote.product_details.type)}
        </Box>
      </Box>

      <Typography
        sx={{
          fontSize: "14px",
          fontWeight: 500,
          color: customStyles.muiTheme.palette.text.secondary,
        }}
      >
        {
          selectedProducts.find(
            (selP) =>
              selP.product.product_identifier === row.quote.product_details.type
          )?.product?.product_name
        }
      </Typography>
    </Box>
  );

  const InsurerLogo = ({ row }: { row: SummaryItem }) => (
    <Box sx={{ maxWidth: "100px" }}>
      {InsurerLogoMapping[row.quote.product_details.insurer]}
    </Box>
  );

  const tableCellCommonSx = {
    color: "#616161",
    fontSize: "14px",
    borderBottom: "none",
    paddingTop: "0px",
    px: "5px",
    py: "10px",
  };

  const tableCommonSx = {
    color: customStyles.muiTheme.palette.primary.main,
    fontFamily: customStyles.muiTheme.typography.fontFamily,
    fontSize: "14px",
    fontStyle: "normal",
    ".MuiTableCell-root": {
      ...tableCellCommonSx,
    },
  };

  const tableBodySx = {
    ...tableCommonSx,
    fontWeight: 500,
  };
  const isSilverySky = COUNTRY === "sg" && productIdentifier === "PET";
  const isJWLOnce = COUNTRY === "us" && productIdentifier === "JWL";

  return (
    <Box>
      {summarydata.map((row, index) => (
        <React.Fragment key={index}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              p: "20px",
              mt: "20px",
              backgroundColor: customStyles.primaryCard.backgroundColor,
              borderRadius: "12px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <LogoAndProductName row={row} />
              <InsurerLogo row={row} />
            </Box>
            <Table sx={{ width: "100%", minWidth: "250px" }}>
              <TableBody sx={tableBodySx}>
                <TableRowEmpty />
                <TableRow>
                  <TableCell>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 500,
                        color: customStyles.muiTheme.palette.text.tertiary,
                      }}
                    >
                      {isSilverySky
                        ? t("PAYMENT_PAGE_BASIC_PREMIUM")
                        : t("PAYMENT_PAGE_PREMIUIM")}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <RenderAmount>
                      {row.quote.premium.premium_value}
                    </RenderAmount>
                  </TableCell>
                </TableRow>

                {clientFeatures?.payment_provider ===
                  PaymentProviders.STRIPE && (
                  <TableRow>
                    <TableCell>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: 500,
                          color: customStyles.muiTheme.palette.text.tertiary,
                        }}
                      > 
                        {t("PAYMENT_PAGE_TRANSACTION_FEE")}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <RenderAmount>{row.transactionFee}</RenderAmount>
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 500,
                        color: customStyles.muiTheme.palette.text.tertiary,
                      }}
                    >
                      {isSilverySky
                        ? t("PAYMENT_PAGE_GST")
                        : isJWLOnce
                        ? t("PAYMENT_PAGE_TAX_JWL")
                        : t("PAYMENT_PAGE_TAX")}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <RenderAmount>{row?.quote?.premium?.tax}</RenderAmount>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </React.Fragment>
      ))}
      <Box
        sx={{
          display: "flex",
          mt: "10px",
          py: "5px",
          px: "20px",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: 500,
            color: customStyles.muiTheme.palette.text.secondary,
          }}
        >
          Total
        </Typography>
        <RenderAmount
          sx={{
            fontSize: "20px",
            color: customStyles.muiTheme.palette.text.secondary,
          }}
        >
          {totalAmount + totalTax + totalTransactionFees}
        </RenderAmount>
      </Box>
    </Box>
  );
};

export default SummaryTableMobile;
