import { Box, Typography } from "@mui/material";
import React from "react";

const SectionCard = ({
  title,
  subTitle,
  Icon,
}: {
  title: React.ReactNode;
  subTitle: string;
  Icon: any;
}) => {
  return (
    <Box
      sx={{
        backgroundColor: "primary.main",
        p: "20px",
        borderRadius: "12px",
        minWidth: "220px",
      }}
    >
      <Icon sx={{ color: "white" }} fontSize="large" />
      <Box>
        <Typography
          sx={{
            fontSize: "12px",
            color: "white",
            fontWeight: 400,
            mt: "20px",
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            color: "white",
            fontWeight: 600,
          }}
        >
          {subTitle}
        </Typography>
      </Box>
    </Box>
  );
};

export default SectionCard;
