import {
  Box,
  CircularProgress,
  Typography,
  circularProgressClasses,
} from "@mui/material";
import ReactDom from "react-dom";
import { useSelector } from "react-redux";

import {
  authSelector,
  customStylesSelector,
  paymentSelector,
} from "store/selectors/selectors";
import { RootState } from "store/store";
import { useT } from "translation";

const modalStyles = {
  padding: "20px",
};

const overlayStyles = {
  position: "fixed" as const,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 1000,
};

const GlobalLoader = () => {
  let el = document.getElementById("portal");
  const { t } = useT();
  const { initialLoader, loaderText } = useSelector(
    (state: RootState) => state.loader
  );
  const { loading: authLoading } = useSelector(authSelector);
  const customStyles = useSelector(customStylesSelector);
  const { loading: paymentWidgetLoading } = useSelector(paymentSelector);
  if (el) {
    return ReactDom.createPortal(
      <Box
        sx={{
          ...overlayStyles,
          backgroundColor: customStyles?.startScreen?.backgroundColor,
          opacity: initialLoader
            ? 1
            : authLoading || paymentWidgetLoading
            ? 0.5
            : 0,
          transition: "opacity 0.3s ease-in-out",
          pointerEvents:
            initialLoader || authLoading || paymentWidgetLoading
              ? "inherit"
              : "none",
        }}
      >
        <Box style={modalStyles}>
          <Typography
            sx={{
              color: "#F1F1FF",
              fontSize: "20px",
              mb: "10px",
            }}
          >
            {initialLoader ? t("GLOBAL_LOADER_MESSAGE") : ""}
            {paymentWidgetLoading && loaderText}
          </Typography>
          <Box
            sx={{
              display: "flex",
              minHeight: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress
              variant="indeterminate"
              disableShrink
              sx={{
                color: "#F1F1FF",
                animationDuration: "550ms",
                left: 0,
                [`& .${circularProgressClasses.circle}`]: {
                  strokeLinecap: "round",
                },
              }}
              size={50}
              thickness={4}
            />
          </Box>
        </Box>
      </Box>,
      el
    );
  }
  return <></>;
};
export default GlobalLoader;
