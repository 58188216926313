import { Box } from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import CustomButton from "components/common/CustomButton/CustomButton";
import CustomInput from "components/common/CustomInput/CustomInput";
import { useSelector } from "react-redux";
import { authSelector } from "store/selectors/selectors";
import { useT } from "translation";

export default function Company() {
  const { t } = useT();
  const { portalAccount } = useSelector(authSelector);

  const initialValues = {
    companyName: portalAccount?.company?.company_name,
    cnpj: "",
    registrationNumber: "",
    dateOfIncorporation: "",
    // city: "",
    // state: "",
    // address: "",
    // neighbourhood: "",
    // ndegree: "",
  };

  const validationSchema = Yup.object().shape({
    companyName: Yup.string().required(t("COMPANY_FORM_COMPANY_NAME_REQUIRED")),
    cnpj: Yup.string().required(t("COMPANY_FORM_CNPJ_REQUIRED")),
    registrationNumber: Yup.string().required(
      t("COMPANY_FORM_REGISTRATION_NUMBER_REQUIRED")
    ),
    dateOfIncorporation: Yup.date().required(
      t("COMPANY_FORM_DATE_OF_INCORPORATION_REQUIRED")
    ),
    // city: Yup.string().required("City is required"),
    // state: Yup.string().required("State is required"),
    // address: Yup.string().required("Address is required"),
    // neighbourhood: Yup.string().required("Neighbourhood is required"),
    // ndegree: Yup.string().required(
    //   "<div class='error-message'>N <span>&deg;</span> is required</div>"
    // ),
  });

  const handleSubmit = (values: typeof initialValues) => {
    // Handle form submission here
    console.log(values);
  };

  return (
    <Box sx={{ maxWidth: "500px" }}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnMount={true}
        onSubmit={handleSubmit}
      >
        {({ isValid }) => (
          <Form>
            <CustomInput
              name={"companyName"}
              label={t("COMPANY_FORM_COMPANY_NAME")}
              type={"text"}
              disabled
            />
            <CustomInput
              name={"cnpj"}
              label={t("COMPANY_FORM_CNPJ")}
              type={"text"}
              disabled
            />
            <CustomInput
              name={"registrationNumber"}
              label={t("COMPANY_FORM_REGISTRATION_NUMBER")}
              type={"text"}
              disabled
            />
            <CustomInput
              name={"dateOfIncorporation"}
              label={t("COMPANY_FORM_DATE_OF_INCORPORATION")}
              type={"date"}
              disabled
            />
            {/* <CustomInput name={"city"} label={"City"} type={"text"} />
            <CustomInput name={"state"} label={"State/UF"} type={"text"} />
            <CustomInput name={"address"} label={"Address"} type={"text"} />
            <CustomInput
              name={"neighbourhood"}
              label={"Neighbourhood"}
              type={"text"}
            />

            <Box sx={{ mb: "15px" }}>
              <InputLabel sx={{ mb: "10px" }} htmlFor="ndegree">
                N <span>&deg;</span>
              </InputLabel>
              <Field
                as={TextField}
                style={{
                  width: "100%",
                  borderRadius: "10px",
                }}
                type="text"
                name="ndegree"
              />
              <ErrorMessage name="ndegree">
                {(msg) => <div dangerouslySetInnerHTML={{ __html: msg }} />}
              </ErrorMessage>
            </Box> */}

            <CustomButton
              btnText={t("SAVE")}
              disabled={!isValid}
              type="submit"
              sx={{ ml: "0px", mt: "10px" }}
            />
          </Form>
        )}
      </Formik>
    </Box>
  );
}
