import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import createSagaMiddleware from "redux-saga";
import createLoadingMiddleware from "saga/middlewares/loadingMiddleware";

import rootSaga from "saga/rootSaga";
import flagSmithSlice from "store/features/flagSmithSlice";
import globalSlice from "store/features/globalSlice";
import authSlice from "./features/authSlice";
import clientSlice from "./features/clientSlice";
import portalConfigSlice from "./features/configSlice";
import customerDetailsSlice from "./features/customerDetailsSlice";
import loaderSlice from "./features/loaderSlice";
import paymentSlice from "./features/paymentSlice";
import quotesSlice from "./features/quotesSlice";
import stepperReducer from "./features/stepperSlice";
import stylesSlice from "./features/stylesSlice";
import surveyJsSlice from "./features/surveyJsSlice";
import webhooksSlice from "./features/webhooksSlice";

const authPersistConfig = {
  key: "auth",
  storage,
  whitelist: ["accessToken", "refreshToken", "idToken", "portalAccount"],
};

const rootReducer = combineReducers({
  stepper: stepperReducer,
  surveyJs: surveyJsSlice,
  payment: paymentSlice,
  styles: stylesSlice,
  loader: loaderSlice,
  quotes: quotesSlice,
  customerDetails: customerDetailsSlice,
  client: clientSlice,
  webhooks: webhooksSlice,
  portalConfig: portalConfigSlice,
  global: globalSlice,
  flagSmith: flagSmithSlice,
  auth: persistReducer(authPersistConfig, authSlice),
});

// Create the saga middleware
const sagaMiddleware = createSagaMiddleware();
const loadingMiddleware = createLoadingMiddleware();

const middleware = [
  ...getDefaultMiddleware({ thunk: false, serializableCheck: false }),
  loadingMiddleware,
  sagaMiddleware,
];

const store = configureStore({
  reducer: rootReducer,
  middleware,
});

// Run the root saga
sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

const persistor = persistStore(store);
export { persistor, store };
