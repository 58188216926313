import { Navigate, useLocation } from "react-router-dom";

import { LSKeys, getFromLocalStorage } from "utils/ls.utils";

const AuthPageRouteV2 = ({ children }) => {
  const location = useLocation();

  const IS_USER_AUTHENTICATED = !!getFromLocalStorage(LSKeys.ACCESS_TOKEN);

  if (IS_USER_AUTHENTICATED) {
    return <Navigate to="/home/dashboard" state={{ from: location }} replace />;
  }

  return <>{children}</>;
};

export default AuthPageRouteV2;
