const Allianz = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.0"
      viewBox="0 0 628.21 150.65"
      id="svg39820"
      {...props}
    >
      <defs id="defs39822">
        <clipPath id="clp4">
          <path
            d="M 0,793.71 L 2152.9,793.71 L 2152.9,0 L 0,0 L 0,793.71 z "
            id="path37621"
          />
        </clipPath>
        <clipPath id="clp3">
          <path
            d="M 0.00012,3e-005 L 2152.9,3e-005 L 2152.9,793.7 L 0.00012,793.7 L 0.00012,3e-005 z "
            id="path37615"
          />
        </clipPath>
        <clipPath id="clp5">
          <path
            d="M 1457.1,761.7 L 1544.4,761.7 L 1544.4,739.1 L 1457.1,739.1 L 1457.1,761.7 z "
            id="path37641"
          />
        </clipPath>
      </defs>
      <g transform="translate(-104.3378,-135.8517)" id="layer1">
        <g id="g39870">
          <path
            d="M 157.90708,218.51549 L 136.20812,218.51549 L 147.7357,181.01696 L 157.90708,218.51549 z M 126.03673,256.42087 L 132.81766,233.56914 L 160.61946,233.56914 L 167.40038,256.42087 L 190.45553,256.42087 L 166.0442,169.69282 C 164.68801,163.99683 161.97564,162.16598 155.87281,162.16598 L 121.96818,162.16598 L 121.96818,169.96405 L 124.68055,169.96405 C 128.7491,169.96405 130.78338,171.25243 130.78338,174.37166 C 130.78338,176.33813 130.78338,177.6265 128.7491,182.30534 L 104.33777,256.42087"
            style={{
              fill: "#143861",
              fillOpacity: 1,
              fillRule: "evenodd",
              stroke: "none",
            }}
            id="path37627"
          />
          <path
            d="M 219.61351,256.42087 L 219.61351,169.08253 C 219.61351,164.53931 216.90114,162.16598 212.1545,162.16598 L 191.13362,162.16598 L 191.13362,169.96405 L 192.48981,169.96405 C 196.55836,169.96405 198.59264,171.65929 198.59264,175.32099 L 198.59264,256.42087"
            style={{
              fill: "#143861",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            id="path37629"
          />
          <path
            d="M 257.5867,256.42087 L 257.5867,169.08253 C 257.5867,164.53931 254.87433,162.16598 250.12768,162.16598 L 229.10681,162.16598 L 229.10681,169.96405 L 230.463,169.96405 C 234.53155,169.96405 236.56583,171.65929 236.56583,175.32099 L 236.56583,256.42087"
            style={{
              fill: "#143861",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            id="path37631"
          />
          <path
            d="M 296.23798,170.50653 C 296.23798,164.26807 291.49133,160.60637 284.03231,160.60637 C 275.8952,160.60637 271.82665,164.26807 271.82665,170.50653 C 271.82665,176.8806 275.8952,180.61011 284.03231,180.61011 C 291.49133,180.61011 296.23798,176.8806 296.23798,170.50653 M 295.55989,256.42087 L 295.55989,192.81578 C 295.55989,188.40817 292.84752,185.83142 288.77896,185.83142 L 267.75809,185.83142 L 267.75809,193.49387 L 269.11428,193.49387 C 273.18283,193.49387 274.53902,195.1891 274.53902,199.05423 L 274.53902,256.42087"
            style={{
              fill: "#143861",
              fillOpacity: 1,
              fillRule: "evenodd",
              stroke: "none",
            }}
            id="path37633"
          />
          <path
            d="M 345.73874,222.17719 L 345.73874,236.28151 C 341.67019,240.35007 336.92354,242.65558 332.85498,242.65558 C 328.78643,242.65558 326.75215,240.68912 326.75215,235.39999 C 326.75215,229.5684 328.10834,227.33069 332.85498,225.49984 C 336.24545,224.07585 339.63591,223.19433 345.73874,222.17719 M 315.90267,205.29268 L 317.93695,204.47897 C 323.36169,202.30907 326.75215,201.49536 330.82071,200.74946 C 332.85498,200.47822 335.56735,200.20699 336.92354,200.20699 C 343.70447,200.20699 345.73874,202.44469 345.73874,208.68314 L 345.73874,210.44618 C 336.24545,212.0058 333.53308,212.54827 328.10834,213.70103 C 325.39596,214.37912 322.68359,215.26064 319.97122,216.4134 C 311.15602,219.93948 307.08746,226.72041 307.08746,237.36646 C 307.08746,250.72489 312.5122,257.09896 324.71787,257.09896 C 329.46452,257.09896 334.21117,256.01401 337.60163,253.84411 C 340.9921,252.2845 342.34828,251.47079 346.41684,247.74128 L 346.41684,249.91118 C 346.41684,254.31878 348.45111,256.42087 352.51967,256.42087 L 372.86245,256.42087 L 372.86245,249.16527 L 372.18436,249.16527 C 368.1158,249.16527 366.08152,247.33442 366.08152,243.67272 L 366.08152,209.02219 C 366.08152,190.98493 359.97869,184.88209 342.34828,184.88209 C 336.24545,184.88209 330.82071,185.56019 325.39596,186.84856 C 319.97122,188.13694 317.25885,189.08627 311.15602,192.2733"
            style={{
              fill: "#143861",
              fillOpacity: 1,
              fillRule: "evenodd",
              stroke: "none",
            }}
            id="path37635"
          />
          <path
            d="M 404.7328,256.42087 L 404.7328,208.54753 C 409.47945,204.13992 412.86991,202.44469 417.61656,202.44469 C 422.36321,202.44469 423.7194,204.47897 423.7194,211.66675 L 423.7194,256.42087 L 444.74027,256.42087 L 444.74027,208.27629 C 444.74027,198.37614 443.38408,194.10415 440.67171,190.37464 C 437.28125,186.71294 433.21269,185.01771 427.10986,185.01771 C 418.97275,185.01771 412.86991,188.00132 403.37662,196.20624 L 403.37662,192.40892 C 403.37662,188.00132 401.34234,185.83142 397.27378,185.83142 L 376.931,185.83142 L 376.931,193.49387 L 377.6091,193.49387 C 381.67765,193.49387 383.71193,195.1891 383.71193,199.05423 L 383.71193,256.42087"
            style={{
              fill: "#143861",
              fillOpacity: 1,
              fillRule: "evenodd",
              stroke: "none",
            }}
            id="path37637"
          />
          <g
            transform="matrix(6.780926,0,0,-6.780926,-9776.146,5300.883)"
            style={{
              fill: "#143861",
              fillOpacity: 1,
              fillRule: "evenodd",
              stroke: "none",
            }}
            clipPath="url(#clp5)"
            id="g37643"
          >
            <path
              d="M 1516.9,745.91 L 1512.3,745.91 L 1516.7,752.19 L 1516.7,754.33 L 1510.1,754.33 C 1509.3,754.33 1509,754.01 1509,753.24 L 1509,751.35 L 1510.1,751.35 L 1510.1,751.56 C 1510.1,752.13 1510.4,752.36 1511,752.36 L 1513.4,752.36 L 1508.9,746.08 L 1508.9,743.92 L 1516.9,743.92"
              // style="fill:#143861;fill-opacity:1;fill-rule:nonzero;stroke:none"
              id="path37645"
            />
            <path
              d="M 1534.6,744.49 L 1534.6,756.27 C 1534.6,757.22 1534.2,757.53 1533.3,757.53 L 1530.6,757.53 L 1530.6,756.23 L 1530.9,756.23 C 1531.4,756.23 1531.6,756.04 1531.6,755.46 L 1531.6,744.49 L 1534.6,744.49 z M 1536.2,744.49 L 1539.1,744.49 L 1539.1,753.38 C 1539.1,754.28 1538.7,754.64 1537.8,754.64 L 1536.2,754.64 L 1536.2,744.49 z M 1530,744.49 L 1530,754.64 L 1528.4,754.64 C 1527.5,754.64 1527.1,754.28 1527.1,753.38 L 1527.1,744.49 L 1530,744.49 z M 1542.5,750.41 C 1542.5,744.9 1538.5,740.88 1533.1,740.88 C 1527.7,740.88 1523.7,744.9 1523.7,750.41 C 1523.7,755.92 1527.7,759.94 1533.1,759.94 C 1538.5,759.94 1542.5,755.92 1542.5,750.41 M 1544.4,750.41 C 1544.4,756.86 1539.6,761.7 1533.1,761.7 C 1526.7,761.7 1521.8,756.86 1521.8,750.41 C 1521.8,743.94 1526.7,739.1 1533.1,739.1 C 1539.6,739.1 1544.4,743.94 1544.4,750.41"
              style={{
                fill: "#143861",
                fillOpacity: 1,
                fillRule: "evenodd",
                stroke: "none",
              }}
              id="path37647"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
export default Allianz;
