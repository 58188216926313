import { SVGProps } from "react";
import { useSelector } from "react-redux";

import { customStylesSelector } from "store/selectors/selectors";

const GroupLifeSvg = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  const customStyles = useSelector(customStylesSelector);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="51"
      viewBox="0 0 48 51"
      fill="none"
    >
      <path
        d="M47.0222 36.2226C45.969 34.3985 43.6282 33.7713 41.8041 34.8244L33.2792 39.7462C33.0458 39.881 32.8049 39.9999 32.5593 40.1057C32.6539 39.8704 32.7276 39.623 32.7762 39.3647C33.152 37.3657 31.91 35.3769 29.9488 34.8372L24.0926 33.2259C22.4555 31.5508 20.2892 30.4322 17.9606 30.0655C15.4991 29.6777 12.9523 30.1518 10.7903 31.4C9.32701 32.2448 8.07037 33.4267 7.14193 34.8263C6.84027 34.4435 6.29627 34.3292 5.86167 34.5801L0.96298 37.4083C0.733912 37.5406 0.566773 37.7584 0.498362 38.0139C0.429851 38.2694 0.465752 38.5416 0.597987 38.7706L7.3708 50.5014C7.55559 50.8213 7.89067 51.0001 8.23542 51.0001C8.40455 51.0001 8.57608 50.9571 8.73304 50.8664L13.6317 48.0382C13.8608 47.906 14.0279 47.6882 14.0963 47.4327C14.1003 47.4178 14.1027 47.4028 14.106 47.3878C16.666 47.115 19.283 47.3726 21.739 48.1463L22.3685 48.3447C24.0728 48.8815 25.8353 49.1464 27.5931 49.1463C30.6265 49.1462 33.6446 48.357 36.315 46.8153L45.6241 41.4407C46.5077 40.9305 47.1398 40.1068 47.404 39.1212C47.6678 38.1357 47.5324 37.1063 47.0222 36.2226ZM8.59941 48.6406L2.82385 38.637L5.9953 36.8061L11.7709 46.8097L8.59941 48.6406ZM45.4774 38.605C45.3511 39.076 45.0491 39.4696 44.6268 39.7135L35.3177 45.088C31.5829 47.2444 27.0813 47.7381 22.9678 46.4423L22.3382 46.244C19.4325 45.3288 16.323 45.0664 13.3042 45.4765L8.24738 36.7177C8.40954 36.5169 8.56162 36.3064 8.70083 36.0862C9.46802 34.8733 10.5354 33.8503 11.7875 33.1272C13.5813 32.0916 15.6086 31.7138 17.6503 32.0357C19.6345 32.3481 21.4738 33.3268 22.8293 34.7913C22.9555 34.9277 23.1174 35.026 23.2966 35.0754L29.4197 36.7602C30.3883 37.0268 31.0016 38.009 30.816 38.9963C30.6183 40.0488 29.6011 40.7459 28.5477 40.5501L23.5593 39.6228C23.0184 39.5224 22.4973 39.8795 22.3967 40.421C22.296 40.9625 22.6534 41.4831 23.1949 41.5837L28.2053 42.515C30.2869 42.9019 32.443 42.5319 34.2766 41.4734L42.8015 36.5516C43.6735 36.0485 44.7918 36.3482 45.2951 37.2198C45.5387 37.6421 45.6034 38.1341 45.4774 38.605Z"
        fill={props.fill ?? customStyles.icons.fill}
      />
      <path
        d="M9.51727 16.016H11.376C11.6879 16.4449 12.035 16.8527 12.4172 17.2348L23.9196 28.7372C24.4737 29.2913 25.2102 29.5963 25.9938 29.5963C26.7775 29.5963 27.5139 29.2912 28.068 28.7372L39.5705 17.2347C39.9526 16.8526 40.2997 16.4448 40.6116 16.0159H43.1466C43.6973 16.0159 44.1438 15.5694 44.1438 15.0187C44.1438 14.4679 43.6973 14.0214 43.1466 14.0214H41.7387C42.2563 12.7948 42.5284 11.4654 42.5284 10.0938C42.5284 7.39639 41.4779 4.86038 39.5706 2.95294C35.86 -0.757119 29.9573 -0.971329 25.9937 2.31102C22.0303 -0.971329 16.1276 -0.75712 12.4172 2.95314C10.5098 4.86058 9.45933 7.39659 9.45933 10.094C9.45933 11.4655 9.73148 12.795 10.249 14.0216H9.51727C8.96659 14.0216 8.52002 14.4681 8.52002 15.0189C8.52002 15.5696 8.96649 16.016 9.51727 16.016ZM26.6576 27.327C26.4804 27.5043 26.2445 27.6019 25.9938 27.6019C25.7431 27.6019 25.5073 27.5043 25.33 27.327L14.019 16.016H18.5585C18.9305 16.016 19.2717 15.8089 19.4433 15.4788L20.4879 13.47L23.8331 20.5992C23.9979 20.9505 24.3506 21.1728 24.7356 21.1728C24.7536 21.1728 24.7717 21.1723 24.7899 21.1713C25.1956 21.1492 25.5474 20.8833 25.6792 20.4989L29.0737 10.6004L30.9165 15.3776C31.0649 15.7622 31.4347 16.0159 31.847 16.0159H37.9685L26.6576 27.327ZM11.4538 10.094C11.4538 7.92932 12.2968 5.89423 13.8276 4.36355C15.4075 2.78361 17.4828 1.99369 19.5582 1.99369C21.6334 1.99369 23.7087 2.78361 25.2887 4.36355C25.6782 4.75288 26.3094 4.75288 26.6991 4.36355C29.859 1.20367 35.0004 1.20377 38.1601 4.36355C39.6909 5.89423 40.5339 7.92942 40.5339 10.094C40.5339 11.4911 40.1809 12.8333 39.5204 14.0216H32.5313L29.9491 7.32817C29.8005 6.94304 29.4303 6.68984 29.0188 6.68984C29.0126 6.68984 29.0061 6.68984 28.9998 6.69003C28.5808 6.69801 28.2114 6.96707 28.0754 7.36358L24.592 17.5212L21.4366 10.7966C21.2755 10.4532 20.9334 10.231 20.5541 10.2232C20.1733 10.2154 19.824 10.4235 19.649 10.7602L17.9531 14.0215H12.4673C11.8069 12.8332 11.4538 11.4911 11.4538 10.094Z"
        fill={props.fill ?? customStyles.icons.fill}
      />
    </svg>
  );
};
export default GroupLifeSvg;
