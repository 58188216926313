import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { TextField, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { InferType } from "yup";

import { v2OTPCredSchema } from "constants/policyholder-validation-schema";
import { PolicyholderPathnames } from "constants/policyholderPathnames";
import { useMutationWithToast } from "hooks/useMutationWithToast";
import { AuthService } from "policyholderApi/auth/auth";
import { useT } from "translation";
import { setAccessToken } from "utils/token";

type V2OTPCredSchema = InferType<typeof v2OTPCredSchema>;

export function V2OTPPage() {
  const { t } = useT();
  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state?.email;
  const v2OTPForm = useForm({
    resolver: yupResolver(v2OTPCredSchema),
  });

  const v2OTPMutation = useMutationWithToast({
    mutationFn: (cred: V2OTPCredSchema) =>
      AuthService.verifyOtpAPI({ email, otp: String(cred.otp) }),

    onSuccess: (res) => {
      setAccessToken(res.data.access_token);
      navigate(PolicyholderPathnames.MyPolicies);
    },

    onError: () => {
      v2OTPForm.setError("root", {
        message: "Invalid OTP",
        type: "validate",
      });
    },
  });

  const onOTPValid = (fieldValue: V2OTPCredSchema) => {
    v2OTPMutation.mutate(fieldValue);
  };

  return (
    <form onSubmit={v2OTPForm.handleSubmit(onOTPValid)} style={{ width: '100%' }}>
      <Typography
        variant="h5"
        marginBottom={4}
        color="textSecondary"
        textAlign="center"
      >
        {t("OTP_MESSAGE")}
      </Typography>
      <TextField
        {...v2OTPForm.register("otp")}
        helperText={v2OTPForm.formState.errors.otp?.message}
        error={!!v2OTPForm.formState.errors.otp?.message}
        type="text"
        variant="outlined"
        label={t("OTP")}
        fullWidth
        sx={{ mb: 3 }}
      />
      {v2OTPForm.formState.errors.root?.message ? (
        <Typography color="error" textAlign="center" sx={{ mt: 2 }}>
          {v2OTPForm.formState.errors.root?.message}
        </Typography>
      ) : null}
      <LoadingButton
        color="primary"
        variant="contained"
        type="submit"
        fullWidth
        loading={v2OTPMutation.isPending}
      >
        {t("SIGN_IN")}
      </LoadingButton>
    </form>
  );
}
