import { Box, Typography } from "@mui/material";
import { PolicyList } from "components/policyholderComponents/policy/PolicyList";
import { useT } from "translation";

export function MyPoliciesPage() {
  const { t } = useT();

  return (
    <Box>
      <Typography variant="h4" mb={2}>
        {t("MY_POLICIES")}
      </Typography>
      <PolicyList />
    </Box>
  );
}
