import { Close } from "@mui/icons-material";
import {
  Box,
  IconButton,
  InputLabel,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import CustomButton from "components/common/CustomButton/CustomButton";
import { FAIRFAX, PORTAL_NAME } from "config";
import { Field, Formik } from "formik";
import { initialCurrentStepSelector } from "portalConfig/selectors";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateStartPageSelection } from "store/features/clientSlice";
import { setCustomerDetails } from "store/features/customerDetailsSlice";
import { resetQuotes } from "store/features/quotesSlice";
import { resetStepper } from "store/features/stepperSlice";
import {
  resetSurveyJS,
  setSurveyPageNumber,
} from "store/features/surveyJsSlice";
import {
  customStylesSelector,
  customerDetailsSelector,
} from "store/selectors/selectors";
import { useT } from "translation";
import * as Yup from "yup";

type Props = {
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: "12px",
};

function CustomerDetailsPopup({ isOpen, setIsOpen }: Props) {
  const { t } = useT();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const initialCurrentStep = useSelector(initialCurrentStepSelector);
  const customStyles = useSelector(customStylesSelector);
  const { customerDetails } = useSelector(customerDetailsSelector);
  const handleClose = () => {
    setIsOpen(false);
  };

  const CustomerDetailsSchema = Yup.object().shape({
    email: Yup.string().email(t("INVALID_EMAIL")).required(t("EMAIL_REQUIRED")),
  });

  const ErrorTextStyle = {
    margin: "0.25rem 0 0 0",
    color: "#df1b41",
    fontSize: "0.93rem",
    fontFamily: customStyles.muiTheme.typography.fontFamily,
    fontWeight: 500,
  };

  const InputLabelStyle = {
    color: customStyles.input.color,
    fontSize: "14px",
    fontWeight: "500",
    mb: "10px",
    fontFamily: customStyles.muiTheme.typography.fontFamily,
  };

  const TextFieldStyle = {
    ".MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${customStyles?.input?.borderColor ?? "#a8a8a8"}`,
      "&:hover": {
        border: `1px solid ${customStyles?.input?.hover?.borderColor}`,
      },
    },
    ".MuiOutlinedInput-input": {
      height: "14px",
    },
    ".MuiInputBase-root": {
      height: "48px",
      borderRadius: "6px",
      fontWeight: "normal",
      color: customStyles.input.color,
      backgroundColor: customStyles.input.backgroundColor,
    },
    ".Mui-error": ErrorTextStyle,
  };
  const isFairFax = PORTAL_NAME === FAIRFAX;
  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            id="modal-modal-title"
            sx={{
              color: customStyles.muiTheme.palette.text.primary,
              fontSize: "22px",
              fontWeight: 600,
            }}
          >
            {t("CUSTOMER_DETAILS_PAGE_DESCRIPTION_PRIMARY")}
          </Typography>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>
        <Box id="modal-modal-description" sx={{ mt: 2 }}>
          <Formik
            initialValues={{
              email: customerDetails?.email,
            }}
            validationSchema={CustomerDetailsSchema}
            onSubmit={(values) => {
              dispatch(setCustomerDetails(values));

              if (customerDetails?.email !== values.email) {
                localStorage.removeItem("prefillData");
                const clientData = {
                  prefill_values: isFairFax
                    ? { business_email: values.email }
                    : { applicant_email: values.email },
                  read_only_values: isFairFax
                    ? { business_email: true }
                    : {
                        applicant_email: true,
                      },
                };
                localStorage.setItem("clientData", JSON.stringify(clientData));
                dispatch(resetSurveyJS());
              }
              dispatch(resetStepper(initialCurrentStep));
              dispatch(resetQuotes());
              dispatch(updateStartPageSelection([]));
              dispatch(setSurveyPageNumber(0));
              navigate("/newQuote/start");
            }}
          >
            {({ errors, touched, handleSubmit }) => (
              <Box
                sx={{ display: "flex", flexDirection: "column", flexGrow: "1" }}
              >
                <Box sx={{ marginBottom: 2 }}>
                  <InputLabel required sx={InputLabelStyle}>
                    {t("PERSONAL_FORM_EMAIL")}
                  </InputLabel>
                  <Field
                    as={TextField}
                    name="email"
                    type="email"
                    sx={TextFieldStyle}
                    fullWidth
                    data-cy="customer-email-input"
                  />
                  {errors.email && touched.email ? (
                    <Typography sx={ErrorTextStyle}>{errors.email}</Typography>
                  ) : null}
                </Box>
                <Box sx={{ alignSelf: "flex-end" }}>
                  <CustomButton
                    data_cy="customer-details-btn"
                    onClickCB={handleSubmit}
                    btnText={t("NEXT")}
                  />
                </Box>
              </Box>
            )}
          </Formik>
        </Box>
      </Box>
    </Modal>
  );
}

export default CustomerDetailsPopup;
