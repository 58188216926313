import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useState } from "react";

import AnimatedTabPanel from "components/custom/AnimatedTabPanel";
import { animateRouteProps } from "constants/common";
import { useT } from "translation";
import ChangePassword from "./ChangePassword";
import Company from "./Company";
import Personal from "./Personal";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </Box>
  );
}

const SettingsTabPanel = () => {
  const { t } = useT();
  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box>
        <Tabs value={value} onChange={handleChange}>
          <Tab
            sx={{
              textTransform: "none",
            }}
            label={t("SETTINGS_PAGE_TAB_COMPANY")}
          />
          <Tab
            sx={{
              textTransform: "none",
            }}
            label={t("SETTINGS_PAGE_TAB_PERSONAL")}
          />
          <Tab
            sx={{
              textTransform: "none",
            }}
            label={t("SETTINGS_PAGE_TAB_CHANGE_PASSWORD")}
          />
        </Tabs>
      </Box>
      <AnimatedTabPanel {...animateRouteProps} value={value} index={0}>
        <Company />
      </AnimatedTabPanel>
      <AnimatedTabPanel {...animateRouteProps} value={value} index={1}>
        <Personal />
      </AnimatedTabPanel>
      <AnimatedTabPanel {...animateRouteProps} value={value} index={2}>
        <ChangePassword />
      </AnimatedTabPanel>
    </Box>
  );
};

export default SettingsTabPanel;
