import FairfaxSupport from "components/Support/Fairfax";
import { FAIRFAX, PORTAL_NAME } from "config";
import ChatboxWraper from "components/Chatbox/ChatboxWrapper";

// This component is used to render the support chatbox.
const Support = () => {
    // If a portal has a custom support implementation, it can be added here.
    switch (PORTAL_NAME) {
        case FAIRFAX:
            return <FairfaxSupport />;

        // If a portal does not have a specific support implementation, the default Stere chatbox is rendered.
        default:
            return <ChatboxWraper />;
    }
};

// Flag to show the support chatbox on the new quotes page. 
// For example, the support option needs to be show on the New Quotes page for Fairfax.
// More portals can be added in the below array if needed.
const SHOW_SUPPORT_ON_NEW_QUOTES_PAGE = [FAIRFAX].includes(PORTAL_NAME);

export { Support, SHOW_SUPPORT_ON_NEW_QUOTES_PAGE };