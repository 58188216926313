import { Box, Paper } from "@mui/material";
import { AppLogo } from "components/policyholderComponents/icons/AppLogo";
import { PageLayoutWrapper } from "components/policyholderComponents/layout/PageLayoutWrapper";
import { UnprotectedRoute } from "components/policyholderComponents/logic-handlers/UnprotectedRoute";
import { IS_ONCE_PH_PORTAL } from "config";
import { Outlet } from "react-router";

export function AuthLayout() {
  return (
    <PageLayoutWrapper bgColor="primary">
      <UnprotectedRoute>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <AppLogo style={{ marginBottom: 20 }} hideName={IS_ONCE_PH_PORTAL} />
          <Paper
            sx={{
              maxHeight: "600px",
              width: "45vw",
              maxWidth: "600px",
              minWidth: "360px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              borderRadius: 3,
              padding: 4,
            }}
          >
            <Outlet />
          </Paper>
        </Box>
      </UnprotectedRoute>
    </PageLayoutWrapper>
  );
}
