

import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { TextField, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { InferType } from "yup";

import { v2loginCredSchema } from "constants/policyholder-validation-schema";
import { PolicyholderPathnames } from "constants/policyholderPathnames";
import { useMutationWithToast } from "hooks/useMutationWithToast";
import { AuthService } from "policyholderApi/auth/auth";
import { useT } from "translation";

type V2LoginCredSchema = InferType<typeof v2loginCredSchema>;

export function NewLoginPage() {
  const navigate = useNavigate();
  const { t } = useT();
  const loginForm = useForm({
    resolver: yupResolver(v2loginCredSchema),
  });

  const loginMutation = useMutationWithToast({
    mutationFn: (cred: V2LoginCredSchema) =>
      AuthService.newSignInAPI({ email: cred.email }),

    onSuccess: (res) => {
      navigate(PolicyholderPathnames.VerifyOtp, { state: { email: loginForm.getValues("email") } })
    },

    onError: () => {
      loginForm.setError("root", {
        message: "Invalid credentials",
        type: "validate",
      });
    },
  });

  const onLoginValid = (fieldValue: V2LoginCredSchema) => {
    loginMutation.mutate(fieldValue);
  };

  return (
    <form onSubmit={loginForm.handleSubmit(onLoginValid)} style={{ width: '100%' }}>
      <Typography
        variant="h5"
        marginBottom={4}
        color="textSecondary"
        textAlign="center"
      >
        {t("SIGN_IN_MESSAGE_V2")}
      </Typography>
      <TextField
        {...loginForm.register("email")}
        helperText={loginForm.formState.errors.email?.message}
        error={!!loginForm.formState.errors.email?.message}
        type="email"
        variant="outlined"
        label={t("EMAIL")}
        fullWidth
        sx={{ mb: 3 }}
      />
      {loginForm.formState.errors.root?.message ? (
        <Typography color="error" textAlign="center" sx={{ mt: 2 }}>
          {loginForm.formState.errors.root?.message}
        </Typography>
      ) : null}
      <LoadingButton
        color="primary"
        variant="contained"
        type="submit"
        fullWidth
        loading={loginMutation.isPending}
      >
        {t("SIGN_IN")}
      </LoadingButton>
    </form>
  );
}
