import { Box } from "@mui/material";
import AppointmentsList from "components/Home/Appointments/AppointmentsList";
import OutletHeader from "components/Home/Common/OutletHeader";
import { animateRouteProps } from "constants/common";
import { useT } from "translation";

function MyAppointments() {
  const { t } = useT();

  return (
    <Box>
      <Box
        sx={{
          backgroundColor: "white",
          borderRadius: "12px",
        }}
        {...animateRouteProps}
      >
        <OutletHeader title={t("MY_APPOINTMENTS")} />
      </Box>
      <Box>
        <AppointmentsList />
      </Box>
    </Box>
  );
}

export default MyAppointments;
