import axios, { AxiosInstance } from "axios";

import { AuthService } from "policyholderApi/auth/auth";
import {
  getAccessToken,
  getIDToken,
  getRefreshToken as getRefreshTokenFromLocalStorage,
  setAccessToken,
  setIDToken,
} from "utils/token";

export const API_VERSION = "v1";

export const API_BASE_URL = process.env.REACT_APP_SERVER_URL + "/v1";

export const STERE_API_BASE_URL = process.env.REACT_APP_SERVER_URL_V2;

const controllerMap: Record<string, AbortController> = {};

export function createAxiosInstanceWithAccessToken(
  abortControllerName?: string
): AxiosInstance {
  const instance = axios.create();

  if (abortControllerName) {
    controllerMap[abortControllerName] = new AbortController();
  }

  instance.interceptors.request.use((config) => {
    if (abortControllerName) {
      // eslint-disable-next-line no-param-reassign
      config.signal = controllerMap[abortControllerName].signal;
    }
    const accessToken = getAccessToken();
    if (accessToken) {
      config.headers.set("Authorization", accessToken);
    }

    return config;
  });

  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      if (
        (error.response.status === 401 ||
          error.response.data?.detail === "Cognito user not found") &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;

        const resp = await AuthService.getRefreshTokenAPI({
          refresh_token: getRefreshTokenFromLocalStorage() as string,
          id_token: getIDToken() as string,
        });

        const newAccessToken = resp?.data?.results?.access_token;
        const newIdToken = resp?.data?.results?.id_token;
        setAccessToken(newAccessToken);
        setIDToken(newIdToken);
        originalRequest.headers.Authorization = getAccessToken();

        return axios(originalRequest);
      }

      return Promise.reject(error);
    }
  );

  instance.defaults.baseURL = API_BASE_URL;

  return instance;
}

export function createAxiosInstanceWithoutAccessToken(
  abortControllerName?: string
): AxiosInstance {
  const instance = axios.create();

  if (abortControllerName) {
    controllerMap[abortControllerName] = new AbortController();
  }

  instance.interceptors.request.use((config) => {
    if (abortControllerName) {
      config.signal = controllerMap[abortControllerName].signal;
    }
    return config;
  });
  instance.defaults.baseURL = API_BASE_URL;

  return instance;
}
