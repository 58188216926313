import { Box, Typography } from "@mui/material";
import ErrorSvg from "assets/images/ErrorSvg";
import { useSelector } from "react-redux";
import { customStylesSelector } from "store/selectors/selectors";

const ErrorComponent = () => {
  const customStyles = useSelector(customStylesSelector);
  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <ErrorSvg />
      <Typography
        sx={{
          fontSize: "20px",
          fontWeight: "600",
          color: customStyles?.muiTheme?.palette?.text?.primary,
          mt: "10px",
        }}
      >
        Something went wrong
      </Typography>
      <Typography
        sx={{
          fontSize: "16px",
          fontWeight: "500",
          color: customStyles?.muiTheme?.palette?.text?.tertiary,
        }}
      >
        Please try again later
      </Typography>
    </Box>
  );
};

export default ErrorComponent;
