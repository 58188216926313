import { createTheme } from "@mui/material";
import merge from "lodash/merge";
import { base } from "./themes";

const baseTheme = base;

export const baseMuiTheme = createTheme(base.muiTheme);

let finalTheme = {
  ...baseTheme,
};
export const setMuiTheme = (clientStyles: any) => {
  const baseStyles = JSON.parse(JSON.stringify(base));
  let mergedTheme = merge(baseStyles, clientStyles);
  finalTheme = { ...mergedTheme };
};

export const getTheme = () => {
  const { muiTheme } = finalTheme;
  const appTheme = createTheme({
    ...muiTheme,
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "none",
          },
        },
      },
    },
  });
  return {
    appTheme,
    ...finalTheme,
  };
};
