import { TranslationKeys } from "./types";

export class TranslationPt implements TranslationKeys {
  public SEARCH = "Buscar";
  public SETTINGS = "Configurações";
  public LOGOUT = "Sair";
  // Home Navigations
  public DASHBOARD = "Painel";
  public QUOTES = "Cotações";
  public POLICIES = "Apólices";
  public INSURANCE_PRODUCTS = "Produtos de seguros";
  public MY_APPOINTMENTS = "Meu compromisso";
  public NEW_QUOTE = "Nova cotação";
  public APPLICATIONS = "Propostas";
  public CLAIMS = "Sinistros";
  // Dashboard Page
  public QUOTES_SENT = "Cotações enviadas";
  public POLICIES_ISSUED = "Apólices emitidas";
  public COMMISSION_EARNED = "Comissão estimada";

  // Customer details page
  public CustomerDetails = "Detalhes do cliente";
  public CUSTOMER_DETAILS_PAGE_DESCRIPTION_PRIMARY = "Detalhes do cliente";
  public CUSTOMER_DETAILS_PAGE_DESCRIPTION_SECONDARY =
    "Insira as informações do cliente";

  public KNOCKOUT_FORM_PAGE_HEADING = "Vamos fazer uma cotação para você";
  public KNOCKOUT_LETS_GET_STARTED = "Vamos começar";
  public KNOCKOUT_FORM_PAGE_HEADING_JWL = "";
  public KNOCKOUT_FORM_PAGE_SUBHEADING = "Isso levará menos de 60 segundos!";
  public CHOOSE_A_PLAN = "Escolha um plano";
  public SELECT_PAYMENT_FREQUENCY = "Selecione sua frequência de pagamento";

  // Quotes Page
  public APPLICATIONS_PAGE = {
    title: "Propostas",
    desc: "Propostas em andamento",
    tableHeaders: {
      application_id: "ID da propostas",
      applicant_name: "Nome do candidato",
      date: "Data",
      quote: "Cotações",
      status: "Status",
      insurance: "Seguro",
      action: "Ação",
    },
  };
  public AGENTS_PAGE = {
    title: "Todos os agentes",
    inviteBtn: "Convidar",
    status: "Status",
    send: "Enviar",
    inviteDialogHeading: "Convidar agente",
    agentsTabView: {
      myAgents: "Meus agentes",
      sentInvites: "Convites enviados",
    },
  };
  // Quotes Page
  public QUOTES_PAGE = {
    title: "Citações",
    desc: "Cotações enviadas ao segurado",
    tableHeaders: {
      application_id: "ID da propostas",
      applicant_name: "Nome do candidato",
      date: "Data",
      quote: "Cotações",
      status: "Status",
      insurance: "Seguro",
      action: "Ação",
    },
    status: {
      quoteSent: "Cotação Enviada",
      quotesSent: "Cotações Enviadas",
      noQuoteSent: "Nenhuma Cotação Enviada",
      paymentMade: "Pagamento Efetuado",
      quotesGenerated: "Cotação Gerada",
      noQuoteGenerated: "Nenhuma cotação gerada",
    },
  };
  public APPLICATION_PAGE = {
    status: {
      draft: "Rascunho",
      submitted: "Submetida",
    },
  };
  // Policies Page
  public POLICIES_PAGE = {
    title: "Apólices",
    desc: "Apólices emitidas",
    tableHeaders: {
      policy_num: "Número da apólice",
      policy_holder: "Titular da Apólice",
      insurance: "Seguro",
      insurer: "Seguradora",
      commission: "Comissão",
      renewal_date: "Data de renovação",
      status: "Status",
      action: "Ação",
    },
    status: {
      active: "Ativa",
    },
  };
  // Insurance Products Page
  public I_PRODUCTS_DESCRIPTION_PRIMARY =
    "Produtos de seguros disponíveis para cotação";
  // Common
  public BACK = "Voltar";
  public NEXT = "Próximo";
  public CONTINUE = "Próximo";
  public COMPLETE = "completa";
  public COMING_SOON = "Em breve...";
  public BACK_TO_DASHBOARD = "Voltar para Dashboard";
  public CANCEL = "Cancelar";
  public EXIT = "Saída";
  public SAVE = "Salvar";
  public PAYMENT_FREQUENCY_MONTHLY = "Mensal";
  public PAYMENT_FREQUENCY_YEARLY = "Anual";
  public FREQUENCY_MONTH = "mês";
  public FREQUENCY_YEAR = "ano";
  // Loader
  public GLOBAL_LOADER_MESSAGE = "Configurando as coisas para você...";
  public FETCHING_APPLICATION = "Buscando a melhor solução para você...";
  public LOADING_DOCUMENT_FOR_SIGN = "Loading documents for sign ...";
  public FETCHING_DOCU_SIGN_STATUS =
    "Buscando status de assinatura do documento...";
  public GETTING_CONFIRMATION = "Obtendo confirmação...";
  public LOADING_PAYMENT_WIDGET = "Carregando widget de pagamento...";
  public FETCHING_PRODUCTS = "Buscando todos os produtos...";
  public FETCHING_PLANS =
    "Encontrando os planos mais adequados. Isso não vai demorar!";
  public SUBMITTING_RESPONSE = "Enviando sua resposta...";
  public FETCHING_QUOTES = "Buscando suas cotações...";
  // Register page
  public REGISTER = "Registro";
  public EnterDetails = "Por favor insira seus dados para continuar";
  public UnableToRegisterUser = "Não foi possível cadastrar o usuário!";
  // SignIn page
  public SIGN_IN = "Entrar";
  public SIGN_IN_MESSAGE = "Entrar com endereço de email";
  public SIGN_IN_MESSAGE_V2 = "Por favor, insira seu email";
  public OTP_MESSAGE = "Por favor, insira o OTP enviado para o seu e-mail";
  public REMEMBER_ME = "Lembrar de mim";
  public FORGOT_PASSWORD = "Esqueceu a senha?";
  public SIGN_IN_BUTTON_TEXT = "Entrar";
  public EMAIL_LABEL = "Endereço de email";
  public FULL_NAME_LABEL = "Nome completo";
  public EMAIL_REQUIRED = "O e-mail é obrigatório";
  public INVALID_EMAIL = "Endereço de email invalido";
  public PASSWORD_LABEL = "Senha";
  public PASSWORD_REQUIRED = "Senha requerida";
  public FULL_NAME_REQUIRED = "O nome completo é obrigatório";
  public INVALID_PASSWORD = "A senha deve conter pelo menos 8 caracteres";
  public PASSWORD_CREATED = "Senha criada";
  public PASSWORD_CREATED_DESCRIPTION_PRIMARY =
    "Sua senha foi criada com sucesso";
  public CREATE_PASSWORD = "Criar senha";
  public CREATE_NEW_PASSWORD = "Criar nova senha";
  public CREATE_NEW_PASSWORD_DESCRIPTION_PRIMARY =
    "Por favor escolha uma nova senha";
  // Forgot Password Page
  public FORGOT_PASSWORD_MESSAGE =
    "Digite seu e-mail cadastrado para definir uma nova senha";
  public RECOVER_PASSWORD = "Recuperar senha";
  // Stepper
  public Start = "Início";
  public Knockout = "Suprimir";

  public Application = "Cotação";
  public Quote = "Resumo da cotação";
  public Payment = "Pagamento";
  public Confirmation = "Confirmação";
  // Quote Start Page
  public START_PAGE_DESCRIPTION_PRIMARY =
    "Selecione um ou mais produtos de seguro para começar a propostas*";
  public START_PAGE_DESCRIPTION_SECONDARY = "  ";
  public START_PAGE_TABS = {
    PERSONAL: "Pessoal",
    BUSINESS: "Negócios",
  };
  // Application Page
  public APPLICATION_PAGE_DESCRIPTION_PRIMARY =
    "Inserir as informações do segurado";
  public APPLICATION_PAGE_DESCRIPTION_SECONDARY = "  ";
  public APPLICATION_PAGE_SELECTED_INSURANCE = "Seguro Selecionado";
  public APPLICATION_PAGE_QUOTES_FROM_INSURER = "Cotação da Seguradora";
  // Quotes Page
  public QUOTE_PAGE_DESCRIPTION_PRIMARY = "Selecione um Plano";
  public QUOTE_PAGE_DESCRIPTION_SECONDARY =
    "Por favor, certifique-se de que todos os detalhes estão em ordem";
  public QUOTE_PAGE_COVERAGES = "Cobertura";
  public QUOTE_PAGE_SELECT_ONE_PLAN =
    "(Selecione um plano para ver os detalhes de sua cobertura)";
  public QUOTE_PAGE_SUMMARY = "Sumário";
  public QUOTE_PAGE_SEND_QUOTES = "Enviar cotação";
  public QUOTE_PAGE_SHARE_LINK = "Compartilhar link";
  public QUOTE_PAGE_SUCCESS_MESSAGE = "Proposta enviada com sucesso!";
  // Quotes Snapshot Page
  public QUOTES_SNAPSHOT_DESCRIPTION_PRIMARY = (applicantName: string) => {
    return `Olá ${applicantName} aqui está sua Proposta`;
  };
  public QUOTES_SNAPSHOT_DESCRIPTION_SECONDARY =
    "O valor estimado do prêmio que você recebeu, é baseado no tipo de apólice que você selecionou previamente e nas informações fornecidas.";
  public QUOTES_SNAPSHOT_MAKE_PAYMENT = "Pagamento Seguro";
  public QUOTES_SNAPSHOT_PAYMENT_DONE = "Paga";
  public QUOTES_SNAPSHOT_PENDING_PAYMENT_STATUS = "Conclusão Pendente";
  public QUOTES_SNAPSHOT_PAID_PAYMENT_STATUS = "";
  public QUOTES_TOTAL_PREMIUM = "Prêmio total";
  public QUOTES_TOTAL_PREMIUM_JWL = "";
  // Payment Page
  public PAYMENT_PAGE_TOTAL_DUE = "Valor Total";
  public PAYMENT_PAGE_BREAKDOWN = "Breakdown";
  public PAYMENT_PAGE_INSURANCE = "Seguro";
  public PAYMENT_PAGE_INSURER = "Seguradora";
  public PAYMENT_PAGE_PREMIUIM = "Prêmio";
  public PAYMENT_PAGE_BASIC_PREMIUM = "Prêmio Básico";
  public PAYMENT_PAGE_PREMIUIM_WITHOUT_VAT = "Prêmio (sem IVA)";
  public PAYMENT_PAGE_TAX = "Taxa";
  public PAYMENT_PAGE_TAX_JWL = "Tax incl. Surplus Lines tax and state fees";
  public PAYMENT_PAGE_GST = "ICMS";
  public PAYMENT_PAGE_DESCRIPTION_PRIMARY =
    "O melhor preço, sob medida para você";
  public PAYMENT_PAGE_DESCRIPTION_SECONDARY =
    "Clique no botão 'Fazar pagamento' para processar";
  public PAYMENT_PAGE_TRANSACTION_FEE = "Taxas de transação";
  public PAYMENT_PAGE_SECURE_PAYMENT = "Pagamento Seguro";
  public PAYMENT_PAGE_MAKE_PAYMENT = "Fazer Pagamento";
  public PAYMENT_PAGE_SUCCESS_MESSAGE = "Pagamento Bem Sucedido!";
  public PAYMENT_PAGE_FAILURE_MESSAGE = "Pagamento falhou!";
  public PAYMENT_COMPLETE = "Pagamento concluído";
  public PAYMENT_PENDING = "Conclusão pendente";
  public PROCESSING_PAYMENT = "Processando o pagamento...";
  public DO_NOT_CLOSE_WINDOW = "Por favor, não feche a janela";
  // Checkout Form
  public CHECKOUT_FORM_CARD = "Cartão";
  public CHECKOUT_FORM_NAME_ON_CARD = "Nome no Cartão";
  public CHECKOUT_FORM_CARD_NUMBER = "Número do Cartão";
  public CHECKOUT_FORM_CARD_EXPIRY = "Data de Vencimento";
  public CHECKOUT_FORM_CVV = "CVV";
  public CHECKOUT_FORM_EMAIL = "E-mail";
  // Settings Page
  public SETTINGS_PAGE_DESCRIPTION_PRIMARY =
    "Precisa atualizar algumas informações?";
  public SETTINGS_PAGE_TAB_COMPANY = "Empresa";
  public SETTINGS_PAGE_TAB_PERSONAL = "Pessoal";
  public SETTINGS_PAGE_TAB_CHANGE_PASSWORD = "Mudar Senha";
  // Company Form
  public COMPANY_FORM_COMPANY_NAME = "Nome da Empresa";
  public COMPANY_FORM_CNPJ = "CNPJ";
  public COMPANY_FORM_REGISTRATION_NUMBER = "Número de Registro";
  public COMPANY_FORM_DATE_OF_INCORPORATION = "Data de inserção";
  public COMPANY_FORM_COMPANY_NAME_REQUIRED = "O nome da empresa é obrigatório";
  public COMPANY_FORM_CNPJ_REQUIRED = "CNPJ é obrigatório";
  public COMPANY_FORM_REGISTRATION_NUMBER_REQUIRED =
    "O número de registro é obrigatório";
  public COMPANY_FORM_DATE_OF_INCORPORATION_REQUIRED =
    "A data de incorporação é obrigatória";
  // Personal Form
  public PERSONAL_FORM_FULL_NAME = "Nome Completo";
  public PERSONAL_FORM_CPF = "CPF";
  public PERSONAL_FORM_EMAIL = "Email";
  public PERSONAL_FORM_PHONE = "Telefone";
  public PERSONAL_FORM_FULL_NAME_REQUIRED = "O nome completo é obrigatório";
  public PERSONAL_FORM_PHONE_REQUIRED = "O telefone é obrigatório";
  public PERSONAL_FORM_INVALID_PHONE =
    "Insira um número de telefone válido (11 dígitos)";
  // Change Password
  public CHANGE_PASSWORD_FORM_NEW_PASSWORD = "Nova Senha";
  public CHANGE_PASSWORD_FORM_CONFIRM_PASSWORD = "Confirme a nova senha";
  public CHANGE_PASSWORD_SUBMIT_BUTTON = "Mudar Senha";
  public CHANGE_PASSWORD_NEW_PASSWORD_REQUIRED = "Nova senha é necessária";
  public CHANGE_PASSWORD_CONFIRM_PASSWORD_REQUIRED =
    "Confirmar senha é obrigatório";
  public CHANGE_PASSWORD_CONFIRM_PASSWORD_INVALID =
    "As senhas devem corresponder";
  public SOMETHING_WENT_WRONG = "Algo deu errado";
  public NO_DATA_AVAILABLE = "Nenhum dado disponível";
  public POP_UPS = {
    BACK_TO_DASHBOARD_POPUP: {
      title: "Cancelar Cotação",
      content: "Você gostaria de cancelar sua cotação?",
      btnText: "Confirmar",
    },
    LOGOUT_POPUP: {
      title: "Sair",
      content: "Gostaria de sair da sua conta?",
      btnText: "Confirmar",
    },
    SEND_QUOTES_POPUP: {
      title: "Enviar cotação",
      content:
        "Você revisou os planos para todos os produtos selecionados e confirmou suas escolhas?",
      btnText: "Enviar",
    },
  };
  public PASSWORD_REQ = {
    title: "Requisitos de senha",
    digit: "Pelo menos um dígito",
    lowerCase: "Pelo menos uma letra minúscula",
    upperCase: "Pelo menos uma letra maiuscula",
    specialChar: "Pelo menos um caractere especial",
    minLength: "Contém pelo menos 10 caracteres",
  };
  public QUOTES_NOT_GENERATED = {
    title: `Não foi possível encontrar os planos certos com base na sua inscrição.`,
    subTitle: "Por favor, tente criar um novo.",
    reason: "Razão",
  };
  public SUCCESS_PAGE_CONTENT = {
    line1: "Você receberá um email de confirmação em breve.",
    line2:
      "Caso você não receba nenhum email, ou tenha alguma dúvida, por favor entre em contato conosco através do endereço de email: ",
    email: "support_br@stere.io ",
    line3: "para auxílio.",
    msigLine2:
      "Para qualquer dúvida ou consulta, entre em contato com MSIG em ",
  };
  public ERROR_PAGE_CONTENT = {
    line1: "Erro ao enviar cotações",
  };
  public CHATBOX = {
    heading: "Estamos aqui para ajudar!",
    hello: "Olá!",
    helpText: "Estamos aqui para ajudá-lo.",
    assistText:
      "Você poderia nos fornecer seu endereço de e-mail e uma breve descrição de sua dúvida?",
    email: "E-mail",
    message: "Mensagem",
    submitQueryButtonText: "Enviar Solicitação",
    submitAnotherQueryButtonText: "Enviar outra Solicitação",
    thanksText: "Obrigado por enviar sua Solicitação!",
    thanksDescText: `Recebemos suas informações e entraremos em contato com você
    O mais breve possível. Se você tiver mais dúvidas, sinta-se
    livre para enviar outra Solicitação.`,
    greatDayText: "Tenha um ótimo dia!",
    requiredText: "Obrigatória",
  };
  public FILTERS = {
    fromDate: "A partir da data",
    toDate: "Até a data",
    filter: "Filtro",
    insuranceProducts: "Produtos de Seguro",
    search: "Buscar",
    allSelected: "Todos Selecionados",
    selectAll: "Selecionar Todos",
    clearAll: "Limpar Todos",
    hide: "Ocultar",
    apply: "Aplicar",
    selected: "Selecionada",
    noOptions: "Sem opções",
  };
  public TABLES = {
    action: "Ação",
    view: "Visualizar",
    rowsPerPage: "Linhas por Página",
    of: "de",
    moreThan: "mais do que",
  };

  /**
   * Auth screens
   */
  public EMAIL = "Email";
  public PASSWORD = "Senha";
  public FORGOT_PASSWORD_LINK = "Esqueceu a senha?";
  public FORGOT_PASSWORD_DESCRIPTION =
    "Insira seu email cadastrado para definir uma nova senha";
  public VERIFY_EMAIL = "Verifique seu email";
  public VERIFY_EMAIL_INFO =
    "Ótimo! Enviamos um e-mail de verificação para o seu endereço de e-mail registrado";
  public VERIFY_EMAIL_SUB_TEXT =
    "Por favor, verifique sua caixa de entrada (ou pasta de spam) e clique no link para confirmar sua conta. Uma vez verificada, você pode fazer login e começar a usar o aplicativo";
  public RESEND_EMAIL = "Reenviar e-mail";
  public AVOID_LOCKED_OUT = "Evite ficar sem acesso a sua conta";
  public SENT_PASS_RECOVERY_INSTRUCTIONS =
    "Enviamos as instruções de redefinição de senha em seu email";
  public VERIFY_USER_TO_PROCEED =
    "Por favor, verifique o usuário para continuar";
  public RESET_PASSWORD = "Redefinir senha";
  public CHOOSE_NEW_PASSWORD = "Por favor escolha uma nova senha";
  public CONFIRM_PASSWORD = "Confirmar Senha";
  public RESET_PASSWORD_SUCCESSFUL = "Redefinição de senha concluída";
  public RESET_PASSWORD_SUCCESSFUL_MESSAGE =
    "Agora você pode utilizar sua nova senha para acessar sua conta";
  public LOGIN = "Acessar conta";

  /**
   * Layout Translations
   */
  public MY_POLICIES = "Minhas apólices";
  public ACCOUNT = "Minhas apólices";
  public CHOOSE_LANGUAGE = "Escolha o seu idioma";
  public FOOTER_TEXT = "Todos os direitos reservados";
  public TERMS = "Termos";
  public PRIVACY = "Privacidade";
  public LICENSE = "Licenças";
  public WELCOME = "Bem Vindo(a) {Name}";
  public WELCOME_WITHOUT_NAME = "Bem Vindo(a)";

  /**
   * Policy Listings
   */
  public POLICY_TERMS = "Termo de apólice";
  public INSURER = "Seguradora";
  public BROKER_ON_RECORD = "Corretor Registrado";
  public ACTIVE = "Ativo";
  public INACTIVE = "Inativo";

  /**
   * Policy Details
   */
  public COVERAGE_DETAILS = "Detalhes de cobertura";
  public COVERAGE = "Cobertura";
  public CANCEL_POLICY = "Cancelar Apólice";
  public RENEW_POLICY = "Renovar Apólice";
  public POLICY = "Apólice";
  public DOCUMENTS = "Documentos";
  public AGENT_INFO = "Informações do agente";
  public CONTACT_AGENT =
    "Duvidas ou preocupações? Entre em contato com o agente para assistência";

  /**
   * Account page
   */
  public CONTACT_INFO = "Informações de contato";
  public NAME = "Nome";
  public PHONE_NUMBER = "Número de telefone";

  // Payment frequency
  public MONTHLY = "Por mês";
  public QUARTERLY = "Trimestral";
  public HALF_YEARLY = "Semestralmente";
  public BI_ANNUAL = "Semestral";
  public YEARLY = "Anual";

  public SUM_ASSURED = "Soma garantida";

  public CONNECT_TO_SERVICE_USER = "Conectarse al usuario del servicio";
  public SELECT = "Selecione";

  public BEST = "Melhor";
  public SELLER = "Vendedor";

  public OR = "ou";

  public NOTIFICATION_PAGE = {
    title: "Todas as notificações",
    accept: "Aceitar",
    decline: "Declínio",
  };
  public FIRST_NAME = "Primeiro nome";
  public LAST_NAME = "Sobrenome";

  public FIRST_NAME_REQUIRED = "O primeiro nome é necessário";
  public LAST_NAME_REQUIRED = "O sobrenome é obrigatório";

  public PASSWORD_VALIDATION = {
    length: "A senha deve ter pelo menos 8 caracteres",
    numericChar: "A senha deve conter pelo menos um número",
    upperCaseLetter: "A senha deve conter pelo menos uma letra maiúscula",
    lowerCaseLetter: "A senha deve conter pelo menos uma letra minúscula",
    specialCharacter: "A senha deve conter pelo menos um caractere especial",
  };
  // verification page
  public DONE_VERIFICATION =
    "Verificação concluída, por favor faça login para continuar";
  public VERIFYING_USER = "Verificando usuário...";
  public USER_ALREADY_REGISTERED =
    "O usuário já está registrado, por favor faça login";
  public DONE_VERIFICATION_ALERT =
    "Verificação de usuário concluída, por favor faça login para continuar.";

  // Agency details page
  public AGENCY_NAME = "Nome da Agência";
  public TAX_ID = "ID Fiscal (CNPJ)";
  public AGENCY_ADDRESS = "Endereço da Agência";
  public LICENSE_NUMBER = "Número da Licença (OPCIONAL)";
  public AGENCY_DETAILS = "Detalhes da Agência";

  // verify email confirmation
  public PLEASE_CHECK_YOUR_EMAIL = "Por favor, verifique seu e-mail";
  public UNABLE_TO_RESEND_EMAIL = "Não foi possível reenviar o e-mail";
  public VERIFY_EMAIL_GET_STARTED = "Verifique seu e-mail para começar!";
  public SENT_VERIFICATION_EMAIL = "Enviamos um e-mail de verificação para";
  public ENSURE_ACCOUNT_SECURITY_MESSAGE =
    "Para garantir a segurança da sua conta e desbloquear todos os recursos do aplicativo, clique no link do e-mail para confirmar o registro";
  public CHECK_SPAM_FOLDER = "Não encontra? Verifique sua pasta de spam";
  public YOUR_EMAIL = "seu e-mail";
  public USER_NOT_EXIST =
    "Este usuário não existe. Por favor, registre-se primeiro.";
  public INVITATION_SENT = "Convite enviado";

  public NO_SUFFICIENT_PERMISSION = "Você não tem permissão suficiente";
  public INVITE_ALREADY_ACCEPTED = "O convite já foi aceito";
  public INVALID_AUTH_KEY =
    "Por favor, faça login primeiro antes de aceitar o convite";
  public INVITE_ACCEPTED = "Convite aceito";
  public ACCEPTING_INVITE = "Aceitando convite";
  public NO_RECORD_FOUND = "Nenhum registro encontrado";
}

export default TranslationPt;
