import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

import { Box } from "@mui/material";
import { IS_HOSTED_INSURANCE } from "config";
import { useFlags } from "flagsmith/react";
import { RootState } from "store/store";
import { LSKeys, getFromLocalStorage } from "utils/ls.utils";

const ProtectedRoute = ({ children }) => {
  const location = useLocation();
  const {
    v2_feature_set: { enabled: isV2FeatureEnabled },
  } = useFlags(["v2_feature_set"]);

  const { portalAccount, loading } = useSelector(
    (state: RootState) => state.auth
  );

  const IS_USER_AUTH_V2 = !!getFromLocalStorage(LSKeys.ACCESS_TOKEN);
  if (isV2FeatureEnabled && IS_USER_AUTH_V2) {
    return <>{children}</>;
  }

  if (IS_HOSTED_INSURANCE || loading) {
    return <>{children}</>;
  }

  if (portalAccount) {
    if (portalAccount.is_temporary_password) {
      return (
        <Navigate
          to="/create-new-password"
          state={{ from: location }}
          replace
        />
      );
    } else {
      return <Box>{children}</Box>;
    }
  }
  return <Navigate to="/login" state={{ from: location }} replace />;
};

export default ProtectedRoute;
