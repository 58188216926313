import { Box, Typography } from "@mui/material";
import { useT } from "translation";

function NoRecordsFound() {
  const { t } = useT();
  return (
    <Box>
      <Typography variant="body1" textAlign={"center"} p={4}>
        {t("NO_RECORD_FOUND")}
      </Typography>
    </Box>
  );
}

export default NoRecordsFound;
