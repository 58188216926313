import { SVGProps } from "react";
import { useSelector } from "react-redux";
import { customStylesSelector } from "store/selectors/selectors";

const PetSvg = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  const customStyles = useSelector(customStylesSelector);
  return (
    <svg
      width="51"
      height="51"
      viewBox="0 0 51 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M50.2282 39.799L30.4302 5.508C29.4012 3.72559 27.558 2.66156 25.5 2.66156C23.4419 2.66156 21.5987 3.72569 20.5697 5.508L0.771777 39.799C-0.25729 41.5814 -0.25729 43.7096 0.771777 45.492C1.80084 47.2743 3.64392 48.3384 5.70205 48.3384H45.298C47.356 48.3384 49.1992 47.2743 50.2282 45.492C51.2572 43.7096 51.2572 41.5814 50.2282 39.799ZM47.6384 43.9966C47.1498 44.8427 46.2749 45.3478 45.298 45.3478H5.70205C4.72508 45.3478 3.85011 44.8427 3.36163 43.9966C2.87314 43.1506 2.87314 42.1402 3.36163 41.2941L23.1595 7.00314C23.6481 6.15706 24.523 5.65194 25.5 5.65194C26.4769 5.65194 27.3519 6.15706 27.8404 7.00314L47.6384 41.2941C48.1269 42.1402 48.1269 43.1506 47.6384 43.9966Z"
        fill={props.fill ?? customStyles.icons.fill}
      />
      <path
        d="M25.4999 35.3796C24.4007 35.3796 23.5063 36.274 23.5063 37.3733C23.5063 38.4726 24.4007 39.367 25.4999 39.367C26.5992 39.367 27.4936 38.4726 27.4936 37.3733C27.4936 36.274 26.5992 35.3796 25.4999 35.3796Z"
        fill={props.fill ?? customStyles.icons.fill}
      />
      <path
        d="M26.9951 16.938H24.0046V32.389H26.9951V16.938Z"
        fill={props.fill ?? customStyles.icons.fill}
      />
    </svg>
  );
};

export default PetSvg;
