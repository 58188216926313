import { Visibility, VisibilityOff } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from "yup";

import LoginBg from "assets/images/login-img.png";
import StereLogo from "assets/logos/StereLogo";
import AnimatedButton from "components/custom/AnimatedButton";
import { animateRouteProps } from "constants/common";
import { changePassword } from "store/features/authSlice";
import { authSelector, customStylesSelector } from "store/selectors/selectors";
import { useT } from "translation";

const CreateNewPassword = () => {
  const { t } = useT();
  const customStyles = useSelector(customStylesSelector);
  const { portalAccount, errorMessage, passwordCreated } =
    useSelector(authSelector);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const CODE = searchParams.get("code");

  const PasswordCreatedComponent = () => (
    <Box
      sx={{
        gap: "25px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
      {...animateRouteProps}
    >
      <Box
        sx={{
          borderRadius: "50%",
          display: "flex",

          height: "75px",
          width: "75px",
          mt: "40%",
          background: customStyles.stepper.backgroundColor,
        }}
      >
        <CheckIcon
          style={{
            color: "white",
            fontSize: "75px",
          }}
        />
      </Box>

      <Typography
        sx={{
          mb: "10px",
          fontWeight: 600,
          textAlign: "center",
          fontSize: "18px",
          color: customStyles.muiTheme.palette.primary.main,
        }}
      >
        {t("PASSWORD_CREATED")}
      </Typography>
      <Typography
        sx={{
          fontWeight: 400,
          textAlign: "center",
          fontSize: "14px",
          color: customStyles.muiTheme.palette.text.secondary,
        }}
      >
        {t("PASSWORD_CREATED_DESCRIPTION_PRIMARY")}
      </Typography>

      <AnimatedButton
        onClick={() => navigate("/login")}
        sx={{ textTransform: "none", width: "100%" }}
        variant="contained"
      >
        {t("SIGN_IN")}
      </AnimatedButton>
    </Box>
  );

  const CreateNewPasswordComponent = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [passRequirements, setPassRequirements] = useState<string[]>([]);
    const handleTogglePasswordVisibility = () =>
      setShowPassword((show) => !show);
    const handleToggleConfirmPasswordVisibility = () =>
      setShowConfirmPassword((show) => !show);

    const validationSchema = Yup.object().shape({
      password: Yup.string()
        .required(t("PASSWORD_REQUIRED"))
        .test("password", passRequirements?.join(","), (value) => {
          const requirements = [
            /[a-z]/,
            /[A-Z]/,
            /[0-9]/,
            /.*[\W_].*/,
            /.{10,}/,
          ];

          const requirementMessages = [
            t("PASSWORD_REQ.lowerCase"),
            t("PASSWORD_REQ.upperCase"),
            t("PASSWORD_REQ.digit"),
            t("PASSWORD_REQ.specialChar"),
            t("PASSWORD_REQ.minLength"),
          ];

          const errorMessages = requirementMessages
            .map((message, index) =>
              requirements[index].test(value) ? null : message
            )
            .filter((message) => message !== null);
          setPassRequirements(errorMessages);
          return errorMessages.length === 0;
        }),

      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Confirm Password is required"),
    });

    const initialValues = {
      email: portalAccount?.user?.email,
      password: "",
      confirmPassword: "",
    };

    const handleSubmit = async (values) => {
      dispatch(
        changePassword({
          password: values.password,
          code: CODE,
        })
      );
    };

    const PasswordErrorComponent = () => {
      if (!passRequirements?.length) return null;
      return (
        <Box>
          <Typography
            sx={{ fontSize: "14px", color: "red", ml: "5px", mt: "2px" }}
          >
            {t("PASSWORD_REQ.title")}
          </Typography>
          {passRequirements.map((msg, ind) => {
            return (
              <Box
                key={ind}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  ml: "10px",
                }}
              >
                <Box
                  sx={{
                    height: "5px",
                    width: "5px",
                    borderRadius: "50%",
                    backgroundColor: "red",
                  }}
                />
                <Typography sx={{ fontSize: "12px", color: "red" }}>
                  {msg}
                </Typography>
              </Box>
            );
          })}
        </Box>
      );
    };

    return (
      <Box sx={{ mt: "80px" }}>
        <Typography
          sx={{
            mb: "10px",
            fontWeight: 600,
            textAlign: "center",
            fontSize: "18px",
            color: customStyles.muiTheme.palette.primary.main,
          }}
        >
          {t("CREATE_NEW_PASSWORD")}
        </Typography>
        <Typography
          sx={{
            mb: "30px",
            fontWeight: 400,
            textAlign: "center",
            fontSize: "14px",
            color: customStyles.muiTheme.palette.text.secondary,
          }}
        >
          {t("CREATE_NEW_PASSWORD_DESCRIPTION_PRIMARY")}
        </Typography>
        <Typography
          sx={{
            mb: !CODE ? "30px" : "0px",
            fontWeight: 400,
            textAlign: "center",
            fontSize: "14px",
            color: "red",
          }}
        >
          {errorMessage}
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnMount={true}
          onSubmit={handleSubmit}
        >
          {({ isValid }) => (
            <Form
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {!CODE ? (
                <Box>
                  <Field
                    as={TextField}
                    type="email"
                    label={t("EMAIL_LABEL")}
                    name="email"
                    disabled
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "12px",
                      },
                    }}
                  />
                  <ErrorMessage
                    className="error-message"
                    name="email"
                    component="div"
                  />
                </Box>
              ) : null}

              <Box
                sx={{
                  mt: "30px",
                }}
              >
                <Field
                  as={TextField}
                  type={showPassword ? "text" : "password"}
                  label={t("PASSWORD_LABEL")}
                  name="password"
                  style={{ color: "black" }}
                  InputProps={{
                    style: {
                      borderRadius: "12px",
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleTogglePasswordVisibility}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {!passRequirements?.length && (
                  <ErrorMessage
                    className="error-message"
                    name="password"
                    component="div"
                  />
                )}
                {passRequirements?.length ? <PasswordErrorComponent /> : null}
              </Box>
              <Box
                sx={{
                  mt: "30px",
                }}
              >
                <Field
                  as={TextField}
                  type={showConfirmPassword ? "text" : "password"}
                  label={t("CHANGE_PASSWORD_FORM_CONFIRM_PASSWORD")}
                  name="confirmPassword"
                  style={{ color: "black" }}
                  InputProps={{
                    style: {
                      borderRadius: "12px",
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleToggleConfirmPasswordVisibility}
                          edge="end"
                        >
                          {showConfirmPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <ErrorMessage
                  className="error-message"
                  name="confirmPassword"
                  component="div"
                />
              </Box>
              <AnimatedButton
                type="submit"
                sx={{ textTransform: "none", mt: "30px" }}
                variant="contained"
                disabled={!isValid}
              >
                {t("CREATE_PASSWORD")}
              </AnimatedButton>
            </Form>
          )}
        </Formik>
      </Box>
    );
  };

  if (!portalAccount?.is_temporary_password && !CODE) {
    return <Navigate to="/home/dashboard" replace />;
  }

  return (
    <Grid className="loginForm" container>
      <Grid
        item
        md={6}
        sm={7}
        xs={12}
        sx={{
          height: "100vh",
          p: "20px",
          pt: "40px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box>
          <StereLogo />
        </Box>
        {passwordCreated ? (
          <PasswordCreatedComponent />
        ) : (
          <CreateNewPasswordComponent />
        )}
      </Grid>
      <Grid
        item
        md={6}
        sm={5}
        sx={{
          height: "100vh",
          position: "relative",
        }}
      >
        <Box
          style={{
            width: "100%",
            height: "100%",
            overflow: "hidden",
          }}
        >
          <img
            src={LoginBg}
            alt="Login Background"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
          <Box
            style={{
              width: "100%",
              height: "100%",
              overflow: "hidden",
              backgroundColor: customStyles.muiTheme.palette.primary.main,
              position: "absolute",
              top: 0,
              left: 0,
              opacity: 0.4,
            }}
          ></Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default CreateNewPassword;
