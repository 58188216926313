import { Question, Serializer } from "survey-core";
import { SurveyQuestionEmpty } from "survey-react-ui";
import AddRemove from "./AddRemove";

export const ADD_REMOVE_PANEL = "panel-add-remove";

export class AddRemovePanelElement extends Question {
  getType() {
    return ADD_REMOVE_PANEL;
  }

  get panelType() {
    return this.getPropertyValue("panelType");
  }

  set panelType(val) {
    this.setPropertyValue("panelType", val);
  }

  get parentPath() {
    return this.getPropertyValue("parentPath");
  }

  set parentPath(val) {
    this.setPropertyValue("parentPath", val);
  }

  get minPanelCount() {
    return this.getPropertyValue("minPanelCount");
  }

  set minPanelCount(val) {
    this.setPropertyValue("minPanelCount", val);
  }

  get maxPanelCount() {
    return this.getPropertyValue("maxPanelCount");
  }

  set maxPanelCount(val) {
    this.setPropertyValue("maxPanelCount", val);
  }
}

// Add question type metadata for further serialization into JSON
Serializer.addClass(
  ADD_REMOVE_PANEL,
  [
    {
      name: "panelType",
      category: "general",
      visibleIndex: 2,
    },
    {
      name: "parentPath:string",
      dependsOn: "panelType",
      category: "general",
      visibleIndex: 3,
    },
    {
      name: "minPanelCount:number",
      dependsOn: "panelType",
      category: "general",
      visibleIndex: 4,
    },
    {
      name: "maxPanelCount:number",
      dependsOn: "panelType",
      category: "general",
      visibleIndex: 4,
    },
  ],
  function () {
    return new AddRemovePanelElement("");
  },
  "question"
);

// A class that add or remove panel
export class AddOrRemovePanelQuestion extends SurveyQuestionEmpty {
  constructor(props) {
    super(props);
    this.state = { value: this.question.value };
    this.attachDynamicPanelEvent();
    this.setInitialCount();
  }

  get value() {
    return this.question.value || 0;
  }

  get panelType() {
    return this.question.panelType;
  }

  get parentPath() {
    return this.question.parentPath;
  }

  get minPanelCount() {
    return this.question.minPanelCount;
  }

  get maxPanelCount() {
    return this.question.maxPanelCount;
  }

  get surveyModel() {
    return this.props.creator.props.model;
  }

  get currentPanel() {
    if (this.parentPath) {
      let panel = this.surveyModel;
      (this.parentPath || "").split("$$").forEach((key, index) => {
        panel =
          index === 0
            ? panel?.getQuestionByName(key)
            : panel?.getQuestionFromArray(key, 0);
      });
      return panel;
    } else {
      const panel = this.surveyModel?.getQuestionByName(this.panelType);
      return panel;
    }
  }

  setInitialCount = () => {
    this.question.value = this.currentPanel?.panelCount;
  };

  attachDynamicPanelEvent = () => {
    this.surveyModel.onDynamicPanelAdded.add((sm) => {
      this.question.value = this.currentPanel?.panelCount;
    });

    this.surveyModel.onDynamicPanelRemoved.add((sm) => {
      this.question.value = this.currentPanel?.panelCount;
    });
  };

  handleAddPanel = () => {
    if (this.maxPanelCount === undefined || this.value < this.maxPanelCount) {
      this.currentPanel?.addPanel();
    }
  };

  handleRemovePanel = () => {
    if (this.minPanelCount === undefined || this.value > this.minPanelCount) {
      this.currentPanel?.removePanel(this.question.value - 1);
    }
  };

  renderElement() {
    return (
      <AddRemove
        handleAddPanel={this.handleAddPanel}
        handleRemovePanel={this.handleRemovePanel}
        value={this.question.value}
        title={this.question.title}
        minPanelCount={this.minPanelCount}
        maxPanelCount={this.maxPanelCount}
        isRequired={this.question.isRequired}
      />
    );
  }
}
