import { Box, CircularProgress, TextField } from "@mui/material";
import { createQuery } from "api/chatsystem";
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik";
import { useSelector } from "react-redux";
import * as Yup from "yup";

import AnimatedButton from "components/custom/AnimatedButton";
import { customStylesSelector } from "store/selectors/selectors";
import { useT } from "translation";

interface QueryFormProps {
  onSuccess: () => void;
}

const QueryForm: React.FunctionComponent<QueryFormProps> = ({ onSuccess }) => {
  const { t } = useT();
  const customStyles = useSelector(customStylesSelector);
  const initialValues: QueryPayload = {
    message: "",
    portal: "Broker Portal",
    user_email: "",
  };

  const validationSchema = Yup.object().shape({
    message: Yup.string().required(t("CHATBOX.requiredText")),
    user_email: Yup.string().email().required(t("CHATBOX.requiredText")),
  });

  const onSubmit = async (
    values: QueryPayload,
    formikHelpers: FormikHelpers<QueryPayload>
  ) => {
    try {
      formikHelpers?.setSubmitting(true);

      const res = await createQuery(values);
      if (res?.data?.success) {
        formikHelpers?.resetForm();
        onSuccess();
      }
    } catch (error) {
      console.error(error);
    } finally {
      formikHelpers?.setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount={true}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ isValid, isSubmitting }) => (
        <Form
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box sx={{ mb: "20px" }}>
            <Field
              as={TextField}
              type="email"
              label={t("CHATBOX.email")}
              name="user_email"
              style={{
                width: "100%",
                borderRadius: "10px",
                fontSize: "14px",
              }}
              InputProps={{
                style: {
                  fontSize: "14px",
                  borderRadius: "12px",
                },
              }}
            />
            <ErrorMessage
              className="error-message"
              name="user_email"
              component="div"
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Field
              as={TextField}
              type="text"
              label={t("CHATBOX.message")}
              name="message"
              multiline
              rows={4}
              style={{
                width: "100%",
                borderRadius: "10px",
                fontSize: "14px",
              }}
              InputProps={{
                style: {
                  fontSize: "14px",
                  borderRadius: "12px",
                },
              }}
            />
            <ErrorMessage
              className="error-message"
              name="message"
              component="div"
            />
          </Box>
          <AnimatedButton
            type="submit"
            sx={{ textTransform: "none", mt: "30px" }}
            variant="contained"
            disabled={!isValid || isSubmitting}
            endIcon={isSubmitting && <CircularProgress size={14} />}
          >
            {t("CHATBOX.submitQueryButtonText")}
          </AnimatedButton>
        </Form>
      )}
    </Formik>
  );
};

export default QueryForm;
