import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "constants/policyholder-query-keys";
import { AuthService } from "policyholderApi/auth/auth";

export const useUserProfile = () =>
  useQuery({
    queryKey: [QueryKeys.UserProfile.identifier],
    queryFn: () => AuthService.getCurrentUserAPI(),
    staleTime: Infinity,
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
  });
