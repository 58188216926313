import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface GlobalState {
  isGlobalLoading: boolean;
  globalLoaderMessage: string;
}

const initialState: GlobalState = {
  isGlobalLoading: false,
  globalLoaderMessage: "",
};

const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setIsGlobalLoading: (state, action: PayloadAction<boolean>) => {
      state.isGlobalLoading = action.payload;
    },
    setGlobalLoaderMessage: (state, action: PayloadAction<string>) => {
      state.globalLoaderMessage = action.payload;
    },
  },
});

export const { setIsGlobalLoading, setGlobalLoaderMessage } =
  globalSlice.actions;

export default globalSlice.reducer;
