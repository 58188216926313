import { Box, InputLabel, TextField } from "@mui/material";
import { ErrorMessage, Field } from "formik";

const CustomInput = ({
  name,
  label,
  type,
  disabled,
}: {
  name: string;
  label: string;
  type: string;
  disabled?: boolean;
}) => {
  return (
    <Box sx={{ mb: "15px" }}>
      <InputLabel
        sx={{ mb: "10px", fontSize: "14px", fontWeight: 500 }}
        htmlFor={name}
      >
        {label}
      </InputLabel>
      <Field
        as={TextField}
        style={{
          width: "100%",
          borderRadius: "10px",
        }}
        InputProps={{
          style: {
            borderRadius: "12px",
          },
        }}
        type={type}
        name={name}
        disabled={disabled}
      />
      <ErrorMessage className="error-message" name={name} component="div" />
    </Box>
  );
};

export default CustomInput;
