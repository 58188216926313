import { Alert, Snackbar } from "@mui/material";
import { createContext, useCallback, useState } from "react";

type ToastOptions = {
  text: string;
  severity: "success" | "error" | "info" | "warning";
} | null;

type TToastCtx = {
  setToast: (alertOptions: ToastOptions | null) => void;
};

export const ToastCtx = createContext<TToastCtx>(undefined);

function ToastProvider({ children }: { children: React.ReactNode }) {
  const [toastOpts, setToastOpts] = useState<ToastOptions>(null);

  const onClose = useCallback(() => setToastOpts(null), []);
  return (
    <ToastCtx.Provider value={{ setToast: setToastOpts }}>
      <>
        {children}
        <Snackbar
          open={Boolean(toastOpts)}
          autoHideDuration={2000}
          onClose={onClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          {Boolean(toastOpts) ? (
            <Alert onClose={onClose} severity={toastOpts?.severity}>
              {toastOpts.text}
            </Alert>
          ) : null}
        </Snackbar>
      </>
    </ToastCtx.Provider>
  );
}

export default ToastProvider;
