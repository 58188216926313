import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import moment from "moment";
import React from "react";

import TableComponent from "components/common/TableComponent";
import ViewOption from "components/common/ViewOption";
import { useNavigate } from "react-router-dom";
import { useT } from "translation";
import { capitalizeFirstLetter } from "utils/utils";
import { statusMapping } from "./utils";

interface ApplicationsTableProps {
  applicationDataList: ApplicationData[];
  loadingData: boolean;
  hasError: boolean;
}

const ApplicationsTable: React.FC<ApplicationsTableProps> = ({
  applicationDataList,
  loadingData,
  hasError,
}) => {
  const { t } = useT();
  const navigate = useNavigate();

  const getUniqueProducts = (quotes: Quote[]) => {
    const typeSet = new Set(quotes.map((item) => item?.product_details?.type));
    return Array.from(typeSet);
  };

  const headCells: TableComponentHeadCell[] = [
    {
      id: "applicationId",
      label: t("QUOTES_PAGE.tableHeaders.application_id"),
    },
    {
      id: "applicantName",
      label: t("QUOTES_PAGE.tableHeaders.applicant_name"),
    },
    {
      id: "date",
      label: t("QUOTES_PAGE.tableHeaders.date"),
    },
    {
      id: "insurance",
      label: t("QUOTES_PAGE.tableHeaders.insurance"),
    },
    {
      id: "status",
      label: t("QUOTES_PAGE.tableHeaders.status"),
    },
    {
      id: "action",
      label: t("QUOTES_PAGE.tableHeaders.action"),
    },
  ];

  const listoptions = (applicationId: string) => [
    {
      title: "View",
      label: <ViewOption />,
      cb: () => navigate(`/newQuote?applicationId=${applicationId}`),
    },
  ];

  const ApplicationStatus: React.FC<{ applicationData: ApplicationData }> = ({
    applicationData,
  }) => {
    const { t } = useT();
    return (
      <Box
        sx={{
          backgroundColor:
            statusMapping[applicationData.status || ""]?.backgroundColor,
          p: "5px",
          borderRadius: "100px",
          maxWidth: "110px",
        }}
      >
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: 500,
            color: statusMapping[applicationData.status || ""]?.color,
            whiteSpace: "pre-wrap",
            textAlign: "center",
          }}
        >
          {t(statusMapping[applicationData.status || ""]?.label)}
        </Typography>
      </Box>
    );
  };

  const tableRows: TableComponentRow[] = applicationDataList?.map(
    (application) => ({
      id: application.applicationId,
      cells: [
        {
          id: application.applicationId,
          label: `${application.applicationId?.slice(0, 12)}...`,
        },
        {
          id: application.applicantName,
          label: application.applicantName,
        },
        {
          id: application.date,
          label: moment(application.date).format("DD MMM YYYY"),
        },
        {
          id: `${application.applicationId}-insurance`,
          label: (
            <Box>
              {getUniqueProducts(application?.insurance).map((type) => (
                <Typography sx={{ fontSize: "14px" }}>
                  {capitalizeFirstLetter(type)}
                </Typography>
              ))}
            </Box>
          ),
        },
        {
          id: `${application.applicationId}-status`,
          label: <ApplicationStatus applicationData={application} />,
        },
        {
          id: `${application.applicationId}-actions`,
          actions:
            application.status === "incomplete"
              ? listoptions(application.applicationId)
              : [],
          label: "Actions",
          type: "action",
        },
      ],
    })
  );

  return (
    <TableComponent
      headCells={headCells}
      loading={loadingData}
      errorMessage={hasError && t("SOMETHING_WENT_WRONG")}
      noDataMessage={t("NO_DATA_AVAILABLE")}
      rows={tableRows}
    />
  );
};
export default ApplicationsTable;
