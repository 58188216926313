const MeupetLogo = (props) => (
  <svg
    id="Camada_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 292.77 168"
    width={74}
    height={100}
    {...props}
  >
    <path d="m50.25,153.58c-2.75,2.45-6.04,3.23-9.74,2.51-3.61-.73-6.55-3.68-8.61-8.71-1.63-4.63-3.66-12.41-5.91-23.39-2.33-10.96-4.54-22.86-6.7-35.69-.19-1.26-.39-2.52-.67-3.76-1.7-9.22-2.96-18.6-3.77-28.05-.82-9.54.16-18.07,2.79-25.47,2.63-7.4,8.17-12.67,16.64-15.64,9.46-3.11,19.05-4.22,28.91-3.54,9.79.79,18.33,4.26,25.76,10.66,7.33,6.33,13,15.36,15.11,29.02,1.27,10.02.11,19.54-2.01,26.67-2.03,7.12-5.17,13.04-9.33,17.73-4.16,4.69-8.64,8.42-13.49,11.01-3.49,1.83-6.78,3.16-10.04,4.12-3.26.96-6.18,1.69-8.78,2.18-2.59.49-4.62,1.08-5.93,1.56-2.71,1.52-3.96,4.2-3.8,8.22s.74,8.35,1.84,13.15c.45,2.32.89,4.56,1.23,6.81.53,4.61-.68,8.11-3.52,10.58Zm-11.04-105.89c.34,7.99,1.37,24.67,4.29,38.69.76,3.65,3.26,6.06,5.95,5.79,9.68-.94,31.81-5.49,30.79-32.11-1.04-27.07-27.03-28.15-38.12-19.79-1.92,1.45-3.05,4.34-2.91,7.41Z" />
    <path d="m157.02,74.51c5.09,1.82,9.28,4.55,12.46,8.37,3.18,3.73,4.64,8.46,4.37,14.19-.46,4.64-2.73,8.83-6.64,12.55-3.91,3.73-8.46,6.91-13.56,9.73-5.09,2.73-9.64,4.91-13.55,6.64-2.37,1-4.09,1.82-5.37,2.46-1.91,1.27-2.37,3-1.36,5.19,1,2.09,3.27,3.64,6.73,4.46,3.55.82,8.19-.09,13.92-2.82,2.91-1.55,6-2.64,9.55-3.18,3.46-.55,6.19.82,8.28,4.18,2.64,5.64-.55,10.55-9.46,14.74-10.28,4.55-19.65,6.19-27.93,4.91-8.28-1.18-15.1-4.64-20.47-10.46-5.37-5.82-8.82-13.37-10.46-22.65-1.27-7.92-.64-15.56,1.73-22.93,2.46-7.37,6.55-13.56,12.55-18.65,5.91-5,13.55-7.91,22.83-8.73,5.73-.46,11.19.27,16.38,2Zm-25.75,36.57c3.55,0,7.82-1.36,12.83-4,4-2.18,6.73-4.46,8.46-7.01,1.64-2.55,1.18-4.73-1.27-6.46-1.73-1.09-4.09-1.64-7.28-1.64-3.18-.09-6.37.82-9.46,2.73-3.18,1.82-5.46,5.09-6.91,9.73-1.09,4.46.09,6.73,3.64,6.64Z" />
    <path d="m208.71,62.7c.27,2.64,1.55,4.46,3.73,5.46,2.27,1,4.73,1.46,7.37,1.36,4.82-.27,9.19.45,13.01,2.09,3.91,1.64,5.73,4.73,5.46,9.28-.55,3.55-2.37,5.91-5.46,7-3.09,1.09-6.46,1.82-10.28,2.09-3.73.36-7,1-9.73,2-2.73,1.09-4.09,3.27-4.09,6.64,0,2.18.09,4.28,0,6.46-.09,4.18.09,8.01.55,11.74.46,3.64,1.73,6.91,3.91,9.64,2.09,2.73,5.73,4.82,10.64,6.09,3.73.91,6.55,2.18,8.28,3.82,1.82,1.73,2.73,4.46,2.91,8.28-.09,5.64-2.27,9.19-6.37,10.55-4.18,1.46-8.92,1.46-14.28.18-5.28-1.27-9.64-3.18-13.1-5.64-3.55-2.73-6.46-5.82-8.55-9.37-2.09-3.55-3.73-7.46-4.82-11.55-1.09-5.46-1.73-10.92-2-16.47-1-19.83-1.09-39.94-.46-60.22.09-4.09.18-8.1.27-12.19,0-.46,0-1,0-1.55,0-3.46.18-6.91.73-10.19.55-3.28,2-5.82,4.37-7.73,2.37-1.82,6.28-2.27,11.64-1.46,3.55.82,5.73,2.73,6.37,5.82s.82,6.28.46,9.64c0,1.18-.09,2.18-.09,3.18,0,3.55-.09,6.91-.36,10.01-.36,4.64-.36,9.64-.09,15.01Z" />
    <path d="m158.25,46.29c-3.61,3.16-7.94,6.32-12.73,9.56-4.89,3.3-9.42,5.79-13.55,7.76-4.19,1.86-6.99,2.35-8.47,1.46-1.22-1.27-.97-3.8.74-7.59,1.71-3.79,4.23-8.01,7.49-12.68,3.24-4.59,6.54-8.71,9.83-12.39,3.37-3.66,6.04-5.88,7.97-6.85,2.33-.78,4.65-.41,7.07,1.06,2.44,1.38,4.46,3.38,6.03,5.81,1.57,2.43,2.2,4.63,1.78,6.77-.47,1.59-2.46,3.97-6.16,7.11Z" />
    <path d="m259.17,74.08c3.57-1.58,7.03-1.61,10.47-.19,3.43,1.42,5.3,4.27,5.68,8.65.37,21.84-.08,44.13-1.26,66.67-.41,2.9-2.35,4.87-5.74,6.18-3.39,1.22-6.84,1.25-10.28.2-3.35-1.15-5.31-3.73-5.61-7.82-.36-11.38-.44-23.39-.42-36.04.02-12.56.27-22.11.83-28.65.71-4.45,2.85-7.51,6.33-9Z" />
    <ellipse
      cx="265.9"
      cy="50.32"
      rx="13.72"
      ry="12.29"
      transform="translate(199.52 312.67) rotate(-86.52)"
    />
  </svg>
);
export default MeupetLogo;
