import CheckIcon from "@mui/icons-material/Check";
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";

import { CustomStepperProps } from "customTypes/CustomStepperProps";
import { customStylesSelector } from "store/selectors/selectors";
import { useT } from "translation";

const NewStepLabel = ({
  currentStep,
  stepNum,
  stepLabel,
  outerDivStyles,
  labelStyles,
  isSubStep,
}: CustomStepperProps) => {
  const { t } = useT();
  const customStyles = useSelector(customStylesSelector);

  const checkifCurrentStepGreater = () => {
    return (
      currentStep !== void 0 && stepNum !== void 0 && currentStep > stepNum
    );
  };

  const checkifCurrentStepEqual = () => {
    return (
      currentStep !== void 0 && stepNum !== void 0 && currentStep === stepNum
    );
  };

  const outerDiv = () => {
    let obj: any = {};
    if (customStyles.stepper.step.active.borderColor.includes("gradient")) {
      obj.backgroundImage = customStyles.stepper.step.active.borderColor;
    } else {
      obj.background = customStyles.stepper.step.active.borderColor;
    }
    return obj;
  };
  return (
    <Box
      sx={{
        display: "flex",
        gap: "10px",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          height: "40px",
          width: "40px",
          p: "2px",
          borderRadius: "50%",
          boxSizing: "border-box",
          transition: "all 0.3s",
          ...outerDivStyles,
          ...(checkifCurrentStepEqual() && outerDiv()),
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            transition: "all 0.3s",
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: checkifCurrentStepEqual()
              ? customStyles.stepper.backgroundColor
              : customStyles.stepper.step.backgroundColor,
          }}
        >
          {checkifCurrentStepGreater() ? (
            <CheckIcon
              style={{
                color: customStyles.stepper.step.checkIcon.fill,
                width: isSubStep ? "15px" : "",
              }}
            />
          ) : (
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 600,
                color: customStyles.stepper.step.color,
              }}
            >
              {stepNum !== void 0 && !isSubStep
                ? parseInt(stepNum?.toString()) > 9
                  ? `${stepNum + 1}`
                  : `0${stepNum + 1}`
                : ""}
            </Typography>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: checkifCurrentStepEqual() ? 600 : 500,
            color: customStyles.stepper.step.color,
            ...labelStyles,
          }}
        >
          {t(stepLabel)}
        </Typography>
      </Box>
    </Box>
  );
};

export default NewStepLabel;
