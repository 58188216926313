import { Box, Popover } from "@mui/material";
import * as React from "react";

interface PopoverComponentProps {
  title: React.ReactNode;
  children?: React.ReactNode;
  setPopoverStatus?: (value: boolean) => void;
}

const PopoverComponent: React.FC<PopoverComponentProps> = ({
  title,
  children,
  setPopoverStatus,
}) => {
  const [anchor, setAnchor] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchor(anchor ? null : event.currentTarget);
  };

  React.useEffect(() => {
    setPopoverStatus && setPopoverStatus(Boolean(anchor));
  }, [anchor]);

  const handleClose = () => {
    setAnchor(null);
  };

  const open = Boolean(anchor);
  const id = open ? "simple-popup" : undefined;

  return (
    <Box>
      <Box
        aria-describedby={id}
        sx={{ cursor: "pointer" }}
        onClick={handleClick}
      >
        {title}
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchor}
        onClose={handleClose}
        sx={{
          mt: "10px",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {children}
      </Popover>
    </Box>
  );
};

export default PopoverComponent;
