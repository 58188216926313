import { useSelector } from "react-redux";

import { customStylesSelector } from "store/selectors/selectors";

const ErrorSvg = (props) => {
  const customStyles = useSelector(customStylesSelector);

  return (
    <svg
      width="187"
      height="164"
      viewBox="0 0 187 164"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M67.732 131.074C65.2187 136.701 69.7438 142.937 75.8727 142.293L148.555 134.654C154.683 134.01 157.813 126.969 154.185 121.988L111.246 63.0367C107.626 58.0656 99.9836 58.8688 97.4755 64.484L67.732 131.074ZM114.855 119.762C115.12 122.279 113.294 124.534 110.776 124.799C108.259 125.063 106.004 123.237 105.739 120.72C105.474 118.202 107.301 115.947 109.818 115.682C112.336 115.418 114.591 117.244 114.855 119.762ZM112.939 101.529C113.204 104.046 111.377 106.301 108.86 106.566C106.343 106.831 104.087 105.004 103.823 102.487L102.865 93.3704C102.6 90.8529 104.426 88.5976 106.944 88.333C109.461 88.0684 111.716 89.8947 111.981 92.4122L112.939 101.529Z"
        fill={customStyles.icons.fill}
      />
      <g clipPath="url(#clip0_567_1070)">
        <path
          d="M9.67497 64.4582C7.49354 66.6344 8.4884 70.3562 11.4647 71.1537L51.1879 81.7975C54.1642 82.595 56.8867 79.8692 56.0856 76.8938L45.4164 37.2656C44.617 34.2964 40.9058 33.302 38.7289 35.4737L9.67497 64.4582ZM36.3293 67.4634C35.972 68.797 34.6011 69.5885 33.2674 69.2311C31.9338 68.8738 31.1423 67.5029 31.4997 66.1693C31.857 64.8356 33.2279 64.0441 34.5615 64.4015C35.8952 64.7588 36.6867 66.1297 36.3293 67.4634ZM38.9175 57.8041C38.5601 59.1378 37.1893 59.9292 35.8556 59.5719C34.522 59.2145 33.7305 57.8437 34.0879 56.51L35.382 51.6804C35.7393 50.3467 37.1102 49.5552 38.4438 49.9126C39.7775 50.27 40.569 51.6408 40.2116 52.9745L38.9175 57.8041Z"
          fill={customStyles.icons.fill}
        />
      </g>
      <path
        d="M148.436 45.4328C148.38 46.9725 150.013 47.9942 151.373 47.2709L177.622 33.314C178.983 32.5908 179.049 30.6657 177.741 29.8508L152.563 14.1596C151.258 13.3464 149.562 14.2483 149.506 15.7848L148.436 45.4328ZM162.839 33.6237C163.272 34.4364 162.963 35.4456 162.15 35.8777C161.338 36.3099 160.328 36.0013 159.896 35.1886C159.464 34.3759 159.773 33.3667 160.585 32.9346C161.398 32.5024 162.407 32.811 162.839 33.6237ZM159.71 27.7374C160.142 28.5501 159.833 29.5593 159.021 29.9914C158.208 30.4236 157.199 30.115 156.766 29.3023L155.202 26.3591C154.769 25.5464 155.078 24.5372 155.891 24.1051C156.703 23.673 157.713 23.9815 158.145 24.7942L159.71 27.7374Z"
        fill={customStyles.icons.fill}
      />
      <path
        d="M163.97 113V113C170.27 125.602 166.164 140.924 154.407 148.687V148.687"
        stroke="url(#paint0_linear_567_1070)"
        strokeWidth="1.1"
      />
      <path
        d="M174.635 121V121C179.396 130.524 176.293 142.105 167.407 147.973V147.973"
        stroke="url(#paint1_linear_567_1070)"
        strokeWidth="1.1"
      />
      <path
        d="M53.4543 123V123C49.6943 136.578 56.6691 150.825 69.6996 156.183V156.183"
        stroke="url(#paint2_linear_567_1070)"
        strokeWidth="1.1"
      />
      <path
        d="M44.5263 132.901V132.901C41.6844 143.164 46.956 153.931 56.8045 157.981V157.981"
        stroke="url(#paint3_linear_567_1070)"
        strokeWidth="1.1"
      />
      <defs>
        <linearGradient
          id="paint0_linear_567_1070"
          x1="153.141"
          y1="150.087"
          x2="163.486"
          y2="111.479"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9595A1" stopOpacity="0" />
          <stop offset="0.510417" stopColor="#9595A1" />
          <stop offset="1" stopColor="#9595A1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_567_1070"
          x1="166.45"
          y1="149.03"
          x2="174.269"
          y2="119.851"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9595A1" stopOpacity="0" />
          <stop offset="0.510417" stopColor="#9595A1" />
          <stop offset="1" stopColor="#9595A1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_567_1070"
          x1="71.2114"
          y1="157.313"
          x2="53.6368"
          y2="121.415"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9595A1" stopOpacity="0" />
          <stop offset="0.510417" stopColor="#9595A1" />
          <stop offset="1" stopColor="#9595A1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_567_1070"
          x1="57.9471"
          y1="158.835"
          x2="44.6642"
          y2="131.703"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9595A1" stopOpacity="0" />
          <stop offset="0.510417" stopColor="#9595A1" />
          <stop offset="1" stopColor="#9595A1" stopOpacity="0" />
        </linearGradient>
        <clipPath id="clip0_567_1070">
          <rect
            width="60"
            height="60"
            fill="white"
            transform="translate(15.9365 18) rotate(15)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ErrorSvg;
