import { useEffect } from "react";
import { Link } from '@mui/material';
import { Box } from "@mui/material";
import WhatsappLogo from "assets/images/whatsapp-logo.png"
import "./style.css";

// FairfaxSupport component
// Adds Zendesk chat widget and whatsapp chat button to the page
const FairfaxSupport = () => {
    // Add the zendesk chat widget to the page
	useEffect(() => {
		const script = document.createElement("script");
		script.id = "ze-snippet";
		script.src = "https://static.zdassets.com/ekr/snippet.js?key=7b8a57c1-8ac0-4548-b500-80776e7cd30b";
		script.async = true;
		document.body.appendChild(script);

		return () => {
            // The script gets removed when the component unmounts
            // However, the changes made by the script will persist.
            // In this case, the zendesk chat widget will still remain on the page. 
			document.body.removeChild(script);
		};
	}, []);
    
    // Adds a floating button that links to the whatsapp chat 
	return (    
		<Box
			sx={{
				position: "fixed",
				bottom: "40%",
				right: "15px",
				zIndex: 99,
			}}
		>
			<Link href="https://api.whatsapp.com/send/?phone=5511989373922&text&type=phone_number" target="_blank" rel="noopener noreferrer">
				<img src={WhatsappLogo} alt="Whatsapp Logo" />
			</Link>
		</Box>
	);
};

export default FairfaxSupport;
