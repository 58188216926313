import React from "react";

import { ThemeProvider } from "@mui/material";
import { OncePolicyholderTheme, PolicyholderTheme } from "./policyholderTheme";
import { IS_ONCE_PH_PORTAL } from "config";

export function PolicyholderThemeProvider({
  children,
}: React.PropsWithChildren<NonNullable<unknown>>) {
  return <ThemeProvider theme={IS_ONCE_PH_PORTAL ? OncePolicyholderTheme : PolicyholderTheme}>{children}</ThemeProvider>;
}
