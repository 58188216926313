export const STERE_JOURNEY_STARTED = "stere_journey_started";
export const STERE_PRODUCT_SELECTED = "stere_product_selected";
export const STERE_APPLICATION_STARTED = "stere_application_started";
export const STERE_APPLICATION_IN_PROGRESS = "stere_application_in_progress";
export const STERE_APPLICATION_COMPLETED = "stere_application_completed";
export const STERE_QUOTES_MISSING = "stere_quotes_missing";
export const STERE_QUOTES_GENERATED = "stere_quotes_generated";
export const STERE_QUOTES_SELECTED = "stere_quotes_selected";
export const STERE_CHECKOUT_STARTED = "stere_checkout_started";
export const STERE_CHECKOUT_COMPLETED = "stere_checkout_completed";
export const STERE_CHECKOUT_DECLINED = "stere_checkout_declined";
