import { Step } from "components/common/Stepper/types";
import { FAIRFAX, IS_BROKER_PORTAL, PORTAL_NAME } from "config";

export const stepperSteps = (): Step[] => {
  let steps: Step[] = [
    {
      label: "Start",
    },
    {
      label: "CustomerDetails",
    },
    {
      label: "Application",
    },
    {
      label: "Quote",
    },
  ];

  if (!IS_BROKER_PORTAL) {
    steps.push(
      ...([
        {
          label: "Payment",
        },
        {
          label: "Confirmation",
        },
      ] as Step[])
    );
  }

  if (!IS_BROKER_PORTAL) {
    steps = steps.filter(step => step.label !== 'CustomerDetails')
  }

  if (PORTAL_NAME === FAIRFAX) {
    steps = steps.filter(step => step.label !== 'CustomerDetails')  
  }

  return steps;
};
