// import { PORTAL_NAME } from "config";
import { HTMLMotionProps, motion } from "framer-motion";
export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const errorStatusCodes = [500, 501, 502, 503, 504, 505];

export const locales = [
  {
    code: "en",
    label: "English",
  },
  {
    code: "pt",
    label: "Português",
  },
  {
    code: "es",
    label: "Spanish",
  },
];

export enum Clients {
  UNIDIN = "Unidin",
}

const routeVariants = {
  initial: {
    marginLeft: "100vw",
    opacity: 0,
  },
  final: {
    marginLeft: "0vw",
    opacity: 1,
  },
};

const animateRoute: HTMLMotionProps<"div"> = {
  variants: routeVariants,
  initial: "initial",
  animate: "final",
  transition: { type: "tween", delay: 0.1 },
};
export const animateRouteProps = {
  component: motion.div,
  ...animateRoute,
};

export const animateOpacityProps = {
  component: motion.div,
  variants: {
    initial: {
      opacity: 0,
    },
    final: {
      opacity: 1,
    },
  },
  initial: "initial",
  animate: "final",
  transition: { type: "tween" },
};
