import axios, { AxiosResponse } from "axios";
import { CLIENT_NAME, IS_BROKER_PORTAL, PLATFORM_TYPE } from "config";

import { store } from "store/store";
import { createAxiosInstanceWithAccessToken } from "utils/utils";

const BASE_URL = process.env.REACT_APP_SERVER_URL + "/v1";

if (IS_BROKER_PORTAL) {
  axios.defaults.headers["Platform-Type"] = PLATFORM_TYPE;
  axios.defaults.headers["Client-Name"] = CLIENT_NAME;
}

export async function signInAPI(
  payload: SignInPayload
): Promise<AxiosResponse<AxiosCommonResponse<SignInResponse>>> {
  return await axios.post(`${BASE_URL}/portal_broker/auth:sign_in`, payload);
}

export function getRefrehToken(
  payload: RefreshTokenPayload
): Promise<AxiosResponse<AxiosCommonResponse<RefreshTokenResponse>>> {
  return axios.post(`${BASE_URL}/portal_broker/auth:refresh_token`, payload);
}

export async function signOutAPI(): Promise<
  AxiosResponse<AxiosCommonResponse<string>>
> {
  const refreshToken = store.getState().auth.refreshToken;
  return await axios.post(`${BASE_URL}/portal_broker/auth:sign_out`, {
    refresh_token: refreshToken,
  });
}

export async function setCurrentUserAPI(): Promise<
  AxiosResponse<AxiosCommonResponse<PortalAccount>>
> {
  const QuoteAPI = createAxiosInstanceWithAccessToken();
  return await QuoteAPI.get(`/portal_broker/auth:current`);
}

export async function changePasswordAPI(
  payload: ChangePasswordPayload
): Promise<AxiosResponse<AxiosCommonResponse<string>>> {
  const QuoteAPI = createAxiosInstanceWithAccessToken();
  if (payload.code) {
    return await axios.post(
      `${BASE_URL}/portal_broker/auth:change_password`,
      payload
    );
  } else {
    return await QuoteAPI.post(`/portal_broker/auth:change_password`, payload);
  }
}

export async function resetPasswordAPI(
  payload: ResetPasswordPayload
): Promise<AxiosResponse<AxiosCommonResponse<string>>> {
  return await axios.post(
    `${BASE_URL}/portal_broker/auth:reset_password`,
    payload
  );
}
