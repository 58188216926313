import { Typography } from "@mui/material";
import { Fragment } from "react";

import { useT } from "translation";

export function VerificationEmailSentPage() {
  const { t } = useT();

  return (
    <Fragment>
      <Typography variant="h6" marginBottom={1}>
        {t("VERIFY_EMAIL")}
      </Typography>

      <Typography color="textSecondary" variant="subtitle2" marginBottom={4}>
        {t("AVOID_LOCKED_OUT")}
      </Typography>

      <Typography variant="subtitle1" marginBottom={4}>
        {t("SENT_PASS_RECOVERY_INSTRUCTIONS")}
      </Typography>
    </Fragment>
  );
}
