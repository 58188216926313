import { Box, Grid, TextField, Typography } from "@mui/material";
import LoginBg from "assets/images/login-img.png";
import StereLogo from "assets/logos/StereLogo";
import AnimatedButton from "components/custom/AnimatedButton";
import { animateRouteProps } from "constants/common";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword } from "store/features/authSlice";
import { authSelector, customStylesSelector } from "store/selectors/selectors";
import { useT } from "translation";
import * as Yup from "yup";

type ForgotPasswordProps = {
  setShowForgotPassword: (val: boolean) => void;
};
function ForgotPassword({ setShowForgotPassword }: ForgotPasswordProps) {
  const { t } = useT();
  const customStyles = useSelector(customStylesSelector);
  const { forgotPasswordEmailSent } = useSelector(authSelector);
  const dispatch = useDispatch();

  const forgotPasswordValidationSchema = Yup.object().shape({
    email: Yup.string().email(t("INVALID_EMAIL")).required(t("EMAIL_REQUIRED")),
  });

  const forgotPasswordInitialValues = {
    email: "",
  };

  const handleForgotPasswordSubmit = (values) => {
    dispatch(resetPassword({ email: values.email }));
  };

  const EmailSentComponent = () => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        mt: "40%",
      }}
    >
      <Typography
        sx={{
          fontWeight: 600,
          textAlign: "center",
          fontSize: "18px",
          color: customStyles.muiTheme.palette.primary.main,
        }}
      >
        Verify E-Mail
      </Typography>
      <Typography
        sx={{
          mb: "25px",
          fontWeight: 500,
          textAlign: "center",
          fontSize: "12px",
          color: customStyles.muiTheme.palette.text.tertiary,
        }}
      >
        Avoid getting locked out.
      </Typography>
      <Typography
        sx={{
          fontWeight: 400,
          textAlign: "center",
          fontSize: "14px",
          color: customStyles.muiTheme.palette.text.secondary,
        }}
      >
        We have sent a password recovery instructions to your email.
      </Typography>
    </Box>
  );

  const ForgotPasswordComponent = () => (
    <Box sx={{ mt: "80px" }}>
      <Typography
        sx={{
          mb: "10px",
          fontWeight: 600,
          textAlign: "center",
          fontSize: "18px",
          color: customStyles.muiTheme.palette.primary.main,
        }}
      >
        {t("FORGOT_PASSWORD")}
      </Typography>
      <Typography
        sx={{
          mb: "30px",
          fontWeight: 400,
          textAlign: "center",
          fontSize: "14px",
          color: customStyles.muiTheme.palette.text.secondary,
        }}
      >
        {t("FORGOT_PASSWORD_MESSAGE")}
      </Typography>
      <Formik
        initialValues={forgotPasswordInitialValues}
        validationSchema={forgotPasswordValidationSchema}
        validateOnMount={true}
        onSubmit={handleForgotPasswordSubmit}
      >
        {({ isValid }) => (
          <Form
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box>
              <Field
                as={TextField}
                type="email"
                label={t("EMAIL_LABEL")}
                name="email"
                style={{
                  width: "100%",
                  borderRadius: "10px",
                }}
                InputProps={{
                  style: {
                    borderRadius: "12px",
                  },
                }}
              />
              <ErrorMessage
                className="error-message"
                name="email"
                component="div"
              />
            </Box>
            <AnimatedButton
              type="submit"
              sx={{ textTransform: "none", mt: "30px" }}
              disabled={!isValid}
              variant="contained"
            >
              {t("RECOVER_PASSWORD")}
            </AnimatedButton>
            <AnimatedButton
              onClick={() => setShowForgotPassword(false)}
              sx={{ textTransform: "none", mt: "20px" }}
            >
              {t("BACK")}
            </AnimatedButton>
          </Form>
        )}
      </Formik>
    </Box>
  );

  return (
    <Box {...animateRouteProps}>
      <Grid className="loginForm" container>
        <Grid
          item
          md={6}
          sm={7}
          xs={12}
          sx={{
            height: "100vh",
            p: "20px",
            pt: "40px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box>
            <StereLogo />
          </Box>
          {forgotPasswordEmailSent ? (
            <EmailSentComponent />
          ) : (
            <ForgotPasswordComponent />
          )}
        </Grid>
        <Grid
          item
          md={6}
          sm={5}
          sx={{
            height: "100vh",
            position: "relative",
          }}
        >
          <Box
            style={{
              width: "100%",
              height: "100%",
              overflow: "hidden",
            }}
          >
            <img
              src={LoginBg}
              alt="Login Background"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
            <Box
              style={{
                width: "100%",
                height: "100%",
                overflow: "hidden",
                backgroundColor: customStyles.muiTheme.palette.primary.main,
                position: "absolute",
                top: 0,
                left: 0,
                opacity: 0.4,
              }}
            ></Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ForgotPassword;
