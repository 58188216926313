import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const loaderSlice = createSlice({
  name: "loader",
  initialState: {
    isLoading: true,
    initialLoader: true,
    loaderText: null,
  },
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    finishLoading: (state) => {
      state.isLoading = false;
    },
    updateLoadingState: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    updateInitialLoader: (state, action: PayloadAction<boolean>) => {
      state.initialLoader = action.payload;
    },
    setLoaderText: (state, action: PayloadAction<string>) => {
      state.loaderText = action.payload;
    },
  },
});

export const {
  startLoading,
  finishLoading,
  updateLoadingState,
  updateInitialLoader,
  setLoaderText,
} = loaderSlice.actions;
export default loaderSlice.reducer;
