import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { WebhooksRequest } from "customTypes/Webhooks";

const webhooksSlice = createSlice({
  name: "webhooks",
  initialState: {},
  reducers: {
    fireWebhookEvent: (state, action: PayloadAction<WebhooksRequest>) => {},
  },
});

export const { fireWebhookEvent } = webhooksSlice.actions;
export default webhooksSlice.reducer;
