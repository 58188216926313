import { AxiosResponse } from "axios";

import { createAxiosInstanceWithAccessToken } from "utils/utils";

const BASE_URL = process.env.REACT_APP_SERVER_URL + "/v1";

export async function getDashboardAnalytics(
  filterProps: FilterParams
): Promise<AxiosResponse<AxiosCommonResponse<DashboardAnalytics>>> {
  const QuoteAPI = createAxiosInstanceWithAccessToken();
  return await QuoteAPI.post(
    `${BASE_URL}/portal_broker/dashboard`,
    filterProps
  );
}
