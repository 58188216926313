import {
  API_BASE_URL,
  createAxiosInstanceWithoutAccessToken,
} from "utils/axios";
import { createAxiosInstanceWithAccessToken } from "utils/axios";
import { PoliciesResponse, PolicyResponse } from "./types";
import jwtDecode, { JwtPayload } from "jwt-decode";
import { getAccessToken } from "utils/token";
import { IS_ONCE_PH_PORTAL } from "config";

function getPolicies({ page = 1 }: { page?: number } = {}) {
  const authenticatedAPI = createAxiosInstanceWithAccessToken();
  const unauthenticatedAPI = createAxiosInstanceWithoutAccessToken();
  const jwtToken: JwtPayload = jwtDecode(getAccessToken());
  const encodedEmail = encodeURIComponent(jwtToken.sub);
  // TODO: We are intentionally not sending the access_token to reduce the amount of changes in backend.
  // In a later release, find a better way to manage this.
  const API = IS_ONCE_PH_PORTAL ? unauthenticatedAPI : authenticatedAPI;
  return API.get<PoliciesResponse>(
    `${API_BASE_URL}/portal_policy_holder/policies?limit=5&offset=${
      (page - 1) * 5
    }${IS_ONCE_PH_PORTAL ? `&email=${encodedEmail}` : ""}`
  );
}

function getPolicy(policyId: string) {
  const API = createAxiosInstanceWithAccessToken();

  return API.get<PolicyResponse>(
    `${API_BASE_URL}/portal_policy_holder/policies/${policyId}`
  );
}

function policyAction(policyId: string, action: "cancel" | "renewal") {
  const API = createAxiosInstanceWithAccessToken();

  return API.post(
    `${API_BASE_URL}/portal_policy_holder/policies/${policyId}/:${action}`
  );
}

export const PolicyService = {
  getPolicies,
  getPolicy,
  policyAction,
};
