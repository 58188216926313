import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, IconButton, InputAdornment, TextField } from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";

import CustomButton from "components/common/CustomButton/CustomButton";
import { changePassword } from "store/features/authSlice";
import { useT } from "translation";

const initialValues = {
  newPassword: "",
  confirmPassword: "",
};

const ChangePassword = () => {
  const { t } = useT();
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    newPassword: Yup.string()
      .required(t("CHANGE_PASSWORD_NEW_PASSWORD_REQUIRED"))
      .min(8, t("INVALID_PASSWORD")),
    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref("newPassword"), null],
        t("CHANGE_PASSWORD_CONFIRM_PASSWORD_INVALID")
      )
      .required(t("CHANGE_PASSWORD_CONFIRM_PASSWORD_REQUIRED")),
  });

  const handleToggleNewPasswordVisibility = () =>
    setShowNewPassword((show) => !show);
  const handleToggleConfirmPasswordVisibility = () =>
    setShowConfirmPassword((show) => !show);

  const handleSubmit = (values) => {
    dispatch(
      changePassword({
        password: values.newPassword,
      })
    );
  };

  return (
    <Box sx={{ maxWidth: "500px" }}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnMount={true}
        onSubmit={handleSubmit}
      >
        {({ isValid }) => (
          <Form>
            <Box
              sx={{
                mt: "30px",
              }}
            >
              <Field
                as={TextField}
                type={showNewPassword ? "text" : "password"}
                label={t("CHANGE_PASSWORD_FORM_NEW_PASSWORD")}
                name="newPassword"
                style={{ color: "black", width: "100%" }}
                InputProps={{
                  style: {
                    borderRadius: "12px",
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleToggleNewPasswordVisibility}
                        edge="end"
                      >
                        {showNewPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <ErrorMessage
                className="error-message"
                name="newPassword"
                component="div"
              />
            </Box>

            <Box
              sx={{
                mt: "30px",
              }}
            >
              <Field
                as={TextField}
                type={showConfirmPassword ? "text" : "password"}
                label={t("CHANGE_PASSWORD_FORM_CONFIRM_PASSWORD")}
                name="confirmPassword"
                style={{ color: "black", width: "100%" }}
                InputProps={{
                  style: {
                    borderRadius: "12px",
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleToggleConfirmPasswordVisibility}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <ErrorMessage
                className="error-message"
                name="confirmPassword"
                component="div"
              />
            </Box>

            <CustomButton
              btnText={t("CHANGE_PASSWORD_SUBMIT_BUTTON")}
              disabled={!isValid}
              type="submit"
              sx={{ ml: "0px", mt: "30px" }}
            />
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default ChangePassword;
