import { SVGProps } from 'react';

export function PetSvg(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props?.height}
      height={props?.width}
      {...props}>
      <path
        fill={props?.fill}
        d="M15.012 13.777a.503.503 0 1 0 0 1.006.503.503 0 0 0 0-1.006Z"
      />
      <path
        fill={props?.fill}
        d="M19.502 16.486c-.472-.472-1.616-1.748-2.518-3.817a4.295 4.295 0 0 0-3.976-2.65 4.295 4.295 0 0 0-3.976 2.65C7.9 15.267 6.354 16.64 6.34 16.654l-.005.005a4.314 4.314 0 0 0-1.436 3.21c0 1.495.713 2.796 1.948 3.62a4.773 4.773 0 0 0 3.913.631A8.625 8.625 0 0 1 13 23.84c.81 0 1.597.1 2.275.29a4.742 4.742 0 0 0 3.895-.613c1.25-.825 1.974-2.138 1.974-3.648a4.28 4.28 0 0 0-1.642-3.382Zm-.887 6.19c-.907.6-2.025.776-3.07.484A9.597 9.597 0 0 0 13 22.833a9.64 9.64 0 0 0-2.507.316 3.767 3.767 0 0 1-3.089-.499c-.948-.632-1.5-1.621-1.5-2.782 0-.937.4-1.833 1.099-2.459.107-.094 1.74-1.555 2.953-4.341l.004-.008a3.292 3.292 0 0 1 3.048-2.034c1.338 0 2.535.798 3.048 2.034l.004.008c.985 2.261 2.254 3.657 2.754 4.153.014.014.03.027.045.04a3.281 3.281 0 0 1 1.278 2.607c0 1.17-.56 2.173-1.522 2.809Z"
      />
      <path
        fill={props?.fill}
        d="M16.383 15.78a.503.503 0 1 0-.843.549 14.38 14.38 0 0 0 1.856 2.32.503.503 0 0 0 .709-.714 13.341 13.341 0 0 1-1.722-2.155ZM11.37 7.567c.483-.845.684-1.968.552-3.08C11.667 2.325 10.174.512 8.507.712c-.753.09-1.422.584-1.885 1.392-.483.844-.685 1.967-.553 3.079.417 3.534 3.707 5.168 5.3 2.385ZM7.494 2.603c.275-.481.705-.9 1.259-.9.99 0 1.984 1.33 2.17 2.903.106.902-.05 1.8-.428 2.46-1.16 2.027-3.154.319-3.428-2.002-.107-.903.049-1.8.427-2.461ZM6.55 12.978c.146-.897-.031-1.889-.498-2.793-.467-.904-1.174-1.622-1.99-2.022-.866-.425-1.753-.447-2.498-.062C.82 8.486.324 9.222.17 10.174c-.146.897.031 1.889.498 2.793.467.904 1.174 1.622 1.99 2.022.81.397 1.706.471 2.498.062.744-.385 1.24-1.12 1.394-2.073Zm-1.856 1.18c-.455.234-1.02.209-1.593-.072-.624-.306-1.17-.868-1.539-1.581-.369-.714-.51-1.484-.399-2.17.102-.63.409-1.105.863-1.34.503-.26 1.085-.178 1.593.071.624.306 1.17.868 1.539 1.581.368.714.51 1.484.399 2.17-.102.63-.409 1.105-.863 1.34ZM16.773 8.983c1.525 0 2.917-1.666 3.17-3.792.267-2.264-.874-4.484-2.686-4.484-1.526 0-2.918 1.665-3.17 3.79-.268 2.27.877 4.486 2.686 4.486Zm-1.686-4.367c.186-1.574 1.18-2.903 2.17-2.903.046 0 .087.002.123.007.536.06.951.528 1.196 1.007.333.65.466 1.506.367 2.346-.193 1.63-1.274 3.029-2.293 2.896a.17.17 0 0 1-.005 0c-.458-.054-.892-.42-1.19-1.007-.334-.65-.467-1.506-.368-2.346ZM25.83 10.176c-.318-1.96-2.09-2.896-3.892-2.011-1.687.827-2.79 2.96-2.488 4.815.322 1.983 2.11 2.885 3.893 2.011 1.687-.828 2.789-2.96 2.487-4.815Zm-2.93 3.912c-.574.28-1.14.306-1.594.071-.454-.235-.76-.71-.863-1.34-.23-1.417.646-3.117 1.938-3.75 1.184-.582 2.244-.035 2.456 1.268.23 1.414-.643 3.116-1.938 3.75Z"
      />
    </svg>
  );
}
