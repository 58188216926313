import { Box } from "@mui/material";
import { Form, Formik } from "formik";
import { useSelector } from "react-redux";
import * as Yup from "yup";

import CustomButton from "components/common/CustomButton/CustomButton";
import CustomInput from "components/common/CustomInput/CustomInput";
import { authSelector } from "store/selectors/selectors";
import { useT } from "translation";

const Personal = () => {
  const { t } = useT();
  const { portalAccount } = useSelector(authSelector);

  const initialValues = {
    fullName: `${portalAccount?.user?.first_name} ${portalAccount?.user?.last_name}`,
    cpf: "",
    email: portalAccount?.user?.email,
    phone: portalAccount?.user?.phone_number,
    // language: "",
  };

  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required(t("PERSONAL_FORM_FULL_NAME_REQUIRED")),
    phone: Yup.date().required(t("PERSONAL_FORM_PHONE_REQUIRED")),
    // language: Yup.string().required("Language is required"),
  });

  const handleSubmit = (values: typeof initialValues) => {
    // Handle form submission here
    console.log(values);
  };

  return (
    <Box sx={{ maxWidth: "500px" }}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnMount={true}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <CustomInput
              name={"fullName"}
              label={t("PERSONAL_FORM_FULL_NAME")}
              type={"text"}
              disabled
            />
            <CustomInput
              name={"cpf"}
              label={t("PERSONAL_FORM_CPF")}
              type={"text"}
              disabled
            />
            <CustomInput
              name={"email"}
              label={t("PERSONAL_FORM_EMAIL")}
              type={"text"}
              disabled
            />
            <CustomInput
              name={"phone"}
              label={t("PERSONAL_FORM_PHONE")}
              type={"text"}
              disabled
            />
            {/* <CustomInput name={"language"} label={"Language"} type={"text"} /> */}

            <CustomButton
              btnText={t("SAVE")}
              disabled
              type="submit"
              sx={{ ml: "0px", mt: "10px" }}
            />
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default Personal;
