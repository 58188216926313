/* eslint-disable jsx-a11y/control-has-associated-label */
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router";

import { DocumentSvg } from "assets/DocumentSvg";
import { PolicyholderPathnames } from "constants/policyholderPathnames";
import { downloadFile, getFileNameFromURL } from "utils/file";
import { capitalizeFirstLetter } from "utils/utils";

type DocumentListItemProps = {
  id: string;
  document: StereDocument;
};

export function DocumentListItem({
  id,
  document,
}: React.PropsWithChildren<DocumentListItemProps>) {
  const navigate = useNavigate();
  const { policyId } = useParams();

  const searchParams = new URLSearchParams();
  searchParams.append("fileURL", document?.download_url);

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Box sx={{ display: "flex", gap: "12px", alignItems: "center" }}>
        <Box sx={{ display: "flex", alignItems: "center", p: "12px" }}>
          <DocumentSvg fill="primary.main" />
        </Box>
        <Typography variant="subtitle1">
          {capitalizeFirstLetter(document?.for_type)}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", gap: "12px", alignItems: "center" }}>
        <Box sx={{ display: "flex", alignItems: "center", p: "12px" }}>
          <IconButton
            onClick={() =>
              navigate({
                pathname: PolicyholderPathnames.MyPolicyDocument(
                  policyId as string,
                  id
                ),
                search: searchParams.toString(),
              })
            }
          >
            <VisibilityOutlinedIcon color="secondary" fontSize="small" />
          </IconButton>
        </Box>
        <Box>
          <IconButton
            onClick={() =>
              downloadFile(
                document?.download_url,
                getFileNameFromURL(document?.file_name)
              )
            }
          >
            <SaveAltIcon color="secondary" fontSize="small" />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
}
