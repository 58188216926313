import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";

import { animateRouteProps } from "constants/common";
import { customStylesSelector } from "store/selectors/selectors";
import { useT } from "translation";
import SettingsTabPanel from "./settings-tab-panel/SettingsTabPanel";

const Settings = () => {
  const { t } = useT();
  const customStyles = useSelector(customStylesSelector);
  return (
    <Box {...animateRouteProps}>
      <Box
        sx={{
          backgroundColor: "white",
          borderRadius: "12px",
          p: "20px",
        }}
      >
        <Typography
          sx={{
            color: customStyles.muiTheme.palette.text.primary,
            fontSize: "24px",
            fontWeight: 600,
            mb: "16px",
          }}
        >
          {t("SETTINGS")}
        </Typography>
        <Typography
          sx={{
            color: customStyles.muiTheme.palette.text.secondary,
            fontSize: "16px",
            fontWeight: 500,
            mb: "5px",
          }}
        >
          {t("SETTINGS_PAGE_DESCRIPTION_PRIMARY")}
        </Typography>
        <Box sx={{ mt: "50px" }}>
          <SettingsTabPanel />
        </Box>
      </Box>
    </Box>
  );
};

export default Settings;
