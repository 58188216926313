import { Middleware } from "redux";
import { updateLoadingState } from "store/features/loaderSlice";
import { RootState } from "store/store";

const createLoadingMiddleware = (): Middleware<{}, RootState> => {
  return (store) => (next) => (action) => {
    const { getState } = store;

    // Get the current state before the action is applied
    const prevState = getState();

    // Dispatch the action
    const result = next(action);

    // Get the state after the action is applied
    const nextState = getState();

    // Check the loading state of each slice and dispatch the corresponding action in the loaderSlice
    const prevLoadingStates = Object.entries(prevState).map(
      ([sliceName, sliceValues]) => {
        if (sliceName !== "loader") return sliceValues.loading;
        return false;
      }
    );

    const nextLoadingStates = Object.entries(nextState).map(
      ([sliceName, sliceValues]) => {
        if (sliceName !== "loader") return sliceValues.loading;
        return false;
      }
    );

    const prevLoadingVal = prevLoadingStates.some((loading) => loading);
    const nextLoadingVal = nextLoadingStates.some((loading) => loading);

    if (prevLoadingVal !== nextLoadingVal) {
      store.dispatch(updateLoadingState(nextLoadingVal));
    }

    return result;
  };
};

export default createLoadingMiddleware;
