import { Box, Card, Typography } from "@mui/material";
import AnimatedButton from "components/custom/AnimatedButton";
import moment from "moment";
import { useT } from "translation";
type Props = {
  description: string;
  timeStamp: Date;
};

function NotificationCard({ description, timeStamp }: Props) {
  const { t } = useT();
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        borderRadius: "12px",
        p: "16px",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "4px",
          marginBottom: "8px",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: "primary.main",
          }}
        >
          {description}
        </Typography>
        <Typography variant="body1">
          {moment(timeStamp).format("DD MMM YYYY")}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", gap: "12px" }}>
        <AnimatedButton variant="outlined">
          {t("NOTIFICATION_PAGE.decline")}
        </AnimatedButton>
        <AnimatedButton variant="contained">
          {t("NOTIFICATION_PAGE.accept")}
        </AnimatedButton>
      </Box>
    </Card>
  );
}

export default NotificationCard;
