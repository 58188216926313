export const PolicyholderPathnames = {
  Auth: "/auth",
  LoginPage: "/auth/login",
  ForgotPassword: "/auth/forget-password",
  VerificationEmailSent: "/auth/verification-email-sent",
  VerifyOtp: "/auth/v2-verify-otp",
  V2Login: "/auth/v2-login",

  App: "/app",

  MyPolicies: "/app/my-policies",
  MyPolicy: (policyId: string) => `/app/my-policies/${policyId}/`,
  MyPolicyDocument: (policyId: string, documentId: string) =>
    `/app/my-policies/${policyId}/documents/${documentId}`,
};
