import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Typography,
} from "@mui/material";
import { useT } from "translation";
import { regionalText } from "utils/utils";

interface CoveragesProps {
  selectedQuote?: Quote;
  showHeading?: boolean;
}

/**
 * TODO: The country needs to dynamic
 */
const COUNTRY = "br";

export function Coverages({ selectedQuote, showHeading }: CoveragesProps) {
  const { t } = useT();

  const Details = selectedQuote?.coverage?.sections?.map((section) => (
    <Accordion
      defaultExpanded
      key={section?.title?.default}
      elevation={0}
      sx={{ borderRadius: 3 }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box
          sx={{
            display: "flex",
            gap: "5px",
            flexDirection: "column",
          }}
        >
          <Typography variant="h5">{regionalText(section, "title")}</Typography>
          {section?.description?.default && (
            <Typography variant="body2" color="textSecondary">
              {regionalText(section, "description")}
            </Typography>
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        {section.children.map((ch, index) => (
          <Box
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            sx={{
              mt: "20px",
              ml: "10px",
              display: "flex",
              gap: "5px",
              flexDirection: "column",
            }}
          >
            <Typography variant="h5">{regionalText(ch, "label")}</Typography>
            <Divider />
            <Typography variant="body2" color="textSecondary">
              {regionalText(ch, "details")}
            </Typography>
            <Box sx={{ display: "flex", gap: "5px" }}>
              {ch?.limit?.default && (
                <Typography variant="body2" color="textSecondary">
                  {COUNTRY === "br" ? "Limite:" : "Limit:"}
                </Typography>
              )}
              <Typography variant="body2" color="textSecondary">
                {regionalText(ch, "limit")}
              </Typography>
            </Box>
          </Box>
        ))}
      </AccordionDetails>
    </Accordion>
  ));

  const AccordionSummaryTitle = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "5px",
      }}
    >
      <Typography variant="h5">{t("QUOTE_PAGE_COVERAGES")}</Typography>
      {!selectedQuote && (
        <Typography variant="subtitle1">
          {t("QUOTE_PAGE_SELECT_ONE_PLAN")}
        </Typography>
      )}
    </Box>
  );

  if (!showHeading) {
    return (
      <>
        <Typography variant="h4" sx={{ mb: 3 }}>
          {t("COVERAGE_DETAILS")}
        </Typography>

        {Details}
      </>
    );
  }

  return (
    <Box>
      <Typography variant="h4"> {t("COVERAGE_DETAILS")}</Typography>
      <Accordion
        defaultExpanded
        disabled={!selectedQuote}
        sx={{ borderRadius: 3 }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          {AccordionSummaryTitle}
        </AccordionSummary>
        <AccordionDetails sx={{ gap: "30px" }}>{Details}</AccordionDetails>
      </Accordion>
    </Box>
  );
}
