import { Box, Card, CardContent, Typography } from "@mui/material";
import BikeSvg from "assets/images/BikeSvg";
import GroupLifeSvg from "assets/images/GroupLife";
import { PetiSvg } from "assets/insurers/PetiSvg";
import { FAIRFAX, LIBERSEGUROS, PORTAL_NAME } from "config";

function AppointmentsList() {
  const getInsuredLogo = () => {
    if (PORTAL_NAME === FAIRFAX) return <GroupLifeSvg />;
    if (PORTAL_NAME === LIBERSEGUROS) return <BikeSvg />;
    return <PetiSvg />;
  };

  const getInsurerName = () => {
    if (PORTAL_NAME === FAIRFAX) return "Fairfax";
    if (PORTAL_NAME === LIBERSEGUROS) return "Liberseguros";
    return "Meu";
  };
  return (
    <Card
      sx={{
        width: "fit-content",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
      }}
    >
      <CardContent sx={{ padding: "24px" }}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          width={"fit-content"}
          gap={"8px"}
        >
          <Box>{getInsuredLogo()}</Box>
          <Typography fontSize={"16px"}>{getInsurerName()}</Typography>
        </Box>
      </CardContent>
    </Card>
  );
}

export default AppointmentsList;
