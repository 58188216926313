import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Box, Typography } from "@mui/material";
import { AddRemovePanelProps } from "customTypes/AddRemovePanelProps";
import { useSelector } from "react-redux";
import { customStylesSelector } from "store/selectors/selectors";

const AddRemove = ({
  title,
  value,
  minPanelCount,
  maxPanelCount,
  isRequired,
  handleAddPanel,
  handleRemovePanel,
}: AddRemovePanelProps) => {
  const minVal = () => {
    if (isRequired) return 2;
    return 1;
  };
  const customStyles = useSelector(customStylesSelector);
  const hideAddRemovePanel = () => {
    if (Boolean(maxPanelCount) && Boolean(minPanelCount)) {
      return maxPanelCount === 1 && minPanelCount === 1;
    }
    return false;
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
      className="add-remove-panel-wrapper"
    >
      <Typography
        sx={{
          fontWeight: "500",
          fontSize: "20px",
          color: customStyles.muiTheme.palette.text.primary,
        }}
      >
        {title}
      </Typography>
      <Box
        sx={{
          display: hideAddRemovePanel() ? "none" : "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex" }} onClick={handleRemovePanel}>
          <RemoveIcon
            fontSize="small"
            sx={{
              marginRight: "18px",
              display: value < minVal() ? "none" : "block",
              color: customStyles.icons.fill,
              cursor:
                (minPanelCount === undefined && value !== 0) ||
                value > minPanelCount
                  ? "pointer"
                  : "default",
            }}
          />
        </div>
        <Typography
          sx={{
            fontWeight: "600",
            fontSize: "14px",
            color: customStyles.muiTheme.palette.text.primary,
          }}
        >
          {value || 0}
        </Typography>
        <div style={{ display: "flex" }} onClick={handleAddPanel}>
          <AddIcon
            fontSize="small"
            sx={{
              marginLeft: "18px",
              color: customStyles.icons.fill,
              cursor:
                maxPanelCount === undefined || value < maxPanelCount
                  ? "pointer"
                  : "default",
            }}
          />
        </div>
      </Box>
    </Box>
  );
};

export default AddRemove;
