import {
  ArticleOutlined,
  BackupTableOutlined,
  DashboardOutlined,
  GppMaybeOutlined,
  ReportGmailerrorredOutlined,
  RequestQuoteOutlined,
  SupportAgent,
} from "@mui/icons-material";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { Link, useLocation } from "react-router-dom";

import StereLogo from "assets/logos/StereLogo";
import { useFlags } from "flagsmith/react";
import { IFlagsmithFeature } from "flagsmith/types";
import { motion } from "framer-motion";
import { portalFontFamilyNameSelector } from "portalConfig/selectors";
import { useSelector } from "react-redux";
import { useT } from "translation";
import { TranslationId } from "translation/types";

const sidebarLinks = [
  {
    title: "DASHBOARD",
    link: "/home/dashboard",
    icon: DashboardOutlined,
    disabled: false,
  },
  {
    title: "APPLICATIONS",
    link: "/home/applications",
    icon: ArticleOutlined,
    disabled: false,
  },
  {
    title: "QUOTES",
    link: "/home/quotes",
    icon: RequestQuoteOutlined,
    disabled: false,
  },
  {
    title: "POLICIES",
    link: "/home/policies",
    icon: GppMaybeOutlined,
    disabled: false,
  },
  {
    title: "Agents",
    link: "/home/v2/agents",
    icon: SupportAgent,
    feature_flag: "v2_agent_management",
  },
  {
    title: "CLAIMS",
    link: "/home/claims",
    icon: ReportGmailerrorredOutlined,
    disabled: true,
  },
  {
    title: "MY_APPOINTMENTS",
    link: "/home/appointments",
    icon: BackupTableOutlined,
    disabled: false,
  },
];

const SidebarLink = ({
  link,
  title,
  Icon,
  isCurrentTab,
  isDisabled,
}: {
  link: string;
  title: string;
  Icon: any;
  isCurrentTab: boolean;
  isDisabled: boolean;
}) => {
  const { t } = useT();
  const isMobile = useMediaQuery("(max-width:820px)");
  return (
    <Link
      style={{
        textDecoration: "none",
        width: isMobile ? "fit-content" : "100%",
        cursor: isDisabled ? "default" : "pointer",
        pointerEvents: isDisabled ? "none" : "auto",
      }}
      to={isDisabled ? undefined : link}
    >
      <Box
        component={motion.div}
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          backgroundColor: isCurrentTab ? "primary.main" : "transparent",
          borderRadius: "4px",
          p: "10px",
          transition: "all 0.3s",
        }}
        whileTap={{ scale: 0.8 }}
        whileHover={{ scale: 1.05 }}
      >
        <Icon
          sx={{
            color: isCurrentTab ? "white" : isDisabled ? "#ACACAC" : "#808080",
          }}
          fontSize="medium"
        />
        {!isMobile && (
          <Typography
            sx={{
              color: isCurrentTab ? "white" : isDisabled ? "#ACACAC" : "grey",
              fontSize: "14px",
              fontWeight: 500,
            }}
          >
            {t(title as TranslationId)}
            {isDisabled ? ` (${t("COMING_SOON")})` : ""}
          </Typography>
        )}
      </Box>
    </Link>
  );
};

const Sidebar = () => {
  const location = useLocation();
  const fontFamily = useSelector(portalFontFamilyNameSelector);
  const flags = useFlags(["v2_agent_management"]) as {
    [key: string]: IFlagsmithFeature;
  };
  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: "20px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          p: "20px",
          gap: "20px",
        }}
      >
        {sidebarLinks
          .filter((link) => {
            return link.feature_flag ? flags[link.feature_flag]?.enabled : true;
          })
          .map((sidebarlink) => {
            return (
              <SidebarLink
                key={sidebarlink.title}
                isCurrentTab={location.pathname.includes(sidebarlink.link)}
                link={sidebarlink.link}
                title={sidebarlink.title}
                Icon={sidebarlink.icon}
                isDisabled={sidebarlink.disabled}
              />
            );
          })}
      </Box>
      <Box
        sx={{ display: "flex", alignItems: "center", mt: "auto", p: "20px" }}
      >
        <Typography
          sx={{
            color: "#020202",
            fontFamily: fontFamily,
            fontSize: "6.875px",
            mr: "5px",
          }}
        >
          Powered By
        </Typography>
        <StereLogo />
      </Box>
    </Box>
  );
};

export default Sidebar;
