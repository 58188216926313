import { Box } from "@mui/material";
import { Dayjs } from "dayjs";
import { FunctionComponent } from "react";

import FiltersContainer from "components/common/FiltersContainer";
import { useT } from "translation";

interface PoliciesFiltersProps {
  onDateChange: (newValue: Dayjs, name: string, filters: Filter[]) => void;
  onApply: (filters: Filter[]) => void;
  onClear: (filters: Filter[]) => void;
}

const insuranceProducts: SelectOption[] = [{ label: "Pet", value: "PET-MEU" }];

const status: SelectOption[] = [
  { label: "Active", value: "active" },
  // { label: "Cancelletion in Progress", value: "cancellation_in_progress" },
  // { label: "Cancelled", value: "cancelled" },
  // { label: "Expired", value: "expired" },
];

const PoliciesFilters: FunctionComponent<PoliciesFiltersProps> = ({
  onDateChange,
  onApply,
  onClear,
}) => {
  const { t } = useT();
  return (
    <Box
      sx={{
        mb: "40px",
      }}
    >
      <FiltersContainer
        key="policies-filter"
        filters={[
          {
            id: "products",
            title: t("FILTERS.insuranceProducts"),
            options: insuranceProducts,
          },
          {
            id: "status",
            title: "Status",
            options: status,
          },
        ]}
        onDateChange={onDateChange}
        onApply={onApply}
        onClear={onClear}
      />
    </Box>
  );
};

export default PoliciesFilters;
