import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import moment from "moment";
import React from "react";

import TableComponent from "components/common/TableComponent";
import ViewOption from "components/common/ViewOption";
import { useNavigate } from "react-router-dom";
import { useT } from "translation";
import { capitalizeFirstLetter } from "utils/utils";
import QuoteStatus from "./QuoteStatus";

interface QuotesTableProps {
  quotesDataList: QuotesData[];
  loadingData: boolean;
  hasError: boolean;
}

const QuotesTable: React.FC<QuotesTableProps> = ({
  quotesDataList,
  loadingData,
  hasError,
}) => {
  const { t } = useT();
  const navigate = useNavigate();

  const getUniqueProducts = (quotes: Quote[]) => {
    const typeSet = new Set(quotes.map((item) => item?.product_details?.type));
    return Array.from(typeSet);
  };

  const headCells: TableComponentHeadCell[] = [
    {
      id: "applicationId",
      label: t("QUOTES_PAGE.tableHeaders.application_id"),
    },
    {
      id: "applicantName",
      label: t("QUOTES_PAGE.tableHeaders.applicant_name"),
    },
    {
      id: "date",
      label: t("QUOTES_PAGE.tableHeaders.date"),
    },
    {
      id: "insurance",
      label: t("QUOTES_PAGE.tableHeaders.insurance"),
    },
    {
      id: "quote",
      label: t("QUOTES_PAGE.tableHeaders.quote"),
    },
    {
      id: "status",
      label: t("QUOTES_PAGE.tableHeaders.status"),
    },
    {
      id: "action",
      label: t("QUOTES_PAGE.tableHeaders.action"),
    },
  ];

  const listoptions = (submissionId: string) => [
    {
      title: "View",
      label: <ViewOption />,
      cb: () => navigate(`/home/quotes/${submissionId}`),
    },
  ];

  const tableRows: TableComponentRow[] = quotesDataList?.map((quote) => ({
    id: quote.applicationId,
    cells: [
      {
        id: quote.applicationId,
        label: `${quote.applicationId?.slice(0, 12)}...`,
      },
      {
        id: quote.applicantName,
        label: quote.applicantName,
      },
      {
        id: quote.date,
        label: moment(quote.date).format("DD MMM YYYY"),
      },
      {
        id: `${quote.applicationId}-insurance`,
        label: (
          <Box>
            {getUniqueProducts(quote?.insurance).map((type) => (
              <Typography key={type} sx={{ fontSize: "14px" }}>
                {capitalizeFirstLetter(type)}
              </Typography>
            ))}
          </Box>
        ),
      },
      {
        id: `${quote.applicationId}-quotes-sent`,
        label: quote?.metrics?.total?.quotes_shortlisted
          ? `${quote?.metrics?.total?.quotes_shortlisted} ${
              quote?.metrics?.total?.quotes_shortlisted > 1
                ? t("QUOTES_PAGE.status.quotesSent")
                : t("QUOTES_PAGE.status.quoteSent")
            }`
          : t("QUOTES_PAGE.status.noQuoteSent"),
      },
      {
        id: `${quote.applicationId}-status`,
        label: <QuoteStatus quoteData={quote} />,
      },
      {
        id: `${quote.applicationId}-actions`,
        actions:
          quote?.status === "no_quotes_generated"
            ? []
            : listoptions(quote.submissionId),
        label: "Actions",
        type: "action",
      },
    ],
  }));

  return (
    <TableComponent
      headCells={headCells}
      loading={loadingData}
      errorMessage={hasError && t("SOMETHING_WENT_WRONG")}
      noDataMessage={t("NO_DATA_AVAILABLE")}
      rows={tableRows}
    />
  );
};
export default QuotesTable;
