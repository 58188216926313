import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  confirmAndDonePayment,
  createEpfPaymentIntent,
  createPayInFullPaymentIntent,
} from "api/anddone-payment";
import { animateRouteProps } from "constants/common";
import { STERE_CHECKOUT_DECLINED } from "constants/webhookEvents";
import { setLoaderText } from "store/features/loaderSlice";
import { setLoadingValue } from "store/features/paymentSlice";
import { nextStep } from "store/features/stepperSlice";
import { fireWebhookEvent } from "store/features/webhooksSlice";
import {
  customStylesSelector,
  quotesSelector,
} from "store/selectors/selectors";
import { RootState } from "store/store";
import { useT } from "translation";
import ErrorComponent from "../ErrorComponent/ErrorComponent";
import "./style.css";
import { getTotalPremium } from "./utils";

interface AndDonePaymentProps {
  quotes?: Quote[];
  policyholder?: Policyholder;
}

const ANDDONE_MINIMUM_PREMIUM_AMOUNT = 201;

const AndDonePayment: React.FunctionComponent<AndDonePaymentProps> = ({
  policyholder,
  quotes,
}) => {
  const customStyles = useSelector(customStylesSelector);
  const [error, setError] = useState(false);

  const dispatch = useDispatch();
  const { t } = useT();
  const { submissionId, siaApplicationId } = useSelector(
    (state: RootState) => state.surveyJs
  );
  const { selectedQuotes } = useSelector(quotesSelector);
  const { clientAPIKey, clientFeatures, applicantId } = useSelector(
    (state: RootState) => {
      return state.client;
    }
  );

  const onAfterSubmit = async (data) => {
    if (data?.transactionResult?.success) {
      dispatch(setLoaderText(t("GETTING_CONFIRMATION")));
      dispatch(setLoadingValue(true));

      let amountExcludingTechFees = undefined;
      switch (data?.channelType) {
        case "ACH":
          amountExcludingTechFees = data?.achTenderInfo?.amount;
          break;
        case "CreditCard":
          amountExcludingTechFees = data?.ccTenderInfo?.amount;
          break;
        case "DebitCard":
          amountExcludingTechFees = data?.debitCardTenderInfo?.amount;
          break;
        default:
          amountExcludingTechFees = undefined;
          break;
      }

      try {
        sessionStorage.setItem("tId", data?.transactionId);
        await confirmAndDonePayment(clientAPIKey, {
          amount: amountExcludingTechFees,
          payment_intent_id: data?.paymentLinkId,
          payment_method: data?.processMethod,
          transaction_id: data?.transactionId,
          transaction_status: data?.transactionStatus,
        });
      } catch (error) {
        console.error(error);
      } finally {
        dispatch(setLoaderText(null));
        dispatch(setLoadingValue(false));
        dispatch(nextStep());
      }
    } else {
      dispatch(
        fireWebhookEvent({
          event_type: STERE_CHECKOUT_DECLINED,
          metadata: {
            applicantId: applicantId,
            applicationId: siaApplicationId,
            submissionid: submissionId,
            selectedQuotes: selectedQuotes?.map((q) => {
              return {
                quote_id: q.id,
                premium: {
                  currency: q.premium?.currency,
                  premium_value: q.premium?.premium_value,
                  tax: q.premium?.tax,
                },
                insurer_name: q?.product_details?.insurer,
              };
            }),
            payment: { status: "Failed", transactionId: data?.transactionId },
            error: data,
          },
        })
      );
      console.error(data);
    }
  };

  const initializeAnddoneWidget = async () => {
    try {
      dispatch(setLoaderText(t("LOADING_PAYMENT_WIDGET")));
      dispatch(setLoadingValue(true));
      let paymentToken = null;
      const totalPremium = getTotalPremium(quotes);
      let retry = 3;
      while (retry) {
        let errorCode = null;
        if (
          totalPremium <= ANDDONE_MINIMUM_PREMIUM_AMOUNT ||
          (clientFeatures && !clientFeatures?.is_epf_enabled)
        ) {
          const results = await createPayInFullPaymentIntent(clientAPIKey, {
            policyholder,
            quotes,
          });
          paymentToken = results.data.results?.paymentToken;
          errorCode = results?.data?.errorObject?.code;
        } else {
          const results = await createEpfPaymentIntent(clientAPIKey, {
            policyholder,
            quotes,
          });
          paymentToken = results.data.results?.[0]?.item?.batchId;
          errorCode = results?.data?.errorObject?.code;
        }
        if (errorCode === "ANDDONE_PAYMENT_INTENT_GENERATION_FAILED") {
          retry = retry - 1;
        } else {
          retry = 0;
        }
      }
      dispatch(setLoaderText(null));
      dispatch(setLoadingValue(false));

      if (paymentToken) {
        const pluginOption = {
          theme: "classic",
          container: "anddoneContainer",
        };

        //@ts-ignore
        const andDone = new AndDone(paymentToken, pluginOption);
        andDone.onload = (plugin) => {
          plugin.addEvent("onAfterSubmit", onAfterSubmit);
        };
      } else {
        setError(true);
      }
    } catch (error) {
      console.error(error);
      setError(true);
    } finally {
      dispatch(setLoaderText(null));
      dispatch(setLoadingValue(false));
    }
  };

  useEffect(() => {
    setError(false);
    initializeAnddoneWidget();
  }, []);

  const mywidgetSx = {
    height: "100% !important",
    ".payment-form": {
      backgroundColor: `${customStyles.muiTheme.palette.background.default} !important`,
      fontFamily: `${customStyles.muiTheme.typography.fontFamily} !important`,
    },
    ".payment-form > div": {
      backgroundColor: `${customStyles.primaryCard.backgroundColor} !important`,
    },
    ".payment-form .finance-card": {
      border: "1.5px solid #D0D8DF !important",
    },
    ".disclaimer-section": {
      border: `1px solid #D0D8DF !important`,
      borderRadius: "4px !important",
      padding: "12px 16px !important",
      fontSize: "14px !important",
      height: "100% !important",
      fontWeight: "500 !important",
      color: `${customStyles.muiTheme.palette.primary.main} !important`,
      backgroundColor: `#ffffff !important`,
    },
    ".terms": {
      fontSize: "14px !important",
      color: `${customStyles.muiTheme.palette.primary.main} !important`,
      fontWeight: "400 !important",
    },
    ".terms > b": {
      fontSize: "14px !important",
    },
    ".price-section, .price-section span.label": {
      fontSize: "14px !important",
      color: `${customStyles.muiTheme.palette.primary.main} !important`,
      fontWeight: "500 !important",
      letterSpacing: "0 !important",
    },
    ".payment-form .errorMessage": {
      color: "red !important",
      fontSize: "14px !important",
    },
    ".payment-form .input-section input, .payment-form .input-section select": {
      border: `1px solid #D0D8DF !important`,
      fontSize: "14px !important",
      backgroundColor: `${customStyles.input.backgroundColor} !important`,
      padding: "12px !important",
      height: "42px !important",
    },
    ".input-section > .label": {
      letterSpacing: "0 !important",
      fontSize: "12px !important",
      color: `${customStyles.muiTheme.palette.primary.main} !important`,
      fontWeight: "500 !important",
      textTransform: "capitalize important",
    },
    ".select-header .paySelect.select": {
      color: `#ffffff !important`,
      textDecoration: `none !important`,
      letterSpacing: "0 !important",
    },
    ".paySelect": {
      textDecoration: `none !important`,
    },
    "#selector-box": {
      backgroundColor: `${customStyles.muiTheme.palette.primary.main} !important`,
      color: `#ffffff !important`,
      letterSpacing: "0 !important",
    },
    ".select-header": {
      backgroundColor: `${customStyles.primaryCard.backgroundColor} !important`,
      border: `1px solid #D0D8DF !important`,
      letterSpacing: "0 !important",
    },
    ".subtitle, .expand-button span.label, .collapse-button span.label": {
      color: `${customStyles.muiTheme.palette.primary.main} !important`,
      fontWeight: "500 !important",
      letterSpacing: "0 !important",
    },
    ".summary-column .summary-row, .summary-column .summary-row span.label, .question-box span.label, #detailLabelBox span.label, #detailAmountBox .amount":
      {
        fontSize: "14px !important",
        color: `${customStyles.muiTheme.palette.primary.main} !important`,
        textTransform: "capitalize !important",
        letterSpacing: "0 !important",
      },
    ".line.secondary": {
      borderColor: "#D0D8DF !important",
    },
    ".line": {
      borderBottom: `1px solid #D0D8DF !important`,
    },
    ".finance-card .floating-tag": {
      color: "#FFC107 !important",
      fontSize: "12px !important",
      fontWeight: "500 !important",
      backgroundColor: "#FFF8E1 !important",
      textTransform: "capitalize !important",
    },
    ".finance-card .body.set, .note": {
      fontSize: "14px !important",
      fontWeight: "400 !important",
    },
    ".confirm-button.secondary, #payButtonAmount": {
      backgroundColor: `${customStyles.button.backgroundColor} !important`,
      color: `${customStyles.button.color} !important`,
    },
    ".confirm-button.secondary:hover, #payButtonAmount:hover": {
      backgroundColor: `${customStyles.button.hover.backgroundColor} !important`,
      color: `${customStyles.button.hover.color} !important`,
    },
    "a:hover": {
      color: `${customStyles.muiTheme.palette.primary.main} !important`,
    },
    ".payment-form > :nth-child(2), #downPaymentHeader": {
      alignItems: "center",
    },
    ".payment-form > :nth-child(2) > img": {
      border: `8px solid ${customStyles.muiTheme.palette.primary.main} !important`,
      padding: "4px !important",
      height: "50px !important",
      width: "50px !important",
      borderRadius: "50% !important",
    },
    ".icon, #downPaymentHeader > img": {
      border: `4px solid ${customStyles.muiTheme.palette.primary.main} !important`,
      padding: "4px !important",
      height: "40px !important",
      width: "40px !important",
      borderRadius: "50% !important",
    },
  };

  return (
    <Box
      sx={{
        display: "flex",
        position: "relative",
        flexDirection: "column",
      }}
      {...animateRouteProps}
    >
      {error && <ErrorComponent />}
      <Box
        id="anddoneContainer"
        sx={{
          ...mywidgetSx,
          flexGrow: 1,
          overflow: "auto",
          background: customStyles.muiTheme.palette.background.default,
        }}
        payment-container
      ></Box>
    </Box>
  );
};

export default AndDonePayment;
