import { SVGProps } from "react";
import { useSelector } from "react-redux";
import { customStylesSelector } from "store/selectors/selectors";

const PetCompanySvg = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  const customStyles = useSelector(customStylesSelector);
  return (
    <svg
      width="51"
      height="51"
      viewBox="0 0 51 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M38.487 7.70667H12.5105C12.1447 7.70667 11.8485 7.41046 11.8485 7.0447V0.661971C11.8485 0.296214 12.1447 0 12.5105 0H38.487C38.8527 0 39.149 0.296214 39.149 0.661971V7.0447C39.149 7.41046 38.8527 7.70667 38.487 7.70667ZM13.1724 6.38273H37.825V1.32394H13.1724V6.38273Z"
        fill={props.fill ?? customStyles.icons.fill}
      />
      <path
        d="M34.5203 50.9974H8.54893C8.18317 50.9974 7.88696 50.7012 7.88696 50.3355V19.7973C7.88696 19.7303 7.89726 19.6633 7.91787 19.6015L11.8794 6.84636C11.9876 6.49606 12.3585 6.30288 12.7088 6.41106C13.0591 6.51924 13.2523 6.89015 13.1441 7.24046L9.21348 19.9003V49.6761H33.8609V19.7999C33.8609 19.7329 33.8712 19.6659 33.8918 19.6041L37.8559 6.84894C37.9641 6.49864 38.335 6.30546 38.6853 6.41364C39.0356 6.52182 39.2288 6.89273 39.1206 7.24303L35.1848 19.9003V50.338C35.1848 50.7038 34.8886 51 34.5229 51L34.5203 50.9974Z"
        fill={props.fill ?? customStyles.icons.fill}
      />
      <path
        d="M34.5229 50.9974C34.3194 50.9974 34.1185 50.9047 33.9897 50.7295C33.7733 50.4359 33.8351 50.0212 34.1288 49.8023L38.0929 46.8711C38.2422 46.7603 38.4303 46.7165 38.6132 46.7526L41.7865 47.3656V19.8977L37.8559 7.23788C37.7477 6.88757 37.9435 6.51667 38.2912 6.40848C38.6389 6.3003 39.0124 6.49606 39.1206 6.84379L43.0821 19.5989C43.1027 19.6633 43.113 19.7277 43.113 19.7947V48.1641C43.113 48.3624 43.0254 48.5479 42.8735 48.6741C42.7215 48.8003 42.5206 48.8518 42.3274 48.8132L38.6518 48.1048L34.9221 50.8635C34.8036 50.9511 34.6671 50.9923 34.528 50.9923L34.5229 50.9974Z"
        fill={props.fill ?? customStyles.icons.fill}
      />
      <path
        d="M34.5203 20.4592H8.54893C8.18317 20.4592 7.88696 20.163 7.88696 19.7973C7.88696 19.4315 8.18317 19.1353 8.54893 19.1353H34.5203C34.8861 19.1353 35.1823 19.4315 35.1823 19.7973C35.1823 20.163 34.8861 20.4592 34.5203 20.4592Z"
        fill={props.fill ?? customStyles.icons.fill}
      />
      <path
        d="M38.4869 48.0662C38.1212 48.0662 37.825 47.77 37.825 47.4042V7.0447C37.825 6.67894 38.1212 6.38273 38.4869 6.38273C38.8527 6.38273 39.1489 6.67894 39.1489 7.0447V47.4042C39.1489 47.77 38.8527 48.0662 38.4869 48.0662Z"
        fill={props.fill ?? customStyles.icons.fill}
      />
      <path
        d="M24.6037 35.0947C25.4511 35.6047 26.8394 34.9582 27.334 33.982C27.6714 33.3148 27.5143 32.6503 27.4602 32.4159C27.2644 31.5813 26.5638 30.4274 25.6649 30.4686C24.5341 30.5227 24.0679 32.4262 24.0473 32.5163C24.0035 32.7018 23.6094 34.4971 24.6037 35.0947Z"
        stroke="#163B61"
        stroke-width="1.0303"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.3943 33.6548C21.9867 33.6136 22.3756 32.8358 22.7852 32.0115C23.0865 31.4062 23.4033 30.7674 23.29 29.938C23.2539 29.6701 23.1149 28.6527 22.3808 28.3462C21.6029 28.0191 20.7014 28.7145 20.3073 29.155C19.5655 29.9844 19.6402 31.0276 19.6762 31.3289C19.805 32.4391 20.6086 33.7064 21.3943 33.6548Z"
        stroke="#163B61"
        stroke-width="1.0303"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.1848 33.8815C18.597 33.5183 18.5506 32.895 18.5145 32.3644C18.4759 31.8415 18.4115 30.9091 17.6542 30.2909C17.528 30.1879 16.8094 29.6006 16.0624 29.835C15.2047 30.1029 14.9652 31.2465 14.9497 31.8312C14.9445 32.0527 14.9239 32.828 15.5061 33.4488C16.0367 34.0154 16.7244 34.0824 16.9717 34.1056C17.3348 34.1417 17.832 34.188 18.1848 33.8789V33.8815Z"
        stroke="#163B61"
        stroke-width="1.0303"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.3254 37.9795C20.6808 38.8759 21.5746 39.4323 22.507 39.3498C23.5476 39.2571 24.2508 38.4045 24.4079 37.6035C24.6655 36.3027 23.4498 35.293 23.1922 35.0792C21.4535 33.6342 18.391 33.9176 17.1443 35.4218C17.0129 35.5815 16.5132 36.1842 16.521 37.042C16.521 37.2403 16.5338 38.2526 17.3632 38.7574C17.9273 39.1 19.5758 38.9454 20.3254 37.977"
        stroke="#163B61"
        stroke-width="1.0303"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default PetCompanySvg;
