import {
  Box,
  InputLabel,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CustomButton from "components/common/CustomButton/CustomButton";
import SectionInfo from "components/common/SectionInfo/SectionInfo";
import { animateRouteProps } from "constants/common";
import { Field, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { setCustomerDetails } from "store/features/customerDetailsSlice";
import { nextStep, prevStep } from "store/features/stepperSlice";
import { resetSurveyJS } from "store/features/surveyJsSlice";
import {
  customStylesSelector,
  customerDetailsSelector,
} from "store/selectors/selectors";
import { useT } from "translation";
import * as Yup from "yup";

export default function CustomerDetails() {
  const { t } = useT();
  const dispatch = useDispatch();
  const customStyles = useSelector(customStylesSelector);
  const { customerDetails } = useSelector(customerDetailsSelector);
  const isMobile = useMediaQuery("(max-width:480px)");

  const CustomerDetailsSchema = Yup.object().shape({
    // name: Yup.string().required(t("PERSONAL_FORM_FULL_NAME_REQUIRED")),
    email: Yup.string().email(t("INVALID_EMAIL")).required(t("EMAIL_REQUIRED")),
    // phone: Yup.string()
    //   .length(11, t("PERSONAL_FORM_INVALID_PHONE"))
    //   .required(t("PERSONAL_FORM_PHONE_REQUIRED")),
  });

  const ErrorTextStyle = {
    margin: "0.25rem 0 0 0",
    color: "#df1b41",
    fontSize: "0.93rem",
    fontFamily: customStyles.muiTheme.typography.fontFamily,
    fontWeight: 500,
  };

  const InputLabelStyle = {
    color: customStyles.input.color,
    fontSize: "14px",
    fontWeight: "500",
    mb: "10px",
    fontFamily: customStyles.muiTheme.typography.fontFamily,
  };

  const TextFieldStyle = {
    ".MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${customStyles?.input?.borderColor ?? "#a8a8a8"}`,
      "&:hover": {
        border: `1px solid ${customStyles?.input?.hover?.borderColor}`,
      },
    },
    ".MuiOutlinedInput-input": {
      height: "14px",
    },
    ".MuiInputBase-root": {
      height: "48px",
      borderRadius: "6px",
      fontWeight: "normal",
      color: customStyles.input.color,
      backgroundColor: customStyles.input.backgroundColor,
    },
    ".Mui-error": ErrorTextStyle,
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: "1",
        pl: { xs: "20px", sm: "40px", md: "20px", lg: "20px" },
        pr: { xs: "20px", sm: "40px", md: "20px", lg: "20px" },
      }}
      {...animateRouteProps}
    >
      <SectionInfo
        subTitle={t("CUSTOMER_DETAILS_PAGE_DESCRIPTION_PRIMARY")}
        description={t("CUSTOMER_DETAILS_PAGE_DESCRIPTION_SECONDARY")}
      />
      <Formik
        initialValues={{
          // name: customerDetails?.name,
          email: customerDetails?.email,
          // phone: customerDetails?.phone,
        }}
        validationSchema={CustomerDetailsSchema}
        onSubmit={(values) => {
          dispatch(setCustomerDetails(values));

          if (
            // customerDetails?.name !== values.name ||
            customerDetails?.email !== values.email
            // customerDetails?.phone !== values.phone
          ) {
            localStorage.removeItem("prefillData");
            const clientData = {
              prefill_values: { applicant_email: values.email },
              read_only_values: {
                applicant_email: true,
              },
            };
            localStorage.setItem("clientData", JSON.stringify(clientData));
            dispatch(resetSurveyJS());
          }
          dispatch(nextStep());
        }}
      >
        {({ errors, touched, handleSubmit }) => (
          <Box sx={{ display: "flex", flexDirection: "column", flexGrow: "1" }}>
            <Box sx={{ flexGrow: "1", marginBottom: "20px" }} maxWidth={440}>
              {/* <Box sx={{ marginBottom: "0.75rem" }}>
                <InputLabel required sx={InputLabelStyle}>
                  {t("PERSONAL_FORM_FULL_NAME")}
                </InputLabel>
                <Field
                  fullWidth
                  as={TextField}
                  sx={TextFieldStyle}
                  name="name"
                />
                {errors.name && touched.name ? (
                  <Typography sx={ErrorTextStyle}>{errors.name}</Typography>
                ) : null}
              </Box> */}
              <Box sx={{ marginBottom: "0.75rem" }}>
                <InputLabel required sx={InputLabelStyle}>
                  {t("PERSONAL_FORM_EMAIL")}
                </InputLabel>
                <Field
                  as={TextField}
                  name="email"
                  type="email"
                  sx={TextFieldStyle}
                  fullWidth
                  data-cy="customer-email-input"
                />
                {errors.email && touched.email ? (
                  <Typography sx={ErrorTextStyle}>{errors.email}</Typography>
                ) : null}
              </Box>
              {/* <Box sx={{ marginBottom: "0.75rem" }}>
                <InputLabel required sx={InputLabelStyle}>
                  {t("PERSONAL_FORM_PHONE")}
                </InputLabel>
                <Field
                  as={TextField}
                  name="phone"
                  type="tel"
                  sx={TextFieldStyle}
                  fullWidth
                />
                {errors.phone && touched.phone ? (
                  <Typography sx={ErrorTextStyle}>{errors.phone}</Typography>
                ) : null}
              </Box> */}
            </Box>

            <footer
              style={
                isMobile
                  ? {
                      position: "fixed",
                      bottom: "0px",
                      right: "0",
                      background: "white",
                      width: "100%",
                      padding: "20px",
                      boxShadow: isMobile && `0px 5px 5px -5px grey inset`,
                    }
                  : {
                      marginTop: "40px",
                      marginBottom: "20px",
                    }
              }
            >
              <Box sx={{ display: "flex", justifyContent: "end" }}>
                <CustomButton
                  onClickCB={() => dispatch(prevStep())}
                  btnText={t("BACK")}
                />
                <CustomButton
                  data_cy="customer-details-btn"
                  onClickCB={handleSubmit}
                  btnText={t("NEXT")}
                />
              </Box>
            </footer>
          </Box>
        )}
      </Formik>
    </Box>
  );
}
