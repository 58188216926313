import { Box } from "@mui/material";
import { motion } from "framer-motion";

export const tabPanelVariant = {
  visible: {
    opacity: 1,
    height: "auto",
  },
  hidden: {
    opacity: 0,
    height: 0,
  },
};
type Props = { padding?: number } & Record<string, any>;
function AnimatedTabPanel({ children, value, index, padding, ...rest }: Props) {
  const isTabPanelVisible = value === index;
  return (
    <Box
      key={index}
      component={motion.div}
      role="tabpanel"
      {...rest}
      variants={tabPanelVariant}
      animate={isTabPanelVisible ? "visible" : "hidden"}
      transition={{ type: "tween", delay: 0.15 }}
    >
      {isTabPanelVisible && <Box sx={{ p: padding ?? 3 }}>{children}</Box>}
    </Box>
  );
}

export default AnimatedTabPanel;
