import { Box } from "@mui/material";

import AutoSvg from "assets/images/Auto";
import BikeSvg from "assets/images/BikeSvg";
import BOPSvg from "assets/images/BopSvg";
import CyberSvg from "assets/images/CyberSvg";
import DroneSvg from "assets/images/DroneSvg";
import ENOSvg from "assets/images/ENOSvg";
import GeneralLiabilitySvg from "assets/images/GeneralLiabilitySvg";
import GroupLifeSvg from "assets/images/GroupLife";
import HealthSvg from "assets/images/HealthSvg";
import MobileSvg from "assets/images/MobileSvg";
import OnceIcon from "assets/images/OnceIcon.png";
import OnceLogo from "assets/images/OnceLogo.png";
import PetCompanySvg from "assets/images/PetCompanySvg";
import PetSvg from "assets/images/PetSvg";
import ProductLiabilitySvg from "assets/images/ProductLiabilitySvg";
import PropertySvg from "assets/images/PropertySvg";
import TravelSvg from "assets/images/TravelSvg";
import ChubbLogo from "assets/images/chubb.png";
import TokioLogo from "assets/images/tokio.png";
import Allianz from "assets/logos/Allianz";
import CoopLiber from "assets/logos/CoopliberNew.png";
import FairfaxLogo from "assets/logos/FairfaxLogo";
import MeupetLogo from "assets/logos/Meupet";
import StereLogo from "assets/logos/StereLogo";
import { COUNTRY, IS_HOSTED_INSURANCE } from "config";

export const ProductIconMapping = (key: string, disabled?: boolean) => {
  const commonProps = {
    style: { width: "inherit", height: "inherit" },
    ...(disabled ? { fill: "#757575" } : {}),
  };
  const ProductIcons = {
    GL: <GeneralLiabilitySvg {...commonProps} />,
    BOP: <BOPSvg {...commonProps} />,
    CY: <CyberSvg {...commonProps} />,
    PL: <ProductLiabilitySvg {...commonProps} />,
    "E&O": <ENOSvg {...commonProps} />,
    PET: <PetSvg {...commonProps} />,
    "PET-COMPANY": <PetCompanySvg {...commonProps} />,
    "PET-MEU": <PetSvg {...commonProps} />,
    DRONE: <DroneSvg {...commonProps} />,
    HEALTH: <HealthSvg {...commonProps} />,
    TRAVEL: <TravelSvg {...commonProps} />,
    AUTO: <AutoSvg {...commonProps} />,
    LIFE: <GroupLifeSvg {...commonProps} />,
    GROUPLIFE: <GroupLifeSvg {...commonProps} />,
    BIKE: <BikeSvg {...commonProps} />,
    MOBILE: <MobileSvg {...commonProps} />,
    PME: <PropertySvg {...commonProps} />,
    JWL: (
      <Box
        sx={{ height: "inherit", width: "inherit" }}
        component="img"
        src={OnceIcon}
        alt={"once-icon"}
      />
    ),
  };

  return ProductIcons[key];
};

export const InsurerLogoMapping = {
  CHUBB: <Box component="img" src={ChubbLogo} alt={"chubb-logo"} />,
  STERE: <StereLogo style={{ width: "100px", height: "40px" }} />,
  ALLIANZ: <Allianz style={{ width: "100px", height: "40px" }} />,
  MEU: (
    <MeupetLogo
      style={{ width: "inherit", height: "inherit", fill: "#8278cb" }}
    />
  ),
  TOKIO: (
    <Box
      style={{ width: "100px", maxHeight: "100px" }}
      component="img"
      src={TokioLogo}
      alt={"tokio-logo"}
    />
  ),
  LIBER: (
    <Box
      component="img"
      sx={{
        height: 60,
        maxHeight: 60,
      }}
      alt="Image"
      src={CoopLiber}
    />
  ),
  FAIRFAX: (
    <FairfaxLogo
      style={{ width: "inherit", height: "inherit", fill: "#8278cb" }}
    />
  ),
  ONCE: (
    <Box
      sx={{ width: "100px", maxHeight: "100px" }}
      component="img"
      src={OnceLogo}
      alt={"once-icon"}
    />
  ),
};

export const InsurerNameMapping = {
  CHUBB: "Chubb",
  STERE: "Stere",
  ALLIANZ: "Allianz",
  MEU: "Peti",
  BROKERY: "Brokery",
  PARAMOUNT: "Paramount",
  ONCE: "Once",
};

export const createPolicyHolderDetails = (data, product): Policyholder => {
  switch (product) {
    case "PET":
      return {
        policyholder_full_name: data.full_name,
        policyholder_dob: data.applicant_dob,
        policyholder_details: {
          br_cpf: data.applicant_cpf,
        },
        policyholder_phone_number: data.applicant_phone,
        policyholder_mail: data.applicant_email,
        primary_country_code: COUNTRY,
        policyholder_address_details: {
          br_complemento: data.location_complement,
          number: data.location_home_number,
          br_cep: data.address_zip_code,
          city: data.address_city,
          country: IS_HOSTED_INSURANCE ? "USA" : "Brasil",
          state: data.address_state,
          street: data.address_street_name,
        },
      };
    case "GROUPLIFE":
      return {
        policyholder_full_name: data.company_name,
        primary_country_code: COUNTRY,
        policyholder_mail: data.applicant_email,
        policyholder_details: {
          br_cpf: data.company_cnpj,
        },
      };
    case "AUTO":
      return {
        policyholder_full_name: data.policyholder_name,
        primary_country_code: COUNTRY,
        policyholder_mail: data.policyholder_email,
        policyholder_details: {
          br_cpf: data.policyholder_cpf,
        },
      };

    case "GL":
    case "BOP":
    case "CY":
      return {
        policyholder_full_name: data.contact_name,
        policyholder_dob: null,
        policyholder_details: {
          br_cpf: "",
        },
        policyholder_phone_number: data.contact_phone_number,
        policyholder_mail: data.contact_email,
        primary_country_code: COUNTRY,
        policyholder_address_details: {
          br_complemento: "",
          number: "",
          br_cep: data.mailing_location_zip_code,
          city: data.mailing_location_city,
          country: IS_HOSTED_INSURANCE ? "USA" : "Brasil",
          state: data.mailing_location_state,
          street: data.mailing_location_address1,
        },
      };

    case "DRONE":
      return {
        policyholder_full_name: `${data?.applicant_first_name} ${data?.applicant_last_name}`,
        policyholder_dob: data.applicant_dob,
        policyholder_details: {
          br_cpf: data.applicant_cpf,
        },
        policyholder_phone_number: data?.applicant_phone_number,
        policyholder_mail: data.applicant_email,
        primary_country_code: COUNTRY,
        policyholder_address_details: {
          br_complemento: data.location_complement,
          number: data.location_home_number,
          br_cep: data.applicant_zip_code,
          city: data.applicant_city,
          country: IS_HOSTED_INSURANCE ? "USA" : "Brasil",
          state: data.applicant_state,
          street: data.applicant_street,
        },
      };
  }
};
