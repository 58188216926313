import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Divider, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";
import { useSelector } from "react-redux";

import { customStylesSelector } from "store/selectors/selectors";
import { useT } from "translation";
import PopoverComponent from "../PopoverComponent";

interface FilterComponentProps {
  onChange: (option: SelectOption) => void;
  toggleSelectAll: () => void;
}

const FilterComponent: React.FC<FilterComponentProps & Filter> = ({
  id,
  title,
  options,
  onChange,
  toggleSelectAll,
}) => {
  const { t } = useT();
  const customStyles = useSelector(customStylesSelector);
  const allSelected = (options ?? []).every((option) => option.isSelected);
  const [searchText, setSearchText] = useState("");
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const handleCheckboxChange = (checkbox: SelectOption) => {
    onChange(checkbox);
  };

  const filterOptions = options.filter((checkbox) =>
    checkbox.label.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <PopoverComponent
      key={id}
      title={
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            py: "5px",
            px: "12px",
            borderRadius: "4px",
            background: customStyles?.muiTheme?.palette?.background?.default,
          }}
        >
          <Typography sx={{ fontSize: "12px" }}>{title}</Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ fontSize: "14px" }}>
              {allSelected
                ? t("FILTERS.allSelected")
                : `${options.filter((option) => option.isSelected).length} ${t(
                    "FILTERS.selected"
                  )}`}
            </Typography>
            {isFilterOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Box>
        </Box>
      }
      setPopoverStatus={setIsFilterOpen}
    >
      {!options?.length ? (
        <Typography>{t("FILTERS.noOptions")}</Typography>
      ) : (
        <Box sx={{ p: "20px", maxWidth: "240px", overflowX: "hidden" }}>
          <TextField
            sx={{
              ".MuiOutlinedInput-notchedOutline": {
                border: "none",
                borderLeft: "none",
                borderRight: "none",
              },
              ".MuiOutlinedInput-input": {
                fontSize: "14px",
                color: customStyles?.input?.color,
              },
            }}
            label={
              <Box
                sx={{ display: "flex", alignItems: "center", fontSize: "14px" }}
              >
                <SearchIcon fontSize="small" />
                <Typography>{t("SEARCH")}</Typography>
              </Box>
            }
            variant="outlined"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <Divider key={"divider"} />
          <Box
            sx={{
              maxHeight: "200px",
              overflowY: "auto",
              overflowX: "hidden",
              display: "flex",
              mt: "10px",
              flexDirection: "column",
            }}
          >
            {!searchText && (
              <FormGroup>
                <FormControlLabel
                  sx={{
                    wordBreak: "break-all",
                    ".MuiFormControlLabel-label": { fontSize: "14px" },
                  }}
                  key={"select-all"}
                  control={
                    <Checkbox
                      size="small"
                      checked={allSelected}
                      onChange={toggleSelectAll}
                    />
                  }
                  label={t("FILTERS.selectAll")}
                />
              </FormGroup>
            )}
            {filterOptions.map((checkbox) => (
              <FormGroup>
                <FormControlLabel
                  sx={{
                    wordBreak: "break-all",
                    ".MuiFormControlLabel-label": { fontSize: "14px" },
                  }}
                  key={checkbox.value}
                  control={
                    <Checkbox
                      size="small"
                      checked={checkbox.isSelected}
                      onChange={() => handleCheckboxChange(checkbox)}
                    />
                  }
                  label={checkbox.formattedLabel ?? checkbox.label}
                />
              </FormGroup>
            ))}
          </Box>
        </Box>
      )}
    </PopoverComponent>
  );
};

export default FilterComponent;
