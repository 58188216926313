import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { useMutation } from "@tanstack/react-query";
import LoginBg from "assets/images/login-img.png";
import StereLogo from "assets/logos/StereLogo";
import { ToastCtx } from "components/Toast/ToastProvider";
import AnimatedButton from "components/custom/AnimatedButton";
import { animateRouteProps } from "constants/common";
import { registerUserApi } from "modules/Auth/api";
import { IRegisterUser, IRegisterUserResponse } from "modules/Auth/types";
import { customStylesSelector } from "store/selectors/selectors";
import { RootState } from "store/store";
import { useT } from "translation";

const Register = () => {
  const { t } = useT();
  const navigate = useNavigate();

  const { errorMessage } = useSelector((state: RootState) => state.auth);
  const { setToast } = useContext(ToastCtx);
  const validationSchema = Yup.object().shape({
    email: Yup.string().email(t("INVALID_EMAIL")).required(t("EMAIL_REQUIRED")),
    password: Yup.string()
      .min(8, t("PASSWORD_VALIDATION.length"))
      .matches(/[0-9]/, t("PASSWORD_VALIDATION.numericChar"))
      .matches(/[A-Z]/, t("PASSWORD_VALIDATION.upperCaseLetter"))
      .matches(/[a-z]/, t("PASSWORD_VALIDATION.lowerCaseLetter"))
      .matches(/[^a-zA-Z0-9]/, t("PASSWORD_VALIDATION.specialCharacter"))
      .required(t("PASSWORD_REQUIRED")),
    firstName: Yup.string().required(t("FIRST_NAME_REQUIRED")),
    lastName: Yup.string().required(t("LAST_NAME_REQUIRED")),
  });
  const registerUserMutation = useMutation<
    IRegisterUserResponse,
    any,
    IRegisterUser
  >({
    mutationFn: registerUserApi,
    onSuccess: (registeredUser) => {
      navigate("/v2/verifyEmail", {
        state: { registeredUser },
      });
    },
    onError: ({ error }) => {
      if (error.code === "user_not_verified") {
        return setToast({
          severity: "warning",
          text: "User is already registered please verify user to proceed",
        });
      }
      setToast({ severity: "error", text: t("UnableToRegisterUser") });
    },
  });

  const initialValues = {
    email: "",
    password: "",
    firstName: "",
    lastName: "",
  };
  const customStyles = useSelector(customStylesSelector);
  const [showPassword, setShowPassword] = useState(false);
  const handleTogglePasswordVisibility = () => setShowPassword((show) => !show);

  const handleSubmit = async (values) => {
    registerUserMutation.mutate({
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      password: values.password,
    });
  };

  return (
    <Box {...animateRouteProps}>
      <Grid className="loginForm" container>
        <Grid
          item
          md={6}
          sm={7}
          xs={12}
          sx={{
            height: "100vh",
            p: "20px",
            pt: "40px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box>
            <StereLogo />
          </Box>
          <Box sx={{ mt: "40px", width: "80%" }}>
            <Typography
              sx={{
                mb: "10px",
                fontWeight: 600,
                textAlign: "center",
                fontSize: "18px",
                color: customStyles.muiTheme.palette.primary.main,
              }}
            >
              {t("REGISTER")}
            </Typography>
            <Typography
              sx={{
                mb: "30px",
                fontWeight: 400,
                textAlign: "center",
                fontSize: "14px",
                color: customStyles.muiTheme.palette.text.secondary,
              }}
            >
              {t("EnterDetails")}
            </Typography>
            <Typography
              data-cy="login-error"
              sx={{
                mb: "30px",
                fontWeight: 400,
                textAlign: "center",
                fontSize: "14px",
                color: "red",
              }}
            >
              {errorMessage}
            </Typography>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              validateOnMount={true}
              onSubmit={handleSubmit}
            >
              {({ isValid }) => (
                <Form
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box>
                    <Field
                      as={TextField}
                      type="text"
                      label={t("FIRST_NAME")}
                      name="firstName"
                      data-cy="register-full-name-input"
                      style={{
                        width: "100%",
                      }}
                      InputProps={{
                        style: {
                          borderRadius: "12px",
                        },
                      }}
                    />
                    <ErrorMessage
                      className="error-message"
                      name="firstName"
                      component="div"
                    />
                  </Box>
                  <Box
                    sx={{
                      mt: "30px",
                    }}
                  >
                    <Field
                      as={TextField}
                      type="text"
                      label={t("LAST_NAME")}
                      name="lastName"
                      data-cy="register-full-name-input"
                      style={{
                        width: "100%",
                      }}
                      InputProps={{
                        style: {
                          borderRadius: "12px",
                        },
                      }}
                    />
                    <ErrorMessage
                      className="error-message"
                      name="lastName"
                      component="div"
                    />
                  </Box>
                  <Box
                    sx={{
                      mt: "30px",
                    }}
                  >
                    <Field
                      as={TextField}
                      type="email"
                      label={t("EMAIL_LABEL")}
                      name="email"
                      data-cy="login-email-input"
                      style={{
                        width: "100%",
                      }}
                      InputProps={{
                        style: {
                          borderRadius: "12px",
                        },
                      }}
                    />
                    <ErrorMessage
                      className="error-message"
                      name="email"
                      component="div"
                    />
                  </Box>

                  <Tooltip
                    title={
                      <ul>
                        {[
                          t("PASSWORD_VALIDATION.length"),
                          t("PASSWORD_VALIDATION.numericChar"),
                          t("PASSWORD_VALIDATION.upperCaseLetter"),
                          t("PASSWORD_VALIDATION.lowerCaseLetter"),
                          t("PASSWORD_VALIDATION.specialCharacter"),
                        ].map((validation) => (
                          <li>{validation}</li>
                        ))}
                      </ul>
                    }
                    placement="right"
                    arrow
                  >
                    <Box
                      sx={{
                        mt: "30px",
                      }}
                    >
                      <Field
                        as={TextField}
                        type={showPassword ? "text" : "password"}
                        label={t("PASSWORD_LABEL")}
                        data-cy="login-password-input"
                        name="password"
                        style={{
                          color: "black",
                          width: "100%",
                          borderRadius: "12px",
                        }}
                        InputProps={{
                          style: {
                            borderRadius: "12px",
                          },
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleTogglePasswordVisibility}
                                edge="end"
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <ErrorMessage
                        className="error-message"
                        name="password"
                        component="div"
                      />
                    </Box>
                  </Tooltip>

                  <AnimatedButton
                    sx={{ ml: "0px", mt: "30px", textTransform: "none" }}
                    disabled={!isValid}
                    type={"submit"}
                    data_cy="login-submit-btn"
                    isLoading={registerUserMutation.isPending}
                    variant="contained"
                  >
                    {t("REGISTER")}
                  </AnimatedButton>
                  <Typography
                    variant="body1"
                    sx={{ my: "16px", textAlign: "center" }}
                  >
                    {t("OR")}
                  </Typography>
                  <Link to={"/v2/login"}>
                    <AnimatedButton
                      variant="outlined"
                      sx={{ ml: "0px", textTransform: "none", width: "100%" }}
                    >
                      {t("SIGN_IN")}
                    </AnimatedButton>
                  </Link>
                </Form>
              )}
            </Formik>
          </Box>
        </Grid>
        <Grid
          item
          md={6}
          sm={5}
          sx={{
            height: "100vh",
            position: "relative",
          }}
        >
          <Box
            style={{
              width: "100%",
              height: "100%",
              overflow: "hidden",
            }}
          >
            <img
              src={LoginBg}
              alt="Login Background"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
            <Box
              style={{
                width: "100%",
                height: "100%",
                overflow: "hidden",
                backgroundColor: customStyles.muiTheme.palette.primary.main,
                position: "absolute",
                top: 0,
                left: 0,
                opacity: 0.4,
              }}
            ></Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Register;
