import { AppContentContainer } from "components/policyholderComponents/layout/AppContentContainer";
import { AppHeader } from "components/policyholderComponents/layout/Header/AppHeader";
import { PageLayoutWrapper } from "components/policyholderComponents/layout/PageLayoutWrapper";
import { SideMenu } from "components/policyholderComponents/layout/SideMenu/SideMenu";
import { SubHeader } from "components/policyholderComponents/layout/SubHeader/SubHeader";
import { ProtectedRoute } from "components/policyholderComponents/logic-handlers/ProtectedRoute";
import { Outlet } from "react-router";

export function AppLayout() {
  return (
    <PageLayoutWrapper bgColor="default">
      <ProtectedRoute>
        <SideMenu />
        <AppHeader />
        <SubHeader />

        <AppContentContainer>
          <Outlet />
        </AppContentContainer>
      </ProtectedRoute>
    </PageLayoutWrapper>
  );
}
