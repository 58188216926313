import { Box, Typography } from "@mui/material";

import {
  InsurerLogoMapping,
  InsurerNameMapping,
} from "components/Quote/sections/utils";

interface InsurerProps {
  name: string;
}

export function Insurer({ name }: React.PropsWithChildren<InsurerProps>) {
  const insurerNameCode = name.split("-")[1].toUpperCase();
  const Icon =
    InsurerLogoMapping[insurerNameCode as keyof typeof InsurerLogoMapping];

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
      {/* {Icon ? <Icon style={{ maxHeight: 40 }} /> : null} */}

      <Typography variant="subtitle2" sx={{ color: "secondary.main" }}>
        {InsurerNameMapping[insurerNameCode as keyof typeof InsurerLogoMapping]}
      </Typography>
    </Box>
  );
}
