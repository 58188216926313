import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { IS_BROKER_PORTAL } from "config";

interface StepperState {
  currentStep: number;
  maxSteps: number;
  currentSubStep: number;
  maxSubSteps: number;
  loading: boolean;
  showPaymentScreen: boolean;
}

const initialState: StepperState = {
  currentStep: 0,
  maxSteps: IS_BROKER_PORTAL ? 4 : 5,
  currentSubStep: 0,
  maxSubSteps: -1,
  loading: false,
  showPaymentScreen: false,
};

const stepperSlice = createSlice({
  name: "stepper",
  initialState,
  reducers: {
    setMaxSubSteps: (state, action: PayloadAction<number>) => {
      state.maxSubSteps = action.payload;
    },
    nextStep: (state) => {
      state.currentStep += 1;
      if (state.currentStep === 1) {
        state.currentSubStep = 0;
      }
      if (state.currentSubStep === state.maxSubSteps - 1)
        state.currentSubStep += 1;
    },
    prevStep: (state) => {
      state.currentStep -= 1;
    },
    nextSubStep: (state) => {
      state.currentSubStep += 1;
    },
    prevSubStep: (state) => {
      state.currentSubStep -= 1;
    },
    setCurrentSubStep: (state, action: PayloadAction<number>) => {
      state.currentSubStep = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setCurentStep: (state, action: PayloadAction<number>) => {
      state.currentStep = action.payload;
    },
    resetStepper: (state, action: PayloadAction<number>) => ({
      ...initialState,
      currentStep: action.payload,
    }),
    setShowPaymentScreen: (state, action: PayloadAction<boolean>) => {
      state.showPaymentScreen = action.payload;
    },
  },
});

export const {
  nextStep,
  prevStep,
  setMaxSubSteps,
  prevSubStep,
  nextSubStep,
  setCurrentSubStep,
  setLoading,
  setCurentStep,
  resetStepper,
  setShowPaymentScreen,
} = stepperSlice.actions;
export default stepperSlice.reducer;
