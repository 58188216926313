import { CircularProgress } from "@mui/material";
import Button, { ButtonProps } from "@mui/material/Button";
import { motion } from "framer-motion";
import React from "react";

type MotionButtonProps = ButtonProps & {
  whileTap?: object;
};

type Props = {
  isLoading?: boolean;
  data_cy?: string;
} & ButtonProps;

const MotionButton = motion<
  ButtonProps &
    MotionButtonProps &
    React.ButtonHTMLAttributes<HTMLButtonElement>
>(Button);

const AnimatedButton: React.FC<Props> = ({
  isLoading,
  data_cy,
  children,
  variant,
  disabled,
  ...rest
}: Props) => {
  return (
    <MotionButton
      data-cy={data_cy}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
      {...(variant ? { variant } : {})}
      {...(rest as any)}
      disabled={disabled || isLoading}
    >
      {isLoading ? (
        <CircularProgress size={24} sx={{ color: "white" }} />
      ) : (
        children
      )}
    </MotionButton>
  );
};

export default AnimatedButton;
