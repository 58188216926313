import React from "react";
import { Navigate } from "react-router";

import { PolicyholderPathnames } from "constants/policyholderPathnames";
import { useUserProfile } from "hooks/useUserProfile";

export function UnprotectedRoute({ children }: React.PropsWithChildren) {
  const userProfile = useUserProfile();

  if (userProfile.isPending) {
    return null;
  }

  if (userProfile.isSuccess) {
    return <Navigate to={{ pathname: PolicyholderPathnames.App }} />;
  }

  return <>{children}</>;
}
