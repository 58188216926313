import { Box } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { stepperSteps } from "constants/stepperSteps";
import { setMaxSubSteps } from "store/features/stepperSlice";
import { RootState } from "store/store";
import NewStepLabel from "./NewStepLabel";
import { Step } from "./types";

const StepConnector = () => {
  return (
    <Box
      sx={{
        height: "20px",
        width: "1px",
        borderRadius: "2px",
        ml: "20px",
        my: "10px",
        background: "linear-gradient(to bottom, transparent, white)",
      }}
    ></Box>
  );
};

const CustomStepper = () => {
  const location = useLocation();
  const isApplicationStep = location?.pathname?.split("/")[2] === "application";
  const { surveyModelFuncs } = useSelector(
    (state: RootState) => state.surveyJs
  );
  const { currentStep, currentSubStep } = useSelector(
    (state: RootState) => state.stepper
  );
  const { productSelection } = useSelector((state: RootState) => state.client);

  const dispatch = useDispatch();

  useEffect(() => {
    const subSteps = isApplicationStep
      ? surveyModelFuncs?.getPageTitles().map((s) => ({ label: s }))
      : stepperSteps()[1]?.subSteps;

    if (subSteps?.length) dispatch(setMaxSubSteps(subSteps.length));
  }, [dispatch, isApplicationStep, surveyModelFuncs]);

  return (
    <Box>
      {stepperSteps().map((step, index) => {
        if (productSelection?.length && step.label === "Start") return null;
        const subSteps = isApplicationStep
          ? surveyModelFuncs?.getPageTitles().map((s) => ({ label: s } as Step))
          : step?.subSteps;

        return (
          <Box key={step.label}>
            <NewStepLabel
              currentStep={
                productSelection?.length ? currentStep - 1 : currentStep
              }
              stepNum={productSelection?.length ? index - 1 : index}
              stepLabel={step.label}
            />
            {subSteps?.length &&
            stepperSteps()[currentStep].label === step.label ? (
              <StepConnector />
            ) : null}
            {stepperSteps()[currentStep]?.label === step.label &&
              subSteps?.map((s, ind) => {
                return (
                  <Box key={s.label}>
                    <NewStepLabel
                      outerDivStyles={{
                        height: "20px",
                        width: "20px",
                        ml: "10px",
                      }}
                      labelStyles={{ fontSize: "12px" }}
                      stepLabel={s.label}
                      stepNum={ind}
                      currentStep={currentSubStep}
                      isSubStep={true}
                    />
                    {ind !== subSteps?.length - 1 && <StepConnector />}
                  </Box>
                );
              })}
            {index !== stepperSteps().length - 1 && <StepConnector />}
          </Box>
        );
      })}
    </Box>
  );
};

export default CustomStepper;
