import { useState } from "react";

import { Close } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

import { LoadingButton } from "@mui/lab";
import { PolicyRequestSvg } from "assets/PolicyRequestSvg";
import { CardWithTitle } from "components/common/CardWithTitle";
import { LoadingSpinner } from "components/common/LoadingSpinner";
import { AgentInfo } from "components/policyholderComponents/policy/AgentInfo";
import { BrokerOnRecord } from "components/policyholderComponents/policy/BrokerOnRecord";
import { DocumentList } from "components/policyholderComponents/policy/DocumentList";
import { Insurer } from "components/policyholderComponents/policy/Insurer";
import { PolicyInfo } from "components/policyholderComponents/policy/PolicyInfo";
import { Coverages } from "components/policyholderComponents/quote/Coverages";
import { QueryKeys } from "constants/policyholder-query-keys";
import { PolicyService } from "policyholderApi/policy/policy";
import { useT } from "translation";
import { PolicyCancellation, PolicyRenewal, PolicyStatus } from "types/policy";
import { IS_ONCE_PH_PORTAL } from "config";

export function PolicyDetails() {
  const { t } = useT();
  const { policyId } = useParams();
  const [isRequestReceived, setIsRequestReceived] = useState(false);
  const policyDetailsQuery = useQuery({
    queryKey: QueryKeys.Policy.item(policyId || ""),
    enabled: !!policyId,
    queryFn: () => PolicyService.getPolicy(policyId || ""),
  });
  const cancelPolicyMutation = useMutation({
    mutationFn: () => PolicyService.policyAction(policyId as string, "cancel"),
    onSuccess: () => setIsRequestReceived(true),
  });
  const renewPolicyMutation = useMutation({
    mutationFn: () => PolicyService.policyAction(policyId as string, "renewal"),
    onSuccess: () => setIsRequestReceived(true),
  });

  if (policyDetailsQuery.isLoading || !policyDetailsQuery.data) {
    return <LoadingSpinner />;
  }

  const policy = policyDetailsQuery?.data?.data?.results;
  const isPolicyActive = [
    PolicyStatus.INITIATED,
    PolicyStatus.ACTIVE,
    PolicyStatus.DOCUMENTS_GENERATED,
    PolicyStatus.APPROVED,
  ].includes(policy?.status);

  const policyCancellationRequested = policy?.cancellations.some(
    (c: PolicyCancellation) => c.status === "requested"
  );

  const policyRenewalRequested = policy?.renewals.some(
    (r: PolicyRenewal) => r.status === "requested"
  );

  return (
    <Box>
      <Grid container>
        <Grid
          item
          md={8}
          sm={8}
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            paddingRight: 2,
          }}
        >
          <Coverages selectedQuote={policy?.quote_details} />
          {policy?.policy_number && !IS_ONCE_PH_PORTAL && (
            <Box mt={2} mb={4}>
              <LoadingButton
                sx={{ mr: 2 }}
                variant="outlined"
                color="error"
                disabled={!isPolicyActive || policyCancellationRequested}
                onClick={() => cancelPolicyMutation.mutate()}
                loading={cancelPolicyMutation.isPending}
              >
                {t("CANCEL_POLICY")}
              </LoadingButton>
              <LoadingButton
                variant="contained"
                color="primary"
                disabled={!isPolicyActive || policyRenewalRequested}
                onClick={() => renewPolicyMutation.mutate()}
                loading={renewPolicyMutation.isPending}
              >
                {t("RENEW_POLICY")}
              </LoadingButton>
            </Box>
          )}
          <Dialog
            open={isRequestReceived}
            onClose={() => setIsRequestReceived(false)}
          >
            <IconButton
              sx={{ alignSelf: "end", mt: 3, mr: 3 }}
              onClick={() => setIsRequestReceived(false)}
            >
              <Close />
            </IconButton>
            <DialogContent
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                px: 16,
                py: 8,
                mt: -4,
              }}
            >
              <PolicyRequestSvg />
              <Typography variant="h4" sx={{ my: 2 }}>
                Request received!
              </Typography>
              <Typography color="textSecondary">
                The insurance agent will get in touch with you.
              </Typography>
            </DialogContent>
          </Dialog>
        </Grid>
        <Grid
          item
          md={4}
          sm={4}
          xs={12}
          gap={2}
          sx={{
            display: "flex",
            flexDirection: "column",
            paddingLeft: 2,
          }}
        >
          <CardWithTitle
            title={t("POLICY")}
            body={
              <PolicyInfo policy={policyDetailsQuery?.data?.data.results} />
            }
          />
          <CardWithTitle
            title={t("INSURER")}
            body={
              <Insurer name={policyDetailsQuery?.data?.data.results?.product} />
            }
          />
          <CardWithTitle
            title={t("BROKER_ON_RECORD")}
            body={
              <BrokerOnRecord
                name={policyDetailsQuery?.data?.data?.results?.broker_on_record}
              />
            }
          />
          <CardWithTitle
            title={t("DOCUMENTS")}
            body={
              <DocumentList
                documents={policyDetailsQuery?.data?.data?.results?.documents}
              />
            }
          />
          {
            !IS_ONCE_PH_PORTAL &&
            <>
              <Typography variant="h5" sx={{ color: "primary.main" }}>
                {t("CONTACT_AGENT")}
              </Typography>
              <CardWithTitle
                title={t("AGENT_INFO")}
                body={
                  policy?.agent_details && (
                    <AgentInfo
                      agentEmail={policy?.agent_details?.email}
                      agentName={policy?.agent_details?.name}
                      agentPhone={policy?.agent_details?.mobile}
                    />
                  )
                }
              />
            </>}
        </Grid>
      </Grid>
    </Box>
  );
}
