import React from "react";
import { Navigate } from "react-router";

import { PolicyholderPathnames } from "constants/policyholderPathnames";
import { useUserProfile } from "hooks/useUserProfile";
import { IS_ONCE_PH_PORTAL } from "config";
import { getAccessToken } from "utils/token";
import { hasTokenExpired } from "utils/utils";

export function ProtectedRoute({ children }: React.PropsWithChildren) {
  const userProfile = useUserProfile();
  const token = getAccessToken();

  if (IS_ONCE_PH_PORTAL) {
    return (!!token && !hasTokenExpired(token)) ? <>{children}</> : <Navigate to={{ pathname: PolicyholderPathnames.V2Login }} />;
  }

  if (userProfile.isPending) {
    return null;
  }

  if (userProfile.isSuccess) {
    return <>{children}</>;
  }

  return <Navigate to={{ pathname: PolicyholderPathnames.LoginPage }} />;
}
