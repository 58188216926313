import {
  createClientSiaRecordAPI,
  createSiaApplicationAPI,
  fetchIncompleteApplicationAPI,
  fetchSiaApplicationAPI,
  updateClientSiaLatestConfigAPI,
  updateSiaApplicationAPI,
} from "api/siaApplication";
import { COUNTRY, CountryMapping, IS_BROKER_PORTAL } from "config";
import {
  STERE_APPLICATION_STARTED,
  STERE_JOURNEY_STARTED,
  STERE_PRODUCT_SELECTED,
} from "constants/webhookEvents";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { setCustomerId } from "store/features/clientSlice";
import {
  createSiaApplication,
  fetchSIAApplication,
  fetchSIAApplicationId,
  sendClientSIALatestConfig,
  setClientSIALatestConfig,
  setClientSiaId,
  setLoadingValue,
  setSIAApplication,
  setSIAApplicationId,
  setUpdatedSIAApplication,
  updateSIAApplication,
} from "store/features/surveyJsSlice";
import { fireWebhookEvent } from "store/features/webhooksSlice";
import { RootState } from "store/store";
import {
  commissions,
  getController,
  getPrefillData,
  getTimestamp,
} from "utils/utils";

const createProductCombo = (startPageSelection) => {
  const prodArray = startPageSelection?.map(
    (p) => p.product.product_identifier
  );
  return prodArray?.join(";").toUpperCase();
};

const createProductsArray = (availableProducts, selectedProducts) => {
  let products = [];
  availableProducts?.forEach((p) => {
    if (selectedProducts?.length) {
      if (selectedProducts?.includes(p.product.product_identifier)) {
        p.carrier.forEach((c) => {
          products.push(`${p?.product?.product_identifier}-${c.toUpperCase()}`);
        });
      }
    }
  });
  return products;
};

export const checkAndCreateProductsArray = (
  productSelection,
  startPageSelection,
  availableProducts
) => {
  let products = [];
  if (productSelection?.length) {
    products = createProductsArray(availableProducts, productSelection);
  } else {
    const startSelected = createProductCombo(startPageSelection);
    products = createProductsArray(availableProducts, startSelected);
  }
  return products;
};

export function* fetchClientSiaId() {
  try {
    const { siaApplicationId } = yield select(
      (state: RootState) => state.surveyJs
    );

    if (siaApplicationId) {
      yield put(fetchSIAApplication());
      return;
    }

    const {
      clientAPIKey,
      applicantId,
      productSelection,
      startPageSelection,
      availableProducts,
    } = yield select((state: RootState) => state.client);
    let productsArr = checkAndCreateProductsArray(
      productSelection,
      availableProducts,
      startPageSelection
    );
    let response = yield call(fetchIncompleteApplicationAPI, clientAPIKey, {
      external_id: applicantId,
      products: productsArr,
    });
    if (response?.status === 200) {
      if (!response?.data) {
        // if no application exists for this customer, create one
        yield put(createSiaApplication());
      } else {
        // else fetch the existing application
        yield put(setSIAApplicationId(response.data.sia_application_id));
        yield put(
          setClientSiaId({
            id: response.data.id,
            config: response.data.sia_application_latest_config,
          })
        );
        yield put(setCustomerId(response.data.customer_id));
        yield put(fetchSIAApplication());
      }
    } else {
      yield put(setLoadingValue(false));
    }
  } catch (e) {
    yield put(setLoadingValue(false));
    console.error(e);
  }
}

export function* createSiaApp() {
  try {
    const { portalAccount } = yield select((state: RootState) => state.auth);
    const { customerDetails } = yield select(
      (state: RootState) => state.customerDetails
    );
    const {
      availableProducts,
      applicantId,
      productSelection,
      startPageSelection,
      clientAPIKey,
    } = yield select((state: RootState) => state.client);
    let products = checkAndCreateProductsArray(
      productSelection,
      startPageSelection,
      availableProducts
    );
    let response = yield call(createSiaApplicationAPI, clientAPIKey, {
      country: CountryMapping[COUNTRY],
      products,
      agent_id: IS_BROKER_PORTAL ? portalAccount?.sia_user_id : null,
      external_id: IS_BROKER_PORTAL ? customerDetails.email : applicantId,
      commissions_config: IS_BROKER_PORTAL
        ? commissions.filter((c) => products.includes(c.product))
        : [],
    });
    let productCombo = productSelection?.length
      ? productSelection?.join(";").toUpperCase()
      : createProductCombo(startPageSelection);

    if (response?.status === 200) {
      yield put(setSIAApplicationId(response.data.id));
      const payload = {
        customer_system_id: applicantId,
        product_combo: productCombo,
        sia_application_id: response.data.id,
        sia_application_latest_config: {
          mainStep: "1",
          subStep: "1",
        },
      };
      yield put(fetchSIAApplication());
      let res = yield call(createClientSiaRecordAPI, clientAPIKey, payload);
      if (!IS_BROKER_PORTAL) {
        yield put(
          fireWebhookEvent({
            event_type: STERE_JOURNEY_STARTED,
            metadata: {
              applicantId: applicantId,
              timeStamp: getTimestamp(),
            },
          })
        );
        yield put(
          fireWebhookEvent({
            event_type: STERE_PRODUCT_SELECTED,
            metadata: {
              applicantId: applicantId,
              timeStamp: getTimestamp(),
              selectedProducts:
                createProductCombo(startPageSelection)?.split(";"),
            },
          })
        );
        yield put(
          fireWebhookEvent({
            event_type: STERE_APPLICATION_STARTED,
            metadata: {
              applicantId: applicantId,
              applicationId: response.data.id,
              timeStamp: getTimestamp(),
            },
          })
        );
      }
      yield put(
        setClientSiaId({
          id: res.data.results.id,
          config: res.data.results.sia_application_latest_config,
        })
      );
      yield put(setCustomerId(res.data.results.customer_id));
    } else {
      yield put(setLoadingValue(false));
    }
  } catch (e) {
    yield put(setLoadingValue(false));
    console.error(e);
  }
}

export function* fetchSiaApp() {
  try {
    const { clientAPIKey } = yield select((state: RootState) => state.client);
    const { siaApplicationId } = yield select(
      (state: RootState) => state.surveyJs
    );
    let response = yield call(
      fetchSiaApplicationAPI,
      clientAPIKey,
      siaApplicationId
    );
    if (response?.status === 200) {
      yield put(setSIAApplication(response.data));
    } else {
      yield put(setLoadingValue(false));
    }
  } catch (e) {
    yield put(setLoadingValue(false));
    console.error(e);
  }
}

export function* updateSiaApp() {
  try {
    const {
      clientAPIKey,
      availableProducts,
      productSelection,
      startPageSelection,
    } = yield select((state: RootState) => state.client);

    const { siaApplicationId, knockout } = yield select(
      (state: RootState) => state.surveyJs
    );
    const selectedQuotes = yield select(
      (state: RootState) => state.quotes.selectedQuotes
    );
    const [selectedQuote] = selectedQuotes;
    const productIdentifier = yield select(
      (state: RootState) =>
        state.client.availableProducts[0]?.product?.product_identifier
    );

    let products = checkAndCreateProductsArray(
      productSelection,
      startPageSelection,
      availableProducts
    );

    const surveyData = getPrefillData();
    // Remove the keys with spacing
    for (const key in surveyData) {
      key.trim();
      if (key.includes(" ")) {
        delete surveyData[key];
      }
    }
    delete surveyData.knockout;

    let payload = {
      country: CountryMapping[COUNTRY],
      products: products,
      params: { ...surveyData, products, ...(productIdentifier === "PET" && selectedQuote && knockout ? { ...(selectedQuote.premium?.additional_info?.plan_info ?? {}) } : {}) },
    };
    const controller = getController("updateSiaApp");
    controller?.abort();
    let response = yield call(
      updateSiaApplicationAPI,
      clientAPIKey,
      siaApplicationId,
      payload
    );

    if (response?.status === 200) {
      yield put(setUpdatedSIAApplication(response.data));
    }
  } catch (e) {
    yield put(setLoadingValue(false));
    console.error(e);
  }
}

export function* updateClientSiaLatestConfig(action) {
  try {
    const { clientAPIKey } = yield select((state: RootState) => state.client);
    const { clientSiaId } = yield select((state: RootState) => state.surveyJs);
    let p = {
      sia_application_latest_config: action.payload,
    };
    let response = yield call(
      updateClientSiaLatestConfigAPI,
      clientAPIKey,
      clientSiaId,
      p
    );
    if (response?.status === 200) {
      yield put(setClientSIALatestConfig(response.data));
    }
  } catch (e) {
    console.error(e);
  }
}

export default function* surveyJSSaga() {
  yield takeLatest(fetchSIAApplicationId.type, fetchClientSiaId);
  yield takeLatest(createSiaApplication.type, createSiaApp);
  yield takeLatest(fetchSIAApplication.type, fetchSiaApp);
  yield takeLatest(updateSIAApplication.type, updateSiaApp);
  yield takeLatest(sendClientSIALatestConfig.type, updateClientSiaLatestConfig);
}
