import React from "react";

import { ChevronLeft } from "@mui/icons-material";
import AnimatedButton from "components/custom/AnimatedButton";
import { useNavigate } from "react-router";
import { useT } from "translation";

export function ContentWithBackButton({
  children,
}: React.PropsWithChildren<unknown>) {
  const { t } = useT();
  const navigate = useNavigate();

  return (
    <div>
      <AnimatedButton sx={{ paddingLeft: 0, mb: 2 }} onClick={() => navigate(-1)}>
        <ChevronLeft /> {t("BACK")}
      </AnimatedButton>
      {children}
    </div>
  );
}
