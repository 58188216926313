import ChatIcon from "@mui/icons-material/Chat";
import CloseIcon from "@mui/icons-material/Close";
// import MinimizeIcon from "@mui/icons-material/Minimize";
import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { Navbar } from "react-chat-elements";
import { useSelector } from "react-redux";

import StereIcon from "assets/logos/StereIcon";
import { customStylesSelector } from "store/selectors/selectors";
import { useT } from "translation";
import QueryForm from "./QueryForm";

import AnimatedButton from "components/custom/AnimatedButton";
import { portalFontFamilyNameSelector } from "portalConfig/selectors";
import "react-chat-elements/dist/main.css";
import "./style.css";

interface ChatboxWraperProps {}

const ChatboxWraper: React.FunctionComponent<ChatboxWraperProps> = () => {
  const { t } = useT();
  const [querySubmitted, setQuerySubmitted] = useState(false);
  const fontFamily = useSelector(portalFontFamilyNameSelector);
  const [showChat, setShowChat] = useState(false);
  const [channelId, setChannelId] = useState<string>(
    `broker-pro-${new Date().getTime()}`
  );
  const customStyles = useSelector(customStylesSelector);

  const handleClose = () => {
    setShowChat(!showChat);
  };

  const onQuerySubmitted = () => {
    setQuerySubmitted(true);
  };

  return (
    <Box>
      <Box
        sx={{
          position: "absolute",
          bottom: "20px",
          right: "20px",
          height: "50px",
          width: "50px",
          borderRadius: "50%",
          backgroundColor: customStyles.muiTheme.palette.primary.main,
          border: "1px solid white",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          opacity: showChat ? 0.3 : 1,
          transition: "all 0.3s",
          pointerEvents: showChat ? "none" : "all",
          "&:hover": {
            transform: "rotate(10deg)",
          },
        }}
        onClick={() => {
          if (!channelId) {
            setChannelId(`broker-pro-${new Date().getTime()}`);
          }
          setShowChat(!showChat);
        }}
      >
        <ChatIcon style={{ color: "white" }} />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "550px",
        }}
        className={`chatBot ${showChat ? "showChat" : "hideChat"}`}
      >
        <Navbar
          className="stere-chat-navbar"
          left={
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <StereIcon fill="white" />
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontFamily: fontFamily,
                    color: "white",
                    fontWeight: "600",
                  }}
                >
                  STERE
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontFamily: fontFamily,
                    color: "white",
                    fontWeight: "500",
                  }}
                >
                  {t("CHATBOX.heading")}
                </Typography>
              </Box>
            </Box>
          }
          right={
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {/* <MinimizeIcon
                style={{
                  color: "white",
                  cursor: "pointer",
                  marginBottom: "13px",
                }}
                titleAccess="Minimize"
                onClick={() => setShowChat(!showChat)}
              /> */}
              <CloseIcon
                style={{ color: "white", cursor: "pointer" }}
                titleAccess="Close"
                onClick={() => handleClose()}
              />
            </Box>
          }
          type="dark"
        />
        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
            gap: "20px",
            p: "20px",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          {querySubmitted && (
            <>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontFamily: fontFamily,
                  m: "auto",
                }}
              >
                {t("CHATBOX.thanksText")}
                <br /> {t("CHATBOX.thanksDescText")}
                <br /> {t("CHATBOX.greatDayText")}
              </Typography>
              <AnimatedButton
                sx={{ textTransform: "none", mt: "30px" }}
                variant="contained"
                onClick={() => setQuerySubmitted(false)}
              >
                {t("CHATBOX.submitAnotherQueryButtonText")}
              </AnimatedButton>
            </>
          )}
          {!querySubmitted && (
            <>
              <Typography sx={{ fontSize: "14px" }}>
                {t("CHATBOX.hello")} <br /> {t("CHATBOX.helpText")} <br />
                {t("CHATBOX.assistText")}
              </Typography>
              <QueryForm onSuccess={onQuerySubmitted} />
            </>
          )}
        </Box>
        {/* Live chat section - temporarily commented */}
        {/* <ChatBox channelId={channelId} /> */}
      </Box>
    </Box>
  );
};

export default ChatboxWraper;
