import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Typography,
} from "@mui/material";
import { COUNTRY, FAIRFAX, PORTAL_NAME } from "config";
import { FunctionComponent } from "react";
import { useSelector } from "react-redux";

import { customStylesSelector } from "store/selectors/selectors";
import { useT } from "translation";
import {
  CountryToLanguageMapping,
  CurrencyPrefix,
  formatCurrency,
} from "utils/utils";

interface CoveragesProps {
  selectedQuote?: Quote;
  showHeading?: boolean;
}

const Coverages: FunctionComponent<CoveragesProps> = ({
  selectedQuote,
  showHeading,
}) => {
  const { t } = useT();
  const customStyles = useSelector(customStylesSelector);

  const AccordionSummaryTitle = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "5px",
        }}
      >
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 500,
            color: customStyles.muiTheme.palette.text.secondary,
          }}
        >
          {t("QUOTE_PAGE_COVERAGES")}
        </Typography>
        {!selectedQuote && (
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 500,
              color: customStyles.muiTheme.palette.text.secondary,
            }}
          >
            {t("QUOTE_PAGE_SELECT_ONE_PLAN")}
          </Typography>
        )}
      </Box>
    );
  };

  const coveragePremiumBreakdown = selectedQuote?.premium?.coverage_info || {};

  const Details = selectedQuote?.coverage?.sections?.map((section) => (
    <Accordion
      defaultExpanded
      key={section?.title?.default}
      sx={{
        borderRadius: "12px !important",
        boxShadow: "none",
        border: "none",
        "&.MuiAccordion-root:before": {
          backgroundColor: "transparent",
        },
        "&.Mui-expanded": {
          margin: "0px",
        },
        backgroundColor: customStyles.primaryCard.backgroundColor,
      }}
    >
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon
            style={{
              color: customStyles.muiTheme.palette.text.secondary,
            }}
          />
        }
        sx={{
          padding: "0px 16px",
          margin: "0px",
          minHeight: "0px",
          ".MuiAccordionSummary-content": {
            margin: "0px",
            color: customStyles.muiTheme.palette.text.secondary,
          },
          "&.Mui-expanded": {
            minHeight: "0px",
          },
        }}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Box
          sx={{
            display: "flex",
            gap: "5px",
            flexDirection: "column",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 600,
              color: customStyles.muiTheme.palette.text.secondary,
            }}
          >
            {section.title?.[CountryToLanguageMapping[COUNTRY]] ??
              section.title?.default}
          </Typography>
          {section?.description?.default && (
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 500,
                color: customStyles.muiTheme.palette.text.tertiary,
              }}
            >
              {section.description?.[CountryToLanguageMapping[COUNTRY]] ??
                section.description?.default}
            </Typography>
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        {section.children.map((ch, index) => (
          <Box
            key={index}
            sx={{
              mt: "20px",
              ml: "10px",
              display: "flex",
              gap: "5px",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{
                fontSize: "15px",
                fontWeight: 600,
                color: customStyles.muiTheme.palette.text.secondary,
              }}
            >
              {ch?.label?.[CountryToLanguageMapping[COUNTRY]] ??
                ch.label.default}
            </Typography>
            <Divider />
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 500,
                color: customStyles.muiTheme.palette.text.tertiary,
              }}
            >
              {ch?.details?.[CountryToLanguageMapping[COUNTRY]] ??
                ch.details.default}
            </Typography>
            <Box sx={{ display: "flex", gap: "5px" }}>
              {/* TODO: Need to analyse this for all products */}
              {ch.limit &&
                ch.limit?.default !== "0" &&
                Number(ch.limit?.default) !== 0 && (
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 500,
                      color: customStyles.muiTheme.palette.text.secondary,
                    }}
                  >
                    {COUNTRY === "br"
                      ? "Limite:"
                      : COUNTRY === "mx"
                      ? "Límite"
                      : "Limit:"}
                  </Typography>
                )}
              {Number(
                ch.limit?.[CountryToLanguageMapping[COUNTRY]] ??
                  ch.limit?.default
              ) !== 0 && (
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 500,
                    color: customStyles.muiTheme.palette.text.tertiary,
                  }}
                >
                  {ch.limit?.[CountryToLanguageMapping[COUNTRY]]
                    ? `${
                        PORTAL_NAME === FAIRFAX ? `${CurrencyPrefix} ` : ""
                      }${formatCurrency(
                        ch.limit?.[
                          CountryToLanguageMapping[COUNTRY]
                        ] as unknown as number
                      )}`
                    : ch.limit?.default}
                </Typography>
              )}
              {PORTAL_NAME === FAIRFAX &&
                coveragePremiumBreakdown[ch.code] !== 0 && (
                  <>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 500,
                        color: customStyles.muiTheme.palette.text.secondary,
                      }}
                    >
                      {COUNTRY === "br" ? "Prêmio:" : "Premium:"}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 500,
                        color: customStyles.muiTheme.palette.text.tertiary,
                      }}
                    >
                      {`${CurrencyPrefix} ${formatCurrency(
                        coveragePremiumBreakdown[ch.code]
                      )}`}
                    </Typography>
                  </>
                )}
            </Box>
          </Box>
        ))}
      </AccordionDetails>
    </Accordion>
  ));

  const TotalAnnualCoverage = () => {
    if (!selectedQuote?.premium?.additional_info?.total_coverage) return null;
    return (
      <Box
        sx={{
          px: "20px",
          display: "flex",
          flexDirection: "column",
          gap: "5px",
          mb: "20px",
        }}
      >
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 600,
            color: customStyles.muiTheme.palette.text.secondary,
          }}
        >
          {COUNTRY === "br"
            ? "Cobertura Total Anual"
            : COUNTRY === "mx"
            ? "Cobertura Anual Total"
            : "Total Annual Coverage"}
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: 600,
            color: customStyles.muiTheme.palette.text.tertiary,
          }}
        >
          {selectedQuote?.premium?.additional_info?.total_coverage}
        </Typography>
      </Box>
    );
  };

  if (!showHeading) {
    return (
      <>
        {/* <TotalAnnualCoverage /> */}
        {Details}
      </>
    );
  }

  return (
    <Accordion
      defaultExpanded
      sx={{
        width: "100%",
        borderRadius: "12px !important",
        boxShadow: "none",
        border: "none",
        "&.MuiAccordion-root:before": {
          backgroundColor: "transparent",
        },
        backgroundColor: customStyles.primaryCard.backgroundColor,
      }}
      disabled={!selectedQuote}
    >
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon
            style={{
              color: customStyles.muiTheme.palette.text.secondary,
            }}
          />
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <AccordionSummaryTitle />
      </AccordionSummary>
      <AccordionDetails sx={{ gap: "30px" }}>
        {/* <TotalAnnualCoverage /> */}
        {Details}
      </AccordionDetails>
    </Accordion>
  );
};

Coverages.defaultProps = { showHeading: true };

export default Coverages;
