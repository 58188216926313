import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { customStylesSelector } from "store/selectors/selectors";

interface LogoWithTextProps {
  logo: JSX.Element;
  text: string;
}

const LogoWithText: React.FunctionComponent<LogoWithTextProps> = ({
  logo,
  text,
}) => {
  const customStyles = useSelector(customStylesSelector);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        borderRadius: "12px",
        gap: "10px",
      }}
    >
      <Box
        sx={{
          width: "30px",
          height: "30px",
          padding: "10px",
          borderRadius: "10px",
          backgroundColor: customStyles.secondaryCard.backgroundColor,
        }}
      >
        {logo}
      </Box>
      <Typography
        sx={{
          fontSize: "14px",
          fontWeight: 500,
          color: customStyles.muiTheme.palette.text.secondary,
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};

export default LogoWithText;
