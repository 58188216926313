import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import React from "react";
import { Question, Serializer } from "survey-core";
import { SurveyQuestionBoolean } from "survey-react-ui";

export const CONFIRMATION_CHECKBOX = "confirmation-checkbox";

export class ConfirmationCheckboxElement extends Question {
  getType() {
    return CONFIRMATION_CHECKBOX;
  }
}

// Add question type metadata for further serialization into JSON
Serializer.addClass(
  CONFIRMATION_CHECKBOX,
  [
    {
      name: "confirmation-checkbox:boolean",
      category: "general",
      visibleIndex: 1,
    },
  ],
  function () {
    return new ConfirmationCheckboxElement("");
  },
  "question"
);

export class ConfirmationCheckboxQuestion extends SurveyQuestionBoolean {
  get value() {
    return this.question.value;
  }

  handleOnChange = (e) => {
    this.question.value = e.target.checked;
  };

  handleOnClick = (e) => {
    this.question.value = e.target.checked;
  };

  get style(): React.CSSProperties {
    return this.question.getPropertyValue("readOnly") ||
      this.question.isDesignMode
      ? { pointerEvents: "none" }
      : {};
  }

  renderElement() {
    return (
      <FormControlLabel
        style={this.style}
        label={
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: "12px",
              color: "#706F6F",
              display: "block",
              letterSpacing: "0.01em",
              whiteSpace: "normal",
            }}
          >
            {this.question.title}
          </Typography>
        }
        control={
          <Checkbox
            checked={this.question.value}
            onClick={this.handleOnClick}
            onChange={this.handleOnChange}
          />
        }
      />
    );
  }
}
