import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { toast } from "react-hot-toast";

import {
  ServiceErrorResponse,
  ServiceSuccessResponse,
} from "policyholderApi/types";
import {
  unwrapServiceErrorMessage,
  unwrapServiceSuccessMessage,
} from "utils/api-error-response";

type UseMutationWithToastOptions<TData, TError, TVariables, TContext> =
  UseMutationOptions<TData, TError, TVariables, TContext> & {
    disableSuccessToast?: boolean;
    disableErrorToast?: boolean;
  };

export function useMutationWithToast<
  TData = unknown,
  TError = ServiceErrorResponse,
  TVariables = void,
  TContext = unknown
>(
  options: UseMutationWithToastOptions<TData, TError, TVariables, TContext>
): UseMutationResult<TData, TError, TVariables, TContext> {
  const {
    disableSuccessToast = false,
    disableErrorToast = false,
    ...nativeReactQueryOptions
  } = options;

  return useMutation({
    ...nativeReactQueryOptions,
    onSuccess: (data: TData, variables: TVariables, context: TContext) => {
      const message = unwrapServiceSuccessMessage(
        data as unknown as ServiceSuccessResponse
      );
      if (message && !disableSuccessToast) {
        toast.success(message);
      }

      return options?.onSuccess?.(data, variables, context);
    },
    onError: (
      error: TError,
      variables: TVariables,
      context: TContext | undefined
    ) => {
      const message = unwrapServiceErrorMessage(
        error as unknown as ServiceErrorResponse
      );

      if (message && !disableErrorToast) {
        toast.error(message);
      }

      return options?.onError?.(error, variables, context);
    },
  });
}
