import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import * as Yup from "yup";

import LoginBg from "assets/images/login-img.png";
import StereLogo from "assets/logos/StereLogo";
import AnimatedButton from "components/custom/AnimatedButton";
import { animateRouteProps } from "constants/common";
import { resetPassword, signIn } from "store/features/authSlice";
import { authSelector, customStylesSelector } from "store/selectors/selectors";
import { RootState } from "store/store";
import { useT } from "translation";

const Login = () => {
  const { t } = useT();
  const location = useLocation();

  const { errorMessage, portalAccount, loading } = useSelector(
    (state: RootState) => state.auth
  );
  const validationSchema = Yup.object().shape({
    email: Yup.string().email(t("INVALID_EMAIL")).required(t("EMAIL_REQUIRED")),
    password: Yup.string().required(t("PASSWORD_REQUIRED")),
  });

  const initialValues = {
    email: "",
    password: "",
  };
  const customStyles = useSelector(customStylesSelector);
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const handleTogglePasswordVisibility = () => setShowPassword((show) => !show);

  const handleSubmit = async (values) => {
    dispatch(
      signIn({
        email: values.email,
        password: values.password,
      })
    );
  };

  const ForgotPassword = () => {
    const customStyles = useSelector(customStylesSelector);
    const { forgotPasswordEmailSent } = useSelector(authSelector);

    const forgotPasswordValidationSchema = Yup.object().shape({
      email: Yup.string()
        .email(t("INVALID_EMAIL"))
        .required(t("EMAIL_REQUIRED")),
    });

    const forgotPasswordInitialValues = {
      email: "",
    };

    const handleForgotPasswordSubmit = (values) => {
      dispatch(resetPassword({ email: values.email }));
    };

    const EmailSentComponent = () => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          mt: "40%",
        }}
      >
        <Typography
          sx={{
            fontWeight: 600,
            textAlign: "center",
            fontSize: "18px",
            color: customStyles.muiTheme.palette.primary.main,
          }}
        >
          Verify E-Mail
        </Typography>
        <Typography
          sx={{
            mb: "25px",
            fontWeight: 500,
            textAlign: "center",
            fontSize: "12px",
            color: customStyles.muiTheme.palette.text.tertiary,
          }}
        >
          Avoid getting locked out.
        </Typography>
        <Typography
          sx={{
            fontWeight: 400,
            textAlign: "center",
            fontSize: "14px",
            color: customStyles.muiTheme.palette.text.secondary,
          }}
        >
          We have sent a password recovery instructions to your email.
        </Typography>
      </Box>
    );

    const ForgotPasswordComponent = () => (
      <Box sx={{ mt: "80px" }}>
        <Typography
          sx={{
            mb: "10px",
            fontWeight: 600,
            textAlign: "center",
            fontSize: "18px",
            color: customStyles.muiTheme.palette.primary.main,
          }}
        >
          {t("FORGOT_PASSWORD")}
        </Typography>
        <Typography
          sx={{
            mb: "30px",
            fontWeight: 400,
            textAlign: "center",
            fontSize: "14px",
            color: customStyles.muiTheme.palette.text.secondary,
          }}
        >
          {t("FORGOT_PASSWORD_MESSAGE")}
        </Typography>
        <Formik
          initialValues={forgotPasswordInitialValues}
          validationSchema={forgotPasswordValidationSchema}
          validateOnMount={true}
          onSubmit={handleForgotPasswordSubmit}
        >
          {({ isValid }) => (
            <Form
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box>
                <Field
                  as={TextField}
                  type="email"
                  label={t("EMAIL_LABEL")}
                  name="email"
                  style={{
                    width: "100%",
                    borderRadius: "10px",
                  }}
                  InputProps={{
                    style: {
                      borderRadius: "12px",
                    },
                  }}
                />
                <ErrorMessage
                  className="error-message"
                  name="email"
                  component="div"
                />
              </Box>
              <AnimatedButton
                type="submit"
                sx={{ textTransform: "none", mt: "30px" }}
                disabled={!isValid}
                variant="contained"
              >
                {t("RECOVER_PASSWORD")}
              </AnimatedButton>
              <AnimatedButton
                onClick={() => setShowForgotPassword(false)}
                sx={{ textTransform: "none", mt: "20px" }}
              >
                {t("BACK")}
              </AnimatedButton>
            </Form>
          )}
        </Formik>
      </Box>
    );

    return (
      <Box {...animateRouteProps}>
        <Grid className="loginForm" container>
          <Grid
            item
            md={6}
            sm={7}
            xs={12}
            sx={{
              height: "100vh",
              p: "20px",
              pt: "40px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box>
              <StereLogo />
            </Box>
            {forgotPasswordEmailSent ? (
              <EmailSentComponent />
            ) : (
              <ForgotPasswordComponent />
            )}
          </Grid>
          <Grid
            item
            md={6}
            sm={5}
            sx={{
              height: "100vh",
              position: "relative",
            }}
          >
            <Box
              style={{
                width: "100%",
                height: "100%",
                overflow: "hidden",
              }}
            >
              <img
                src={LoginBg}
                alt="Login Background"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
              <Box
                style={{
                  width: "100%",
                  height: "100%",
                  overflow: "hidden",
                  backgroundColor: customStyles.muiTheme.palette.primary.main,
                  position: "absolute",
                  top: 0,
                  left: 0,
                  opacity: 0.4,
                }}
              ></Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  };

  if (portalAccount) {
    if (portalAccount?.is_temporary_password) {
      return (
        <Navigate
          to="/create-new-password"
          state={{ from: location }}
          replace
        />
      );
    } else {
      return <Navigate to="/home/dashboard" replace />;
    }
  }

  if (showForgotPassword) {
    return <ForgotPassword />;
  }

  return (
    <Box {...animateRouteProps}>
      <Grid className="loginForm" container>
        <Grid
          item
          md={6}
          sm={7}
          xs={12}
          sx={{
            height: "100vh",
            p: "20px",
            pt: "40px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box>
            <StereLogo />
          </Box>
          <Box sx={{ mt: "80px" }}>
            <Typography
              sx={{
                mb: "10px",
                fontWeight: 600,
                textAlign: "center",
                fontSize: "18px",
                color: customStyles.muiTheme.palette.primary.main,
              }}
            >
              {t("SIGN_IN")}
            </Typography>
            <Typography
              sx={{
                mb: "30px",
                fontWeight: 400,
                textAlign: "center",
                fontSize: "14px",
                color: customStyles.muiTheme.palette.text.secondary,
              }}
            >
              {t("SIGN_IN_MESSAGE")}
            </Typography>
            <Typography
              data-cy="login-error"
              sx={{
                mb: "30px",
                fontWeight: 400,
                textAlign: "center",
                fontSize: "14px",
                color: "red",
              }}
            >
              {errorMessage}
            </Typography>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              validateOnMount={true}
              onSubmit={handleSubmit}
            >
              {({ isValid }) => (
                <Form
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box>
                    <Field
                      as={TextField}
                      type="email"
                      label={t("EMAIL_LABEL")}
                      name="email"
                      data-cy="login-email-input"
                      style={{
                        width: "100%",
                      }}
                      InputProps={{
                        style: {
                          borderRadius: "12px",
                        },
                      }}
                    />
                    <ErrorMessage
                      className="error-message"
                      name="email"
                      component="div"
                    />
                  </Box>

                  <Box
                    sx={{
                      mt: "30px",
                    }}
                  >
                    <Field
                      as={TextField}
                      type={showPassword ? "text" : "password"}
                      label={t("PASSWORD_LABEL")}
                      data-cy="login-password-input"
                      name="password"
                      style={{
                        color: "black",
                        width: "100%",
                        borderRadius: "12px",
                      }}
                      InputProps={{
                        style: {
                          borderRadius: "12px",
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleTogglePasswordVisibility}
                              edge="end"
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <ErrorMessage
                      className="error-message"
                      name="password"
                      component="div"
                    />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      mt: "5px",
                    }}
                  >
                    <FormControlLabel
                      control={<Checkbox size="small" />}
                      label={
                        <Typography
                          sx={{
                            fontSize: "14px",
                          }}
                        >
                          {t("REMEMBER_ME")}
                        </Typography>
                      }
                    />

                    <Typography
                      px={2}
                      onClick={() => setShowForgotPassword(true)}
                      sx={{
                        color: customStyles.input.color,
                        cursor: "pointer",
                        textDecoration: "underline",
                        fontSize: "14px",
                        textUnderlineOffset: "3px",
                      }}
                    >
                      {t("FORGOT_PASSWORD")}
                    </Typography>
                  </Box>

                  <AnimatedButton
                    sx={{ ml: "0px", mt: "30px", textTransform: "none" }}
                    disabled={!isValid}
                    type={"submit"}
                    data_cy="login-submit-btn"
                    isLoading={loading}
                    variant="contained"
                  >
                    {t("SIGN_IN")}
                  </AnimatedButton>
                </Form>
              )}
            </Formik>
          </Box>
        </Grid>
        <Grid
          item
          md={6}
          sm={5}
          sx={{
            height: "100vh",
            position: "relative",
          }}
        >
          <Box
            style={{
              width: "100%",
              height: "100%",
              overflow: "hidden",
            }}
          >
            <img
              src={LoginBg}
              alt="Login Background"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
            <Box
              style={{
                width: "100%",
                height: "100%",
                overflow: "hidden",
                backgroundColor: customStyles.muiTheme.palette.primary.main,
                position: "absolute",
                top: 0,
                left: 0,
                opacity: 0.4,
              }}
            ></Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Login;
