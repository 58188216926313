import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { InferType } from "yup";

import { loginCredSchema } from "constants/policyholder-validation-schema";
import { PolicyholderPathnames } from "constants/policyholderPathnames";
import { useMutationWithToast } from "hooks/useMutationWithToast";
import { useUserProfile } from "hooks/useUserProfile";
import { AuthService } from "policyholderApi/auth/auth";
import { useT } from "translation";
import { setAccessToken, setIDToken, setRefreshToken } from "utils/token";

type LoginCredSchema = InferType<typeof loginCredSchema>;

export function LoginPage() {
  const userProfile = useUserProfile();
  const { t } = useT();
  const loginForm = useForm({
    resolver: yupResolver(loginCredSchema),
  });

  const loginMutation = useMutationWithToast({
    mutationFn: (cred: LoginCredSchema) =>
      AuthService.signInAPI({ email: cred.email, password: cred.password }),

    onSuccess: (res) => {
      setAccessToken(res.data.results.access_token);
      setRefreshToken(res.data.results.refresh_token);
      setIDToken(res.data.results.id_token);
      userProfile.refetch();
    },

    onError: () => {
      loginForm.setError("root", {
        message: "Invalid credentials",
        type: "validate",
      });
    },
  });

  const onLoginValid = (fieldValue: LoginCredSchema) => {
    loginMutation.mutate(fieldValue);
  };

  return (
    <form onSubmit={loginForm.handleSubmit(onLoginValid)}>
      <Typography
        variant="h5"
        marginBottom={4}
        color="textSecondary"
        textAlign="center"
      >
        {t("SIGN_IN_MESSAGE")}
      </Typography>
      <TextField
        {...loginForm.register("email")}
        helperText={loginForm.formState.errors.email?.message}
        error={!!loginForm.formState.errors.email?.message}
        type="email"
        variant="outlined"
        label={t("EMAIL")}
        fullWidth
        sx={{ mb: 3 }}
      />
      <TextField
        {...loginForm.register("password")}
        type="password"
        variant="outlined"
        label={t("PASSWORD")}
        fullWidth
        helperText={loginForm.formState.errors.password?.message}
        error={!!loginForm.formState.errors.password?.message}
      />
      {loginForm.formState.errors.root?.message ? (
        <Typography color="error" textAlign="center" sx={{ mt: 2 }}>
          {loginForm.formState.errors.root?.message}
        </Typography>
      ) : null}
      <Box
        width="100%"
        sx={{
          mt: 3,
          mb: 3,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <FormControlLabel
          label={t("REMEMBER_ME")}
          control={<Checkbox checked />}
        />
        <Link to={{ pathname: PolicyholderPathnames.ForgotPassword }}>
          {t("FORGOT_PASSWORD_LINK")}
        </Link>
      </Box>
      <LoadingButton
        color="primary"
        variant="contained"
        type="submit"
        fullWidth
        loading={loginMutation.isPending}
      >
        {t("SIGN_IN")}
      </LoadingButton>
    </form>
  );
}
