import { Quote } from "./quote";

export enum PolicyStatus {
  INITIATED = "initiated",
  DOCUMENTS_GENERATED = "document_generated",
  ACTIVE = "active",
  INACTIVE = "inactive",
  REQUESTED = "requested",
  APPROVED = "approved",
  CANCELLED = "cancelled",
  RENEWAL_IN_PROGRESS = "renewal-in-progress",
  RENEWED = "renewed",
}

export type AgentDetails = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

export type PolicyCancellation = {
  id: string;
  object: string;
  policy_id: string;
  status: string;
  mode: string;
  created_at: string;
  updated_at: string;
  reasons: string[];
  cancelled_at: string;
};

export type PolicyRenewal = {
  id: string;
  object: null;
  policy_id: string;
  status: string;
  mode: string;
  created_at: string;
  updated_at: string;
  renewed_at: string;
  renewed_policy_id: string;
};

export type Policy = {
  id: string;
  broker_on_record: string;
  policy_number: string;
  start_date: string | null;
  end_date: string | null;
  status: PolicyStatus;
  product: string;
  agent_details?: AgentDetails;
  documents?: StereDocument[] | null;
  quote_details?: Quote;
  cancellations: PolicyCancellation[];
  renewals: PolicyRenewal[];
};
