import React from 'react';

import { Box, SxProps, Theme } from '@mui/material';

type AppContentContainerProps = {
  style?: React.CSSProperties;
  sx?: SxProps<Theme>;
};

export function AppContentContainer({
  children,
  style,
  sx,
}: React.PropsWithChildren<AppContentContainerProps>) {
  return (
    <Box
      style={style}
      sx={{
        ...sx,
        py: { xs: '16px', sm: '16px', md: '40px', lg: '40px' },
        px: { xs: '16px', sm: '16px', md: '120px', lg: '240px' },
      }}>
      {children}
    </Box>
  );
}
