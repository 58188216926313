import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { ToastCtx } from "components/Toast/ToastProvider";
import AnimatedButton from "components/custom/AnimatedButton";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { inviteMemberApi } from "modules/Agents/api";
import { ICreateMemberInvite } from "modules/Agents/types";
import { useContext } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { useT } from "translation";
import * as Yup from "yup";

type Props = {
  isInviteDialogOpen: boolean;
  setIsInviteDialogOpen: (val: boolean) => void;
};
const defaultInviteValues = { name: "", email: "" };

function AgentInviteDialog({
  isInviteDialogOpen,
  setIsInviteDialogOpen,
}: Props) {
  const { t } = useT();
  const { setToast } = useContext(ToastCtx);
  const accountId = useSelector(
    (state: RootState) => state.auth.v2Login.activeAccount.id
  );

  const inviteUserMutation = useMutation<unknown, unknown, ICreateMemberInvite>(
    {
      mutationFn: (data) => inviteMemberApi(data, accountId),
      onSuccess: (response) => {
        // TODO:
        // invalidate get invited member API
        // set Success toast for invitation
        setToast({
          severity: "success",
          text: t("INVITATION_SENT"),
        });
      },
      onError: ({ error }) => {
        if (error.code === "insufficient_permissions") {
          return setToast({
            severity: "error",
            text: "You don't sufficient permissions",
          });
        }
        if (error.code === "user_already_member") {
          return setToast({
            severity: "error",
            text: "Invite is already sent",
          });
        }
        setToast({
          severity: "error",
          text: t("SOMETHING_WENT_WRONG"),
        });
      },
    }
  );

  const inviteValidationSchema = Yup.object().shape({
    email: Yup.string().email(t("INVALID_EMAIL")).required(t("EMAIL_REQUIRED")),
  });

  const handleSendInvite = (values) =>
    inviteUserMutation.mutate({
      email: values.email,
      roles: ["agent"],
    });

  return (
    <Dialog
      open={isInviteDialogOpen}
      onClose={() => setIsInviteDialogOpen(false)}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        <Typography color="primary" variant="h5">
          {t("AGENTS_PAGE.inviteDialogHeading")}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={defaultInviteValues}
          validationSchema={inviteValidationSchema}
          validateOnMount={true}
          onSubmit={handleSendInvite}
        >
          {({ isValid }) => (
            <Form
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "12px",
                  flexDirection: "column",
                  marginTop: "16px",
                }}
              >
                <Box>
                  <Field
                    as={TextField}
                    type="email"
                    label={t("EMAIL_LABEL")}
                    name="email"
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "12px",
                      },
                    }}
                  />
                  <ErrorMessage
                    className="error-message"
                    name="email"
                    component="div"
                  />
                </Box>

                <Box
                  sx={{ alignSelf: "flex-end", display: "flex", gap: "8px" }}
                >
                  <AnimatedButton
                    color="primary"
                    variant="contained"
                    type="submit"
                    isLoading={inviteUserMutation.isPending}
                  >
                    {t("AGENTS_PAGE.send")}
                  </AnimatedButton>
                  <AnimatedButton
                    color="primary"
                    variant="outlined"
                    onClick={() => setIsInviteDialogOpen(false)}
                  >
                    {t("CANCEL")}
                  </AnimatedButton>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}

export default AgentInviteDialog;
