import { Box, CircularProgress, Typography } from "@mui/material";
import { FunctionComponent, useEffect, useState } from "react";

import { getPolicyAPI } from "api/siaApplication";
import { useT } from "translation";
import EsignPending from "./sections/EsignPending";
import EsignSuccess from "./sections/EsignSuccess";

interface VerifyESignProps {}

const VerifyESign: FunctionComponent<VerifyESignProps> = () => {
  const [policyDocuSignStatus, setPolicyDocuSignStatus] = useState<string>();
  const [intervalInstance, setIntervalInstance] = useState<NodeJS.Timer>();

  const { t } = useT();

  const fetchPolicy = async () => {
    try {
      const res = await getPolicyAPI(
        sessionStorage.getItem("clientAPIKey"),
        sessionStorage.getItem("policyId")
      );
      if (
        res.data.esign_info?.envelope_status &&
        res.data.esign_info?.envelope_status !== "sent"
      ) {
        setPolicyDocuSignStatus(res.data.esign_info?.envelope_status);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (policyDocuSignStatus) {
      sessionStorage.removeItem("envelopId");
      clearInterval(intervalInstance);
    }
  }, [policyDocuSignStatus]);

  const fetchPolicyInIntervals = () => {
    const interval = setInterval(() => {
      fetchPolicy();
    }, 5000);
    setIntervalInstance(interval);
  };

  useEffect(() => {
    if (sessionStorage.getItem("policyId") && !intervalInstance) {
      fetchPolicyInIntervals();
    }
  }, [sessionStorage.getItem("policyId")]);

  useEffect(() => {
    // Update state when sessionStorage changes
    const handleStorageChange = () => {
      if (sessionStorage.getItem("policyId") && !intervalInstance) {
        fetchPolicyInIntervals();
      }
    };

    // Listen to storage changes
    window.addEventListener("storage", handleStorageChange);

    return () => {
      // Clean up listener
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  if (!policyDocuSignStatus) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "80vh",
        }}
      >
        <Typography sx={{ mb: "20px" }}>
          {t("FETCHING_DOCU_SIGN_STATUS")}
        </Typography>
        <CircularProgress />
      </Box>
    );
  }

  return policyDocuSignStatus === "completed" ? (
    <EsignSuccess />
  ) : (
    <EsignPending />
  );
};

export default VerifyESign;
