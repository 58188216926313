import { createBrowserRouter, Navigate } from "react-router-dom";

import { AccountPage } from "pages/app/Account/AccountPage";
import { AppLayout } from "pages/app/AppLayout";
import { PolicyDetailLayout } from "pages/app/MyPolicies/:policyId/PolicyDetailLayout";
import { PolicyDetails } from "pages/app/MyPolicies/:policyId/PolicyDetails/PolicyDetails";
import { PolicyDocumentPage } from "pages/app/MyPolicies/:policyId/PolicyDocumentPage/PolicyDocumentPage";
import { MyPoliciesLayout } from "pages/app/MyPolicies/MyPoliciesLayout";
import { MyPoliciesPage } from "pages/app/MyPolicies/MyPoliciesPage/MyPoliciesPage";
import { AuthLayout } from "pages/auth/AuthLayout";
import { ForgetPasswordPage } from "pages/auth/ForgetPasswordPage/ForgetPasswordPage";
import { LoginPage } from "pages/auth/LoginPage/LoginPage";
import { ResetPasswordPage } from "pages/auth/ResetPasswordPage/ResetPasswordPage";
import { VerificationEmailSentPage } from "pages/auth/VerificationEmailSentPage/VerificationEmailSentPage";
import { PageLayout } from "pages/PageLayout";
import { V2OTPPage } from "pages/auth/V2OTPPage/V2OTPPage";
import { NewLoginPage } from "pages/auth/NewLoginPage/NewLoginPage";
import { IS_ONCE_PH_PORTAL } from "config";



let policyholderAuthChildren = [
  {
    path: "",
    element: <Navigate to={{ pathname: "login" }} />,
  },
  {
    path: "login",
    Component: LoginPage,
  },
  {
    path: "forget-password",
    Component: ForgetPasswordPage,
  },
  {
    path: "reset-password",
    Component: ResetPasswordPage,
  },
  {
    path: "verification-email-sent",
    Component: VerificationEmailSentPage,
  },
];

if (IS_ONCE_PH_PORTAL) {
  policyholderAuthChildren.push(
    {
      path: "v2-login",
      Component: NewLoginPage,
    },
    {
      path: "v2-verify-otp",
      Component: V2OTPPage,
    }
  );
}

export const policyholderRouter = createBrowserRouter([
  {
    path: "/",
    Component: PageLayout,
    children: [
      {
        path: "",
        element: <Navigate to={{ pathname: "app" }} />,
      },
      {
        path: "app",
        Component: AppLayout,
        children: [
          {
            path: "",
            element: <Navigate to={{ pathname: "my-policies" }} />,
          },
          {
            path: "my-policies",
            Component: MyPoliciesLayout,
            children: [
              {
                path: "",
                Component: MyPoliciesPage,
              },
              {
                path: ":policyId",
                Component: PolicyDetailLayout,
                children: [
                  { path: "", Component: PolicyDetails },
                  {
                    path: "documents/:documentId",
                    Component: PolicyDocumentPage,
                  },
                ],
              },
            ],
          },
          {
            path: "account",
            Component: AccountPage,
          },
        ],
      },
      {
        path: "auth",
        Component: AuthLayout,
        children: policyholderAuthChildren
      },
    ],
  },
]);
