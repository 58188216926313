import { TableCell, TableRow } from "@mui/material";
import { FunctionComponent } from "react";

interface TableRowEmptyProps {}

const TableRowEmpty: FunctionComponent<TableRowEmptyProps> = () => {
  return (
    <TableRow>
      <TableCell />
    </TableRow>
  );
};

export default TableRowEmpty;
