import { useContext } from "react";

import SegmentIcon from "@mui/icons-material/Segment";
import { LoadingButton } from "@mui/lab";
import { Box, Tab, Tabs } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useLocation, useNavigate } from "react-router";

import { AppLogo } from "components/policyholderComponents/icons/AppLogo";
import { useMedia } from "hooks/useMedia";
import { useUserProfile } from "hooks/useUserProfile";
import { AuthService } from "policyholderApi/auth/auth";
import { useT } from "translation";
import { AppContentContainer } from "../AppContentContainer";
import { LanguagePopover } from "./LanguagePopover";
import { PolicyholderDispatchContext } from "contexts/PolicyholderContext";
import { IS_ONCE_PH_PORTAL } from "config";
import { removeAllTokens } from "utils/token";
import { PolicyholderPathnames } from "constants/policyholderPathnames";

function NavigationTabs() {
  const { t } = useT();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "20px",
      }}
    >
      <Tabs
        value={location.pathname.split("/")?.[2]}
        onChange={(_e, value) => navigate({ pathname: value })}
        aria-label="basic tabs example"
      >
        <Tab
          label={t("MY_POLICIES")}
          value="my-policies"
          sx={{
            textTransform: "none",
            "&.MuiTab-root": {
              fontSize: "14px",
            },
            "&.Mui-selected": {
              py: "10px",
              px: "12px",
            },
          }}
        />
        <Tab
          label={t("ACCOUNT")}
          value="account"
          sx={{ textTransform: "none" }}
        />
      </Tabs>
    </Box>
  );
}

function HeaderRightSection() {
  const { isMobile, isTab } = useMedia();
  const { t } = useT();
  const dispatch = useContext(PolicyholderDispatchContext);
  const userProfile = useUserProfile();
  const navigate = useNavigate();
  const logoutMutation = useMutation({
    mutationFn: () => AuthService.signOutAPI(),
    onSuccess: () => {
      userProfile.refetch();
    },
  });

  if (isMobile || isTab) {
    return (
      <Box
        onClick={() => dispatch({ type: "toggleSideMenu", payload: true })}
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <SegmentIcon color="primary" fontSize="medium" />
      </Box>
    );
  }

  const onLogout = () => {
    if (IS_ONCE_PH_PORTAL) {
      removeAllTokens()
      navigate(PolicyholderPathnames.V2Login)
    } else {
      logoutMutation.mutate()
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "20px",
      }}
    >
      <NavigationTabs />
      <LanguagePopover />
      <LoadingButton
        color="primary"
        variant="contained"
        onClick={onLogout}
        loading={logoutMutation.isPending}
      >
        {t("LOGOUT")}
      </LoadingButton>
    </Box>
  );
}

export function AppHeader() {
  return (
    <AppContentContainer
      sx={{
        display: "flex",
        justifyContent: "space-between",
        bgcolor: "Background",
      }}
    >
      <AppLogo style={{ fill: "#333" }} hideName={IS_ONCE_PH_PORTAL} />
      <HeaderRightSection />
    </AppContentContainer>
  );
}
