import { TextField } from "@mui/material";
import Box from "@mui/material/Box";
import FiltersContainer from "components/common/FiltersContainer";
import { createContext, useState } from "react";
import { useT } from "translation";

const agentsStatusFilters: SelectOption[] = [
  { label: "Active", value: "active" },
  { label: "Inactive", value: "inactive" },
];
const initialAgentFilters = { first_name: "", email: "" };
export const AgentFilterCtx = createContext<Record<string, string>>({});

function AgentsFilterProvider({
  hideStatusFilter,
  hideNameFilter,
  children,
}: {
  hideStatusFilter: boolean;
  hideNameFilter: boolean;
  children: React.ReactNode;
}) {
  const { t } = useT();
  const [agentFilters, setAgentFilters] = useState<FilterParams>({
    ...initialAgentFilters,
  });
  const inputChange = (value: string, key: string) => {
    setAgentFilters({
      ...agentFilters,
      [key]: value,
    });
  };

  const onApply = (filterData: Filter[]) => {
    const obj = { ...agentFilters };
    filterData.forEach((f) => {
      if (f.options.every((o) => o.isSelected)) {
        if (initialAgentFilters[f.id]) {
          obj[f.id] = initialAgentFilters[f.id];
        } else {
          delete obj[f.id];
        }
      } else {
        obj[f.id] = f.options.filter((o) => o.isSelected).map((so) => so.value);
      }
    });
    setAgentFilters(obj);
  };

  const onClear = (filterData: Filter[]) => {
    setAgentFilters(initialAgentFilters);
  };

  return (
    <>
      <Box marginTop={"16px"}>
        <Box width={"100%"}>
          <FiltersContainer
            title={null}
            key="agent-filter"
            filters={
              hideStatusFilter
                ? []
                : [
                    {
                      id: "status",
                      title: t("AGENTS_PAGE.status"),
                      options: agentsStatusFilters,
                    },
                  ]
            }
            onApply={onApply}
            onClear={onClear}
            extraFilters={
              <Box sx={{ display: "flex", gap: "16px" }}>
                {!hideNameFilter && (
                  <TextField
                    type="name"
                    label={t("FIRST_NAME")}
                    value={agentFilters.first_name}
                    style={{
                      width: "40%",
                      borderRadius: "10px",
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "12px",
                      },
                    }}
                    onChange={(e) => inputChange(e.target.value, "first_name")}
                  />
                )}
                <TextField
                  value={agentFilters.email}
                  type="email"
                  label={t("EMAIL_LABEL")}
                  name="email"
                  style={{
                    width: !hideNameFilter ? "40%" : "100%",
                    borderRadius: "10px",
                  }}
                  InputProps={{
                    style: {
                      borderRadius: "12px",
                    },
                  }}
                  onChange={(e) => inputChange(e.target.value, "email")}
                />
              </Box>
            }
          />
        </Box>
      </Box>
      <AgentFilterCtx.Provider value={agentFilters}>
        {children}
      </AgentFilterCtx.Provider>
    </>
  );
}

export default AgentsFilterProvider;
