import { Box, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

import { customStylesSelector } from "store/selectors/selectors";

interface OutletHeaderProps {
  title: string;
  subTitle?: string;
  actionBtn?: React.ReactNode;
}

const OutletHeader: React.FC<OutletHeaderProps> = ({
  title,
  subTitle,
  actionBtn,
}) => {
  const customStyles = useSelector(customStylesSelector);
  return (
    <Box
      sx={{
        mb: "20px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography
          sx={{
            color: customStyles.muiTheme.palette.text.primary,
            fontSize: "24px",
            fontWeight: 600,
            mb: "16px",
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            color: customStyles.muiTheme.palette.text.secondary,
            fontSize: "16px",
            fontWeight: 500,
            mb: "5px",
          }}
        >
          {subTitle ?? ""}
        </Typography>
      </Box>
      <Box>{actionBtn}</Box>
    </Box>
  );
};

export default OutletHeader;
