import { all } from "redux-saga/effects";
import clientSaga from "./clientSaga";
import surveyJSSaga from "./surveyJSSaga";
import webhooksSaga from "./webhooksSaga";
import authSaga from "./authSaga";
import quotesSaga from "./quotesSaga";

export default function* rootSaga() {
  yield all([
    clientSaga(),
    surveyJSSaga(),
    webhooksSaga(),
    authSaga(),
    quotesSaga(),
  ]);
}
