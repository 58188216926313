import { useContext, useState } from "react";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";
import LogoutIcon from "@mui/icons-material/Logout";
import { LoadingButton } from "@mui/lab";
import { Box, Divider, Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useLocation, useNavigate } from "react-router";

import { AppLogo } from "components/policyholderComponents/icons/AppLogo";
import { useUserProfile } from "hooks/useUserProfile";
import { AuthService } from "policyholderApi/auth/auth";
import { AvailableLanguages, useSetTranslationLang, useT } from "translation";

import "./style.css";
import {
  PolicyholderContext,
  PolicyholderDispatchContext,
} from "contexts/PolicyholderContext";

type SideMenuHeaderProps = {
  onClose: () => void;
};

export function SideMenuHeader({ onClose }: SideMenuHeaderProps) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        py: { xs: "16px", sm: "16px", md: "24px", lg: "24px" },
        px: { xs: "16px", sm: "16px", md: "120px", lg: "240px" },
        color: "primary.contrastText",
      }}
    >
      <AppLogo style={{ fill: "#FAFAFA" }} />
      <Box
        onClick={onClose}
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <CloseIcon fontSize="medium" />
      </Box>
    </Box>
  );
}

type SideMenuContentProps = {
  onClose: () => void;
  onChooseLanguageClick: (value: boolean) => void;
};

export function SideMenuContent({
  onClose,
  onChooseLanguageClick,
}: SideMenuContentProps) {
  const { t } = useT();
  const navigate = useNavigate();
  const location = useLocation();
  const userProfile = useUserProfile();

  const logoutMutation = useMutation({
    mutationFn: () => AuthService.signOutAPI(),
    onSuccess: () => {
      userProfile.refetch();
    },
  });

  const onNavigationClick = (pathname: string) => {
    navigate(pathname);
    onClose();
  };

  const onLogout = () => logoutMutation.mutate();

  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        py: "24px",
        px: "16px",
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <Box
          sx={{
            p: "12px",
            borderRadius: "8px",
            bgcolor: location.pathname === "/app/my-policies" ? "#2D4F71" : "",
          }}
          onClick={() => onNavigationClick("my-policies")}
        >
          <Typography
            variant="subtitle1"
            sx={{
              color: "primary.contrastText",
            }}
          >
            {t("MY_POLICIES")}
          </Typography>
        </Box>
        <Box
          sx={{
            p: "12px",
            borderRadius: "8px",
            bgcolor: location.pathname === "/app/account" ? "#2D4F71" : "",
          }}
          onClick={() => onNavigationClick("account")}
        >
          <Typography
            variant="subtitle1"
            sx={{
              color: "primary.contrastText",
            }}
          >
            {t("ACCOUNT")}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            p: "12px",
            borderRadius: "8px",
          }}
          onClick={() => onChooseLanguageClick(true)}
        >
          <Typography
            variant="subtitle1"
            sx={{
              color: "primary.contrastText",
            }}
          >
            {t("CHOOSE_LANGUAGE")}
          </Typography>
          <ChevronRightIcon
            fontSize="medium"
            sx={{ color: "primary.contrastText" }}
          />
        </Box>
      </Box>
      <LoadingButton
        fullWidth
        variant="contained"
        color="secondary"
        onClick={onLogout}
        loading={logoutMutation.isPending}
        startIcon={<LogoutIcon />}
      >
        {t("LOGOUT")}
      </LoadingButton>
    </Box>
  );
}

type ChooseLanguageHeaderProps = {
  onClose: () => void;
};

export function ChooseLanguageHeader({ onClose }: ChooseLanguageHeaderProps) {
  const { t } = useT();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        p: "16px",
        color: "primary.contrastText",
        gap: "12px",
      }}
    >
      <Box
        onClick={onClose}
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <ChevronLeftIcon fontSize="medium" />
      </Box>
      <Typography sx={{ color: "primary.contrastText" }}>
        {t("CHOOSE_LANGUAGE")}
      </Typography>
    </Box>
  );
}

type ChooseLanguageContentProps = {
  onClose: () => void;
};

function ChooseLanguageContent({ onClose }: ChooseLanguageContentProps) {
  const { lang } = useT();
  const setTranslationLang = useSetTranslationLang();
  const onLanguageSelect = (language: AvailableLanguages) => {
    setTranslationLang(language);
    onClose();
  };

  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        py: "24px",
        px: "16px",
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <Box
          sx={{
            p: "12px",
            borderRadius: "8px",
            bgcolor: lang === "en" ? "#2D4F71" : "",
          }}
          onClick={() => onLanguageSelect("en")}
        >
          <Typography
            variant="subtitle1"
            sx={{
              color: "primary.contrastText",
            }}
          >
            English
          </Typography>
        </Box>
        <Box
          sx={{
            p: "12px",
            borderRadius: "8px",
            bgcolor: lang === "pt" ? "#2D4F71" : "",
          }}
          onClick={() => onLanguageSelect("pt")}
        >
          <Typography
            variant="subtitle1"
            sx={{
              color: "primary.contrastText",
            }}
          >
            Portuguese
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export function SideMenu() {
  const dispatch = useContext(PolicyholderDispatchContext);
  const { showSideMenu } = useContext(PolicyholderContext);
  const [openChooseLanguageScreen, setOpenChooseLanguageScreen] =
    useState(false);

  const onClose = () => dispatch({ type: "toggleSideMenu", payload: false });
  const closeLanguageScreen = () => setOpenChooseLanguageScreen(false);

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column" }}
      className={`sidemenu ${showSideMenu ? "" : "closed"}`}
      bgcolor="primary.main"
      height="100%"
      width="100%"
      position="absolute"
    >
      {openChooseLanguageScreen ? (
        <ChooseLanguageHeader onClose={closeLanguageScreen} />
      ) : (
        <SideMenuHeader onClose={onClose} />
      )}
      <Divider sx={{ bgcolor: "secondary.main" }} />
      {openChooseLanguageScreen ? (
        <ChooseLanguageContent onClose={closeLanguageScreen} />
      ) : (
        <SideMenuContent
          onClose={onClose}
          onChooseLanguageClick={setOpenChooseLanguageScreen}
        />
      )}
    </Box>
  );
}
