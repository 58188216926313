import { Box, useTheme } from "@mui/material";
import { ChartConfiguration, ChartData } from "chart.js";
import Chart from "chart.js/auto";
import { portalFontFamilyNameSelector } from "portalConfig/selectors";
import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";


interface ChartComponentProps {
  data: HistogramData;
}

const ChartComponent: React.FC<ChartComponentProps> = ({ data }) => {
  const theme = useTheme();
  const chartInstance = useRef<Chart | null>(null);
  const fontFamily = useSelector(portalFontFamilyNameSelector);

  useEffect(() => {
    if (data?.labels.length > 0) {
      const ctx = document.getElementById("myChart") as HTMLCanvasElement;
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }

      const chartData: ChartData = {
        labels: data?.labels,
        datasets: [
          {
            data: data?.data,
            backgroundColor: theme?.palette?.primary?.main,
          },
        ],
      };
      const config: ChartConfiguration = {
        type: "bar",
        data: chartData,
        options: {
          scales: {
            x: {
              ticks: {
                font: {
                  family: fontFamily,
                  size: 12,
                },
              },
              grid: {
                display: false,
              },
            },
            y: {
              ticks: {
                font: {
                  family: fontFamily,
                  size: 12,
                },
              },
              grid: {
                display: false,
              },
            },
          },
          responsive: true,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              titleFont: {
                family: fontFamily,
              },
              bodyFont: {
                family: fontFamily,
              },
            },
          },
        },
      };
      chartInstance.current = new Chart(ctx, config);
    }
  }, [data]);

  return (
    <Box>
      <canvas id="myChart" />
    </Box>
  );
};

export default ChartComponent;
