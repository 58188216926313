import { Box, Theme, ThemeProvider } from "@mui/material";
import { Suspense, useEffect, useReducer, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, RouterProvider } from "react-router-dom";

import { fetchFactoryFunctionsAPI } from "api/siaApplication";

import GlobalLoader from "components/loader/GlobalLoader";
import CircularProgressLoader from "components/loader/Loader";
import {
  IS_HOSTED_INSURANCE,
  IS_POLICYHOLDER_PORTAL,
  PORTAL_NAME,
} from "config";
import {
  fetchAvailableProducts,
  fetchClientFeatures,
  saveClientSentInfo,
  saveNoQuoteText,
  setClientUrl,
  setSectionHeaderInfoConfig,
  setShowPaymentSummaryPage,
  setSimulateErrorConfig,
} from "store/features/clientSlice";
import { setLoaderText, updateInitialLoader } from "store/features/loaderSlice";
import { setCurentStep } from "store/features/stepperSlice";
import { setCustomStyles } from "store/features/stylesSlice";
import { setScriptRef } from "store/features/surveyJsSlice";
import { customStylesSelector } from "store/selectors/selectors";
import { RootState } from "store/store";
import { baseMuiTheme, getTheme, setMuiTheme } from "theme";
import { useT } from "translation";

import { sendMessageToClient } from "utils/utils";

import "./App.css";

import AppRoutes from "AppRoutes";
import ErrorComponent from "components/common/ErrorComponent/ErrorComponent";
import {
  PolicyholderContext,
  PolicyholderDispatchContext,
} from "contexts/PolicyholderContext";
import flagsmith from "flagsmith";
import { FlagsmithProvider } from "flagsmith/react";
import { policyholderRouter } from "policyholderRouter";
import { portalConfigSelector } from "portalConfig/selectors";
import useSetupPortalConfig from "portalConfig/useSetupPortalConfig";
import { policyholderReducer } from "reducers/policyholderReducer";
import { initialPolicyholderState } from "states/policyholderState";
import { setIsFlagSmithLoading } from "store/features/flagSmithSlice";

function App() {
  const { t } = useT();
  const dispatch = useDispatch();
  const [theme, setTheme] = useState<Theme>(baseMuiTheme);
  const customStyles = useSelector(customStylesSelector);
  const [policyholderState, policyholderDispatch] = useReducer(
    policyholderReducer,
    initialPolicyholderState
  );
  useSetupPortalConfig({
    clientName: PORTAL_NAME,
  });
  const {
    data: config,
    loading,
    error: configError,
  } = useSelector(portalConfigSelector);

  const { clientAPIKey, productSelection } = useSelector((state: RootState) => {
    return state.client;
  });

  // Pre-fetching all factory functions and adding them as a script for the Application step.
  const script = useRef(document.createElement("script"));
  const registerFactoryFunctions = async () => {
    try {
      const factoryFunctions = await fetchFactoryFunctionsAPI(clientAPIKey);
      script.current.text = factoryFunctions?.data;
      document.head.appendChild(script.current);
      localStorage.setItem("scriptLoaded", "true");
      localStorage.removeItem("scriptCallMade");
      dispatch(setScriptRef(script));
    } catch (error) {
      console.error(error);
    }
  };

  // Setting css root values for the SurveyJS component/form
  const changeCSSRootValues = () => {
    const root = document.documentElement;
    root.style.setProperty("--default-background", theme.palette.primary.main);
    root.style.setProperty("--font-family", theme.typography.fontFamily);
    root.style.setProperty("--input-label-color", customStyles?.input?.color);
    root.style.setProperty(
      "--input-box-bg-color",
      customStyles?.input?.backgroundColor
    );
    root.style.setProperty("--input-text-color", customStyles?.input?.color);
    root.style.setProperty(
      "--input-focus-border-color",
      customStyles?.input?.active?.borderColor
    );
    root.style.setProperty(
      "--input-border-color",
      customStyles?.input?.borderColor || "transparent"
    );
    root.style.setProperty(
      "--input-hover-border-color",
      customStyles?.input?.hover?.borderColor
    );
    root.style.setProperty(
      "--animation-color",
      customStyles.muiTheme.palette.primary.main
    );
  };

  // Method that receives client data/styles and applies it to the app
  const setClientReceivedStyles = (event: { origin: string; data: any }) => {
    const data = event.data;

    if (data.auth) {
      dispatch(saveClientSentInfo(data.auth));

      if (data.clientUrl) {
        dispatch(setClientUrl(data.clientUrl));
      }

      if (data.data) {
        localStorage.setItem("clientData", JSON.stringify(data.data));
      }

      if (data.sectionHeaderInfo) {
        dispatch(setSectionHeaderInfoConfig(data.sectionHeaderInfo));
      }

      if (data.showPaymentSummaryPage === false) {
        dispatch(setShowPaymentSummaryPage(false));
      }

      if (data.simulateError) {
        dispatch(setSimulateErrorConfig(data.simulateError));
      }
    }

    if (data.noQuoteText) {
      dispatch(saveNoQuoteText(data.noQuoteText));
    }

    if (data.styles) {
      setMuiTheme(data.styles);
      const { appTheme, ...finalTheme } = getTheme();
      dispatch(setCustomStyles(finalTheme));
      setTheme(appTheme);
      // to hide globalLoader screen for iframe app
      setTimeout(() => {
        dispatch(updateInitialLoader(false));
        sendMessageToClient(
          { type: "SHOW_IFRAME", details: {} },
          data.clientUrl
        );
      }, 2000);
    }
  };

  useEffect(() => {
    localStorage.removeItem("scriptCallMade");
    localStorage.removeItem("scriptLoaded");
    localStorage.removeItem("clientData");
    localStorage.removeItem("prefillData");

    if (!window?.location?.href?.includes("/verify-esign")) {
      sessionStorage.removeItem("policyId");
      sessionStorage.removeItem("envelopId");
      sessionStorage.removeItem("clientAPIKey");
      sessionStorage.removeItem("docusignUrl");
    }

    const receiveMessage = setClientReceivedStyles;
    window.addEventListener("message", receiveMessage);

    return () => {
      window.removeEventListener("message", receiveMessage);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    changeCSSRootValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theme]);

  // Useeffect that fetches all available products for a client as soon as the clientAPIKey has been received
  useEffect(() => {
    if (
      clientAPIKey &&
      !window?.location?.href?.includes("/application/payment?")
    ) {
      dispatch(setLoaderText(t("FETCHING_PRODUCTS")));
      dispatch(fetchAvailableProducts());
      if (IS_HOSTED_INSURANCE) {
        dispatch(fetchClientFeatures());
      }

      // Attaching factory functions script
      localStorage.setItem("scriptCallMade", "true");
      registerFactoryFunctions();
    }
    // to hide globalLoader screen for portal app
    setTimeout(() => {
      if (!clientAPIKey) {
        dispatch(updateInitialLoader(false));
      }
    }, 2000);
    // redirect to applications section if client has sent products
    if (clientAPIKey && productSelection?.length) {
      dispatch(setCurentStep(1));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientAPIKey]);

  useEffect(() => {
    if (config?.styles && !IS_HOSTED_INSURANCE) {
      setMuiTheme(config.styles);
      const { appTheme, ...finalTheme } = getTheme();
      dispatch(setCustomStyles(finalTheme));
      setTheme(appTheme);
    }
  }, [config?.styles, dispatch]);

  useEffect(() => {
    const initializeFlagsmith = async () => {
      try {
        await flagsmith.init({
          environmentID: process.env.REACT_APP_FLAGSMITH_ENV_ID,
        });
        await flagsmith.getFlags();
      } catch (error) {
        console.error("Error initializing Flagsmith:", error);
      } finally {
        dispatch(setIsFlagSmithLoading(false));
      }
    };

    dispatch(setIsFlagSmithLoading(true));
    initializeFlagsmith();
  }, [dispatch]);

  const isConfigLoading = loading && !IS_HOSTED_INSURANCE;
  const isConfigError =
    !isConfigLoading && Boolean(configError) && !IS_HOSTED_INSURANCE;

  if (isConfigError) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <ErrorComponent />
      </Box>
    );
  }
  return (
    <Suspense fallback={<CircularProgressLoader />}>
      <ThemeProvider theme={theme}>
        {!window?.location?.href?.includes("/application/payment?") && (
          <GlobalLoader />
        )}
        {IS_POLICYHOLDER_PORTAL ? (
          isConfigLoading ? (
            <GlobalLoader />
          ) : (
            <PolicyholderContext.Provider value={policyholderState}>
              <PolicyholderDispatchContext.Provider
                value={policyholderDispatch}
              >
                <RouterProvider router={policyholderRouter} />
              </PolicyholderDispatchContext.Provider>
            </PolicyholderContext.Provider>
          )
        ) : (
          <FlagsmithProvider flagsmith={flagsmith}>
            <BrowserRouter>
              <AppRoutes />
            </BrowserRouter>
          </FlagsmithProvider>
        )}
      </ThemeProvider>
    </Suspense>
  );
}

export default App;
