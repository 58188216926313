import { Box } from "@mui/material";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { IconLogout, IconSettings, IconUser } from "@tabler/icons-react";
import PopupModal from "components/common/PopupModal/PopupModal";
import AnimatedButton from "components/custom/AnimatedButton";
import { useFlags } from "flagsmith/react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  setActiveAccountV2Login,
  setAllAccountsV2Login,
  signOut,
} from "store/features/authSlice";
import { authSelector, customStylesSelector } from "store/selectors/selectors";
import { useT } from "translation";
import { LSKeys, deleteFromLocalStorage } from "utils/ls.utils";

const UserPopover = () => {
  const { t } = useT();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const customStyles = useSelector(customStylesSelector);
  const { portalAccount } = useSelector(authSelector);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const {
    v2_feature_set: { enabled: isV2FeatureEnabled },
  } = useFlags(["v2_feature_set"]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSave = () => {
    if (isV2FeatureEnabled) {
      deleteFromLocalStorage(LSKeys.ACCESS_TOKEN);
      dispatch(setActiveAccountV2Login(null));
      dispatch(setAllAccountsV2Login([]));
      navigate("/v2/login");
    } else {
      dispatch(signOut());
      navigate("/login");
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "user-popover" : undefined;

  return (
    <>
      <Box>
        <AnimatedButton
          aria-describedby={id}
          variant="contained"
          onClick={handleClick}
          sx={{
            minWidth: "fit-content",
            padding: "8px",
            borderRadius: "50%",
          }}
        >
          <IconUser stroke={1.5} size="1.5rem" color="white" />
        </AnimatedButton>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          sx={{
            mt: "10px",
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Box
            sx={{
              p: "20px",
              minWidth: "200px",
              display: "flex",
              flexDirection: "column",
              gap: "15px",
            }}
          >
            <Typography
              sx={{
                color: customStyles.muiTheme.palette.text.primary,
                fontSize: "18px",
                fontWeight: 600,
                ml: "8px",
              }}
            >
              {`${portalAccount?.user?.first_name} ${portalAccount?.user?.last_name}`}
            </Typography>
            <AnimatedButton
              sx={{ textTransform: "none", justifyContent: "flex-start" }}
              onClick={() => navigate("/home/settings")}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "15px",
                }}
              >
                <IconSettings stroke={1.5} size="1.5rem" color="grey" />
                <Typography
                  sx={{
                    color: "grey",
                    fontSize: "14px",
                    fontWeight: 500,
                  }}
                >
                  {t("SETTINGS")}
                </Typography>
              </Box>
            </AnimatedButton>
            <AnimatedButton
              sx={{ textTransform: "none", justifyContent: "flex-start" }}
              onClick={handleOpenModal}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "15px",
                }}
              >
                <IconLogout stroke={1.5} size="1.5rem" color="grey" />
                <Typography
                  sx={{
                    color: "grey",
                    fontSize: "14px",
                    fontWeight: 500,
                  }}
                >
                  {t("LOGOUT")}
                </Typography>
              </Box>
            </AnimatedButton>
          </Box>
        </Popover>
      </Box>
      <PopupModal
        open={isModalOpen}
        title={t("POP_UPS.LOGOUT_POPUP.title")}
        content={t("POP_UPS.LOGOUT_POPUP.content")}
        btnText={t("POP_UPS.LOGOUT_POPUP.btnText")}
        onClose={handleCloseModal}
        onSave={handleSave}
      />
    </>
  );
};

export default UserPopover;
