import { PolicyholderState } from "states/policyholderState";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const policyholderReducer = (
  policyholderState: PolicyholderState,
  action: any
) => {
  switch (action.type) {
    case "toggleSideMenu": {
      return { ...policyholderState, showSideMenu: action.payload };
    }

    default: {
      throw Error(`Unknown action: ${action?.type}`);
    }
  }
};
