import { SVGProps } from 'react';

export function DocumentSvg(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={22}
      fill="none"
      {...props}>
      <path
        fill={props?.fill}
        d="M10.109 7.8H2.527c-.164 0-.297.143-.297.321 0 .178.133.323.297.323h7.582c.163 0 .296-.145.296-.323 0-.178-.133-.322-.296-.322ZM10.109 10.063H2.527c-.164 0-.297.145-.297.323 0 .178.133.322.297.322h7.582c.163 0 .296-.144.296-.322 0-.178-.133-.322-.296-.322ZM10.109 12.327H2.527c-.164 0-.297.145-.297.323 0 .178.133.322.297.322h7.582c.163 0 .296-.144.296-.322 0-.178-.133-.323-.296-.323ZM10.405 14.914c0-.178-.133-.322-.296-.322H2.527c-.164 0-.297.144-.297.322 0 .178.133.322.297.322h7.582c.163 0 .296-.144.296-.322ZM2.527 17.91c-.164 0-.297.144-.297.322 0 .178.133.323.297.323h3.8c.164 0 .297-.145.297-.323 0-.178-.133-.322-.297-.322h-3.8ZM13.522 3.99V2.944c0-.398-.298-.722-.664-.722h-6.08c-.367 0-.665.324-.665.722V3.99c0 .398.298.722.664.722h.514c.163 0 .296-.144.296-.322 0-.178-.133-.322-.296-.322h-.514c-.04 0-.071-.035-.071-.078V2.944c0-.043.032-.078.071-.078h6.08c.04 0 .073.035.073.078V3.99c0 .043-.033.078-.072.078H8.675c-.163 0-.296.144-.296.322 0 .178.133.322.296.322h4.183c.366 0 .664-.324.664-.722Z"
      />
      <path
        fill={props?.fill}
        d="M15.737 19.65c-.164 0-.296.144-.296.322v.434c0 .523-.392.95-.873.95H1.465c-.481 0-.873-.427-.873-.95V4.916c0-.119.107-.204.227-.204h2.49c.564 0 1.022-.498 1.022-1.11V.856c0-.111.077-.213.188-.213h10.049c.481 0 .873.427.873.95v4.29c0 .178.132.323.296.323.164 0 .296-.145.296-.323v-4.29C16.033.715 15.376 0 14.568 0H4.519c-.391 0-.76.166-1.036.467L.429 3.789C.152 4.091 0 4.491 0 4.917v15.489C0 21.285.657 22 1.465 22h13.103c.808 0 1.465-.715 1.465-1.594v-.434c0-.178-.132-.323-.296-.323ZM2.294 2.671c.513-.558 1.445-.195 1.445.563v.366c0 .257-.193.467-.43.467h-.403a.832.832 0 0 1-.612-1.396Z"
      />
      <path
        fill={props?.fill}
        d="M19.794 11.515c.208-.332.262-.741.148-1.123a1.237 1.237 0 0 0-.727-.815.638.638 0 0 1-.392-.586 1.316 1.316 0 0 0-.487-1.004 1.1 1.1 0 0 0-1.028-.194.562.562 0 0 1-.634-.224 1.145 1.145 0 0 0-.937-.5c-.369 0-.71.182-.936.5a.562.562 0 0 1-.634.224 1.1 1.1 0 0 0-1.028.194 1.316 1.316 0 0 0-.487 1.004.638.638 0 0 1-.392.586 1.237 1.237 0 0 0-.727.815c-.114.382-.06.792.148 1.123a.687.687 0 0 1 0 .726 1.375 1.375 0 0 0-.148 1.123c.114.382.38.68.727.815.23.09.384.32.392.587.01.369.165.716.426.952.286.258.596.57.596.955v2.264c0 .247.123.476.322.597.198.12.442.115.636-.015l1.087-.727c.017-.012.028-.007.038 0l1.086.727c.194.13.437.136.636.015a.697.697 0 0 0 .322-.597v-2.264c0-.385.31-.697.596-.955.26-.236.416-.583.427-.952a.638.638 0 0 1 .391-.587c.348-.136.613-.433.727-.815.114-.382.06-.791-.148-1.123a.687.687 0 0 1 0-.726Zm-2.64 7.456-1.087-.728a.586.586 0 0 0-.66 0l-1.085.728c-.025.016-.056-.003-.056-.034V16.56c0-.353.33-.66.535-.372.226.317.567.5.937.5.369 0 .71-.183.936-.5.205-.288.534.019.534.372v2.377c0 .031-.03.05-.055.034Zm2.15-6.367c.104.166.131.37.074.561a.619.619 0 0 1-.363.407c-.461.18-.769.641-.784 1.174a.657.657 0 0 1-.213.476.554.554 0 0 1-.544.123c-.47-.15-.968.027-1.268.448a.573.573 0 0 1-.469.25.573.573 0 0 1-.468-.25 1.146 1.146 0 0 0-.933-.5c-.111 0-.224.017-.335.052a.553.553 0 0 1-.544-.123.657.657 0 0 1-.214-.476c-.014-.533-.322-.994-.783-1.174a.618.618 0 0 1-.363-.407.687.687 0 0 1 .073-.561c.276-.441.276-1.01 0-1.451a.687.687 0 0 1-.073-.562c.057-.19.189-.34.363-.407.461-.18.769-.641.783-1.174a.657.657 0 0 1 .244-.501.55.55 0 0 1 .514-.098c.47.15.968-.026 1.268-.448a.573.573 0 0 1 .468-.25c.185 0 .356.091.469.25.3.422.797.598 1.268.448a.55.55 0 0 1 .514.097c.149.118.238.301.243.502.015.533.323.994.784 1.174a.619.619 0 0 1 .363.407c.057.19.03.396-.074.562a1.375 1.375 0 0 0 0 1.45Z"
      />
      <path
        fill={props?.fill}
        d="M15.738 9.177c-1.369 0-2.482 1.212-2.482 2.701 0 1.49 1.113 2.7 2.482 2.7 1.369 0 2.482-1.21 2.482-2.7 0-1.49-1.113-2.7-2.482-2.7Zm0 4.757c-1.042 0-1.89-.922-1.89-2.056s.848-2.056 1.89-2.056c1.042 0 1.89.922 1.89 2.056s-.848 2.056-1.89 2.056Z"
      />
    </svg>
  );
}
